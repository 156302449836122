@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 119px;
  height: 20px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.button-base {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;

  border: none;
  background: none;

  width: 119px;
  height: 20px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.button-text {
  width: 119px;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;

  color: #6941c6;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.update-password-modal {
  padding: 0px;

  display: block !important;
  max-height: 100%;
  // overflow-y: auto;

  background: rgba(52, 64, 84, 0.6);

  backdrop-filter: blur(16px);
}

.update-password-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 32px;

  width: 400px;
  // height: 462px;

  background: #ffffff;

  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1),
    0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  border-radius: 12px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;

  width: 352px;
  // height: 282px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.modal-content-set-email {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 5px;

  width: 352px;
  // height: 282px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.modal-text-and-supporting-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;

  width: 352px;
  // height: 76px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.modal-text {
  width: 352px;
  height: 28px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;

  margin: 0;

  color: #101828;
}

.modal-supporting-text {
  width: 352px;
  height: 40px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  margin: 0;

  color: #667085;
}

.frame1030-input-field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 352px;
  // height: 96px; (if it has error message underneath)
  // height: 70px;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.frame1030-label {
  width: 121px;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #344054;
}

.frame1030-password-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;

  width: 352px;
  height: 44px;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.frame1030-verify-input-field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 6px;

  width: 352px;
  // height: 70px;

  flex: none;
  order: 2;
  flex-grow: 0;
}

.frame1030-verify-label {
  width: 352px;
  height: 70px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #344054;
}

.frame1030-verify-password-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;

  width: 352px;
  height: 44px;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  position: absolute;
  width: 352px;
  height: 20px;
  left: 0px;
  top: 82px;
}

.error-message {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  margin: 0;
  margin-top: 24px;
  color: #ff4d4f;
}

.forgot-button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 139px;
  height: 20px;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.forgot-button-base {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 139px;
  height: 20px;

  border: none;
  background: none;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.forgot-button-text {
  width: 139px;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  margin: 0;

  color: #6941c6;
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  width: 352px;
  // height: 44px;

  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}

.cancel-button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 170px;
  height: 44px;

  border-radius: 8px;

  flex: none;
  order: 0;
  flex-grow: 1;
}

.cancel-button-base {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 170px;
  height: 44px;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.cancel-button-text {
  // width: 53px;
  height: 24px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  margin: 0;

  color: #344054;
}

.next-button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 170px;
  height: 44px;

  border-radius: 8px;

  flex: none;
  order: 1;
  flex-grow: 1;
}

.next-button-base {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 170px;
  height: 44px;

  background: #7f56d9;

  border: 1px solid #7f56d9;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  flex: none;
  order: 0;
}

.next-button-text {
  // width: 36px;
  height: 24px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  margin: 0;

  color: #ffffff;
}

.stepper-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;

  width: 343px;
  // height: 24px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.step-icon-base {
  width: 32px;
  height: 32px;
  position: relative;

  background: #f9f5ff;
  box-shadow: 0px 0px 0px 4px #f4ebff;
  border-radius: 16px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.selected-icon-content {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;

  border: 1.5px solid #7f56d9;

  background: #f9f5ff;
  border-radius: 16px;
}

.selected-dot {
  position: absolute;
  width: 10px;
  height: 10px;
  left: calc(50% - 10px / 2);
  top: calc(50% - 10px / 2);
  border-radius: 50%;

  background: #7f56d9;

  z-index: 2;
}

.unselected-line {
  width: 48px;
  height: 2px;

  background: #e4e7ec;
}

.check-step-icon-base {
  position: relative;
  width: 32px;
  height: 32px;

  background: #f9f5ff;
  border-radius: 16px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.checked-icon-content {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;

  border-radius: 16px;
}

.check-icon {
  position: absolute;
  left: 26.46%;
  right: 23.56%;
  top: 29.65%;
  bottom: 26.58%;
}

.hintText {
  width: 352px;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  margin: 0;

  color: #667085;
}

.reset-password-complete-container {
  height: 430px;
  background-color: white;
}
