@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.lawp-operator-stripe-invoices-page-container {
  padding: 20px;

  .date-pickers {
    display: flex;
    gap: 20px;
    justify-content: flex-start;
    align-items: center;
  }

  .save-button {
    display: inline-block !important;
    height: 44px !important;
    left: 0px !important;
    top: 0px !important;
    padding: 5px 16px 5px 16px !important;
    color: white !important;
    font-family: $fontFamily !important;
    @include get-font-weight($key: 'Medium');
    font-size: 14px !important;
    line-height: 20px !important;
    margin-left: 15px !important;
    background-color: #7f56d9 !important;
    text-transform: none !important;
    border-radius: 9px !important;
  }
}
