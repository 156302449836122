.lawp-api-response-modal-confirm-message-modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 512px;
  background-color: #fff;
  padding: 40px;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1),
    0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  border-radius: 12px;

  margin-bottom: 20px;

  .icon-container-success {
    //circle background
    background: #d1fadf;
    border: 10px solid #ecfdf3;
    border-radius: 50%;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

  .icon-container-error {
    //circle background
    background: #fdd3d3;
    border: 10px solid #fde8e8;
    border-radius: 50%;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

  .error-icon {
    color: red;
    font-size: 32px !important;
  }

  .title-text {
    /* Text lg/Medium */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;

    /* identical to box height, or 156% */
    text-align: center;
    margin-bottom: 8px;

    /* Gray/900 */
    color: #101828;
  }

  .text-container {
    margin-bottom: 32px;
  }

  .description-text {
    width: 100%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    text-align: center;
    margin-bottom: 0px;

    /* Gray/500 */

    color: #667085;
  }

  .confirm-botton {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 18px;
    gap: 8px;

    width: 464px;
    height: 44px;

    /* Primary/600 */
    background: #7f56d9;

    /* Primary/600 */
    border: 1px solid #7f56d9;

    /* Shadow/xs */
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;

    /* Text md/Medium */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    /* identical to box height, or 150% */

    /* White */
    color: #ffffff;
  }
}
