@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.lawp-part-of-community-mockup-section-container {
  display: flex;
  flex-direction: row;
  // justify-content: center;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 752px;
  padding: 96px 0px 96px 0px;

  //under 1460px
  @media (max-width: 1480px) {
    flex-direction: column;
    height: 100%;
    padding: 96px 0px 0px 0px;
  }
}

.lawp-part-of-community-mockup-section-left-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 35%;

  //under 1460px
  @media (max-width: 1480px) {
    width: 90%;
  }
}

.lawp-part-of-community-mockup-section-right-container {
  display: flex;
  justify-content: flex-end;
  width: 50%;

  //under 1460px
  @media (max-width: 1460px) {
    justify-content: center;
    margin-top: 64px;
    margin-bottom: 64px;
    width: 90%;
  }
}

.lawp-part-of-community-mockup-section-title {
  /* Display lg/Semibold */
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 60px;

  /* or 125% */
  letter-spacing: -0.02em;

  /* Gray/900 */
  color: #101828;
}

.lawp-part-of-community-mockup-section-description {
  /* Text xl/Normal */
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;

  /* identical to box height, or 150% */

  /* Gray/500 */
  color: #667085;

  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.lawp-part-of-community-mockup-section-buttons-container {
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
}

.lawp-part-of-community-mockup-section-client-button {
  display: inline-block !important;
  height: 45px !important;
  left: 0px !important;
  top: 0px !important;
  border-radius: 8px !important;
  padding: 5px 15px 5px 15px !important;
  color: #344054 !important;
  font-family: $fontFamily !important;
  @include get-font-weight($key: 'Medium');
  font-size: 14px !important;
  line-height: 20px !important;
  background-color: white !important;
  text-transform: none !important;
  border-radius: 9px !important;
  border: 1px solid #d6dae1 !important;
}
.lawp-part-of-community-mockup-section-client-button-a-href {
  color: #344054 !important;
  font-family: $fontFamily !important;
  @include get-font-weight($key: 'Medium');
  font-size: 14px !important;
  line-height: 20px !important;
  text-transform: none !important;
}

.lawp-part-of-community-mockup-section-lawyer-button {
  display: inline-block !important;
  height: 44px !important;
  left: 0px !important;
  top: 0px !important;
  border-radius: 8px !important;
  padding: 5px 16px 5px 16px !important;
  color: white !important;
  font-family: $fontFamily !important;
  @include get-font-weight($key: 'Medium');
  font-size: 14px !important;
  line-height: 20px !important;
  margin-left: 15px !important;
  background-color: #7f56d9 !important;
  text-transform: none !important;
  border-radius: 9px !important;
}

.lawp-part-of-community-mockup-section-lawyer-button-a-href {
  color: white !important;
  font-family: $fontFamily !important;
  @include get-font-weight($key: 'Medium');
  font-size: 14px !important;
  line-height: 20px !important;
  text-transform: none !important;
}

//----

.lawp-part-of-community-mockup-section-gray-background {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  width: 75%;
  max-width: 576px;
  // height: 500px;
  height: 400px;
  background: #f2f4f7;

  @media (max-width: 1480px) {
    width: 100%;
  }

  @media (max-width: 1060px) {
    width: 100%;
    max-width: 1000px;
  }
}

.lawp-part-of-community-mockup-notification-group {
  //   display: block;
  position: relative;
  left: 130px;
  top: -15px;
  z-index: 6;

  @media (max-width: 1060px) {
    left: 250px;
  }
}

.lawp-part-of-community-mockup-section-iphone-mockup-container {
  position: relative;
  top: 0;
  left: 0;
}

.lawp-part-of-community-mockup-section-iphone-mockup {
  position: relative;
  top: 0;
  left: 0;
}

.lawp-part-of-community-mockup-section-iphone-mockup-image {
  position: absolute;
  top: 12px;
  left: 46px;
  width: 408px;
  height: 264px;

  object-fit: cover;
}
