@import "Assets/theme.scss";

$fontFamily: map-get($theme-font, $key: "primaryFontFamily");

$checkboxSize: 16px;
$checkboxRadius: 4px;

*,
*::before,
*::after {
  box-sizing: border-box;
}

.password-reset-complete-box-container {
  height: 382px;
}
.password-reset-complete-header {
  height: 254px;
}
.password-reset-complete-header-icon {
  /* Featured icon */
  display: flex;
  align-items: center;
  justify-content: center;

  width: 56px;
  height: 56px;

  /* Success/100 */

  background: #d1fadf;
  /* Success/50 */

  border: 10px solid #ecfdf3;
  border-radius: 28px;
}
.password-reset-complete-header-text-support {
  height: 174px;
}
.password-reset-complete-header-text {
  height: 114px;
}
.password-reset-complete-header-support {
  height: 48px;
}

