@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$phoneBreakpoint: map-get($screen-width-breakpoints, $key: 'phone');
$tabletBreakpoint: map-get($screen-width-breakpoints, $key: 'tablet');

.lawp-about-us-page-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  padding-top: 100px;
}

.lawp-about-us-page-top-container {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  // background-color: #53389e;
}

.lawp-about-us-page-top-container-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  height: 100%;

  @media (max-width: $phoneBreakpoint) {
    flex-direction: column;
    justify-content: center;
  }
}

.lawp-about-us-page-review-section-only-text {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 96px 0px;
  width: 100%;

  @media (max-width: 1060px) {
    flex-direction: column;
    gap: 64px;
    padding: 64px 32px;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;

    @media (max-width: 1480px) {
      margin-left: 60px;
    }

    @media (max-width: 1060px) {
      width: 95%;
      margin-left: 0px;
      margin-right: 0px;
    }

    .review-text {
      /* Display md/Medium */
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 44px;

      /* or 122% */
      letter-spacing: -0.02em;

      /* Gray/900 */
      color: #101828;
    }

    .name {
      /* Text lg/Medium */
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;

      /* identical to box height, or 156% */

      /* Gray/900 */
      color: gray;
      margin-bottom: 0px;
    }

    .description {
      /* Text md/Normal */
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      /* identical to box height, or 150% */

      /* Gray/500 */
      color: #667085;
    }
  }
}

.lawp-about-us-page-top-container-content-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 45%;

  @media (max-width: $phoneBreakpoint) {
    width: 100%;
  }

  h3 {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #7f56d9;
  }

  h1 {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 60px;
    color: #101828;
  }
}

.lawp-about-us-page-top-container-content-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 45%;

  @media (max-width: $phoneBreakpoint) {
    width: 100%;
  }

  h2 {
    margin-top: 60px;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #667085;
  }
}

.lawp-about-us-page-bottom-container {
  margin-top: 0px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.lawp-about-us-page-bottom-container-content {
  display: flex;
  width: 90%;
  align-items: center;
  flex-direction: column;
}

.lawp-about-us-page-achievements-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-direction: row;
  width: 100%;

  @media (max-width: $tabletBreakpoint) {
    justify-content: space-between;
    width: 75%;
    flex-wrap: wrap;
  }

  @media (max-width: $phoneBreakpoint) {
    width: 100%;
    flex-wrap: wrap;
  }
}

.lawp-about-us-page-our-values-container {
  margin-top: 48px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.lawp-about-us-page-our-values-container-info {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 60%;

  @media (max-width: $phoneBreakpoint) {
    width: 90%;
  }

  h4 {
    /* Text md/Semibold */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;

    /* identical to box height, or 150% */

    /* Primary/700 */
    color: #6941c6;
  }

  h2 {
    /* Display md/Semibold */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    text-align: center;

    /* identical to box height, or 122% */
    letter-spacing: -0.02em;

    /* Gray/900 */
    color: #101828;
  }

  h3 {
    /* Text lg/Normal */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    /* or 156% */

    /* Gray/500 */
    color: #667085;
  }
}

.lawp-about-us-page-values-container {
  margin-top: 64px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: $tabletBreakpoint) {
    width: 100%;
    flex-direction: row;
  }

  @media (max-width: $phoneBreakpoint) {
    flex-wrap: wrap;
  }

  .content {
    margin-top: 50px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: $tabletBreakpoint) {
      flex-direction: row;
    }

    @media (max-width: $phoneBreakpoint) {
      justify-content: center;
      flex-wrap: wrap;
    }
  }
}

.lawp-about-us-page-favorite-lawyers-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  padding-bottom: 96px;
  background-color: #f9fafb;
  width: 100%;

  .lawyer-cards-container {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .lawyer-cards {
    margin-top: 62px;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    flex-wrap: wrap;

    @media (max-width: $tabletBreakpoint) {
      width: 80%;
      justify-content: space-around;
    }

    @media (max-width: $phoneBreakpoint) {
      width: 100%;
      justify-content: space-between;
    }
  }
}

.lawp-about-us-page-review-section {
  width: 90%;
  display: flex;
  flex-direction: row;
  padding: 96px 0px;

  @media (max-width: $tabletBreakpoint) {
    justify-content: center;
  }

  .reviews-container {
    width: 50%;
    min-width: 600px;
    display: flex;
    flex-direction: column;

    .stars {
      margin-bottom: 40px;
    }
    .review-title {
      height: 250px;
      /* Display lg/Medium */
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 48px;
      line-height: 60px;

      /* or 125% */
      letter-spacing: -0.02em;

      /* Gray/900 */
      color: #101828;
    }

    .name {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;

      color: #101828;
      margin-bottom: 0px;
    }
  }

  .bottom-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .lawyer-data-group {
    .review-lawyer {
      display: flex;
      align-items: center;

      .lawyer-image {
        display: flex;
        flex-direction: row;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        margin-right: 13px;
      }

      .lawyer-name {
        font-family: $fontFamily;
        font-size: 14px;
        @include get-font-weight($key: 'Medium');
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
      }

      .lawyer-description {
        font-family: $fontFamily;
        font-size: 14px;
        @include get-font-weight($key: 'Regular');
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #667085;
      }
    }
  }

  .buttons-group {
    display: flex;

    .button {
      margin-left: 32px;
      height: 56px;
      width: 56px;
      border: 1px solid #e4e7ec !important;
    }

    .css-i4bv87-MuiSvgIcon-root {
      font-size: 26px;
    }
  }

  .img-container {
    margin-left: 20px;
    width: 50%;
    height: 496px;
    min-width: 574px;
    display: flex;
    justify-content: flex-end;

    @media (max-width: $tabletBreakpoint) {
      display: none;
    }

    .images-group {
      height: 100%;
    }
  }
}

.lawp-about-us-page-no-lawyer-yet {
  margin-top: 96px;
  padding: 32px 0px;
  margin-bottom: 96px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f9fafb;

  .avatar-group {
    display: flex;
    flex-direction: row;
    height: 60px;
    margin-bottom: 20px;

    .avatar {
      border: 1.5px solid #ffffff;
      border-radius: 200px;
    }

    .avatar-side {
      width: 48px;
      height: 48px;

      margin-top: 7px;
      z-index: 1;
    }

    .avatar-middle {
      margin-left: -15px;
      margin-right: -15px;

      width: 56px;
      height: 56px;
      z-index: 2;
    }
  }

  .text1 {
    /* Text xl/Medium */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;

    margin: 20px 0;

    /* identical to box height, or 150% */
    text-align: center;

    /* Gray/900 */
    color: #101828;
  }

  .text2 {
    margin-top: -10px;
    margin-bottom: 25px;
    /* Text lg/Normal */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;

    margin: -10px 0 25px;

    /* identical to box height, or 156% */
    text-align: center;

    /* Gray/500 */
    color: #667085;
  }

  .button-style {
    display: inline-block !important;
    height: 44px !important;
    left: 0px !important;
    top: 0px !important;
    border-radius: 8px !important;
    padding: 5px 16px 5px 16px !important;
    color: white !important;
    font-family: $fontFamily !important;
    @include get-font-weight($key: 'Medium');
    font-size: 14px !important;
    line-height: 20px !important;
    background-color: #7f56d9 !important;
    text-transform: none !important;
    border-radius: 9px !important;
  }
}
