@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

*,
*::before,
*::after {
  box-sizing: border-box;
}

.form-carousel-container,
.register-client-form-carousel-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 40px 0px;

  position: relative;
  width: 100%;

  background: #ffffff;
}

@media screen and (max-width: 1060px) {
  .form-carousel-container {
    flex-direction: column;
    justify-content: center;
  }

  .register-client-form-carousel-container {
    flex-direction: column;
  }
}

.div1-section {
  background-color: red;
  width: 100%;
  height: 960px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.div1-container {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  flex-grow: 1;
}

.div1-parent {
  width: 360px;
  height: 572px;

  background-color: blue;
}

.div1-child1 {
  width: 100%;
  height: 112px;

  display: flex;
  align-items: flex-start;

  background-color: fuchsia;
}

.div1-child-logo {
  width: 142px;
  height: 32px;

  background-color: purple;
}

.div1-child2 {
  width: 100%;
  height: 460px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 32px;

  background-color: white;
}

.div1-child3 {
  width: 100%;
  height: 104px;

  background-color: sandybrown;
}
.div1-child4 {
  width: 100%;
  height: 272px;

  background-color: hotpink;
}
.div1-child5 {
  width: 100%;
  height: 20px;
  background-color: lightblue;
}

.div1-footer {
  width: 100%;
  height: 96px;
  background-color: black;
}

.div2-section {
  background-color: green;
  width: 100%;
  height: 960px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.div2-container {
  width: 100%;
  height: 570px;
}

@media screen and (max-width: 1060px) {
  .div1-container,
  .div2-container {
    width: 100%;
  }

  .div1-footer {
    height: 50px;
  }
}
