@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.parent {
  width: 100%;

  display: flex;
  flex-direction: row;
  // justify-content: flex-start;
  justify-content: space-around;
}
.parent-modal {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  background: rgba(52, 64, 84, 0.6);
  // /* Background blur/md */
  // z-index: 300;
  // backdrop-filter: blur(80%);
  opacity: 0.5;
}
.gray-margin {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  // width: 35%;
  // width: 32%;
  // height: 960px;
  // height: 937px;
  height: 800px;

  background: #f9fafb;
}
.delete-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  // margin-left: 10%;
  // margin-top: 10%;
  // width: 1000px;
}
.delete-inner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 32px;
  gap: 80px;

  // width: 976px;
  // height: 636px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.delete-frame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 80px;

  // width: 360px;
  // height: 442px;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.delete-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: space-between;
  // padding: 0px;

  gap: 24px;

  // width: 360px;
  width: 100%;
  // height: 442px;
}
.delete-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;

  width: 358px;
  height: 76px;
  // margin-bottom:40%;
}
.delete-parent-featured-icon {
  width: 56px;
  height: 56px;

  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  background: #fee4e2;

  border: 10px solid #fef3f2;
  border-radius: 28px;

  flex: none;
  order: 0;
  flex-grow: 0;
}
.x-container {
  width: 24px;
  height: 24px;

  display: flex;
  justify-content: center;
  align-items: center;

  flex: none;
  order: 1;
  flex-grow: 0;
}
.avatar-label-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;

  width: 210px;
  height: 56px;

  flex: none;
  order: 2;
  flex-grow: 0;
}
.avatar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  object-fit: cover;

  width: 56px;
  height: 56px;
  border-radius: 200px;

  flex: none;
  order: 0;
  flex-grow: 0;
}
.default-avatar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  object-fit: cover;

  height: 56px;
  border-radius: 200px;

  flex: none;
  order: 0;
  flex-grow: 0;
}
.lawyer-name-and-email {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 100%;
  //   width: 238px;
  height: 52px;

  flex: none;
  order: 1;
  flex-grow: 0;
}
.lawyer-name {
  // width: 95px;
  height: 28px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  margin: 0;

  color: #344054;

  flex: none;
  order: 0;
  flex-grow: 0;
}
.lawyer-email {
  width: 100%;
  //   width: 138px;
  height: 24px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0;

  text-decoration-line: underline;

  color: #667085;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.delete-buttons-container {
  display: flex;
  flex-direction: column;
  // align-items: center;
  // padding: 0px;
  grid-gap: 8px;
  gap: 8px;

  // margin-top: -60px;
}
.no-delete-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 24px;

  width: 360px;
  height: 44px;

  border-radius: 12px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}
.button {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 360px;
  height: 44px;

  border-radius: 8px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.button-base {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 360px;
  height: 44px;

  /* Primary/600 */

  background: #7f56d9;
  /* Primary/600 */

  border: 1px solid #7f56d9;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.button-text {
  width: 184px;
  height: 24px;
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 0;

  color: #ffffff;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.delete-account-button {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  // width: 348px;
  // height: 20px;

  gap: 10px;

  flex: none;
  order: 2;
  flex-grow: 0;
}

.delete-account-button-base {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 348px;
  height: 20px;
  border: none;
  background: none;

  flex: none;
  order: 0;
  flex-grow: 0;
}
.delete-account-button-text {
  // width: 320px;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;

  color: #667085;

  flex: none;
  order: 1;
  flex-grow: 0;
}

@media screen and (max-width: 1060px) {
  .parent {
    flex-direction: column;
  }

  .gray-margin {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding-bottom: 50px;
    // gap: 16px;

    width: 100%;
    height: auto;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    background: none;
  }
}
