@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.lawp-delete-account-box-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 32px;

  width: 400px;

  background: #ffffff;

  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1),
    0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  border-radius: 12px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.lawp-delete-account-box-stepper-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;

  width: 343px;
  height: 24px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.lawp-delete-account-box-step-icon-base {
  width: 32px;
  height: 32px;
  position: relative;

  background: #f9f5ff;
  box-shadow: 0px 0px 0px 4px #f4ebff;
  border-radius: 16px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.lawp-delete-account-box-selected-icon-content {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;

  background: #f9f5ff;
  border-radius: 16px;
}

.lawp-delete-account-box-selected-dot {
  position: absolute;
  width: 10px;
  height: 10px;
  left: calc(50% - 10px / 2);
  top: calc(50% - 10px / 2);
  border-radius: 50%;

  background: #7f56d9;

  z-index: 2;
}
.lawp-delete-account-box-unselected-line {
  width: 48px;
  height: 2px;

  background: #e4e7ec;
}

.lawp-delete-account-box-unselected-step-icon-base {
  width: 32px;
  height: 32px;
  position: relative;

  background: #f9fafb;
  border-radius: 16px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.lawp-delete-account-box-unselected-icon-content {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;

  border: 1.5px solid #e4e7ec;

  border-radius: 16px;
}

.lawp-delete-account-box-unselected-dot {
  position: absolute;
  width: 10px;
  height: 10px;
  left: calc(50% - 10px / 2);
  top: calc(50% - 10px / 2);
  border-radius: 50%;

  background: #e4e7ec;
}

.lawp-delete-account-box-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;

  width: 352px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}
.lawp-delete-account-box-content-text-and-support-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;

  width: 352px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}
.lawp-delete-account-box-content-text-container {
  width: 352px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;

  color: #101828;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.lawp-delete-account-box-content-support-container {
  width: 352px;
  height: 20px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #667085;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}
.lawp-delete-account-box-content-alert-container {
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 12px;

  width: 343px;

  background: #fffbfa;

  border: 1px solid #fda29b;
  border-radius: 8px;

  flex: none;
  order: 2;
  flex-grow: 0;
}
.lawp-delete-account-box-content-alert-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  width: 311px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.lawp-delete-account-box-content-alert-content-badge-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 10px 4px 4px;
  gap: 8px;

  width: 274px;
  height: 30px;

  background: #fef3f2;
  mix-blend-mode: multiply;
  border-radius: 16px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.lawp-delete-account-box-content-alert-content-badge-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 65px;
  height: 22px;

  mix-blend-mode: normal;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.lawp-delete-account-box-content-alert-content-badge {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;

  width: 65px;
  height: 22px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */

  text-align: center;

  /* Error/700 */

  color: #b42318;

  background: #ffffff;
  border-radius: 16px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.lawp-delete-account-box-content-alert-content-badge-message {
  width: 187px;
  height: 18px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;

  color: #b42318;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.lawp-delete-account-box-content-alert-content-support {
  width: 311px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #d92d20;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.lawp-delete-account-box-content-alert-actions {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  width: 208px;
  height: 20px;

  flex: none;
  order: 1;
  flex-grow: 0;
}
.lawp-delete-account-box-content-alert-button-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px;

  // width: 208px;
  height: 20px;

  border-radius: 8px;

  flex: none;
  order: 1;
  flex-grow: 0;
}
.lawp-delete-account-box-content-alert-button {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;

  // width: 208px;
  height: 20px;
}
.lawp-delete-account-box-content-alert-button-text {
  // width: 180px;
  height: 20px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #b42318;
}

.lawp-delete-account-box-content-alert-button-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-top: 1px;
}

.lawp-delete-account-box-content-actions-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  width: 352px;

  flex: none;
  order: 3;
  align-self: stretch;
  flex-grow: 0;
}
.lawp-delete-account-box-content-actions-cancel-button-container {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 352px;

  border-radius: 8px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.lawp-delete-account-box-content-actions-cancel-button {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 352px;

  background: #7f56d9;

  border: 1px solid #7f56d9;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #ffffff;

  flex: none;
  order: 0;
  flex-grow: 1;
}

.lawp-delete-account-box-content-actions-accept-button-container {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 352px;
  height: 24px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.lawp-delete-account-box-content-actions-accept-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 352px;
  height: 24px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #6941c6;

  flex: none;
  order: 0;
  flex-grow: 1;
}
.lawp--delete-account-modal-content-actions-accept-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 352px;
  height: 24px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #6941c6;

  flex: none;
  order: 0;
  flex-grow: 1;
}
