@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$phoneBreakpoint: map-get($screen-width-breakpoints, $key: 'phone');
$tabletBreakpoint: map-get($screen-width-breakpoints, $key: 'tablet');

.lawp-filters-drawer-container-not-displaying {
  display: none;
}

.lawp-filters-drawer-container {
  .MuiSvgIcon-root {
    width: 40px !important;
    height: 40px !important;
    color: #7f56d9 !important;
  }

  .hamburger-text {
    margin-left: 10px;
    font-family: $fontFamily !important;
    @include get-font-weight($key: 'SemiBold');
    font-size: 20px !important;
    color: #7f56d9 !important;
    text-decoration: none !important;
    text-transform: none !important;
  }
}

.lawp-filters-drawer-drawer {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 280px !important;
}

.lawp-filters-drawer-container {
  display: none;
}

@media (max-width: 1480px) {
  .lawp-filters-drawer-container {
    display: flex;
  }
}
