@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.third-state {
  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  align-items: center;
  padding: 0px 32px 40px;
  gap: 24px;
  // margin-top: 24px;
  // width: 360px;
  // height: 192px;
  // margin-bottom: 65%;
}

.text {
  width: 360px;
  // height: 76px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  margin: 0;

  text-align: center;

  color: #101828;
}
.third-state-title {
  width: 360px;
  // height: 112px;

  /* Text md/Normal */

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;

  /* Gray/500 */

  color: #667085;
}

.container {
  display: flex;

  flex-direction: column;

  gap: 24px;
}

.no-delete-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  margin-bottom: 4px;

  // width: 296px;
  // height: 24px;
}

.deleteTextSubTitle {
  width: 296px;
  height: 24px;
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #101828;
}

.container-benefits {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  grid-gap: 16px;
  gap: 16px;
  padding: 0px 32px 40px 32px;
  width: 296px;
  // margin-left: 32px;
  // height: 104px;

  // margin-top: 25%;
}
.row-details {
  display: flex;
  flex-direction: row;

  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  width: 296px;
  height: 24px;
}
.purple-circle {
  width: 24px;
  height: 24px;
  position: absolute;

  background: #f4ebff;
  border-radius: 12px;
}
.check-mark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 12px;
  height: 10.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  //      left: 26.46%;
  //    right: 23.56%;
  //    top: 29.65%;
  //    bottom: 26.58%;
}

.check-mark-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-left: 36px;
  padding: 0px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #667085;

  width: 260px;
  height: 24px;
}

.text-details {
  // width: 360px;
  // height: 24px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  text-align: center;
  color: #667085;
  // flex: none;
  // order: 1;
  // align-self: stretch;
  // flex-grow: 0;
}
.benefits-sub-text {
  width: 296px;
  height: 24px;

  /* Text md/Semibold */

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Gray/900 */

  color: #101828;
}

// .container-position {
//   margin-top: 30%;
//   margin-left: 32px;
// }

@media screen and (max-width: 1230px) {
}
