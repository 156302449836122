@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.snack-bar-component-style {
  width: 100% !important;
  background-color: #7f56d9 !important;
  font-family: $fontFamily !important;
}
