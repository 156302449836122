@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.lawp-add-meeting-modal-container-right-heading-form-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  // gap: 24px;
  // gap: 35px;

  width: 512px;
  // height: 357.33px;

  border-radius: 12px;
}

.lawp-add-meeting-modal-container-right-heading-and-supporting-text-large {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  width: 512px;
  // height: 190px;
}

.lawp-add-meeting-modal-container-right-heading-large {
  width: 512px;
  // height: 88px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;

  margin: 0;

  letter-spacing: -0.02em;

  color: #101828;
}

.lawp-add-meeting-modal-container-right-heading-firstname-input {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  margin-bottom: 20px;

  gap: 20px;

  width: 512px;
  // height: 70px;
}
.lawp-add-meeting-modal-container-right-heading-lastname-input {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  margin-bottom: 20px;

  gap: 20px;

  width: 512px;
  // height: 70px;
}
.lawp-add-meeting-modal-container-right-heading-email-input {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  gap: 20px;

  width: 512px;
  // height: 70px;
}

.lawp-add-meeting-modal-container-right-heading-input-with-label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 6px;

  width: 512px;
  // height: 70px;
}

.lawp-add-meeting-modal-container-right-heading-firstname-label,
.lawp-add-meeting-modal-container-right-heading-lastname-label,
.lawp-add-meeting-modal-container-right-heading-email-label {
  width: 68px;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #344054;
}

.lawp-add-meeting-modal-container-right-heading-firstname-input-base,
.lawp-add-meeting-modal-container-right-heading-lastname-input-base,
.lawp-add-meeting-modal-container-right-heading-email-input-base {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;

  width: 512px;
  height: 44px;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.lawp-add-meeting-modal-container-right-heading-hint-text {
  width: 360px;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 0;

  color: #667085;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.lawp-set-meeting-row-terms {
  display: flex;
  flex-direction: row;
  // justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  // width: 25rem;
  // height: 1.25rem;
}

.lawp-set-meeting-checkbox-container-selected {
  width: 16px;
  height: 16px;
  max-width: 16px;
  background-color: #f9f5ff;
  border: 2px solid #7f56d9;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  margin-top: 2px;
}

.lawp-set-meeting-check-button {
  width: 16px !important;
  height: 16px !important;
  min-width: 16px !important;
}

.lawp-set-meeting-checkbox-container {
  width: 16px;
  height: 16px;
  max-width: 16px;
  background-color: #ffffff;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 2px solid #d0d5dd;
  border-radius: 4px;

  margin-top: 2px;
}

.lawp-set-meeting-check-style-selected-img {
  font-size: 16px;
  height: 14px;
  margin-right: 1px;
  margin-top: 2px;
  color: #7f56d9;
}

.lawp-set-meeting-check-style-img {
  display: none;
  font-size: 16px;
  color: rgba(0, 0, 0, 0);
}

.lawp-set-meeting-checkbox-text-label {
  // width: 140px;
  // height: 20px;
  // height: 16px;

  /* Text sm/Medium */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Gray/700 */

  color: #344054;
}

.lawp-add-meeting-modal-container-right-heading-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  margin-top: 24px;
  gap: 16px;

  width: 512px;
  // height: 44px;
}

.lawp-add-meeting-modal-container-right-heading-button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 512px;
  height: 44px;

  border-radius: 8px;
}

.lawp-add-meeting-modal-container-right-login-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 512px;
  height: 20px;
}

.lawp-add-meeting-modal-container-right-login-text {
  width: 221px;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #667085;
}

.lawp-set-meeting-error-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 100%;

  margin-top: 25px;
}

.lawp-set-meeting-error-message {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 0;

  color: red;
}

.lawp-add-meeting-modal-container-right-login-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;

  // width: 106px;
  // height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #6941c6;
}

.lawp-add-meeting-modal-container-right-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 100%;
  height: 20px;

  flex: none;
  align-self: stretch;
  flex-grow: 0;
}

.lawp-add-meeting-modal-container-right-login-text {
  max-width: 100%;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #667085;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.lawp-add-meeting-modal-container-right-checkbox-link {
  /* Text md/Medium */
  text-decoration: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  // font-size: 16px;
  line-height: 24px;

  /* identical to box height, or 150% */

  /* Primary/700 */
  color: #6941c6;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: #6941c6;
  }
}

.lawp-add-meeting-modal-container-right-login-button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  // max-width: 100%;
  // height: 20px;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.lawp-add-meeting-modal-container-right-login-button-base {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;

  // width: 106px;
  // height: 20px;

  flex: none;
  order: 0;
  flex-grow: 0;
  background-color: #ffffff;

  outline: none;
  border: none;
}

.lawp-add-meeting-modal-container-right-login-button-text {
  // width: 106px;
  // height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;

  color: #6941c6;

  flex: none;
  order: 0;
  flex-grow: 0;
}
