@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: auto;
}

.leftSection {
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: left;
}

.rightSection {
  display: flex;
  width: 50%;
  justify-content: flex-end;
  align-items: center;
}

.titleContainer {
  display: block;
  margin-top: 5px;
}

.titleText {
  font-size: 18;
  line-height: 28px;
  font-family: $fontFamily;
  @include get-font-weight($key: 'Medium');
}

.contentContainer {
  display: block;
}

.contentText {
  font-size: 14px;
  line-height: 20px;
  font-family: $fontFamily;
  @include get-font-weight($key: 'Regular');
  color: gray;
}

.revertButton {
  display: inline-block !important;
  height: 45px !important;
  left: 0px !important;
  top: 0px !important;
  border-radius: 8px !important;
  padding: 5px 15px 5px 15px !important;
  color: #344054 !important;
  font-family: $fontFamily !important;
  @include get-font-weight($key: 'Medium');
  font-size: 14px !important;
  line-height: 20px !important;
  background-color: white !important;
  text-transform: none !important;
  border-radius: 9px !important;
  border: 1px solid #d6dae1 !important;
}

.saveButton {
  display: inline-block !important;
  height: 44px !important;
  left: 0px !important;
  top: 0px !important;
  border-radius: 8px !important;
  padding: 5px 16px 5px 16px !important;
  color: white !important;
  font-family: $fontFamily !important;
  @include get-font-weight($key: 'Medium');
  font-size: 14px !important;
  line-height: 20px !important;
  margin-left: 15px !important;
  background-color: #7f56d9 !important;
  text-transform: none !important;
  border-radius: 9px !important;
}

@media screen and (max-width: 1060px) {
  .leftSection {
    width: 100%;
  }
}
