@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$phoneBreakpoint: map-get($screen-width-breakpoints, $key: 'phone');
$tabletBreakpoint: map-get($screen-width-breakpoints, $key: 'tablet');

.lawp-footer-container {
  display: flex;
  flex-direction: column;

  width: calc(100vw - 8px);

  min-width: 640px;

  padding: 64px 96px 48px 96px;

  background: #f9fafb;

  .top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    @media (max-width: $phoneBreakpoint) {
      flex-direction: column;
      align-items: center;
      gap: 32px;
    }

    .logo-container {
      display: flex;
      flex-direction: column;
      width: 35%;

      @media (max-width: $phoneBreakpoint) {
        width: 100%;
        align-items: center;
      }

      .logo {
        width: 190px;
        height: 33px;
        margin-bottom: 32px;
      }

      .logo-description {
        /* Text md/Normal */
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        /* identical to box height, or 150% */

        /* Gray/500 */
        color: #667085;
      }
    }

    .link-container {
      display: flex;
      flex-direction: row;
      width: 30%;
      justify-content: space-between;

      @media (max-width: $tabletBreakpoint) {
        width: 35%;
        justify-content: space-between;
      }

      @media (max-width: $phoneBreakpoint) {
        width: 100%;
        justify-content: space-evenly;
      }

      .link-container-list {
        display: flex;
        flex-direction: column;

        gap: 12px;

        .title {
          /* Text sm/Semibold */
          font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;

          /* identical to box height, or 143% */

          /* Gray/900 */
          color: #101828;
          margin-bottom: 4px;
        }

        .list-element {
          /* Text md/Medium */
          text-decoration: none;
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;

          /* identical to box height, or 150% */

          /* Primary/700 */
          color: #6941c6;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
            text-decoration-color: #6941c6;
          }
        }
      }
    }
  }

  .MuiInput-root {
    text-decoration: underline;
    text-decoration-color: #98a2b3;
  }

  .divider {
    margin-top: 64px;
    margin-bottom: 32px;
    border: 1px solid #e4e7ec;
    width: 100%;
  }

  .bottom {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    @media (max-width: $phoneBreakpoint) {
      flex-direction: column;
      align-items: center;
      gap: 32px;
    }

    .left {
      display: flex;
      flex-direction: column;
      margin-bottom: 0px;
      flex-direction: column;

      p {
        margin-bottom: 0px;
      }
    }

    .right {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;
    }

    .bottom-link {
      /* Text md/Normal */
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      /* identical to box height, or 150% */

      /* Gray/400 */

      color: #98a2b3;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
        text-decoration-color: #98a2b3;
      }
    }

    h3 {
      /* Text md/Normal */
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      /* identical to box height, or 150% */

      /* Gray/400 */
      color: #98a2b3;
    }
    h4 {
      /* Text md/Normal */
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;

      /* identical to box height, or 150% */

      /* Gray/400 */
      color: #98a2b3;

      position: absolute !important;
      clip: rect(1px, 1px, 1px, 1px);
      padding: 0 !important;
      border: 0 !important;
      height: 1px !important;
      width: 1px !important;
      overflow: hidden;
    }
  }
}
