@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.container {
  // width: 100%;
  width: 512px;
}

.oneThirdSection {
  display: flex;
  width: 55%;
  // justify-content: center;
  align-items: flex-end;
}

.fullSection {
  display: flex;
  width: 100%;
}
