@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.lawp-reviews-view-container {
  display: flex;
  align-items: center;

  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

.lawp-reviews-view-back-to-profile-overview-button {
  font-size: 16px !important;
  width: 300px;
  color: #7f56d9 !important;
  line-height: 24px !important;
  text-transform: none !important;
  font-family: $fontFamily !important;
  @include get-font-weight($key: 'Medium');

  background-color: transparent !important;
  margin-bottom: 20px !important;
}

.lawp-reviews-view-back-to-profile-overview-button-container {
  display: flex;
  justify-content: flex-start !important;
  width: 100%;
}

.lawp-reviews-view-content-container {
  width: 85%;
}

.lawp-reviews-view-reviews-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}

.lawp-reviews-view-article-pagination-divider {
  width: 100%;
  height: 1.5px;
  background-color: #e4e7ec;
  margin-top: 35px;
  margin-bottom: 20px;
}

.lawp-reviews-view-articles-pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lawp-reviews-view-left-arrow-button {
  display: flex;
  justify-content: flex-start !important;
  width: 150px;
  height: 64px;
  cursor: pointer;

  font-family: $fontFamily !important;
  @include get-font-weight($key: 'Medium');
  font-size: 14px !important;
  color: #667085 !important;

  background-color: transparent !important;
}

.lawp-reviews-view-right-arrow-button {
  display: flex;
  justify-content: flex-end !important;
  width: 150px;
  height: 64px;
  cursor: pointer;

  font-family: $fontFamily !important;
  @include get-font-weight($key: 'Medium');
  font-size: 14px !important;
  color: #667085 !important;

  background-color: transparent !important;
}

.lawp-reviews-view-pagination {
  .MuiButtonBase-root {
    font-family: $fontFamily !important;
    color: #667085 !important;
    background-color: white !important;
  }

  .Mui-selected {
    font-family: $fontFamily !important;
    color: #7f56d9 !important;
    background-color: #f9f5ff !important;
  }

  .MuiPaginationItem-icon {
    display: none !important;
  }
}
