@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$checkboxSize: 16px;
$checkboxRadius: 4px;

*,
*::before,
*::after {
  box-sizing: border-box;
}
.login-container {
  width: 50%;
  height: 960px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;

  z-index: 10;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  font-family: $fontFamily;
  margin-bottom: 25px;
}

.client-login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 96px 0px;

  width: 50%;
  height: 960px;
}

.main-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 0px 32px;

  width: 100%;
  height: 864px;
}

.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 32px;
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 80px;

  width: 100%;
}

.client-content-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0px;
  gap: 80px;

  width: 100%;
}

.logo-container {
  width: 142px;
  height: 32px;
}
.header-and-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;

  width: 360px;
  height: 460px;
}
.header-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 24px;

  width: 360px;
  height: 104px;
}
.text-supporting-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  width: 360px;
  height: 104px;
}
.header-text {
  width: 360px;
  height: 44px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;

  letter-spacing: -0.02em;

  color: #101828;
}
.header-supporting-text {
  width: 360px;
  height: 48px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #667085;
}
.form-login {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px;
}
.email-form-login {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.password-form-login {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 24px;
}
.input-with-label {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px;
  width: 360px;
}
.form-input-label {
  width: 36px;
  height: 20px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #344054;
}
.email-input-login {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;

  width: 360px;
  height: 44px;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #667085;
}

.password-input-login {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;

  width: 360px;
  height: 44px;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #667085;
}

.row-form-login {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  margin-top: 24px;
}
.checkbox-label-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 164px;
  height: 20px;
}
.checkbox-container-selected {
  width: $checkboxSize;
  height: $checkboxSize;
  max-width: $checkboxSize;
  display: inline-flex;
  background-color: #f9f5ff;
  border: 2px solid #7f56d9;
  border-radius: $checkboxRadius;
  align-items: center;
  justify-content: center;
}
.checkbox-container {
  width: $checkboxSize;
  height: $checkboxSize;
  max-width: $checkboxSize;
  display: inline-flex;
  background-color: #ffffff;
  border: 2px solid #d0d5dd;
  border-radius: $checkboxRadius;
  align-items: center;
  justify-content: center;
}

.check-button {
  width: $checkboxSize !important;
  height: $checkboxSize !important;
  min-width: $checkboxSize !important;
}

.check-style-selected-img {
  font-size: 16px;
  height: 14px;
  margin-right: 1px;
  margin-top: 2px;
  color: #7f56d9;
}
.check-style-img {
  display: none;
  font-size: 16px;
  color: rgba(0, 0, 0, 0);
}
.checkbox-text-label {
  width: 140px;
  height: 20px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #344054;
}
.actions-form-login {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  margin-top: 24px;
}
.action-login-button-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 360px;
  height: 44px;

  border-radius: 8px;
}
.login-button {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 360px;
  height: 44px;

  background: #7f56d9;

  border: 1px solid #7f56d9;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
.row-login {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;

  width: 360px;
  height: 20px;
}
.row-text {
  height: 20px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #667085;
}

.client-row-text {
  width: 149px;
  height: 20px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #667085;
}

.row-button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 165px;
  height: 20px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #6941c6;
  cursor: pointer;

  &:hover {
    color: #6941c6;
  }
}

.client-row-button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 106px;
  height: 20px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #6941c6;
  cursor: pointer;

  &:hover {
    color: #6941c6;
  }
}

.footer-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 32px;

  width: 100%;
  height: 96px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #667085;
}

@media screen and (max-width: 1060px) {
  .login-container {
    width: 100%;
  }

  .main-container {
    padding: 64px 32px;
    width: unset;
  }

  .footer-container {
    justify-content: center;
  }

  .header-text {
    font-size: 1.5rem;
  }

  .header-supporting-text,
  .login-button,
  .email-input-login,
  .password-input-login {
    font-size: 14px;
  }

  .row-button,
  .checkbox-text-label,
  .client-row-text,
  .client-row-button {
    font-size: 12px;
  }
}

@media screen and (max-width: 1480px) and (min-width: 1060px) {
}

@media screen and (min-width: 1480px) {
  .client-login-container {
    padding: unset;
  }

  .main-container {
    width: unset;
  }

  .header-text {
    font-size: 36px;
  }

  .header-supporting-text,
  .login-button,
  .email-input-login,
  .password-input-login {
    font-size: 16px;
  }

  .row-button,
  .checkbox-text-label,
  .client-row-text,
  .client-row-button {
    font-size: 14px;
  }
}
