@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.send-message-to-lawyer-not-logged-modal-actions-cancel-event-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 115px;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #6941c6;

  border: none;
  background: none;
}

.send-message-to-lawyer-not-logged-modal-lawyer-email-button {
  padding: 0;
  border: 0;
  background-color: #fff;
  font-weight: 500;
  color: #6941c6;

  background: none;
  border: none;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 16px !important;
}

.send-message-to-lawyer-not-logged-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  position: absolute;
  width: 100%;
  height: 100%;
  left: 7217px;
  top: 7459px;

  background: rgba(52, 64, 84, 0.6);

  backdrop-filter: blur(8px);
}

.send-message-to-lawyer-not-logged-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 32px;

  width: 400px;
  // height: 574px;

  background: #ffffff;

  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1),
    0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  border-radius: 12px;
}

.send-message-to-lawyer-not-logged-modal-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;

  width: 100%;
  // height: 394px;
}

.send-message-to-lawyer-not-logged-modal-avatar-label-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 12px;

  width: 100%;
  // height: 40px;
}

.send-message-to-lawyer-not-logged-modal-profile-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  width: 40px;
  height: 40px;

  object-fit: cover;
  object-position: 50% 50%;

  border-radius: 200px;
}

.send-message-to-lawyer-not-logged-modal-name-and-email {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 100%;
  // height: 40px;
}

.send-message-to-lawyer-not-logged-modal-name {
  width: 100%;
  // height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #344054;
}

.send-message-to-lawyer-not-logged-modal-email {
  width: 100%;
  // height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #667085;
}

.send-message-to-lawyer-not-logged-modal-text-and-supporting-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  //   gap: 8px;
  gap: 15px;

  width: 100%;
  // height: 134px;
}

.send-message-to-lawyer-not-logged-modal-text {
  width: 100%;
  // height: 28px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;

  color: #101828;
}

.send-message-to-lawyer-not-logged-modal-supporting-text {
  width: 100%;
  // height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #667085;
}

.send-message-to-lawyer-not-logged-modal-textarea-input-field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 100%;
  // height: 180px;
}
.send-message-to-lawyer-not-logged-modal-textarea-input-hint {
  display: flex;
  width: 352px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  /* Gray/500 */

  color: #667085;
}

.send-message-to-lawyer-not-logged-modal-actions {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  width: 100%;
  // height: 44px;
}

.send-message-to-lawyer-not-logged-modal-cancel-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 180px;
  height: 44px;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #344054;
}

.send-message-to-lawyer-not-logged-modal-success-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  //   width: 180px;
  width: 180px;
  height: 44px;

  background: #7f56d9;

  border: 1px solid #7f56d9;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #ffffff;
}

.stepper-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 12px;

  width: 343px;
  // height: 24px;
}

.step-icon-base {
  width: 24px;
  height: 24px;

  position: relative;

  background: #f9f5ff;

  box-shadow: 0px 0px 0px 4px #f4ebff;
  border-radius: 12px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.selected-icon-content {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;

  background: #7f56d9;
  border-radius: 12px;
}

.selected-dot {
  position: absolute;
  width: 8px;
  height: 8px;
  left: calc(50% - 8px / 2);
  top: calc(50% - 8px / 2);

  border-radius: 12px;

  background: #ffffff;
}

.unselected-step-icon-base {
  width: 24px;
  height: 24px;

  position: relative;

  background: #f2f4f7;
  border-radius: 12px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.unselected-icon-content {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;

  border-radius: 12px;
}

.unselected-dot {
  position: absolute;
  width: 8px;
  height: 8px;
  left: calc(50% - 8px / 2);
  top: calc(50% - 8px / 2);

  background: #ffffff;
  border-radius: 12px;
}

@media screen and (max-width: 1060px) {
  .send-message-to-lawyer-not-logged-modal-actions-cancel-event-button {
    font-size: 12px;
  }

  .send-message-to-lawyer-not-logged-modal-lawyer-email-button {
    font-size: 16px !important;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
