@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.ql-editor {
  padding: 10px 12px;
  width: 100%;
  height: 100%;
  min-height: 128px;

  resize: none;
  // border: 2px solid green;
}

.lawp-article-blog-page-details-to-lawyer-profile-button-container {
  margin-top: 50px;
  display: flex;
  justify-content: flex-start !important;
  width: 100%;
}

.lawp-article-blog-page-details-to-lawyer-profile-button {
  font-size: 16px !important;
  width: 300px;
  color: #7f56d9 !important;
  line-height: 24px !important;
  text-transform: none !important;
  font-family: $fontFamily !important;
  @include get-font-weight($key: 'Medium');

  background-color: transparent !important;
  margin-bottom: 20px !important;
}

.lawp-article-blog-page-details-article-image{
  max-width: 1216px;
  max-height: 516px;
  object-fit: cover;
}
