@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$phoneBreakpoint: map-get($screen-width-breakpoints, $key: 'phone');
$tabletBreakpoint: map-get($screen-width-breakpoints, $key: 'tablet');

.lawp-landing-page-paper-container {
  padding: 22px 22px 22px 22px;
  margin-bottom: 25px;
  width: 1000px;
  border-radius: 20px !important;
  border: 1px solid #e4e7ec;
  background-color: white;
  cursor: pointer;

  &:hover {
    box-shadow: 0 2px 8px -3px rgb(0 0 0 / 25%);
  }

  @media (max-width: $phoneBreakpoint) {
    width: 96%;
  }

  .speciality-tag{
    &:hover{
      text-decoration: underline;
    }
  }

  .MuiTooltip-tooltip {
    border-radius: 5px !important;
    // border: 1px solid darkgray !important;
    box-shadow: 0 2px 8px -3px rgb(0 0 0 / 25%) !important;
  }

  .content-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (max-width: $phoneBreakpoint) {
      flex-direction: column;
      gap: 10px;
    }

    .image-container {
      display: flex;
      position: relative;
      width: 25%;
      height: 175px;
      border-radius: 5%;

      // cursor: pointer;

      @media (max-width: $phoneBreakpoint) {
        width: 100%;
        height: 300px;
        border-radius: 0%;
      }

      .image-bookmark {
        display: none;
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 1;
        cursor: pointer;

        @media (max-width: $phoneBreakpoint) {
          display: block;
        }
      }

      .avatarImage {
        border-radius: 5%;
        width: 234px;
        height: 175px;
        object-fit: cover;
        justify-content: center;

        @media (max-width: $phoneBreakpoint) {
          width: 100%;
          height: 300px;
          border-radius: 0%;
        }
      }

      .defaultAvatarImage {
        border-radius: 7.46509px;
        border-radius: 5%;
        width: 50%;
        height: 50%;
        object-fit: object-fit;
        justify-content: center;
      }

      .defaultAvatarImageSize {
        position: absolute;

        left: 26.42%;
        right: 27.13%;
        top: 19.32%;
        bottom: 18.75%;
      }

      .lawyerAvatarTagsContainer {
        display: flex;
        position: absolute;
        top: 85%;
        left: 1%;
        width: 58px;
        height: 21px;
      }

      .avatarTag {
        display: flex;
        flex-direction: row;
        // align-items: center;
        // flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        background-color: #f9f5ff;
        margin-left: 3px;
        padding: 1px 9px 1px 9px;
      }

      .avatarTagIconBadge {
        display: block;
        width: 9px;
      }

      .avatarTagIconChecked {
        display: block;
        height: 13px;
        width: 13px;
      }

      .avatarTagText {
        margin-left: 5px;
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 1px;
        font-family: $fontFamily;
        @include get-font-weight($key: 'Medium');
        color: $primaryColor;
      }
    }

    .info-container {
      display: flex;
      width: 73%;
      flex-direction: column;
      justify-content: space-between;

      gap: 10px;

      // height: 180px;

      @media (max-width: $phoneBreakpoint) {
        justify-content: space-between;
        width: 100%;
        height: auto;
      }

      .name-and-bookmark-section {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        // margin-bottom: -10px;

        .lawyer-name-text {
          /* Text lg/Medium */
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 28px;
          margin-bottom: 0px;

          /* identical to box height, or 156% */

          /* Gray/900 */
          color: #101828;

          color: inherit;
          text-decoration: none !important;
          // cursor: pointer;

          @media (max-width: $phoneBreakpoint) {
            font-size: 20px;
            line-height: 24px;
            margin-top: 10px;
            margin-bottom: 10px;
            height: 25px;
          }
        }

        .name-and-badge-section {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          width: 100%;

          @media (max-width: $phoneBreakpoint) {
            align-items: center;
          }
        }

        .subscription-container {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 0;
          width: 76px;
          height: 28px;
          left: 374px;
          top: 5px;
          margin: 0px 10px;
        }

        .subscription-inner-container {
          display: flex;
          flex-direction: row;
          justify-content: center;
          padding: 4px 10px 4px 12px;
          left: 0;
          top: 0;
          margin: 0px 0px;
          background: #f9f5ff;
          border-radius: 16px;
        }

        .subscription-logo {
          width: 20;
          height: 20;
          margin: 0px 0px;
          top: 2px;
          left: 0px;
          order: 0;
        }

        .subscription-type {
          width: 38px;
          height: 20px;
          left: 28px;
          top: 4px;
          font-family: $fontFamily;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #6941c6;
          order: 1;
          margin: 0px 4px;
        }

        .bookmark-container {
          @media (max-width: $phoneBreakpoint) {
            display: none;
          }
        }
      }
      .specialist-attourneys-section {
        /* Text sm/Semibold */
        margin: 0;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        // margin-bottom: 10px;
        /* identical to box height, or 143% */

        /* Primary/700 */
        color: #6941c6;

        // @media (max-width: $phoneBreakpoint) {
        // height: 20px;
        // margin-bottom: 10px;
        // }
      }

      .rating-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        // margin-bottom: -20px;
        margin-bottom: -10px;

        @media (max-width: $phoneBreakpoint) {
          margin-bottom: -15px;
        }

        .rating-stars {
          margin-left: -3px !important;
        }

        .rating-text {
          margin: 0;
          margin-left: 8px;
          font-family: $fontFamily;
          @include get-font-weight($key: 'Medium');
          color: black;
          line-height: 24px;
          font-size: 16px;
        }

        .rating-reviews {
          margin: 0;
          margin-left: 8px;
          font-family: $fontFamily;
          @include get-font-weight($key: 'Regular');
          color: grey;
          font-size: 16px;
          line-height: 24px;
          white-space: pre;
        }
      }

      .area-of-laws-container {
        display: flex;
        flex-direction: row;
        align-items: flex-end;

        height: 45px;
        margin-bottom: 5px;
        .area-of-law-text {
          font-family: $fontFamily;
          @include get-font-weight($key: 'Regular');
          color: grey;
          font-size: 14px;
          //   line-height: 20px;
          max-width: 90%;
          height: 45px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          display: -webkit-box !important;
          -webkit-line-clamp: 1 !important;
          // -webkit-box-orient: vertical;
          white-space: normal;

          @media (max-width: $phoneBreakpoint) {
            font-size: 14px;
          }
        }

        .area-of-law-overflow {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 35px;
          height: 35px;

          border-radius: 50%;
          background-color: #f9f5ff;
        }
      }

      .appointment-button-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @media (max-width: $phoneBreakpoint) {
          flex-direction: column;
          gap: 15px;
          align-items: flex-start;
        }

        .location-container {
          display: flex;
          font-family: $fontFamily;
          @include get-font-weight($key: 'Medium');
          align-items: center;
          flex-wrap: wrap;
          font-size: 14px;
          line-height: 20px;

          color: #344054;
          background-color: #f2f4f7;
          border-radius: 20px;
          height: 23px;
          padding-right: 5px;
          padding-left: 5px;

          .country-round-flag {
            width: 15px;
            height: 15px;
            margin-right: 5px;
          }

          @media (max-width: $phoneBreakpoint) {
            background-color: transparent;
          }
        }

        .appointment-button {
          // margin-right: 40px;

          background-color: #7f56d9 !important;
          color: white;
          font-size: 13px !important;
          height: 34px !important;
          text-transform: none !important;
          font-family: $fontFamily !important;
          @include get-font-weight($key: 'Regular');
          border-radius: 7px !important;
          padding-left: 13px !important;
          padding-right: 15px !important;

          @media (max-width: $phoneBreakpoint) {
            margin-right: 0px;
            width: 100%;
          }
        }

        .request-appointment-button {
          // margin-right: 40px;

          background-color: #ffffff !important;
          color: #7f56d9 !important;
          border: 1px solid #7f56d9;
          font-size: 13px !important;
          height: 34px !important;
          text-transform: none !important;
          font-family: $fontFamily !important;
          @include get-font-weight($key: 'Regular');
          border-radius: 7px !important;
          padding-left: 13px !important;
          padding-right: 15px !important;

          @media (max-width: $phoneBreakpoint) {
            margin-right: 0px;
            width: 100%;
          }
        }
        .button-disabled {
          background-color: gray !important;
          color: white !important;

          height: 34px !important;

          font-size: 13px;
          font-family: $fontFamily !important;
          @include get-font-weight($key: 'Regular');
          text-transform: none !important;

          border-radius: 7px !important;
          border: 0;

          padding-left: 13px !important;
          padding-right: 15px !important;
          cursor: default;

          @media (max-width: $phoneBreakpoint) {
            margin-right: 0px;
            width: 100%;
          }
        }
      }
    }
  }
}
