@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$checkboxSize: 16px;
$checkboxRadius: 4px;

*,
*::before,
*::after {
  box-sizing: border-box;
}

.email-password-sent-box-container {
  height: 320px;
}
.email-password-sent-header {
  height: 216px;
}
.email-password-sent-header-text-support-container {
  height: 136px;
}
.email-password-sent-header-text {
  height: 76px;
}
.email-password-sent-content-text-and-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 400px;
  height: 20px;
}
.email-password-sent-content-text {
  // width: 209px;
  // height: 20px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #667085;
}
.email-password-sent-content-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;

  // width: 185px;
  // height: 20px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #6941c6;

  cursor: pointer;
}

.email-password-sent-content-button-timeout-container {
  margin-bottom: -16px;
  margin-top: -16px;
}

.email-password-sent-content-button-timeout {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;

  // width: 185px;
  // height: 20px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #6941c6;
}

.email-password-sent-back-button-text-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 180px;
  height: 20px;
  cursor: pointer;
}
.email-password-sent-back-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
}
.email-password-sent-back-text {
  /* Text */

  width: 152px;
  height: 20px;

  /* Text sm/Medium */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Gray/500 */

  color: #667085;
}
