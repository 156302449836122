@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$phoneBreakpoint: map-get($screen-width-breakpoints, $key: 'phone');
$tabletBreakpoint: map-get($screen-width-breakpoints, $key: 'tablet');

.payment-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 30.72px;
  width: 100%;
  // height: 237px;
}

.actual-plan-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 40px;
  gap: 48px;

  @media (max-width: $tabletBreakpoint) {
    flex-direction: column;
    gap: 25px;
  }
}

.card-current-plan {
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 520px;
  height: 237px;

  background: #ffffff;

  border: 1px solid #e4e7ec;
  border-radius: 8px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.header {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 20px 16px 16px;
  gap: 4px;

  width: 520px;
  height: 60px;

  background: #f9f5ff;

  border: 1px solid #d6bbfb;
  border-radius: 8px 8px 0px 0px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.header-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;

  width: 484px;
  height: 28px;

  flex: none;
  order: 0;
  flex-grow: 1;
}

.header-icon {
  position: relative;
  width: 28px;
  height: 28px;

  display: flex;
  justify-content: center;
  align-items: center;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.featured-icon {
  position: absolute;
  width: 32px;
  height: 32px;
  left: -2px;
  top: -2px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: #f4ebff;

  border: 4px solid #f9f5ff;
  border-radius: 28px;
}

.header-text {
  width: 440px;
  height: 24px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  margin: 0;

  color: #53389e;

  flex: none;
  order: 1;
  flex-grow: 1;
}

.pricing-tier-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 520px;
  height: 177px;

  background: #ffffff;

  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.pricing-tier-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;

  width: 520px;
  height: 124px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.heading-and-price {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;

  width: 472px;
  height: 76px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.heading-and-supporting-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px 0px 0px;
  gap: 4px;

  width: 299px;
  height: 76px;

  flex: none;
  order: 0;
  flex-grow: 1;
}

.heading-and-badge {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 150px;
  height: 28px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.heading-title-text {
  width: 150px;
  height: 28px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;

  margin: 0;

  color: #101828;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.heading-text {
  width: 299px;
  height: 40px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  margin: 0;

  color: #667085;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.price {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 2px;

  width: 141px;
  height: 60px;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 0px 10px;
  gap: 10px;

  width: 25px;
  height: 54px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.price-type {
  width: 25px;
  height: 44px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;

  margin: 0;

  letter-spacing: -0.02em;

  color: #101828;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.price-text {
  width: 33px;
  height: 60px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 60px;

  margin: 0;

  letter-spacing: -0.02em;

  color: #101828;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.period-wrap {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 0px 7px;

  width: 79px;
  height: 31px;

  flex: none;
  order: 2;
  flex-grow: 0;
}

.period {
  width: 79px;
  height: 24px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  margin: 0;

  color: #667085;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.pricing-tier-action {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;

  width: 520px;
  height: 53px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.pricing-divider {
  width: 520px;
  height: 1px;

  background: #e4e7ec;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.pricing-action-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 24px;
  gap: 16px;

  width: 520px;
  height: 52px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.pricing-action-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;

  background: none;
  border: none;

  width: 169px;
  height: 20px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.pricing-action-button-text {
  width: 141px;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  margin: 0;

  color: #6941c6;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.inline-cta {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 20px;

  width: 788px;
  height: 237px;

  background: #ffffff;

  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;

  flex: none;
  order: 1;
  flex-grow: 1;
}

.text-and-supporting-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 740px;
  height: 52px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.text {
  width: 740px;
  height: 28px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;

  margin: 0;

  color: #101828;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.supporting-text {
  width: 740px;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  margin: 0;

  color: #667085;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.card {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;

  width: 740px;
  height: 100px;

  background: #ffffff;

  border: 1px solid #e4e7ec;
  border-radius: 8px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.card-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  width: 708px;
  height: 68px;

  flex: none;
  order: 0;
  flex-grow: 1;
}

.payment-method-icon {
  position: relative;
  box-sizing: border-box;
  display: flex;

  justify-self: center;
  align-items: center;

  width: 58px;
  height: 40px;

  background: #ffffff;

  border: 1px solid #f2f4f7;
  border-radius: 6px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.payment-img {
  position: absolute;
  left: 13.96%;
  right: 14.86%;
  top: 27.95%;
  bottom: 27.95%;
}

.payment-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;

  width: 634px;
  height: 68px;

  flex: none;
  order: 1;
  flex-grow: 1;
}

.payment-text-and-supporting-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 411px;
  height: 40px;

  flex: none;
  order: 0;
  flex-grow: 1;
}

.payment-text {
  width: 411px;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  margin: 0;

  color: #344054;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.payment-supporting-text {
  width: 411px;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  margin: 0;

  color: #667085;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.payment-actions {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  width: 215px;
  height: 40px;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.payment-button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 215px;
  height: 40px;

  border-radius: 8px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.payment-button-base {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;

  width: 215px;
  height: 40px;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.payment-action-button-text {
  width: 183px;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  margin: 0;

  color: #344054;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.payment-icon-and-supporting-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  margin-top: 8px;

  width: 634px;
  height: 20px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.payment-mail-icon {
  color: grey;
}

.payment-email-address {
  width: 610px;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  margin: 0;

  color: #667085;

  flex: none;
  order: 1;
  flex-grow: 1;
}

@media screen and (max-width: 1480px) {
  .header-text {
    width: 90%;
  }

  .header,
  .header-content {
    width: 100%;
  }
}
