.faqTitle {
  position: static;
  width: 768px;
  height: 44px;
  left: 0px;
  top: 0px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;

  letter-spacing: -0.02em;

  color: #101828;
}

.faqSubTitle {
  position: static;
  width: 768px;
  height: 30px;
  left: 0px;
  top: 64px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;

  color: #667085;
}

.faqTitleCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 32px 0px 0px;
  gap: 20px;
  position: relative;
  width: 100%;

  /* margin-left: 22px; */
  /* height: 94px; */
  /* left: 91.5px; */
  /* top: 96px; */
  /* margin: 64px 0px; */
}

@media only screen and (max-width: 1060px) {
  .faqTitle,
  .faqSubTitle {
    /* width: 384px; */
    width: 100%;
    height: auto;
  }

  .faqTitleCard {
    margin-left: 0;
  }

  .faqTitle {
    font-size: 30px;
  }
}
