@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.gold-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  height: 100%;

  background: rgba(255, 255, 255, 0.8);
}

.box {
  // position: relative;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // width: 537px;
  width: 640px;
  // height: 811px;
  padding: 40px;

  // border: 1px solid #e4e7ec;
  // box-shadow: 0px 1px 2px 0px #1018280f;

  // box-shadow: 0px 1px 3px 0px #1018281a;

  background: #ffffff;
  /* Gray/200 */

  border: 1px solid #e4e7ec;
  /* Shadow/sm */

  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // height: 662px;
  width: 100%;
  padding: 24px;

  gap: 8px;
}

.header-horizontal-tabs {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px;
  gap: 8px;

  // width: 425px;
  // height: 56px;

  background: #f9fafb;

  border: 1px solid #f2f4f7;
  border-radius: 8px;
}

.horizontal-tabs-month {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;

  width: 180px;
  height: 44px;

  border-radius: 6px;

  background: #f9fafb;

  border: 0px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  color: #667085;
}

.horizontal-tabs-year {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;
  border: 0;
  cursor: pointer;

  width: 226px;
  height: 44px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Gray/700 */

  color: #344054;

  background: #f9fafb;
}
.horizontal-tabs-year-active {
  background: #ffffff;

  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 6px;
}

.horizontal-tabs-month-active {
  background: #ffffff;

  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
}

.horizontal-tabs-year-text {
  width: 136px;
  height: 24px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #344054;
}

.horizontal-tabs-year-badge {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 2px 10px;

  width: 54px;
  height: 24px;

  background-color: white;
  border-radius: 16px;
}

.horizontal-tabs-year-badge-active {
  background-color: #f2f4f7 !important;
}

.container-top {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 278px;
  width: 100%;
  padding: 20px 16px 20px 16px;

  border: 1px solid #e4e7ec;
  box-shadow: 0px 1px 2px 0px #1018280f;

  box-shadow: 0px 1px 3px 0px #1018281a;
}

.next-level {
  font-size: 16px;
  line-height: 24px;
  font-family: $fontFamily;
  @include get-font-weight($key: 'Regular');
  color: #667085;
}

.lightning-icon-circle {
  display: block;
  position: relative;
  width: 45px;
  height: 45px;
  top: -10px;
}

.price-text {
  position: relative;
  top: 10px;
  font-size: 20px;
  line-height: 30px;
  font-family: $fontFamily;
  @include get-font-weight($key: 'SemiBold');
  color: #000000;
}

.subscription-type-text {
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -2%;
  font-family: $fontFamily;
  @include get-font-weight($key: 'SemiBold');
  color: #6941c6;
}

.under-price-text {
  width: 320px;
  // height: 24px;

  /* Text md/Normal */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Gray/500 */

  color: #667085;
}

.card-content-check-items-text {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 12px;
  // margin-left: 32px;
  // padding: 32px 32px 12px;
  gap: 12px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.card-content-check-items-text-check {
  width: 24px;
  height: 24px;

  /* Primary/100 */

  background: #f4ebff;
  border-radius: 12px;
}
.card-content-check-items-text-checkIcon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-content-check-items-text-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
}

.card-content-check-items-text-wrapText {
  /* Text md/Normal */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Gray/500 */

  color: #667085;
}

.container-bottom {
  display: block;
  position: relative;
  // height: 384px;
  // width: 409px;
  width: 100%;
  left: 0px;
  // top: 278px;
  border-radius: 0px;
  padding: 32px 24px 32px 24px;
  margin-top: 24px;
}

.list-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.check-icon {
  width: 25px;
  height: 25px;
  margin-right: 11px;
}

.list-item-text {
  font-size: 16px;
  line-height: 24px;
  font-family: $fontFamily;
  @include get-font-weight($key: 'Regular');
  color: #667085;
}

.title-container {
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
}

.title-text {
  font-size: 16px;
  line-height: 24px;
  font-family: $fontFamily;
  @include get-font-weight($key: 'Medium');
  color: #101828;
}

.content-container {
  display: block;
}

.arrow-container {
  position: absolute;
  left: -16%;
  top: 31%;
}

.arrow-container-text {
  display: flex;
  position: relative;
  left: -64%;
  font-size: 30px;
  line-height: 43px;
  font-family: $fontFamily;
  @include get-font-weight($key: 'Medium');
  color: #6941c6;
}

.arrow-container-icon {
  height: 90px;
  width: 133px;
}

.content-text {
  font-size: 14px;
  line-height: 20px;
  font-family: $fontFamily;
  @include get-font-weight($key: 'Regular');
  color: #667085;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 93px;
  width: 100%;
}

.save-button {
  display: flex !important;
  height: 60px !important;
  width: 273px !important;
  left: 0px !important;
  top: 0px !important;
  border-radius: 8px !important;
  padding: 16px 20px 16px 28px !important;
  color: white !important;
  font-family: $fontFamily !important;
  @include get-font-weight($key: 'Regular');
  font-size: 18px !important;
  line-height: 28px !important;
  background-color: #7f56d9 !important;
  text-transform: none !important;
  border-radius: 9px !important;
}
.button-icon {
  width: 22px;
  height: 22px;
  margin-right: 5px;
  margin-left: 5px;
}

@media screen and (max-width: 1060px) {
  .arrow-container {
    display: none;
  }
}
