@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.lawyerProfileCardContainer {
  width: 100%;
  position: relative;
  top: -50px;
  display: flex;
  flex-flow: column wrap;
  gap: 10px;
}

.lawyerProfileImageContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.lawyerProfileImage {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  object-fit: cover;
}

.lawyerProfileImagePlaceholder {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  object-fit: cover;
}

.lawyerDataContainer {
  width: 100%;

  display: flex;
  flex-flow: column wrap;
  align-items: center;
  gap: 10px;
}

.lawyerNameSubscriptionVerifyContainer {
  display: flex;
  flex-direction: row;
  font-family: $fontFamily;
  font-size: 30px;
  gap: 8px;
}

.lawyerSubscriptionVerifyContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.lawyerSubscriptionContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  // width: 76px;
  height: 28px;
}

.lawyerSubscriptionInnerContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 4px 10px 4px 12px;
  gap: 8px;

  width: 100%;
  height: 100%;

  background: #f9f5ff;
  border-radius: 16px;
}

.subscriptionLogo {
  width: 20px;
  height: 20px;
}

.subscriptionType {
  width: 59px;
  height: 20px;

  font-family: $fontFamily;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #6941c6;
}

.verifyContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
}

.verifyInnerContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 104px;
  height: 28px;
  padding: 4px 10px 4px 12px;
  background: #ecfdf3;
  border-radius: 16px;
}

.verifyLogo {
  color: #12b76a;
  order: 0;
  margin-bottom: 5px;
  position: relative;
  top: 50%;
  // ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.verifyText {
  width: 66px;
  height: 20px;
  font-family: $fontFamily;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #027a48;
  order: 1;
  margin: 0px 4px;
}

.ratingContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.ratingText {
  vertical-align: middle;
  color: #667085 !important;
  font-family: $fontFamily !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-transform: none !important;
  background-color: transparent !important;
}

.fachanwaltContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  color: #667085;
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  width: 450px;

  text-align: center;

  h3 {
    font-size: 16px;
    line-height: 24px;
    margin: 0px;
  }
}

.specialitiesTagsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 0px;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 12px;
}

.appointmentButton {
  margin-right: 40px;

  height: 38.5px;

  background-color: #7f56d9 !important;
  color: #fff !important;
  font-size: 13px !important;
  // height: 34px !important;
  text-transform: none !important;
  font-family: $fontFamily !important;
  @include get-font-weight($key: 'Regular');
  border-radius: 7px !important;
  padding-left: 13px !important;
  padding-right: 15px !important;
}

.appointmentButtonHref {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 40px;

  height: 38.5px;

  background-color: #7f56d9 !important;
  color: #fff !important;
  font-size: 13px !important;
  // height: 34px !important;
  text-transform: none !important;
  font-family: $fontFamily !important;
  @include get-font-weight($key: 'Regular');
  border-radius: 7px !important;
  padding-left: 13px !important;
  padding-right: 15px !important;
}

.requestAppointmentButton {
  margin-right: 40px;

  background-color: #ffffff !important;
  color: #7f56d9 !important;
  border: 1px solid #7f56d9 !important;
  font-size: 13px !important;
  height: 34px !important;
  text-transform: none !important;
  font-family: $fontFamily !important;
  @include get-font-weight($key: 'Regular');
  border-radius: 7px !important;
  padding-left: 13px !important;
  padding-right: 15px !important;
}

.requestAppointmentButtonHref {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 40px;

  background-color: #ffffff !important;
  color: #7f56d9 !important;
  border: 1px solid #7f56d9 !important;
  font-size: 13px !important;
  height: 34px !important;
  text-transform: none !important;
  font-family: $fontFamily !important;
  @include get-font-weight($key: 'Regular');
  border-radius: 7px !important;
  padding-left: 13px !important;
  padding-right: 15px !important;
}

@media screen and (max-width: 1060px) {
  .lawyerProfileCardContainer {
    // border: 1px solid orange;
    align-items: center;
    justify-content: center;
  }

  .lawyerNameSubscriptionVerifyContainer {
    flex-direction: column;
    align-items: center;
    font-size: 25px;
  }
}
