@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$phoneBreakpoint: map-get($screen-width-breakpoints, $key: 'phone');
$tabletBreakpoint: map-get($screen-width-breakpoints, $key: 'tablet');

.invoice-list-section-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 30.72px;
  width: 100%;
  .invoice-list-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 31px;
    width: 100%;
    padding: 0px 40px;

    @media (max-width: $phoneBreakpoint) {
      align-items: center;
    }

    .invoice-list-header-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px 0px 1.28014px;
      gap: 25.6px;

      width: 100%;
      height: 95.28px;
      .invoice-list-header-content {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 20.48px;

        width: 100%;
        height: 67.12px;
        .invoice-list-header-title-text {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          flex-grow: 1;
          padding: 0px;
          gap: 5.12px;
          .invoice-list-header-title {
            height: 36px;

            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 23.0426px;
            line-height: 36px;

            color: #101828;
          }
          .invoice-list-header-text {
            height: 26px;

            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 17.922px;
            line-height: 26px;

            color: #667085;
          }
        }
        .invoice-list-header-action {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0px;
          gap: 15.36px;
          .invoice-list-header-button {
            cursor: pointer;
            box-sizing: border-box;

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 12.8014px 20.4823px;
            gap: 10.24px;

            // width: 186.81px;
            height: 51.6px;

            background: #ffffff;

            border: 1.28014px solid #d0d5dd;
            box-shadow: 0px 1.28014px 2.56028px rgba(16, 24, 40, 0.05);
            border-radius: 10.2411px;
          }
          .invoice-list-header-button-disabled {
            cursor: auto;
            box-sizing: border-box;

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 12.8014px 20.4823px;
            gap: 10.24px;

            // width: 186.81px;
            height: 51.6px;

            background: #ffffff;

            border: 1.28014px solid #d0d5dd;
            box-shadow: 0px 1.28014px 2.56028px rgba(16, 24, 40, 0.05);
            border-radius: 10.2411px;
          }
        }
      }
    }
    .invoice-list-header-divider {
      width: 100%;
      height: 1.28px;

      background: #e4e7ec;
    }
  }
}

.invoice-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 30.72px;
  width: 100%;
  // width: 1444px;
  // height: 836.49px;
}

.invoice-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding: 0px 40.9645px;
  gap: 30.72px;

  // width: 1444px;
  // height: 836.49px;
  width: 100%;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.invoice-section-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 0px 1px;
  gap: 20px;

  // width: 100%;
  height: 95.28px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.invoice-section-header-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 20.48px;

  width: 1362.07px;
  height: 67.12px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.text-and-supporting-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 5.12px;

  width: 1154.78px;
  height: 67.12px;

  flex: none;
  order: 0;
  flex-grow: 1;
}

.text {
  width: 1154.78px;
  height: 36px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 23.0426px;
  line-height: 36px;

  margin: 0;

  color: #101828;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.supporting-text {
  width: 1154.78px;
  height: 26px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 17.922px;
  line-height: 26px;

  margin: 0;

  color: #667085;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 15.36px;

  width: 186.81px;
  height: 51.6px;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 186.81px;
  height: 51.6px;

  border-radius: 10.2411px;

  flex: none;
  order: 2;
  flex-grow: 0;
}

.button-base {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12.8014px 20.4823px;
  gap: 10.24px;

  width: 186.81px;
  height: 51.6px;

  background: #ffffff;

  border: 1.28014px solid #d0d5dd;
  box-shadow: 0px 1.28014px 2.56028px rgba(16, 24, 40, 0.05);
  border-radius: 10.2411px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.download-cloud {
  width: 25.6px;
  height: 25.6px;

  color: #344054;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.button-text {
  width: 110px;
  height: 26px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 17.922px;
  line-height: 26px;

  margin: 0;

  color: #344054;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.divider {
  width: 1362.07px;
  height: 1.28px;

  background: #e4e7ec;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

@media screen and (max-width: 1480px) {
  .text-and-supporting-text,
  .text,
  .supporting-text {
    width: 70%;
  }

  .invoice-section,
  .invoice-section-header,
  .invoice-section-header-content,
  .divider {
    width: 100%;
  }
}

@media screen and (max-width: 1060px) {
  .text {
    font-size: 20px;
  }

  .supporting-text {
    font-size: 14px;
  }

  .text,
  .supporting-text {
    width: 100%;
  }

  .text-and-supporting-text {
    width: 55%;
  }
}
