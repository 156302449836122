@import 'Assets/theme.scss';

$checkboxSize: 16px;
$checkboxRadius: 4px;

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.signUp {
  display: flex;
  flex-direction: row;
  align-items: center;
  // padding: 0px;
  padding: 40px 0px;
  // margin-top: 80px;
  position: relative;
  max-width: 100%;
  height: 960px;

  background: #ffffff;
}

.registerLawyerOneThirdSection {
  display: flex;
  width: 100%;
  // justify-content: center;
  align-items: flex-end;

  margin-bottom: 24px;
}

.error-message {
  color: red;
  font-size: 0.8em;
  margin-top: 5px;
  font-family: $fontFamily;
}

.leftSection {
  max-width: 100%;
  // height: 960px;

  display: flex;
  justify-content: center;
  align-items: center;
  background: url('../../pictures/registerBackgroundImage.svg');
  background-position: center;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 120px;
  gap: 32px;

  position: relative;
  height: 674.08px;
  left: 0px;
  right: 0px;
  // top: calc(50% - 674.08px / 2 - 64px);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 48px;

  width: 100%;
  height: 674.08px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.registerLawyerLogo {
  width: 86px;
  height: 86.08px;

  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 2;
}

.textAndSupportingText {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 24px;

  width: 100%;
  // height: 444px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  z-index: 2;
}

.text {
  width: 672px;
  // height: 330px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 72px;
  line-height: 90px;
  margin: 0;

  letter-spacing: -0.02em;

  color: #ffffff;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.supportingText {
  width: 466px;
  // width: 100%;
  // height: 60px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin: 0;

  color: #e9d7fe;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.lawyersContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;

  width: 100%;
  height: 48px;

  flex: none;
  order: 2;
  flex-grow: 0;

  z-index: 2;
}

.avatarGroup {
  display: flex;
}

.ratingContainer {
  display: flex;
  width: 397px;
  height: 48px;
  margin-left: 10px;
  flex-direction: column;
  justify-content: center;
}

.starsContainer {
  display: flex;
  align-items: center;
}

.ratingText {
  margin-left: 10px;
  font-family: $fontFamily;
  @include get-font-weight($key: 'SemiBold');
  color: black;
  font-size: 1.2em;
}

.reviewsContainer {
  display: block;
}

.reviewsText {
  width: 100%;
  height: auto;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #e9d7fe;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.backgroundOverlay {
  position: absolute;
  left: 0px;
  right: 0px;
  // top: 0px;
  // bottom: 0px;

  max-width: 100%;
  height: 92%;

  background: linear-gradient(45deg, #42307d 0%, #7f56d9 100%);
  opacity: 0.8;
}

.rightSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  width: 528px;
  // height: 960px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.rightSectionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 64px;

  width: 528px;
  // height: 628px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.rightSectionContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;
  // gap: 64px;

  width: 400px;
  // height: 570px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.rightSectionTextAndSupportingText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  margin-bottom: -20px;

  width: 400px;
  // height: 220px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.registerLawyerPurpleLogo {
  // width: 48px;
  // height: 48px;

  filter: drop-shadow(0px 1.5px 4.5px rgba(16, 24, 40, 0.1))
    drop-shadow(0px 1.5px 3px rgba(16, 24, 40, 0.06));

  flex: none;
  order: 0;
  flex-grow: 0;
}

.rightSectionText {
  width: 25rem;
  height: 4.75rem;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 38px;

  color: #101828;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.rightSectionSupportingText {
  width: 25rem;
  // height: 4.5rem;
  margin-bottom: 0px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;

  color: #667085;

  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}

.rightSectionInputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  // gap: 24px;
  // gap: 35px;

  width: 25rem;
  // height: 15.65rem;

  border-radius: 12px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.rightSectionForm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 0px;
}

.firstNameInputField {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.lastNameInputField {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 24px;
}

.emailInputField {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 24px;
}

.label-input {
  width: 5rem;
  height: 1.25rem;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #344054;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.registerInput {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;

  width: 24.813rem;
  height: 2.75rem;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.inputWithLabel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 6px;

  width: 24.813rem;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  margin-top: 24px;
  width: 25rem;
  height: 2.75rem;

  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}

.registerButton {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 25rem;
  height: 2.75rem;

  background: #7f56d9;

  border: 1px solid #7f56d9;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  flex: none;
  order: 0;
  flex-grow: 1;
}

.buttonText {
  width: 8.43rem;
  height: 1.5rem;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 24px;
  margin: 0;

  color: #ffffff;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  margin-top: 24px;
  width: 25rem;
  height: 1.25rem;

  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}

.loginText {
  width: 13.813rem;
  height: 1.25rem;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 0.87rem;
  line-height: 20px;

  color: #667085;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.loginButton {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 6.62rem;
  height: 1.25rem;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.loginButtonBase {
  display: flex;
  flex-direction: row;
  justify-content: center;
  // align-items: center;
  padding: 0px;
  gap: 8px;

  width: 6.62rem;
  height: 1.25;

  flex: none;
  order: 0;
  flex-grow: 0;
  background-color: #ffffff;

  outline: none;
  border: none;
}

.loginButtonText {
  width: 6.62rem;
  height: 1.25rem;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 20px;
  margin: 0;

  color: #6941c6;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.hintText {
  width: 22.5rem;
  height: 1.25rem;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 20px;
  margin: 0;

  color: #667085;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.disabledButton {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 100%;
  height: 2.75rem;

  background: grey;

  border: 1px solid grey;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  flex: none;
  order: 0;
  flex-grow: 1;
}



.checkbox-label-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 164px;
  height: 20px;
}

@media screen and (max-width: 1060px) {
  .signUp {
    flex-direction: column-reverse;
    width: 628px;
    height: 100%;
  }

  .rightSectionText {
    font-size: 1.5rem;
  }

  .supportingText {
    font-size: 18px;
  }

  .registerLawyerLogo {
    width: 65px;
    height: 65px;
  }

  .text {
    width: 100%;
    height: unset;
    font-size: 48px;
    line-height: 80px;
  }

  .textAndSupportingText {
    height: unset;
  }

  .rightSection {
    margin: 0 auto;

    height: 960px;

    // padding: 7rem 0;
  }

  .rightSectionContainer {
    height: unset;
  }

  .backgroundOverlay {
    // height: 50%;
    height: 48%;
    top: unset;
  }

  .container {
    position: absolute;
    top: calc(80% - 337.04px - 64px);
    padding: 0 100px;
  }

  .lawyersContainer {
    width: 500px;
    height: unset;
  }

  .leftSection {
    height: 960px;
  }

  .content {
    width: 466px;
  }
}

@media screen and (max-width: 1480px) and (min-width: 1060px) {
  .backgroundOverlay {
    width: 50%;
    // height: 100%;
  }

  .content {
    width: 400px;
  }

  .container {
    // padding: 0 100px;
    padding: 0 50px;
  }

  .textAndSupportingText {
    height: unset;
  }

  .text {
    width: 100%;
    height: unset;
    // font-size: 32px;
    font-size: 48px;
    line-height: 80px;
  }

  .leftSection {
    width: 50%;
  }

  .rightSection {
    width: 50%;
  }

  .rightSectionContainer {
    width: 100%;
    padding: 0;
  }

  .rightSectionContent {
    width: 80%;
  }

  .rightSectionTextAndSupportingText,
  .rightSectionText,
  .rightSectionSupportingText,
  .rightSectionInputs,
  .rightSectionForm,
  .firstNameInputField,
  .lastNameInputField,
  .emailInputField,
  .inputWithLabel,
  .registerInput,
  .actions,
  .registerButton,
  .row {
    width: 100%;
  }
}

@media screen and (min-width: 1480px) {
  .backgroundOverlay {
    width: 50%;
    // height: 100%;
  }

  .leftSection {
    width: 50%;
  }

  .rightSection {
    width: 50%;
  }

  .rightSectionContainer {
    width: 100%;
  }
}
