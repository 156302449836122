@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$phoneBreakpoint: map-get($screen-width-breakpoints, $key: 'phone');
$tabletBreakpoint: map-get($screen-width-breakpoints, $key: 'tablet');

.client-profile-settings-page-container {
  display: block;
  width: 95.5%;
  margin: auto;
  margin-bottom: 50px;
}

.client-profile-settings-snackbar {
  width: 100% !important;
  background-color: #7f56d9 !important;
  font-family: $fontFamily !important;
}

.client-profile-settings-top-menu {
  margin-top: 0px;
}

.client-profile-settings-edit-save-changes-section-not-displaying,
.client-profile-settings-divider-not-displaying {
  display: none;
}

.client-profile-settings-edit-save-changes-section {
  padding: 30px 30px 30px 60px;
}

.client-profile-settings-divider {
  width: 100%;
  height: 1px;

  background: #e4e7ec;

  margin: 0px 0px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.client-profile-settings-section {
  display: flex;
  width: 100%;
  padding: 30px 30px 30px 60px;
  flex-direction: row;
  margin-top: 40px;
  margin-bottom: 40px;
}

.client-profile-settings-section-information {
  width: 30%;
  display: flex;

  @media (max-width: $phoneBreakpoint) {
    width: 100%;
    justify-content: flex-start;
  }
}

.client-profile-settings-section-information-container {
  width: 70%;

  @media (max-width: $phoneBreakpoint) {
    width: 100%;
  }
}
.client-profile-settings-section-content {
  width: 70%;

  @media (max-width: $phoneBreakpoint) {
    width: 100%;
  }
}

.client-profile-settings-edit-save-changes-section-bottom {
  margin-top: 10px;
  margin-bottom: 30px;
}

.favorite-lawyers-filter {
  margin-left: 64px;
  display: block;
  width: 25%;

  @media (max-width: $tabletBreakpoint) {
    display: none;
  }
}

.favorite-lawyer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 30px;
  gap: 64px;

  width: 1423px;
  height: 1010px;

  background: #ffffff;

  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}

.client-profile-settings-image-dropzone-container {
  display: block;
  width: 512px;
  height: 150;
}

.loading-container {
  display: flex;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  width: 1000px;
}

.client-profile-settings-section-title {
  font-weight: 500;
  color: #344054;
  font-family: $fontFamily;

  font-size: 14px;

  margin-top: 20px;
  margin-bottom: 5px;
}

@media screen and (max-width: 1060px) {
  .client-profile-settings-section {
    flex-direction: column;
    align-items: center;
  }

  .client-profile-settings-section-information-container,
  .client-profile-settings-image-dropzone-container {
    width: 100%;
    align-items: center;
  }
}

.loading-overlay {
  position: fixed;
  top: 80px; /* Adjust the top position based on your navbar height */
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 1);
  z-index: 9999;
}
