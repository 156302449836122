@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.send-message-to-lawyer-not-logged-modal-success-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 230px;
  height: 44px;

  background: #7f56d9;

  border: 1px solid #7f56d9;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #ffffff;
}

.send-message-to-lawyer-not-logged-modal-success-button-disabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 230px;
  height: 44px;

  background: gray;

  border: 1px solid gray;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #ffffff;
}

.send-message-to-lawyer-not-logged-modal-error-message {
  font-family: $fontFamily;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  width: 100%;

  color: #ff0000;
}

.send-message-to-lawyer-not-logged-name-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  position: absolute;
  width: 100%;
  height: 100%;
  left: 7217px;
  top: 7459px;

  //   background: rgba(52, 64, 84, 0.6);

  //   backdrop-filter: blur(8px);
}

.send-message-to-lawyer-not-logged-name-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3px;

  width: 420px;
  height: 714px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background: #ffffff;

  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1),
    0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  border-radius: 12px;
}

.send-message-to-lawyer-not-logged-scroll-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 32px;

  width: 100%;
  height: 714px;
  overflow-y: auto;

  background: #ffffff;
}

.send-message-to-lawyer-not-logged-name-modal-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;

  width: 100%;
  // height: 610px;
}

.send-message-to-lawyer-not-logged-name-modal-avatar-label-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 12px;

  width: 100%;
  // height: 40px;
}

.send-message-to-lawyer-not-logged-name-modal-profile-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  width: 40px;
  height: 40px;

  object-fit: cover;
  object-position: 50% 50%;

  border-radius: 200px;
}

.send-message-to-lawyer-not-logged-name-modal-name-and-email {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 100%;
  height: 40px;
}

.send-message-to-lawyer-not-logged-name-modal-name {
  width: 100%;
  // height: 20px;

  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 8px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #344054;
}

.send-message-to-lawyer-not-logged-name-modal-email {
  width: 100%;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #667085;
}

.send-message-to-lawyer-not-logged-name-modal-text-and-supporting-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  //   gap: 8px;
  gap: 15px;

  width: 100%;
  // height: 116px;
}

.send-message-to-lawyer-not-logged-name-modal-arrow-left {
  cursor: pointer;
}

.send-message-to-lawyer-not-logged-name-modal-text {
  width: 100%;
  // height: 56px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;

  color: #101828;
}

.send-message-to-lawyer-not-logged-name-modal-supporting-text {
  width: 100%;
  // height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #667085;
}

.send-message-to-lawyer-not-logged-name-modal-frame19 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 100%;
  //   height: 180px;
  // min-height: 50px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #667085;
}

.send-message-to-lawyer-not-logged-name-modal-first-name-input-field,
.send-message-to-lawyer-not-logged-name-modal-last-name-input-field {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;

  width: 100%;
  // height: 70px;
}

.send-message-to-lawyer-not-logged-name-modal-email-input-field {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  margin-bottom: -15px;

  width: 100%;
  // height: 70px;
}

.send-message-to-lawyer-not-logged-name-modal-actions {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  width: 100%;
  // height: 44px;
}

.send-message-to-lawyer-not-logged-modal-row-terms {
  display: flex;
  flex-direction: row;
  // justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  // width: 25rem;
  // height: 1.25rem;
}

.send-message-to-lawyer-not-logged-modal-checkbox-container-selected {
  width: 16px;
  height: 16px;
  max-width: 16px;
  background-color: #f9f5ff;
  border: 2px solid #7f56d9;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  margin-top: 2px;
}

.send-message-to-lawyer-not-logged-modal-check-button {
  width: 16px !important;
  height: 16px !important;
  min-width: 16px !important;
}

.send-message-to-lawyer-not-logged-modal-checkbox-container {
  width: 16px;
  height: 16px;
  max-width: 16px;
  background-color: #ffffff;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 2px solid #d0d5dd;
  border-radius: 4px;

  margin-top: 2px;
}

.send-message-to-lawyer-not-logged-modal-check-style-selected-img {
  font-size: 16px;
  height: 14px;
  margin-right: 1px;
  margin-top: 2px;
  color: #7f56d9;
}

.send-message-to-lawyer-not-logged-modal-check-style-img {
  display: none;
  font-size: 16px;
  color: rgba(0, 0, 0, 0);
}

.send-message-to-lawyer-not-logged-modal-checkbox-text-label {
  // width: 140px;
  // height: 20px;
  // height: 16px;

  /* Text sm/Medium */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Gray/700 */

  color: #344054;
}

.send-message-to-lawyer-not-logged-name-modal-cancel-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 168px;
  height: 44px;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #344054;
}

.stepper-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 12px;

  width: 60px;
  // height: 24px;
}

.check-step-icon-base {
  width: 24px;
  height: 24px;

  position: relative;

  background: #7f56d9;
  border-radius: 12px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.checked-icon-content {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;

  border-radius: 16px;
}

.check-icon {
  position: absolute;
  left: 26.46%;
  right: 23.56%;
  top: 29.65%;
  bottom: 26.58%;
}

.step-icon-base {
  width: 24px;
  height: 24px;

  position: relative;

  background: #f9f5ff;

  box-shadow: 0px 0px 0px 4px #f4ebff;
  border-radius: 12px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.checkbox-link {
  /* Text md/Medium */
  text-decoration: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  // font-size: 16px;
  line-height: 24px;

  /* identical to box height, or 150% */

  /* Primary/700 */
  color: #6941c6;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: #6941c6;
  }
}

.selected-icon-content {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;

  background: #7f56d9;
  border-radius: 12px;
}

.selected-dot {
  position: absolute;
  width: 8px;
  height: 8px;
  left: calc(50% - 8px / 2);
  top: calc(50% - 8px / 2);

  background: #ffffff;
  border-radius: 12px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 100%;
  height: 20px;

  flex: none;
  align-self: stretch;
  flex-grow: 0;
}

.loginText {
  max-width: 100%;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #667085;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.loginButton {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  max-width: 100%;
  height: 20px;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.loginButtonBase {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 106px;
  height: 20px;

  flex: none;
  order: 0;
  flex-grow: 0;
  background-color: #ffffff;

  outline: none;
  border: none;
}

.loginButtonText {
  width: 106px;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;

  color: #6941c6;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.ask-lawyer-to-set-schedule-modal-profile-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  width: 40px;
  height: 40px;

  object-fit: cover;
  object-position: 50% 50%;

  border-radius: 200px;
}

@media screen and (max-width: 1060px) {
  .send-message-to-lawyer-not-logged-name-modal-actions-cancel-event-button {
    font-size: 12px;
  }
}
