@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.formContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 24px;
  //   margin-top: 15%;
  width: 360px;
  // height: 258px;

  border-radius: 12px;

  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}

.passwordInput {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 6px;

  width: 360px;
  // height: 96px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.inputWithLabel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 6px;

  width: 360px;
  // height: 70px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.passwordLabel {
  width: 70px;
  // height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #344054;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.setPasswordInput {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;

  width: 360px;
  // height: 44px;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}
