@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.lawp-cookies-accept-bar-decline-button {
  display: inline-block !important;
  height: 45px !important;
  left: 0px !important;
  top: 0px !important;
  border-radius: 8px !important;
  padding: 5px 15px 5px 15px !important;
  color: #344054 !important;
  font-family: $fontFamily !important;
  @include get-font-weight($key: 'Medium');
  font-size: 14px !important;
  line-height: 20px !important;
  background-color: white !important;
  text-transform: none !important;
  border-radius: 9px !important;
  border: 1px solid #d6dae1 !important;
}

.lawp-cookies-accept-bar-accept-button {
  display: inline-block !important;
  height: 44px !important;
  left: 0px !important;
  top: 0px !important;
  border-radius: 8px !important;
  padding: 5px 16px 5px 16px !important;
  color: white !important;
  font-family: $fontFamily !important;
  @include get-font-weight($key: 'Medium');
  font-size: 14px !important;
  line-height: 20px !important;
  background-color: #7f56d9 !important;
  text-transform: none !important;
  border-radius: 9px !important;
}

.lawp-cookies-accept-bar-learn-more-text {
  cursor: pointer;
  text-decoration: underline;
}

.lawp-cookies-accept-bar-container-class {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  font-family: $fontFamily !important;
  @include get-font-weight($key: 'Medium');
  font-size: 14px !important;
  line-height: 20px !important;
}

.lawp-cookies-accept-bar-button-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 10px;
}

.lawp-cookies-accept-bar-text {
  flex: 1 !important;
}
