@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.register-lawyer-header {
  font-family: $fontFamily;
  color: #101828;
  font-size: 48px;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: -0.02em;
  text-align: center;

  margin-bottom: 24px;

  width: 768px;

  @media screen and (max-width: 1480px) {
    width: 526px;
  }
}

.register-lawyer-description {
  font-family: $fontFamily;
  color: #667085;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;

  width: 768px;

  @media screen and (max-width: 1480px) {
    width: 467px;
  }
}

.available-lawyers-container {
  width: 768px;

  max-height: 500px;
  overflow-y: auto;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 50px;
  // background-color: #667085;
  padding: 20px 50px;

  @media screen and (max-width: 1480px) {
    width: 467px;
  }
}

.available-lawyer-container {
  cursor: pointer;
  // background-color: #d6bbfb;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: black;
  border: 1px solid #e4e7ec;
  /* Shadow/lg */

  padding: 40px 0;

  width: 280px;
  height: 368px;

  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1),
    0px 4px 6px -2px rgba(16, 24, 40, 0.05);
  border-radius: 16px;

  .available-lawyer-image {
    /* Auto layout */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;

    width: 80px;
    height: 80px;

    /* Avatar user square/Olivia Rhye (color background) */
    border-radius: 40px;
    object-fit: cover;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .text-container {
    display: flex;
    flex-direction: column;

    gap: 10px;
  }

  .name-text {
    /* Text lg/Medium */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    // line-height: 28px;

    /* identical to box height, or 156% */
    text-align: center;
    align-self: stretch;
    /* Gray/900 */
    color: #101828;

    margin: 0;
    // margin-top: 15px;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical;
    white-space: normal;
  }

  .profile-checker-text {
    margin: 0;

    /* Text md/Normal */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    // line-height: 24px;

    /* identical to box height, or 150% */
    text-align: center;
    align-self: stretch;

    /* Gray/500 */
    color: #667085;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 1 !important;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
}

.available-lawyer-container.active {
  background-color: coral;
}

.background-unselected {
  opacity: 0.5;

  border: 1px solid #e4e7ec;
  /* Shadow/lg */

  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1),
    0px 4px 6px -2px rgba(16, 24, 40, 0.05);
  border-radius: 16px;
}
.background-selected {
  opacity: 1;

  border: 1px solid #e4e7ec;
  /* Shadow/lg */

  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1),
    0px 4px 6px -2px rgba(16, 24, 40, 0.05);
  border-radius: 16px;
}
.available-lawyer-highlighed {
  background-color: coral;
}

.emailCheckerContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 50px 0;
  gap: 50px;

  width: 976px;
  min-height: 1032px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;

  @media screen and (max-width: 1480px) and (min-width: 1060px) {
    max-height: 1200px;
    min-height: 1092px;
  }
}

.emailCheckerInnerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 32px;
  gap: 80px;

  width: 976px;
  height: 636px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.emailCheckerAlert {
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 12px;

  width: 343px;
  height: 114px;

  background: #fcfaff;

  border: 1px solid #d6bbfb;
  border-radius: 8px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.emailCheckerAlertContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  width: 311px;
  height: 82px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.badgeGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 12px 4px 4px;
  gap: 8px;

  // width: 274px;
  height: 30px;

  background: #f9f5ff;
  mix-blend-mode: multiply;
  border-radius: 16px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.badge {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 62px;
  height: 22px;

  mix-blend-mode: normal;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.badgeBase {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;

  width: 62px;
  height: 22px;

  background: #ffffff;
  border-radius: 16px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.badgeText {
  width: 46px;
  height: 18px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  margin: 0;

  text-align: center;

  color: #6941c6;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.badgeMessage {
  // width: 188px;
  height: 18px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  margin: 0;

  color: #6941c6;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.emailCheckerSupportingText {
  width: 311px;
  height: 40px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #7f56d9;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.emailCheckerFrame2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 80px;

  width: 360px;
  height: 442px;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.emailCheckerContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  gap: 32px;

  width: 360px;
  height: 442px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.emailCheckerHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 24px;

  width: 360px;
  height: 260px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.emailCheckerHeaderHeadless {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;

  // width: 358px;
  height: 76px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.emailCheckerFeaturedIcon {
  width: 56px;
  height: 56px;

  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  background: #f4ebff;

  border: 10px solid #f9f5ff;
  border-radius: 28px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.emailCheckerKey {
  position: absolute;
  width: 28px;
  height: 28px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.XIconContainer {
  width: 24px;
  height: 24px;

  display: flex;
  justify-content: center;
  align-items: center;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.avatarLabelGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;

  // width: 210px;
  height: 56px;

  flex: none;
  order: 2;
  flex-grow: 0;
}

.default-avatar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  width: 40px;
  height: 40px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.avatar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  width: 56px;
  height: 56px;
  border-radius: 200px;

  object-fit: cover;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.lawyerNameAndEmail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 238px;
  height: 52px;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.lawyerName {
  // width: 95px;
  height: 28px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  margin: 0;

  color: #344054;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.lawyerEmail {
  // width: 138px;
  height: 24px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0;

  text-decoration-line: underline;

  color: #667085;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.emailCheckerHeaderTextAndSupportingText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 12px;

  width: 360px;
  height: 160px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.text {
  width: 360px;
  height: 76px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  margin: 0;

  text-align: center;

  color: #101828;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.textSupport {
  width: 360px;
  height: 72px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0;

  text-align: center;

  color: #667085;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.emailCheckButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  padding-bottom: 20px;
  gap: 24px;

  width: 360px;
  // height: 44px;

  border-radius: 12px;

  flex: none;
  order: 1;
  // align-self: stretch;
  flex-grow: 0;
}

.Button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 360px;
  height: 44px;

  border-radius: 8px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.buttonBase {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 360px;
  height: 44px;

  background: #7f56d9;

  border: 1px solid #7f56d9;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  flex: none;
  order: 0;
  flex-grow: 1;
}

.buttonText {
  width: 126px;
  height: 24px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 0;

  color: #ffffff;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.emailCheckerCreateAccountButton {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 348px;
  height: 20px;

  flex: none;
  order: 2;
  flex-grow: 0;
}

.CreateAccountButtonBase {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 348px;
  height: 20px;
  border: none;
  background: none;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.refreshIcon {
  width: 20px;
  height: 20px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.CreateAccountButtonText {
  width: 320px;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;

  color: #667085;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.emailCheckerHintText {
  flex: none;
  order: 3;
  flex-grow: 0;
}

@media screen and (max-width: 1060px) {
  .emailCheckerContainer {
    // padding: 128px 0;
    // padding-bottom: 379px;
    width: 100%;
    // height: 100%;
  }

  .emailCheckerInnerContainer {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 1480px) and (min-width: 1060px) {
  .emailCheckerContainer {
    width: 50%;
  }

  .emailCheckerInnerContainer {
    width: 100%;
  }
}

@media screen and (min-width: 1480px) {
}
