@import "Assets/theme.scss";

$phoneBreakpoint: map-get($screen-width-breakpoints, $key: "phone");
$tabletBreakpoint: map-get($screen-width-breakpoints, $key: "tablet");

.gray-container {
  display: flex;
  flex-direction: column;

  padding: 32px 32px 40px;
  gap: 24px;

  width: 90%;

  height: fit-content;

  /* Gray/50 */

  background: #f9fafb;
  border-radius: 16px;
}

.gray-container-header {
  width: 100%;

  margin-bottom: 24px;

  /* Display sm/Semibold */

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  /* or 127% */

  /* Gray/900 */

  color: #101828;
}

.gray-container-paragraph {
  width: 100%;

  /* Text lg/Normal */

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  /* or 156% */

  /* Gray/500 */

  color: #667085;
}

.button-text {
  display: inline-block;
  align-items: center;
  width: fit-content;
  /* Text lg/Normal */
  position: relative;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  /* or 156% */
  border: none;
  margin-top: 28px;
  background: none;
  color: #6941c6;
}
.content {
  width: 100%;
  height: fit-content;

  /* Text lg/Normal */

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  /* or 156% */

  /* Gray/500 */

  color: #667085;
}
