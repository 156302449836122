@import "Assets/theme.scss";

$primaryColor: map-get($theme-colors, $key: "primaryColor");
$fontFamily: map-get($theme-font, $key: "primaryFontFamily");

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.title-container {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
}

.title-text {
  font-size: 17px;
  line-height: 20px;
  font-family: $fontFamily;
  @include get-font-weight($key: "Medium");
}

.content-container {
  display: block;
}

.content-text {
  font-size: 13px;
  line-height: 20px;
  font-family: $fontFamily;
  @include get-font-weight($key: "Regular");
  color: gray;
}

.text-field-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.copy-button-container {
  margin-bottom: 5px !important;
  margin-left: 5px !important;
  //   border: 1px solid green;
}

.save-button {
  display: inline-block !important;
  height: 44px !important;
  width: 100% !important;
  left: 0px !important;
  top: 0px !important;
  border-radius: 8px !important;
  padding: 5px 16px 5px 16px !important;
  color: white !important;
  font-family: $fontFamily !important;
  @include get-font-weight($key: "Medium");
  font-size: 14px !important;
  line-height: 20px !important;
  background-color: #7f56d9 !important;
  text-transform: none !important;
  border-radius: 9px !important;
}
