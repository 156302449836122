@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.lawp-lawyer-page-article-section-center-container {
  display: flex;
  // width: 100%;
  width: 95%;
  justify-content: center;

  padding: 40px;

  // margin-bottom: 100px;

  @media (max-width: 1480px) {
    padding: 40px 0;
  }
}

.lawp-lawyer-page-article-section-container {
  display: flex;
  // width: 90%;
  width: 100%;
  // height: 500px;
  // flex-direction: column;
  // border: 1px solid red;
  //below 1060 px
  @media (max-width: 1480px) {
    flex-direction: column;
    gap: 20px;
    // height: 1000px;
  }
}

//description
.lawp-lawyer-page-article-section-description-container {
  display: flex;
  width: 40%;

  @media (max-width: 1480px) {
    width: 100%;
    // height: 1000px;
  }
}

.lawp-lawyer-page-article-section-all-article-container {
  width: 70%;

  display: flex;
  flex-direction: row;
  gap: 10px;

  @media (max-width: 1480px) {
    width: 100%;
    gap: 0px;
  }

  @media (max-width: 1060px) {
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    gap: 10px;
    margin-left: -5px;
  }
}

.lawp-lawyer-page-article-section-article-container {
  display: flex;
  width: 50%;
  justify-content: center;
}

.lawp-lawyer-page-article-section-public-title-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 70%;
  margin-left: 22px;

  box-sizing: border-box;
}

.lawp-lawyer-page-article-section-client-name {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  /* identical to box height, or 143% */

  /* Primary/700 */
  color: #6941c6;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  margin-bottom: 15px;
}

.lawp-lawyer-page-article-section-review-title {
  font-size: 36px !important;
  line-height: 32px !important;
  font-family: $fontFamily !important;
  @include get-font-weight($key: 'Medium');
  color: #101828;
}

.lawp-lawyer-page-article-section-lawyer-description {
  margin-top: 20px !important;

  width: 100%;
}

.lawp-lawyer-page-article-section-lawyer-description-text {
  font-family: $fontFamily;
  @include get-font-weight($key: 'Regular');
  color: grey;
  font-size: 18px;
  line-height: 24px;
}

.lawp-lawyer-page-article-section-view-all-articles-button-container {
  display: flex;
  width: 100%;
  margin-top: 20px;
}

.lawp-lawyer-page-article-section-view-all-articles-button {
  display: inline-block !important;
  height: 48px !important;
  width: 183px !important;

  padding: 5px 16px 5px 16px !important;
  color: white !important;
  font-family: $fontFamily !important;
  @include get-font-weight($key: 'Medium');
  font-size: 15px !important;
  line-height: 24px !important;

  border-radius: 8px !important;
  background-color: #7f56d9 !important;
  text-transform: none !important;
  border-radius: 7px !important;

  box-sizing: border-box !important;
}

.lawp-lawyer-page-article-section-no-articles-container {
  display: flex;
  width: 70%;
  justify-content: center;
  align-items: center;
}

.lawp-lawyer-page-article-section-only-one-articles-container {
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
}

.lawp-lawyer-page-article-section-no-articles-container-text {
  font-family: $fontFamily;
  @include get-font-weight($key: 'Regular');
  color: grey;
  font-size: 18px;
  line-height: 24px;
  text-align: center;

  width: 100%;
}

@media screen and (max-width: 1060px) {
  .lawp-lawyer-page-article-section-public-title-container {
    margin-left: unset;
  }

  .lawp-lawyer-page-article-section-review-title {
    font-size: 22px !important;
  }
}
