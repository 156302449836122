@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.stepBlock {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  width: 400px;
  height: 102px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.conectorWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 0px 4px;
  gap: 4px;

  width: 32px;
  height: 102px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.stepIconBase {
  width: 32px;
  height: 32px;
  position: relative;

  background: #f9f5ff;
  box-shadow: 0px 0px 0px 4px #f4ebff;
  border-radius: 16px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.unselectedStepIconBase {
  width: 32px;
  height: 32px;
  position: relative;

  background: #f9fafb;
  border-radius: 16px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.selectedIconContent {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;

  background: #f9f5ff;
  border-radius: 16px;
}

.selectedDot {
  position: absolute;
  width: 10px;
  height: 10px;
  left: calc(50% - 10px / 2);
  top: calc(50% - 10px / 2);
  border-radius: 50%;

  background: #7f56d9;

  z-index: 2;
}

.checkStepIconBase {
  position: relative;
  width: 32px;
  height: 32px;

  background: #f9f5ff;
  border-radius: 16px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.CheckedIconContent {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;

  border-radius: 16px;
}

.checkIcon {
  position: absolute;
  left: 26.46%;
  right: 23.56%;
  top: 29.65%;
  bottom: 26.58%;
}

.unSelectedIconContent {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;

  border-radius: 16px;
}

.unSelectedDot {
  position: absolute;
  width: 10px;
  height: 10px;
  left: calc(50% - 10px / 2);
  top: calc(50% - 10px / 2);
  border-radius: 50%;

  background: #e4e7ec;
}

.selectedConnector {
  width: 2px;
  height: 77px;

  background: #7f56d9;
  border-radius: 2px;

  flex: none;
  order: 1;
  flex-grow: 1;
}

.unselectedConnector {
  width: 2px;
  height: 77px;

  background: #e4e7ec;
  border-radius: 2px;

  flex: none;
  order: 1;
  flex-grow: 1;
}

.selected {
  width: 20px;
  height: 29px;
  line-height: 25px;
  background-color: blue;
  color: black;
  border-radius: 50%;
}

.circle {
  width: 20px;
  height: 29px;
  line-height: 25px;
  background-color: green;
  color: white;
  border-radius: 50%;
  border: 2px solid transparent;
}

.stepTextAndSupportingText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px 0px 24px;
  gap: 2px;

  width: 352px;
  height: 102px;

  flex: none;
  order: 1;
  flex-grow: 1;
}

.stepText {
  width: 352px;
  height: 24px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 0;

  color: #344054;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.stepSupportingText {
  width: 352px;
  height: 48px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #667085;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.selectedStepText {
  width: 352px;
  height: 24px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #6941c6;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.selectedStepSupportingText {
  width: 352px;
  height: 48px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #7f56d9;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.verticalStepWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  width: 400px;
  // height: 102px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.horizontalStepWrapper {
  display: none;
}

.horizontalSelectedConnector {
  width: 48px;
  height: 2px;

  background: #7f56d9;
}

.horizontalUnelectedConnector {
  width: 48px;
  height: 2px;

  background: #e4e7ec;
}

.horizontalStepWrapperSubscription {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0px;
  // gap: 16px;

  width: 100%;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  .stepBlock {
    width: 75px;
    height: unset;
  }

  .stepBlock:last-child {
    width: 32px;
  }

  .conectorWrap {
    flex-direction: row;
    width: 70px;
    height: unset;
  }

  .verticalStepWrapper {
    display: none;
  }

  .stepTextAndSupportingText {
    display: none;
  }

  .unselectedConnector,
  .selectedConnector {
    width: 33px;
    height: 2px;
  }
}

@media screen and (max-width: 1060px) {
  .horizontalStepWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 0px;
    // gap: 16px;

    width: 100%;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }

  .stepBlock {
    width: 75px;
    height: unset;
  }

  .stepBlock:last-child {
    width: 32px;
  }

  .conectorWrap {
    flex-direction: row;
    width: 70px;
    height: unset;
  }

  .verticalStepWrapper {
    display: none;
  }

  .stepTextAndSupportingText {
    display: none;
  }

  .unselectedConnector,
  .selectedConnector {
    width: 33px;
    height: 2px;
  }
}
