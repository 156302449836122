@import 'Assets/theme.scss';

$phoneBreakpoint: map-get($screen-width-breakpoints, $key: 'phone');
$tabletBreakpoint: map-get($screen-width-breakpoints, $key: 'tablet');

.lawp-upcoming-events-accordion-container {
  width: 100%;

  .MuiAccordionSummary-root {
    background-color: transparent !important;
    box-shadow: none !important;
    &:hover {
      box-shadow: none !important;
    }
  }

  .MuiPaper-root {
    background-color: transparent !important;
    box-shadow: none !important;
  }

  .MuiAccordionDetails-root {
    padding: 9px 8px 0px 8px;
  }

  #panel1a-header {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
  }

  .MuiAccordionSummary-content.Mui-expanded {
    display: flex;
    align-self: center;
  }

  .show-more-text {
    animation: none;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;

    color: #6941c6;

    margin: 0;
  }

  #panel1a-content {
    display: flex;
    flex-direction: column;

    gap: 20px;
  }

  .title-text {
    width: 100%;

    display: flex;
    justify-content: flex-start;

    margin-left: 15px !important;

    font-weight: 500;
    font-size: 18px;

    text-align: center;

    margin: 0;
  }

  .description-text {
    white-space: pre-wrap;

    margin-left: 15px;
    margin-bottom: 15px;

    width: 100%;
    height: auto;
  }

  .MuiAccordionDetails-root {
    display: flex;
    align-items: center;
  }

  // Adauga aici mergin left, cat e nevoie, ca sa centrezi sageata;
  // verifica si pe view de tel/tableta
  .MuiAccordionSummary-content {
    // margin-left: -10px !important;

    margin: 0;

    // @media (max-width: $tabletBreakpoint) {
    //   margin-left: ?px !important;
    // }

    // @media (max-width: $phoneBreakpoint) {
    //   margin-left: ?px !important;
    // }
  }
  .MuiAccordionSummary-content.Mui-expanded {
    // margin-left: -10px !important;

    margin: 0;

    // @media (max-width: $tabletBreakpoint) {
    //   margin-left: ?px !important;
    // }

    // @media (max-width: $phoneBreakpoint) {
    //   margin-left: ?px !important;
    // }
  }

  .MuiIconButton-edgeEnd {
    margin: 0;
  }

  .MuiIconButton-root {
    padding: 0;
  }
}
