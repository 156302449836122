@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$tabletBreakpoint: map-get($screen-width-breakpoints, $key: 'tablet');
$phoneWidthBreakpoint: map-get($screen-width-breakpoints, $key: 'phone');

.lawp-home-page-lawyer-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .all-in-one-container {
    display: flex;
    width: 90%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 100px;

    @media (max-width: 1060px) {
      flex-direction: column;
    }

    .left {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 55%;

      @media (max-width: 1060px) {
        //make it column in the parent class
        width: 100%;

        margin-bottom: 64px;
      }

      .container-tag {
        display: flex;
        width: fit-content;
        height: 30px;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        border-radius: 16px;
        padding: 4px 12px 4px 4px;

        background-color: #f9f5ff;
      }

      .article-type-tag {
        background-color: white;
        border-radius: 15px;
        padding: 3px 8px 3px 8px;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;

        text-align: center;

        color: #6941c6;

        margin-right: 8px;
      }

      .article-new-text {
        font-size: 14px;
      }

      .article-read-time {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;

        color: #6941c6;
      }

      .arrow-icon {
        margin-left: 8px;
        font-size: 20px;
      }

      .title {
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 600;
        font-size: 48px;
        line-height: 60px;

        letter-spacing: -0.02em;

        color: #101828;
      }

      .description {
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;

        color: #667085;

        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
      }

      .buttons-container {
        display: flex;
        width: 100%;
        height: 50px;
        align-items: center;
        justify-content: flex-start;
        margin-top: 20px;
      }

      .demo {
        display: flex !important;
        justify-content: center;
        align-items: center;
        height: 45px !important;
        left: 0px !important;
        top: 0px !important;
        border-radius: 8px !important;
        padding: 5px 15px 5px 15px !important;
        color: #344054 !important;
        font-family: $fontFamily !important;
        @include get-font-weight($key: 'Medium');
        font-size: 14px !important;
        line-height: 20px !important;
        background-color: white !important;
        text-transform: none !important;
        border-radius: 9px !important;
        border: 1px solid #d6dae1 !important;

        .css-i4bv87-MuiSvgIcon-root {
          margin-top: 1px;
          margin-left: 1px;
        }
      }

      .sign-up {
        display: inline-block !important;
        width: 160px;
        height: 44px !important;
        left: 0px !important;
        top: 0px !important;
        border-radius: 8px !important;
        padding: 5px 16px 5px 16px !important;
        color: white !important;
        font-family: $fontFamily !important;
        @include get-font-weight($key: 'Medium');
        font-size: 14px !important;
        line-height: 20px !important;
        // margin-left: 15px !important;
        background-color: #7f56d9 !important;
        text-transform: none !important;
        border-radius: 9px !important;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      width: 65%;
      align-items: center;
      justify-content: center;
      margin-left: 20px;

      position: relative;

      @media (max-width: 1060px) {
        //make it column in the parent class
        width: 100%;
        margin-left: 0;
      }

      .mock-up {
        position: relative;
        width: 100%;
      }

      .mock-up-image {
        position: absolute;
        top: 13px;
        left: 62px;
        width: 82%;
        height: 87%;

        object-fit: cover;

        video {
          border-radius: 5px;

          object-fit: contain;
          background-color: black;
        }

        @media (max-width: 1480px) {
          top: 10px;
          left: 44px;
        }

        @media (max-width: 1060px) {
          top: 12px;
          left: 52px;
        }
      }
    }
  }

  .review-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 96px 0px;
    gap: 128px;
    width: 100%;
    // background-color: #f9fafb;
    margin: 100px 0px;

    @media (max-width: 1060px) {
      flex-direction: column;
      gap: 64px;
      padding: 64px 32px;
    }

    .review-image {
      width: 328px;
      height: 328px;
      border-radius: 16px;
      object-fit: cover;
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-right: -10px;
      width: 55%;

      @media (max-width: 1480px) {
        width: 95%;
        margin-left: 0px;
        margin-right: 0px;
      }

      .review-text {
        margin-top: 23px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 36px;
        line-height: 44px;

        letter-spacing: -0.02em;

        color: #101828;
      }

      .name {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;

        color: #101828;
        margin-bottom: 0px;
      }

      .description {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        color: #667085;
      }
    }
  }

  .advantages-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .container-info {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      width: 90%;

      h4 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: left;

        color: #6941c6;
      }

      h1 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
        text-align: left;

        letter-spacing: -0.02em;

        color: #101828;
      }

      h3 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        text-align: left;

        color: #667085;
      }
    }

    .content {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: flex-end;
      align-items: center;
      margin-top: 64px;

      @media (max-width: 1060px) {
        width: 90%;
        flex-direction: column;
        justify-content: center;
      }

      .left {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        width: 45%;
        flex-wrap: wrap;
        margin-right: 5%;

        @media (max-width: 1060px) {
          width: 100%;
          margin-right: 0px;
          margin-bottom: 64px;
        }

        .value {
          display: flex;
          width: 45%;

          flex-direction: column;
          align-items: flex-start;
          justify-content: center;

          margin-top: 10px;
          margin-bottom: 10px;

          .image-container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 48px;
            height: 48px;
            background: #f4ebff;

            border: 8px solid #f9f5ff;
            border-radius: 28px;

            .image {
              max-height: 22px;
            }

            margin-bottom: 20px;
          }

          .value-title {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;

            text-align: left;

            color: #101828;
          }

          .value-text {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;

            text-align: left;

            color: #667085;
          }
        }
      }

      .right {
        display: flex;
        width: 45%;

        @media (max-width: 1060px) {
          width: 100%;
        }

        .screen-mockup {
          width: 100%;
          height: 100%;
          object-fit: cover;

          border-top-left-radius: 24px;
          border-bottom-left-radius: 24px;

          @media (max-width: 1060px) {
            width: 100%;
            border-top-right-radius: 24px;
            border-bottom-right-radius: 24px;
          }
        }
      }
    }
  }

  .secure-customer-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 752px;
    padding: 96px 0px 96px 0px;

    @media (max-width: 1480px) {
      flex-direction: column;
      height: 100%;
    }

    .purple-background-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      width: 85%;

      background: #53389e;

      box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1),
        0px 8px 8px -4px rgba(16, 24, 40, 0.04);
      border-radius: 24px;

      @media (max-width: 1480px) {
        flex-direction: column;
        width: 90%;
        padding: 64px 0px 64px 0px;
      }
    }

    .left-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 38%;

      margin-left: 5%;
      @media (max-width: 1480px) {
        width: 90%;
        margin-left: 0%;
      }

      .title-section {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
    }

    .right-container {
      display: flex;
      justify-content: center;
      width: 50%;

      @media (max-width: 1480px) {
        margin-top: 0px;
        width: 90%;
      }
    }

    .section-title {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 44px;

      letter-spacing: -0.02em;

      color: #ffffff;
    }

    .section-description {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;

      color: #e9d7fe;

      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 0;
    }

    .buttons-container {
      display: flex;
      width: 100%;
      height: 50px;
      align-items: center;
      justify-content: flex-start;
      margin-top: 20px;

      gap: 12px;
    }

    .sign-up-button {
      display: inline-block !important;
      height: 45px !important;
      left: 0px !important;
      top: 0px !important;
      border-radius: 8px !important;
      padding: 5px 15px 5px 15px !important;
      color: #344054 !important;
      font-family: $fontFamily !important;
      @include get-font-weight($key: 'Medium');
      font-size: 14px !important;
      line-height: 20px !important;
      background-color: white !important;
      text-transform: none !important;
      border-radius: 9px !important;
      border: 1px solid #d6dae1 !important;
    }

    .right-container-content {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      max-width: 576px;
      height: 500px;

      @media (max-width: 1480px) {
        width: 100%;

        height: auto;

        margin-top: 64px;

        margin-left: 10%;
      }

      @media (max-width: 1060px) {
        width: 100%;
        max-width: 1000px;
      }
    }

    .notification-group {
      position: relative;
      left: 130px;
      top: -15px;
      z-index: 6;

      @media (max-width: 1060px) {
        left: 250px;
      }
    }

    .mockup-container {
      position: relative;
      top: 0;
      left: 0;
    }

    .iphone-mockup {
      position: relative;
      top: 0;
      left: 0;
    }

    .mockup-image {
      position: absolute;
      top: 12px;
      left: 46px;
      width: 408px;
      height: 264px;

      object-fit: cover;
    }
  }

  .prices-for-everyone-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 96px 0px;

    @media (max-width: 1480px) {
      padding: 64px 0px;
    }

    @media (max-width: 1060px) {
      padding: 32px 0px;
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 90%;

      .left {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 64px;

        @media (max-width: 1060px) {
          margin-bottom: 32px;
        }

        h4 {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          text-align: left;

          color: #6941c6;
        }

        h2 {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          font-size: 36px;
          line-height: 44px;
          text-align: left;

          letter-spacing: -0.02em;

          color: #101828;
        }

        h3 {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 28px;
          text-align: left;

          color: #667085;
        }
      }

      .right {
        display: flex;
        flex-direction: row;
        width: 60%;
        justify-content: space-between;
        align-items: flex-start;

        @media (max-width: 1480px) {
          width: 100%;
          justify-content: center;
          gap: 30px;
        }

        @media (max-width: 1060px) {
          width: 130%;
          gap: 10px;
          transform: scale(0.8);
        }

        .card {
          display: flex;
          flex-direction: column;

          width: 383px;
          padding: 36px 27px;

          justify-content: center;
          align-items: center;

          border: 1px solid #e4e7ec;
          box-shadow: 10px 14px 16px -4px rgba(16, 24, 40, 0.1),
            0px 4px 6px -2px rgba(16, 24, 40, 0.05);
          border-radius: 16px;

          .arrow-container {
            position: absolute;
            left: 68%;
            top: -8%;
            width: 200px;

            .arrow-container-text {
              display: flex;
              position: relative;
              left: 45%;
              top: 25% !important;
              font-family: 'Inter';
              font-style: normal;
              font-weight: 500;
              font-size: 20px;
              line-height: 20px;
              color: #6941c6;

              margin-bottom: 0px !important;
            }

            .arrow-container-icon {
              height: 50px;
              width: 100px;

              -webkit-transform: rotate(100deg);
              -moz-transform: rotate(100deg);
              -ms-transform: rotate(100deg);
              -o-transform: rotate(100deg);
              transform: rotate(100deg);
            }
          }

          .title {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 48px;
            line-height: 60px;

            text-align: center;
            letter-spacing: -0.02em;
          }

          .first-description {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;

            text-align: center;

            color: #101828;
          }

          .second-description {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;

            text-align: center;

            color: #667085;

            margin-top: -15px;
            margin-bottom: 30px;
          }

          .check-box-container {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            width: 100%;

            .check-item {
              display: flex;
              flex-direction: row;
              align-items: flex-start;

              .check-item-icon {
                width: 25px;
                height: 25px;
                margin-right: 11px;
              }

              .check-item-text {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;

                color: #667085;
              }
            }
          }

          .card-button {
            margin-top: 30px;

            display: flex !important;
            height: 48px !important;
            width: 100% !important;
            left: 0px !important;
            top: 0px !important;
            border-radius: 8px !important;
            padding: 12px 20px !important;
            color: white !important;
            font-family: $fontFamily !important;
            @include get-font-weight($key: 'Regular');
            font-size: 18px !important;
            line-height: 28px !important;
            background-color: #7f56d9 !important;
            text-transform: none !important;
            border-radius: 9px !important;
          }
        }

        .card-small {
          display: flex;
          flex-direction: column;

          width: 320px;
          padding: 36px 27px;

          justify-content: center;
          align-items: center;

          border: 1px solid #e4e7ec;
          box-shadow: 10px 14px 16px -4px rgba(16, 24, 40, 0.1),
            0px 4px 6px -2px rgba(16, 24, 40, 0.05);
          border-radius: 16px;

          .title {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 41.2857px;
            line-height: 52px;

            text-align: center;
            letter-spacing: -0.02em;
          }

          .first-description {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 17.2024px;
            line-height: 26px;

            text-align: center;
          }

          .second-description {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 13.7619px;
            line-height: 21px;

            text-align: center;

            color: #667085;

            margin-top: -15px;
            margin-bottom: 30px;
          }

          .check-box-container {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            width: 100%;

            .check-item {
              display: flex;
              flex-direction: row;
              align-items: flex-start;

              .check-item-icon {
                width: 25px;
                height: 25px;
                margin-right: 11px;
              }

              .check-item-text {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;

                color: #667085;
              }
            }
          }
        }
      }
    }
  }

  .all-administration-container {
    display: flex;
    width: 90%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    margin: 96px 0px;

    .info-container {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      width: 60%;

      @media (max-width: 1480px) {
        width: 100%;
      }

      @media (max-width: 1060px) {
        margin-bottom: -32px;
      }

      h4 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: left;

        color: #6941c6;
      }

      h2 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
        text-align: left;

        letter-spacing: -0.02em;

        color: #101828;
      }

      h3 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        text-align: left;

        color: #667085;
      }
    }

    .content-container {
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: center;

      margin-top: 64px;

      @media (max-width: 1480px) {
        margin-top: 32px;
        flex-direction: column;
      }

      @media (max-width: 1060px) {
        margin-top: 16px;
      }

      .left {
        width: 50%;

        @media (max-width: 1480px) {
          width: 100%;
        }

        .achievements-container {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          flex-direction: row;
          width: 100%;
          gap: 16px;

          flex-wrap: wrap;

          margin: 96px 0px;
        }
      }

      .right {
        display: flex;
        width: 50%;
        justify-content: flex-end;
        align-items: flex-end;
        position: relative;
        top: 0;
        left: 0;

        @media (max-width: $tabletBreakpoint) {
          width: 100%;
          justify-content: center;
          align-items: center;
        }

        .iphone-mockup {
          position: relative;
          top: 0;
          left: 0;
        }

        .mockup-image {
          position: absolute;
          top: 12px;
          left: 192px;
          width: 408px;
          height: 264px;

          object-fit: cover;

          @media (max-width: $tabletBreakpoint) {
            top: 12px;
            left: 256px;
          }
          @media (max-width: $phoneWidthBreakpoint) {
            top: 12px;
            left: 83px;
          }
        }
      }
    }
  }

  .faq-container {
    display: flex;
    width: 55%;
    flex-direction: column;
    justify-content: center;
    gap: 64px;
    align-items: center;

    @media (max-width: 1480px) {
      width: 90%;
    }

    .info-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;

      h2 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
        text-align: center;

        letter-spacing: -0.02em;

        color: #101828;
      }

      h3 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        text-align: center;

        color: #667085;
      }
    }
  }

  .no-lawyer-yet-concainer {
    margin-top: 64px;
    padding: 32px 0px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f9fafb;

    .avatar-group {
      display: flex;
      flex-direction: row;
      height: 60px;
      margin-bottom: 20px;

      .avatar {
        border: 1.5px solid #ffffff;
        border-radius: 200px;
      }

      .avatar-side {
        width: 48px;
        height: 48px;

        margin-top: 7px;
        z-index: 1;
      }

      .avatar-middle {
        margin-left: -15px;
        margin-right: -15px;

        width: 56px;
        height: 56px;
        z-index: 2;
      }
    }

    .text1 {
      /* Text xl/Medium */
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;

      margin-bottom: 16px;

      /* identical to box height, or 150% */
      text-align: center;

      /* Gray/900 */
      color: #101828;
    }

    .text2 {
      margin-top: -10px;
      margin-bottom: 25px;
      /* Text lg/Normal */
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      width: 60%;

      margin-bottom: 16px;

      /* identical to box height, or 156% */
      text-align: center;

      /* Gray/500 */
      color: #667085;
    }

    .button-style {
      display: inline-block !important;
      height: 44px !important;
      left: 0px !important;
      top: 0px !important;
      border-radius: 8px !important;
      padding: 5px 16px 5px 16px !important;
      color: white !important;
      font-family: $fontFamily !important;
      @include get-font-weight($key: 'Medium');
      font-size: 14px !important;
      line-height: 20px !important;
      background-color: #7f56d9 !important;
      text-transform: none !important;
      border-radius: 9px !important;
    }
  }

  .purple-footer-section {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    background-color: #53389e;

    margin: 96px 0px;
    padding: 64px 0px;

    @media (max-width: 1480px) {
      padding: 48px 0px;
    }

    @media (max-width: 1060px) {
      padding: 32px 0px;
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 55%;

      @media (max-width: 1480px) {
        width: 80%;
      }

      @media (max-width: 1060px) {
        width: 90%;
      }

      h2 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 38px;

        text-align: center;

        color: #ffffff;
      }

      h3 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;

        text-align: center;

        color: #e9d7fe;
      }

      .buttons-container {
        display: flex;
        width: 100%;
        height: 50px;
        align-items: center;
        justify-content: center;
        margin-top: 20px;

        gap: 12px;
      }

      .demo {
        display: flex !important;
        justify-content: center;
        align-items: center;
        height: 45px !important;
        left: 0px !important;
        top: 0px !important;
        border-radius: 8px !important;
        padding: 5px 15px 5px 15px !important;
        color: #344054 !important;
        font-family: $fontFamily !important;
        @include get-font-weight($key: 'Medium');
        font-size: 14px !important;
        line-height: 20px !important;
        background-color: white !important;
        text-transform: none !important;
        border-radius: 9px !important;
        border: 1px solid #d6dae1 !important;

        .css-i4bv87-MuiSvgIcon-root {
          margin-top: 1px;
          margin-left: 1px;
        }
      }

      .sign-up {
        display: inline-block !important;
        height: 44px !important;
        left: 0px !important;
        top: 0px !important;
        border-radius: 8px !important;
        padding: 5px 16px 5px 16px !important;
        color: white !important;
        font-family: $fontFamily !important;
        @include get-font-weight($key: 'Medium');
        font-size: 14px !important;
        line-height: 20px !important;
        background-color: #7f56d9 !important;
        text-transform: none !important;
        border-radius: 9px !important;
      }
    }
  }
}
