@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

*,
*::before,
*::after {
  box-sizing: border-box;
}

.ComponentContainer {
  background: linear-gradient(45deg, #42307d 0%, #7f56d9 100%);
  display: block;
  width: 50%;
  height: 960px;
  position: relative;
}
.line-pattern-up {
  position: absolute;
  right: 0px;
  top: 0px;
}
.line-pattern-down {
  position: absolute;
  bottom: 0;
}
.carouselContainer {
  display: flex;
  width: 100%;
  height: 56.875%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 32px;
  gap: 48px;

  position: absolute;
  left: 0px;
  right: 0px;
  top: 23.5625%;
}

.mobile-stepper {
  background-color: white;
  z-index: 3;
}

.carousel {
  width: 50%;
  height: 100%;
  position: relative;
}

.carousel > ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.slide {
  position: absolute;
  inset: 0;
  opacity: 0;
}

.slide > img {
  display: block;
  width: 500px;
  height: 500px;
}

.slide[data-active] {
  opacity: 1;
}

.carousel-button-prev {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 12px;

  width: 36px;
  height: 36px;

  border-radius: 18px;
  background-color: transparent;
  z-index: 3;
  color: white;
  font-size: 4rem;
}
.carousel-button-next {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 12px;

  width: 36px;
  height: 36px;
  border-radius: 18px;

  background-color: transparent;
  z-index: 3;
  color: white;
  font-size: 4rem;
}

.mockup {
  width: 432px;
  height: 328px;
  position: relative;
  background-color: transparent;
}
.mockup-show-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  position: absolute;
  width: 432px;
  height: 260px;
  left: 0px;
  top: 0px;

  background: #ffffff;

  box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.25);
  border-radius: 12px;
}

.mockup-back-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  gap: 32px;

  position: absolute;
  width: 192px;
  height: 192px;
  left: 280px;
  top: 108px;

  background: rgba(255, 255, 255, 0.9);

  backdrop-filter: blur(16px);

  border-radius: 12px;
}

.text-and-support {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 400px;
  height: 86px;
  background-color: transparent;
}
.text {
  width: 400px;
  height: 30px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;

  text-align: center;

  color: #ffffff;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.support {
  width: 400px;
  height: 48px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  text-align: center;

  color: #e9d7fe;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.mobile-stepper-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 64px;

  width: 656px;
  height: 36px;
}

.Image {
  position: relative;
  width: 100%;
}

.Bottom-panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 128px 0px 0px;

  position: absolute;
  height: 501px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.3) 100%
  );
}

.divider {
  width: 100%;
  height: 1px;

  background: rgba(255, 255, 255, 0.5);
}

.attribution-card {
  position: absolute;
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 32px 40px;
  gap: 32px;

  width: 100%;

  background: rgba(255, 255, 255, 0.3);

  backdrop-filter: blur(24px);
}

.quote {
  width: 512px;
  height: 152px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;

  margin: 0;

  color: #ffffff;
}

.name-and-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 16px;

  width: 100%;
  height: 116px;
}

.name-and-stars {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0px;
  gap: 16px;

  width: 100%;
  height: 44px;
}

.name {
  max-width: 100%;
  height: 44px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;

  margin: 0;

  letter-spacing: -0.02em;

  color: #ffffff;
}

.stars-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;

  max-width: 100%;
  height: 100%;
}

.carousel-supporting-and-text {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0px;
  gap: 12px;

  width: 512px;
  height: 56px;
}

.carousel-text-and-supporting-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  max-width: 100%;
  height: 56px;
}

.carousel-text {
  max-width: 100%;
  height: 28px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;

  margin: 0;

  color: #ffffff;
}

.carousel-supporting-text {
  max-width: 100%;
  height: 24px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  margin: 0;

  color: #ffffff;
}

.carousel-arrows {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;

  width: 144px;
  height: 56px;
}

@media screen and (max-width: 1060px) {
  .ComponentContainer {
    width: 100%;
  }

  .attribution-card {
    padding: 32px 0 40px;
  }

  .carousel-supporting-and-text {
    justify-content: space-evenly;
  }

  .name-and-stars {
    justify-content: space-evenly;
  }

  .quote,
  .name {
    font-size: 1.5rem;
  }

  .carousel-text {
    font-size: 12px;
  }

  .carousel-supporting-text {
    font-size: 14px;
  }
}

@media screen and (max-width: 1480px) and (min-width: 1060px) {
  .ComponentContainer {
    height: 960px;
  }

  .mockup {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mockup-back-card {
    left: 260px;
  }
}

@media screen and (min-width: 1480px) {
  .Image {
    width: unset;
  }
}
