@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.editLawyerContainerAutoComplete {
  display: block;
  // padding: 5;
  // margin-top: 10;
}

.editLawyerTitleAutoComplete {
  color: #344054;
  font-family: $fontFamily;
  @include get-font-weight($key: 'Medium');
  font-size: 14px;
  line-height: 4px;
}

.editLawyerAutoCompleteStyling {
  margin-top: -1px !important;

  .MuiOutlinedInput-root {
    height: 45.5px;

    &:focus-within .MuiOutlinedInput-notchedOutline {
      transition: border-color 0.3s ease-in-out !important;
      border-color: #7f56d9 !important;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: gray !important;
    }
  }
}

.editLawyerAutoCompleteStyling-error {
  .MuiOutlinedInput-root {
    height: 45.5px;

    &:focus-within .MuiOutlinedInput-notchedOutline {
      transition: border-color 0.3s ease-in-out !important;
      border-color: red !important;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: red !important;
    }
  }
}

.editLawyerAutocompleteTextfield-error {
  .MuiOutlinedInput-root {
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid red !important;
      border-radius: 8px !important;
      padding: 0px !important;
      top: 0px !important;
    }
  }

  .MuiOutlinedInput-notchedOutline legend {
    display: none !important;
  }
  .MuiInputBase-input {
    padding: 2px !important;
    font-family: $fontFamily !important;
    @include get-font-weight($key: 'Regular');
  }
}

.editLawyerTextFieldAutoComplete {
  .MuiOutlinedInput-root {
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid #d0d5dd !important;
      border-radius: 8px !important;
      padding: 0px !important;
      top: 0px !important;
    }
  }

  .MuiOutlinedInput-notchedOutline legend {
    display: none !important;
  }
  .MuiInputBase-input {
    padding: 2px !important;
    font-family: $fontFamily !important;
    @include get-font-weight($key: 'Regular');
  }
}

.editLawyerTagContainer {
  display: inline-block;
  padding: 0px 8px 0px 8px;
  border-radius: 70px;
  margin-top: 12px;
  margin-left: 2px;
  margin-right: 2px;
}

.editLawyerTagFlagImg {
  margin-right: 5px;
  border-radius: 100px;
  width: 15px;
  height: 15px;
  margin-top: -1px;
}

.editLawyerTagText {
  font-family: $fontFamily;
  @include get-font-weight($key: 'Medium');
  font-size: 13px;
  margin-bottom: 0px;
}

.editLawyerTagAlignDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.editLawyerTagRemoveIcon {
  display: inline-block !important;
  font-size: 14px !important;
  cursor: pointer !important;
  margin-left: 5px !important;
  margin-top: -1px !important;
}

.error-message {
  font-weight: 400;
  line-height: 20px;
  color: #ff0000;
  margin-top: 5px;
  margin-bottom: 0;
}

.editLawyerTextFieldAutoCompleteDisabled {
  .MuiInputBase-input {
    background-color: lightgray !important;
    border: 1px solid #d0d5dd !important;
    color: #667085 !important;
  }

  .MuiOutlinedInput-root {
    z-index: 10 !important;
  }

  .MuiOutlinedInput-notchedOutline {
    background-color: lightgray !important;
    z-index: -3 !important;
  }

  cursor: not-allowed;
}
