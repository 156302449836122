@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.lawp-add-lawyer-review-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  background-color: white;
}

.lawp-add-lawyer-review-modal-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  // height: 100%;
}

.lawp-add-lawyer-review-modal-container-left {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 48px;
  gap: 32px;
  background: #f9fafb;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  // width: 32%;
  width: 29rem;
  // height: 100%;
  // height: 67.5rem;

  @media (max-width: 1060px) {
    width: 60%;
  }
}

.lawp-add-lawyer-review-modal-container-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // width: 68%;
  width: 61rem;
  height: 100vh;
  // height: 67.5rem;

  padding: 40px 0;
}

.lawp-add-lawyer-review-modal-container-right-centered {
  width: 60%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // align-items: center;
  padding: 0px;
  gap: 12px;
}

.lawp-add-lawyer-review-modal-back-to-profile-overview-button {
  font-size: 16px !important;
  color: #7f56d9 !important;
  line-height: 24px !important;
  text-transform: none !important;
  font-family: $fontFamily !important;
  @include get-font-weight($key: 'Medium');
}

.lawp-add-lawyer-review-modal-lawyer-image-container {
  display: flex;
}

.lawp-add-lawyer-review-modal-lawyer-image {
  aspect-ratio: 1/1;
  width: 160px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: -20px;
}

.lawp-add-lawyer-review-modal-lawyer-specialization {
  width: 368px;
  // height: 20px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  /* identical to box height, or 143% */

  /* Primary/700 */
  color: #6941c6;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  margin-bottom: -16px;
}

.lawp-add-lawyer-review-modal-lawyer-name-text {
  font-size: 24px !important;
  line-height: 32px !important;
  font-family: $fontFamily !important;
  @include get-font-weight($key: 'SemiBold');
  color: #000000;

  margin-bottom: -18px;
}

.lawp-add-lawyer-review-modal-bewertung {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;

  width: 100%;
  // height: 167.67px;
}

.lawp-add-lawyer-review-modal-rating-container {
  width: 200px;
  display: flex;

  margin-bottom: -20px;
}

.lawp-add-lawyer-review-modal-frame1034 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 10.87px;

  width: 100%;
  height: 93.87px;
}

.lawp-add-lawyer-review-modal-frame1034-text {
  width: 100%;
  height: 28px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 19.0213px;
  line-height: 27px;

  color: #101828;
}

.lawp-add-lawyer-review-modal-frame1034-supporting-text {
  width: 100%;
  height: 55px;

  font-family: $fontFamily;
  font-style: italic;
  font-weight: 200;
  font-size: 19.0213px;
  line-height: 27px;

  color: #667085;
}

.lawp-add-lawyer-review-modal-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;

  width: 100%;
  // height: 250px;
}

.lawp-add-lawyer-review-modal-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  width: 100%;
  // height: 44px;
}

.lawp-add-lawyer-review-modal-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 100%;
  // height: 20px;
}

.lawp-add-lawyer-review-modal-row-text {
  // width: 221px;
  // height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #667085;
}

.lawp-add-lawyer-review-modal-row-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;

  // width: 106px;
  // height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #6941c6;

  background: none;
  border: none;
}

.lawp-add-lawyer-review-modal-rating-stars {
  margin-left: -3px;
}

.lawp-add-lawyer-review-modal-rating-text {
  margin-left: 8px;
  font-family: $fontFamily;
  @include get-font-weight($key: 'Medium');
  color: black;
  line-height: 24px;
  font-size: 16px;
}

.lawp-add-lawyer-review-modal-rating-reviews {
  margin-left: 8px;
  font-family: $fontFamily;
  @include get-font-weight($key: 'Regular');
  color: grey;
  font-size: 16px;
  line-height: 24px;
  white-space: pre;
}

.lawp-add-lawyer-review-modal-lawyer-description {
  margin-top: 10px !important;
}

.lawp-add-lawyer-review-modal-lawyer-description-text {
  font-family: $fontFamily;
  @include get-font-weight($key: 'Regular');
  color: grey;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 1rem;
}

.lawp-add-lawyer-review-modal-areas-of-law-container {
  margin-top: -40px;
}

.lawp-add-lawyer-review-modal-area-of-law-tag-container {
  display: inline-block;
  align-items: center;
  justify-content: 'center';
  // padding: 2px 10px 2px 10px;
  padding: 4px 10px 4px 10px;
  border-radius: 16px;
  margin-top: 12px;
  margin-left: 3px;
  margin-right: 3px;
  
  &:hover {
    cursor: pointer;
  }
}

.lawp-add-lawyer-review-modal-area-of-law-tag-text {
  font-family: $fontFamily;
  @include get-font-weight($key: 'Medium');
  // font-size: 13;
  font-size: 16px;
}

.lawp-add-lawyer-review-modal-rating-stars-writable {
  margin-top: 15px;
  margin-bottom: 15px;
  .MuiRating-icon {
    font-size: 4.5rem !important;
  }
}

.lawp-add-lawyer-review-modal-container-heading-and-supporting-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  width: 100%;
  // height: 174px;
}

.lawp-add-lawyer-review-modal-title-text {
  /* Heading */

  max-width: 512px;
  // height: 44px;

  /* Display md/Semibold */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 44px;

  /* identical to box height, or 122% */
  letter-spacing: -0.02em;

  /* Gray/900 */
  color: #101828;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.lawp-add-lawyer-review-modal-title-description-text {
  /* You can reach us anytime via hi@untitledui.com */

  // height: 90px;

  /* Text xl/Normal */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;

  /* or 150% */

  /* Gray/500 */
  color: #667085;

  /* Inside auto layout */
  flex: none;
  // order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.lawp-add-lawyer-review-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 24px;

  width: 100%;
  width: 100%;
  // height: 357.33px;

  border-radius: 12px;
}

.lawp-add-lawyer-review-modal-textfield-container {
  // margin-top: 30px;

  width: 100%;
}

.lawp-add-lawyer-review-modal-checkbox-container {
  display: block;
  // margin-top: 15px;

  width: 100%;
}

.lawp-add-lawyer-review-modal-checkbox-title {
  /* Text sm/Medium */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  /* identical to box height, or 143% */

  /* Gray/700 */
  color: #344054;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.lawp-add-lawyer-review-modal-checkbox {
  display: flex;
  height: 100%;
  width: 35;
  margin-top: 3px;
}

.lawp-add-lawyer-review-modal-switch-title-container {
  display: block;
  margin-top: 5px;
}

.lawp-add-lawyer-review-modal-switch-title-text {
  /* Text */

  // width: 480px;

  /* Text md/Normal */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  /* identical to box height, or 150% */

  /* Gray/700 */
  color: #344054;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 1;
}

.lawp-add-lawyer-review-modal-error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  font-family: $fontFamily;
  margin-top: 50px;
  margin-bottom: -10px;
}

.lawp-add-lawyer-review-not-logged-error-message {
  color: red;
  font-size: 12px;

  font-family: $fontFamily;
  margin: 0;
  margin-bottom: -10px;
  width: 100%;
}

.lawp-add-lawyer-review-not-logged-row-terms {
  display: flex;
  flex-direction: row;
  // justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  // width: 25rem;
  // height: 1.25rem;
}

.lawp-add-lawyer-review-not-logged-checkbox-container-selected {
  width: 16px;
  height: 16px;
  max-width: 16px;
  background-color: #f9f5ff;
  border: 2px solid #7f56d9;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  margin-top: 2px;
}

.lawp-add-lawyer-review-not-logged-check-button {
  width: 16px !important;
  height: 16px !important;
  min-width: 16px !important;
}

.lawp-add-lawyer-review-not-logged-checkbox-container {
  width: 16px;
  height: 16px;
  max-width: 16px;
  background-color: #ffffff;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 2px solid #d0d5dd;
  border-radius: 4px;

  margin-top: 2px;
}

.lawp-add-lawyer-review-not-logged-check-style-selected-img {
  font-size: 16px;
  height: 14px;
  margin-right: 1px;
  margin-top: 2px;
  color: #7f56d9;
}

.lawp-add-lawyer-review-not-logged-check-style-img {
  display: none;
  font-size: 16px;
  color: rgba(0, 0, 0, 0);
}

.lawp-add-lawyer-review-not-logged-checkbox-text-label {
  // width: 140px;
  // height: 20px;
  // height: 16px;

  /* Text sm/Medium */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Gray/700 */

  color: #344054;
}

.lawp-add-lawyer-review-not-logged-checkbox-link {
  /* Text md/Medium */
  text-decoration: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  // font-size: 16px;
  line-height: 24px;

  /* identical to box height, or 150% */

  /* Primary/700 */
  color: #6941c6;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: #6941c6;
  }
}

.lawp-add-lawyer-review-modal-submit-button {
  display: inline-block !important;
  width: 100% !important;
  height: 48px !important;

  border-radius: 8px !important;
  padding: 5px 15px 5px 15px !important;

  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 25px !important;
  color: #ffffff !important;

  background-color: #7f56d9 !important;
  text-transform: none !important;
  border-radius: 6px !important;

  margin-top: 10px !important;
}

.lawp-add-lawyer-review-modal-submit-button-disabled {
  display: inline-block !important;
  width: 100% !important;
  height: 48px !important;

  border-radius: 8px !important;
  padding: 5px 15px 5px 15px !important;

  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 25px !important;
  color: #ffffff !important;

  background-color: gray !important;
  text-transform: none !important;
  border-radius: 6px !important;

  margin-top: 10px !important;
}

@media screen and (max-width: 1060px) {
  .lawp-add-lawyer-review-modal-container {
    flex-direction: column-reverse;
    overflow: scroll;
    justify-content: unset;

    padding: 64px 0;
    gap: 16px;
  }

  .lawp-add-lawyer-review-modal-container-right {
    height: unset;
  }

  .lawp-add-lawyer-review-modal-container-left {
    align-self: center;
    width: 550px;
    align-items: center;

    height: auto;
  }

  .lawp-add-lawyer-review-modal-lawyer-specialization {
    align-items: center;
    width: 100%;
    text-align: center;
  }

  .lawp-add-lawyer-review-modal-areas-of-law-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
  }

  .lawp-add-lawyer-review-modal-title-description-text,
  .lawp-add-lawyer-review-modal-title-text {
    height: unset;
  }
}

.lawp-add-lawyer-review-modal-container-right-back-button-container_first_step {
  display: none;
}

.lawp-add-lawyer-review-modal-container-right-back-button-container-last-modal {
  display: flex;
  align-self: center;

  width: 400px;

  padding: 0 40px;
}

.lawp-add-lawyer-review-modal-container-right-row-arrow-button-back {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;

  width: 36px;
  height: 36px;

  background: #f9f5ff;

  border: 1px solid #f9f5ff;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #f4ebff;
  border-radius: 8px;
}

@media screen and (max-width: 1060px) {
  .lawp-add-lawyer-review-modal-container-right-back-button-container,
  .lawp-add-lawyer-review-modal-container-right-back-button-container_first_step {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;

    width: 512px;
    height: 36px;
  }

  .lawp-add-lawyer-review-modal-back-to-profile-overview-button {
    display: none !important;
  }
}

@media screen and (max-width: 1480px) and (min-width: 1060px) {
}

@media screen and (min-width: 1480px) {
}
