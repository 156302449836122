@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.delete-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  // position: absolute;
  width: 100%;
  height: 100%;
  // left: 7217px;
  // top: 7459px;

  background: rgba(52, 64, 84, 0.6);

  backdrop-filter: blur(8px);
}

.delete-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 32px;

  //   width: 679px;
  width: 400px;
  //   height: 649px;

  background: #ffffff;

  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1),
    0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  border-radius: 12px;

  height: auto;

  overflow-y: auto;
}

.delete-modal-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //   padding: 24px;
  padding: 24px 0;
  gap: 24px;

  width: 100%;
  // height: 576px;
}

.delete-modal-text-and-supporting-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;

  width: 100%;
  // height: 56px;
}

.delete-modal-text {
  width: 100%;
  height: 28px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;

  color: #101828;
}

.delete-modal-supporting-text {
  width: 100%;
  //   height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #667085;
}

.delete-modal-textarea-input-field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 100%;
  // height: 180px;
}
.delete-modal-textarea-input-hint {
  display: flex;
  width: 352px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #667085;
}

.delete-modal-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px;
  gap: 12px;

  width: 100%;
}

.delete-modal-success-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 90%;
  height: 44px;

  background: #7f56d9;

  border: 1px solid #7f56d9;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;

  color: #ffffff;
}

@media screen and (max-width: 1060px) {
  .delete-modal-actions-cancel-event-button {
    font-size: 12px;
  }

  .delete-modal-lawyer-email-button {
    font-size: 14px;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
