@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.meeting-calendar-date-picker-menu {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  margin-left: 10px;

  height: 452px;
}

.meeting-calendar-date-picker {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  width: 328px;
  height: 448px;

  background: #ffffff;

  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1),
    0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  border-radius: 8px;

  margin-right: 50px;
  transform: translateX(50px);
  transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;
  z-index: 4;
}

.meeting-calendar-date-picker.active {
  margin-right: 0px;
  transform: translateX(0px);
}

.meeting-calendar-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 24px;
  gap: 16px;

  width: 280px;
  height: 408px;
}

.meeting-calendar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  width: 280px;
  height: 408px;
}

.meeting-calendar-month {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;

  width: 280px;
  height: 40px;
}

.meeting-calendar-month-year {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  text-align: center;

  color: #344054;
}

.meeting-calendar-arrow-left {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 8px;
  cursor: pointer;

  border-radius: 8px;
}

.meeting-calendar-arrow-left:hover {
  background: rgba(111, 148, 182, 0.1);
  border-radius: 20px;
}

.meeting-calendar-arrow-right {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 8px;
  cursor: pointer;

  border-radius: 8px;
}

.meeting-calendar-arrow-right:hover {
  background: rgba(111, 148, 182, 0.1);
  border-radius: 20px;
}

.meeting-calendar-actions {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  width: 280px;
  height: 40px;
}

.meeting-calendar-input-field {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 14px;
  gap: 8px;

  width: 195px;
  height: 40px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  background: #ffffff;

  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.meeting-calendar-action-button {
  cursor: pointer;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;

  width: 73px;
  height: 40px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #344054;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.meeting-calendar-dates {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 280px;
  height: 304px;
}

.meeting-calendar-weekdays {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 280px;
  height: 40px;
}

.meeting-calendar-weekday {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;

  border-radius: 20px;
  text-align: center;
  font-size: 14px;

  color: #344054;
}

.meeting-calendar-days {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.meeting-calendar-days div {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  border-radius: 20px;
  text-align: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;

  color: #344054;
}

.meeting-calendar-prev-date,
.meeting-calendar-next-date {
  color: #667085 !important;
}

.meeting-calendar-available {
  background: #7f56d9;
  color: white !important;
  cursor: pointer;
  transition: background-color 0.2s;
}
.meeting-calendar-available-inactive {
  background: #f9fafb !important;
  color: #667085 !important;
}

.meeting-calendar-available:hover {
  background-color: #6941c6 !important;
  color: white !important;
}
.meeting-calendar-available.active {
  background-color: #6941c6 !important;
  color: white !important;
}

.meeting-calendar-appointments-overflow {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  width: 160px;
}

.meeting-calendar-appointments {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;

  // height: 452px;
  height: 448px;
  background-color: white;
  overflow-y: auto;

  opacity: 0;
  pointer-events: none;
  transform: translateX(-80px);
  transition: opacity 400ms ease-in-out, transform 400ms ease-in-out;
  z-index: 3;
}

.meeting-calendar-appointments.active {
  display: flex;
  align-items: center;

  opacity: 1;
  transform: translateX(0);
  pointer-events: auto;

  width: 185px;
}

.meeting-calendar-appointments-day-selected {
  // width: 170px;
  height: 20px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #344054;
}

.meeting-calendar-appointments-button-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.meeting-calendar-appointment-button {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // padding: 8px 14px;
  gap: 8px;
  cursor: pointer;

  margin-bottom: 12px;

  // width: 170px;
  width: 148px;
  height: 36px;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #344054;
}

.meeting-calendar-appointment-button.active {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  border: 0px;

  width: 148px;
  height: 36px;

  box-shadow: none;
}

.meeting-calendar-appointment-button-selected {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // padding: 8px 14px;
  /* margin-left: 4px; */
  gap: 8px;

  width: 94px;
  height: 36px;

  background: #f9f5ff;

  border: 1px solid #f9f5ff;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #f4ebff;
  border-radius: 8px;
}

.meeting-calendar-next-step {
  cursor: pointer;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;

  width: 44px;
  height: 36px;

  background: #7f56d9;

  border: 1px solid #7f56d9;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #f4ebff;
  border-radius: 8px;
  transition: background-color 0.2s;
}

.meeting-calendar-next-step:hover {
  background-color: #6941c6 !important;
}
.meeting-calendar-next-step:active {
  background-color: #6941c6 !important;
}
