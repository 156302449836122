@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$phoneBreakpoint: map-get($screen-width-breakpoints, $key: 'phone');
$tabletBreakpoint: map-get($screen-width-breakpoints, $key: 'tablet');

.lawp-lawyer-categories-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f9fafb;
  padding: 64px 0px 64px 0px;
  margin-top: 96px;

  .more-link {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;

    font-family: $fontFamily;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #6941c6;

    &:hover {
      text-decoration: underline;
    }
  }

  .content-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 80%;

    @media (max-width: $tabletBreakpoint) {
      width: 85%;
    }

    @media (max-width: $phoneBreakpoint) {
      width: 90%;
    }
  }

  h2 {
    font-family: $fontFamily;
    font-size: 34px;
    font-weight: 600;
    color: black;
    margin-bottom: 1rem;
  }

  .multi-column-list {
    column-count: 3;
    column-gap: 10px;
    width: 100%;
    overflow: auto;
    list-style: none;
    padding: 0;

    @media (max-width: $tabletBreakpoint) {
      column-count: 2;
    }

    @media (max-width: $phoneBreakpoint) {
      column-count: 2;
    }
  }

  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    width: 100%;
  }

  .multi-column-list li {
    break-inside: avoid-column;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    border: 1px solid transparent;

    a {
      color: $primaryColor;
      text-decoration: none;
      font-family: $fontFamily;
      font-size: 1.1rem;
      letter-spacing: 0.5px;
      text-transform: none;
      text-align: flex-start;

      &:hover {
        text-decoration: underline;
      }

      @media (max-width: $phoneBreakpoint) {
        font-size: 1rem;
      }
    }
  }

  .MuiAppBar-colorPrimary {
    background-color: transparent;
  }

  .MuiPaper-root {
    background-color: transparent;
    box-shadow: none;
  }

  .MuiButtonBase-root {
    color: $primaryColor !important;
    font-family: $fontFamily;
    font-size: 1.1rem;
    font-weight: 600;
    text-transform: none;

    border-color: green !important;
  }

  .MuiButton-outlined {
    border-color: red !important;
  }
}
