.lawp-app-container-background {
  background-color: lightgray;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 640px) {
    width: 640px;
  }

  background: #ffffff;

  min-height: 100vh;
}

.lawp-app-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 1440px;
  min-width: 1440px;
  max-width: 1440px;
  background-color: white;

  @media (max-width: 1480px) {
    width: 1024px;
    min-width: 1024px;
    max-width: 1024px;
  }

  @media (max-width: 1060px) {
    width: 640px;
    min-width: 640px;
    max-width: 640px;
  }
}

video {
  border-radius: 8px;
  // object-fit: contain;
  // background-color: black;
}

//scrollbar width for firefox
* {
  scrollbar-width: thin;
  scrollbar-color: #e4e7ec white;
}

//for chronium based browsers?
::-webkit-scrollbar {
  width: 8px;
  background: white;
}

//To do: check width on safari;

::-webkit-scrollbar-thumb {
  background: #e4e7ec;
  border-radius: 8px;
}

h1,
h2,
h3,
h4 {
  margin: 0;
}
a {
  text-decoration: none;
}
