@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.confirm-message-to-lawyer-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  position: absolute;
  width: 100%;
  height: 100%;
  left: 7217px;
  top: 7459px;

  background: rgba(52, 64, 84, 0.6);

  backdrop-filter: blur(8px);
}

.confirm-message-to-lawyer-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  gap: 32px;

  width: 400px;
  // min-height: 336px;
  // max-height: 430px;

  background: #ffffff;

  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1),
    0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  border-radius: 12px;
}

.confirm-message-to-lawyer-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 20px;

  width: 100%;
  height: 180px;
}

.confirm-message-to-lawyer-modal-featured-icon {
  width: 56px;
  height: 56px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #d1fadf;

  border: 10px solid #ecfdf3;
  border-radius: 28px;
}

.confirm-message-to-lawyer-modal-text-and-supporting-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;

  width: 100%;
  // min-height: 104px;
  // max-height: 198px;
}

.confirm-message-to-lawyer-modal-text {
  width: 100%;
  // height: 56px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;

  text-align: center;

  color: #101828;
}

.confirm-message-to-lawyer-modal-supporting-text {
  width: 100%;
  // min-height: 40px;
  // max-height: 134px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  text-align: center;

  color: #667085;
}

.confirm-message-to-lawyer-modal-actions {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  width: 100%;
  // height: 44px;
}

.confirm-message-to-lawyer-modal-success-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 320px;
  // height: 44px;

  background: #7f56d9;

  border: 1px solid #7f56d9;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #ffffff;
}

@media screen and (max-width: 1060px) {
  .confirm-message-to-lawyer-modal-actions-cancel-event-button {
    font-size: 12px;
  }
}
