@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$phoneBreakpoint: map-get($screen-width-breakpoints, $key: 'phone');
$tabletBreakpoint: map-get($screen-width-breakpoints, $key: 'tablet');

.lawp-policy-page-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.lawp-policy-page-fair-use-policy {
  width: 100%;
  padding: 96px 0 96px 0;
  background-color: #f9fafb;
}

.lawp-policy-page-special-platform {
  width: 100%;
  padding: 96px 0 96px 0;
}

.lawp-policy-page-our-values-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.lawp-policy-page-our-values-container-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: $phoneBreakpoint) {
    width: 90%;
  }

  h4 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;

    color: #6941c6;
  }

  h1,
  h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    text-align: center;

    letter-spacing: -0.02em;

    color: #101828;
  }

  h3 {
    // width: 768px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;

    color: #667085;

    flex: none;
    align-self: stretch;
    flex-grow: 0;

    @media (max-width: $tabletBreakpoint) {
      width: 100%;
    }
  }

  .buttons-container {
    display: flex;
    width: 100%;
    height: 50px;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  .contact {
    display: inline-block !important;
    height: 45px !important;
    left: 0px !important;
    top: 0px !important;
    border-radius: 8px !important;
    padding: 5px 15px 5px 15px !important;
    color: #344054 !important;
    font-family: $fontFamily !important;
    @include get-font-weight($key: 'Medium');
    font-size: 14px !important;
    line-height: 20px !important;
    background-color: white !important;
    text-transform: none !important;
    border-radius: 9px !important;
    border: 1px solid #d6dae1 !important;
  }

  .register {
    display: inline-block !important;
    height: 44px !important;
    left: 0px !important;
    top: 0px !important;
    border-radius: 8px !important;
    padding: 5px 16px 5px 16px !important;
    color: white !important;
    font-family: $fontFamily !important;
    @include get-font-weight($key: 'Medium');
    font-size: 14px !important;
    line-height: 20px !important;
    margin-left: 15px !important;
    background-color: #7f56d9 !important;
    text-transform: none !important;
    border-radius: 9px !important;
  }
}

.lawp-policy-page-info-image-section {
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-bottom: 96px;

  @media (max-width: $phoneBreakpoint) {
    flex-direction: column;

    align-items: center;
    gap: 40px;

    height: auto;
  }

  .left {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    min-width: 500px;

    @media (max-width: $phoneBreakpoint) {
      order: 0;

      width: 100%;
    }
  }

  .right {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: $phoneBreakpoint) {
      order: 1;

      width: 70%;
    }

    .policy-image {
      width: 100%;
    }
  }

  .check-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    h3 {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;

      color: #667085;

      @media (max-width: $tabletBreakpoint) {
        width: 80%;
      }
    }
  }
  .check-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: #f4ebff;

    margin-bottom: 16px;
    margin-right: 10px;
    margin-left: 10px;
  }
  .check {
    width: 14px;
    height: 12px;
  }
  .content {
    width: 60%;

    @media (max-width: $phoneBreakpoint) {
      width: 80%;
    }
  }
  .check-group {
    margin-top: -20px;
  }
}

.lawp-policy-page-our-values-data-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: #53389e;

  padding: 96px 0 96px 0;

  .title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 64px;
    .title {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;

      text-align: center;

      color: #e9d7fe;
    }

    .subtitle {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 44px;

      text-align: center;
      letter-spacing: -0.02em;

      color: #ffffff;

      @media (max-width: $phoneBreakpoint) {
        width: 90%;
      }
    }
  }

  .values-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 85%;

    .value {
      display: flex;
      width: 30%;

      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 64px;

      @media (max-width: $tabletBreakpoint) {
        width: 50%;
      }

      .image-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        background: #7f56d9;

        border: 8px solid #6941c6;
        border-radius: 28px;

        .image {
          width: 18px;
          aspect-ratio: 1/1;
        }

        margin-bottom: 20px;
      }

      .value-title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;

        text-align: center;

        color: #ffffff;
      }

      .value-text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        width: 100%;
        text-align: center;

        color: #e9d7fe;
      }
    }
  }
}

.lawp-policy-page-experts-in-your-area-section {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 96px;
  margin-bottom: 96px;
  gap: 20px;

  @media (max-width: $phoneBreakpoint) {
    flex-direction: column;
  }

  .left-container {
    width: 45%;

    @media (max-width: $phoneBreakpoint) {
      width: 90%;
    }

    .top-text {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;

      color: #6941c6;
    }

    .faqCard {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0px 32px 0px 0px;
      position: static;
      height: 126px;
      left: 0px;
      top: 0px;

      background: #ffffff;
      border-radius: 16px;
    }

    .component-card {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 50px;
    }

    .component-text-card {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 8px;
      position: static;
      left: 0px;
      top: 0px;
    }

    .component-card-one-text-field {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;

      color: #667085;
    }

    .component-card-one-title {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 38px;

      color: #101828;
    }

    .buttons-container {
      display: flex;
      width: 100%;
      height: 50px;
      align-items: center;
      justify-content: flex-start;
      margin-top: 20px;
    }

    .faq-button {
      display: inline-block !important;
      height: 45px !important;
      left: 0px !important;
      top: 0px !important;
      border-radius: 8px !important;
      padding: 5px 15px 5px 15px !important;
      color: #344054 !important;
      font-family: $fontFamily !important;
      @include get-font-weight($key: 'Medium');
      font-size: 14px !important;
      line-height: 20px !important;
      background-color: white !important;
      text-transform: none !important;
      border-radius: 9px !important;
      border: 1px solid #d6dae1 !important;
    }

    .lawyer-button {
      display: inline-block !important;
      height: 44px !important;
      left: 0px !important;
      top: 0px !important;
      border-radius: 8px !important;
      padding: 5px 16px 5px 16px !important;
      color: white !important;
      font-family: $fontFamily !important;
      @include get-font-weight($key: 'Medium');
      font-size: 14px !important;
      line-height: 20px !important;
      margin-left: 15px !important;
      background-color: #7f56d9 !important;
      text-transform: none !important;
      border-radius: 9px !important;
    }
  }

  .right-container {
    width: 45%;
    height: 496px;
    min-width: 574px;
    display: flex;
    justify-content: flex-end;
    .images-group {
      height: 100%;
    }
  }
}

.lawp-policy-page-to-ask-section {
  display: flex;
  width: 90%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: #f9f5ff;
  border-radius: 16px;
  padding: 64px 0;
  margin-bottom: 96px;

  h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;

    text-align: center;
    letter-spacing: -0.02em;

    color: #42307d;
  }

  h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;

    padding: 0 30px;

    text-align: center;

    color: #6941c6;
  }

  .buttons-container {
    display: flex;
    height: 50px;
    align-items: center;
    margin-top: 20px;
  }

  .contact-button {
    display: inline-block !important;
    height: 45px !important;
    left: 0px !important;
    top: 0px !important;
    border-radius: 8px !important;
    padding: 5px 15px 5px 15px !important;
    color: #344054 !important;
    font-family: $fontFamily !important;
    @include get-font-weight($key: 'Medium');
    font-size: 14px !important;
    line-height: 20px !important;
    background-color: white !important;
    text-transform: none !important;
    border-radius: 9px !important;
    border: 1px solid #d6dae1 !important;
  }

  .sign-button {
    display: inline-block !important;
    height: 44px !important;
    left: 0px !important;
    top: 0px !important;
    border-radius: 8px !important;
    padding: 5px 16px 5px 16px !important;
    color: white !important;
    font-family: $fontFamily !important;
    @include get-font-weight($key: 'Medium');
    font-size: 14px !important;
    line-height: 20px !important;
    margin-left: 15px !important;
    background-color: #7f56d9 !important;
    text-transform: none !important;
    border-radius: 9px !important;
  }
}
