@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.lawp-autocomplete-select-aol-popper {
  width: 300px !important;
  z-index: 1300;
  // margin-top: 5px !important;
  // margin-bottom: 5px !important;
}

.lawp-autocomplete-select-aol-list-option {
  font-family: $fontFamily;
  @include get-font-weight($key: 'Medium');
  font-size: 16px !important;
  line-height: 20px;
  color: #344054;
  margin-bottom: 0px;
  cursor: default;
}

.lawp-autocomplete-select-aol-popper-list-option-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
  gap: 12px;
  padding: 8px;

  //on hover background color
  &:hover {
    background-color: #f2f2f2;
  }

  //no horrizontal scroll
  overflow-x: hidden;
}

.lawp-autocomplete-select-aol-container {
  width: 100%;
  height: 100%;

  .end-icon {
    cursor: pointer;
  }

  .MuiOutlinedInput-root {
    height: 100% !important;
  }

  .start-icon {
    margin-left: 8px;
  }

  .end-icon {
    margin-right: 4px;
    color: gray;
  }

  .MuiInputBase-input {
    text-align: center;
    //on focus border color
    &:focus {
      text-align: left;
      //placeholder color
      &::placeholder {
        color: white;
      }
    }
  }

  // .css-viou3o-MuiAutocomplete-root {
  //   width: 100%;
  //   height: 100%;
  //   padding: 200px !important;
  // }

  .MuiOutlinedInput-notchedOutline legend {
    display: none !important;
  }

  // .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  //   top: -1px !important;
  // }

  .MuiOutlinedInput-root {
    padding: 11px !important;
  }

  //   -----------------------------------------

  // .css-wb57ya-MuiFormControl-root-MuiTextField-root {
  //   height: 100% !important;
  // }

  // .css-16awh2u-MuiAutocomplete-root .MuiAutocomplete-inputRoot {
  //   height: 100% !important;
  // }

  .editLawyerAutoCompleteStyling {
    margin-top: 0 !important;
    height: 50px !important;

    // .MuiOutlinedInput-root {
    //   .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    //     border-color: green !important;
    //   }
    // }
  }

  .searchTextFieldAutoComplete {
    height: 100% !important;
    background-color: white;
    border-radius: 8px !important;

    .MuiOutlinedInput-root {
      font-family: 'Inter' !important;
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 24px !important;
      color: #101828 !important;

      .MuiOutlinedInput-notchedOutline {
        border: 10px solid #d0d5dd !important;
        border-radius: 8px !important;
        padding: 0 !important;
        top: 0 !important;
      }

      .MuiOutlinedInput-notchedOutline {
        border: 1px solid #d0d5dd !important;
        border-radius: 8px !important;
        padding: 0px !important;
        top: 0px !important;
      }
    }

    .MuiOutlinedInput-notchedOutline legend {
      display: none !important;
    }
    .MuiInputBase-input {
      padding: 2px !important;
      font-family: $fontFamily !important;
      @include get-font-weight($key: 'Regular');
    }
  }
}
