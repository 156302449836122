@import "Assets/theme.scss";

$primaryColor: map-get($theme-colors, $key: "primaryColor");
$fontFamily: map-get($theme-font, $key: "primaryFontFamily");

.container {
  display: "block";
}

.avatarTag {
  display: inline-block;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 13px;
  background-color: #f9f5ff;
  margin-left: 3px;
  padding: 4px 10px 4px 12px;
  gap: 4px;
  margin-bottom: 5px;
}

.avatarTagIcon {
  display: inline-block;
  position: relative;
  width: 9px;
  // font-size: 19px !important;
  color: $primaryColor;
  bottom: 1px;
}

.avatarTagText {
  display: inline-block;
  margin-left: 3px;
  font-size: 14px;
  font-family: $fontFamily;
  @include get-font-weight($key: "Medium");
  color: $primaryColor;
}

.titleContainer {
  display: block;
  margin-top: 0px;
}

.titleText {
  color: #344054;
  font-size: 14px;
  line-height: 20px;
  font-family: $fontFamily;
  @include get-font-weight($key: "Medium");
}

.titleTextSubscription {
  margin-left: 5px;
  font-size: 14px;
  line-height: 20px;
  font-family: $fontFamily;
  @include get-font-weight($key: "Medium");
  color: $primaryColor;
}

.contentContainer {
  display: block;
}

.contentText {
  font-size: 14px;
  line-height: 20px;
  font-family: $fontFamily;
  @include get-font-weight($key: "Regular");
  color: gray;
}
