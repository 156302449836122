@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.send-message-to-lawyer-modal-actions-cancel-event-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 115px;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #6941c6;

  border: none;
  background: none;
}

.send-message-to-lawyer-modal-lawyer-email-button {
  padding: 0;
  border: 0;
  background-color: #fff;
  font-weight: 500;
  color: #6941c6;

  background: none;
  border: none;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  font-size: 16px !important;
  margin-top: 2px;
}

.send-message-to-lawyer-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  position: absolute;
  width: 100%;
  height: 100%;
  left: 7217px;
  top: 7459px;

  background: rgba(52, 64, 84, 0.6);

  backdrop-filter: blur(8px);
}

// send-message-to-lawyer-modal

.send-message-to-lawyer-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 32px;

  width: 400px;
  // height: 440px;

  background: #ffffff;

  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1),
    0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  border-radius: 12px;
}

.send-message-to-lawyer-modal-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;

  width: 100%;
  // height: 316px;
}

.send-message-to-lawyer-modal-avatar-label-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 12px;

  width: 100%;
  // height: 40px;
}

.send-message-to-lawyer-modal-profile-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  width: 40px;
  height: 40px;

  object-fit: cover;
  object-position: 50% 50%;

  border-radius: 200px;
}

.send-message-to-lawyer-modal-name-and-email {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 100%;
  // height: 40px;
}

.send-message-to-lawyer-modal-name {
  width: 100%;
  // height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #344054;
}

.send-message-to-lawyer-modal-email {
  width: 100%;
  // height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #667085;
}

.send-message-to-lawyer-modal-text-and-supporting-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;

  width: 100%;
  // height: 56px;
}

.send-message-to-lawyer-modal-text {
  width: 100%;
  // height: 28px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;

  color: #101828;
}

.send-message-to-lawyer-modal-supporting-text {
  width: 100%;
  // height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #667085;
}

.send-message-to-lawyer-modal-textarea-input-field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 100%;
  // height: 180px;
}
.send-message-to-lawyer-modal-textarea-input-hint {
  display: flex;
  width: 352px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #667085;
}

.send-message-to-lawyer-modal-actions {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  width: 100%;
  // height: 44px;
}

.send-message-to-lawyer-modal-cancel-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 168px;
  height: 44px;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #344054;
}

.send-message-to-lawyer-modal-success-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  //   width: 180px;
  width: 230px;
  height: 44px;

  background: #7f56d9;

  border: 1px solid #7f56d9;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #ffffff;
}

.send-message-error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  font-family: $fontFamily;
  margin-bottom: -5px;
}

@media screen and (max-width: 1060px) {
  .send-message-to-lawyer-modal-actions-cancel-event-button {
    font-size: 12px;
  }

  .send-message-to-lawyer-modal-lawyer-email-button {
    font-size: 16px;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
