@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.modal-body {
  height: 100%;

  margin-bottom: 106px;
}

.subscription-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;

  background: #ffffff;
}

.pricing-action-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;

  background: none;
  border: none;

  // width: 169px;
  // height: 20px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.pricing-action-button-text {
  // width: 141px;
  // height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  margin: 0;

  color: #6941c6;

  flex: none;
  order: 0;
  flex-grow: 0;
}
.subscription-modal-title-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 36px;
  gap: 64px;

  background: #ffffff;
}
.subscription-modal-title-compoent-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 32px;
  gap: 32px;

  // width: 1280px;
  // height: 246px;
}
.subscription-modal-title-compoent-container-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 40px;

  // width: 1216px;
  // height: 246px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.heading-and-supporting-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 24px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.heading-and-subheading {
  display: flex;
  flex-direction: column;

  align-items: center;
  padding: 0px;
  gap: 12px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.subheading {
  cursor: pointer;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  background-color: #ffffff;
  text-align: center;
  border: none;

  color: #7f56d9;
}
.heading {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 60px;

  text-align: center;
  letter-spacing: -0.02em;

  color: #101828;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.suport-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;

  text-align: center;

  color: #667085;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.header-horizontal-tabs {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px;
  gap: 8px;

  background: #f9fafb;

  border: 1px solid #f2f4f7;
  border-radius: 8px;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.subscription-card-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding: 0px 0px 96px;
  gap: 64px;
}
.subscription-card-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 32px;
  gap: 64px;

  flex: none;
  order: 0;
  flex-grow: 0;
}
.subscription-card-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0px 0px 32px;
  gap: 32px;
}

.horizontal-tabs-month {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;

  width: 180px;
  height: 44px;

  border-radius: 6px;

  background: #f9fafb;

  border: 0px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  color: #667085;
}
.horizontal-tabs-month-active {
  background: #ffffff;

  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
}

.horizontal-tabs-month-text {
  width: 151px;
  height: 24px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #667085;
}

.horizontal-tabs-year-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;

  width: 226px;
  height: 44px;
  border: none;

  background: #ffffff;

  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 6px;
  border-color: #ffffff;
}

.horizontal-tabs-year-text {
  width: 136px;
  height: 24px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #344054;
}

.horizontal-tabs-year-badge {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 2px 10px;

  width: 54px;
  height: 24px;

  background-color: white;
  border-radius: 16px;
}

.horizontal-tabs-year-badge-active {
  background-color: #f2f4f7 !important;
}

.horizontal-tabs-year-badge-text {
  width: 34px;
  height: 20px;

  /* Text sm/Medium */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: center;

  /* Gray/700 */

  color: #344054;
}

.horizontal-tabs-month-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;

  width: 189px;

  border: none;

  width: 179px;
  left: 6px;
  top: 6px;

  background: #ffffff;

  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 6px;
  border-color: #ffffff;
}

.horizontal-tabs-year {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;
  border: 0;
  cursor: pointer;

  width: 226px;
  height: 44px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Gray/700 */

  color: #344054;

  background: #f9fafb;
}
.horizontal-tabs-year-active {
  background: #ffffff;

  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 6px;
}

.horizontal-tabs-button-text {
  width: 156px;
  height: 24px;

  /* Text md/Medium */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Gray/700 */

  color: #344054;
}

.downgrade-subscription-button {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 8px;

  width: 320px;
  height: 48px;

  background: #fee4e2;

  border: 1px solid #fef3f2;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}
.actual-subscription-button {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 8px;

  width: 320px;
  height: 48px;

  background: #f9f5ff;

  border: 1px solid #f9f5ff;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

@media screen and (max-width: 1480px) {
  .subscription-card-content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .subscription-card-container {
    align-items: center;
  }
}

@media screen and (max-width: 1480px) {
  .subscription-modal-title-component,
  .subscription-modal-title-compoent-container,
  .subscription-modal-title-compoent-container-content,
  .heading-and-supporting-text,
  .suport-text,
  .heading-and-subheading,
  .heading {
    width: 100%;
  }

  .subscription-overlay {
    height: 100%;
    // gap: 50px;
  }

  .subscription-card-section,
  .subscription-card-container {
    width: 100%;
    // height: 1811px;
  }
}

.register-next-step {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .update-subscription-button {
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    gap: 8px;

    width: 320px;
    height: 48px;

    background: #7f56d9;

    border: 1px solid #7f56d9;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    color: #ffffff;

    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
  }
}
