@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.lawp-review-card-public-container {
  /* Bewertung Item */

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 32px;
  gap: 22.4px;

  width: 359px;
  max-width: 359px;
  height: 460px;

  /* White */
  background: #ffffff;

  /* Gray/200 */
  border: 0.933136px solid #e4e7ec;
  border-radius: 14.9302px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 1;

  margin: 20px;

  &:hover {
    cursor: pointer;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1),
      0px 4px 6px -2px rgba(16, 24, 40, 0.05);
  }
}

.lawp-review-card-public-center-content-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  margin-bottom: -10px;
}

.lawp-review-card-public-review-title {
  font-size: 24px !important;
  line-height: 32px !important;
  font-family: $fontFamily !important;
  @include get-font-weight($key: 'Medium');
  color: #101828;

  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical;
  white-space: normal;

  margin-bottom: -5px;
}

.lawp-review-card-public-rating-container {
  // width: 200px;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 10px;
}

.lawp-review-card-public-rating-stars {
  margin-left: -3px;
}

.lawp-review-card-public-rating-text {
  margin-left: 8px;
  font-family: $fontFamily;
  @include get-font-weight($key: 'Medium');
  color: black;
  line-height: 24px;
  font-size: 16px;
}

.lawp-review-card-public-rating-reviews {
  margin-left: 8px;
  font-family: $fontFamily;
  @include get-font-weight($key: 'Regular');
  color: grey;
  font-size: 16px;
  line-height: 24px;
  white-space: pre;
}

.lawp-review-card-public-lawyer-description {
  margin-top: 10px !important;
  height: 240px;
  margin-bottom: 10px;
  width: 100%;
}

.lawp-review-card-public-lawyer-description-text {
  font-family: $fontFamily;
  @include get-font-weight($key: 'Regular');
  color: grey;
  font-size: 16px;
  line-height: 24px;
  text-overflow: ellipsis;
  margin-bottom: 0px;

  display: -webkit-box !important;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
  // white-space: normal;
  white-space: pre-wrap;
}

.lawp-review-card-public-article-author {
  display: flex;
  width: 100%;
  align-items: flex-start;
}

.lawp-review-card-public-author-name {
  font-family: $fontFamily;
  font-size: 14px;
  @include get-font-weight($key: 'Medium');
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.lawp-review-card-public-review-date {
  font-family: $fontFamily;
  font-size: 14px;
  @include get-font-weight($key: 'Regular');
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #667085;
}

.lawp-review-card-public-article-author-image {
  display: flex;
  flex-direction: row;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 13px;
}

.lawp-review-card-public-see-more-button-container {
  display: flex;
  height: 0px;

  width: 100%;
  justify-content: center;
}

.lawp-reviews-card-public-lawyer-response-container {
  display: flex;
  width: 100%;
  // margin-bottom: 16px;

  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  gap: 8px;

  background: #ffffff;

  /* Shadow/lg */
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1),
    0px 4px 6px -2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  .review-content {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 200;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0px;

    /* or 143% */

    /* Gray/500 */
    color: #667085;

    display: -webkit-box !important;
    -webkit-line-clamp: 5 !important;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: pre-wrap;
  }
}

.lawp-review-card-public-see-more-button {
  display: inline-block !important;
  height: 0px !important;

  color: #7f56d9 !important;
  font-family: $fontFamily !important;
  @include get-font-weight($key: 'Medium');
  font-size: 14px !important;
  line-height: 0px !important;
  text-transform: none !important;

  background-color: transparent !important;
}

@media screen and (max-width: 1060px) {
  .lawp-review-card-public-container {
    margin: 0;
  }
}
