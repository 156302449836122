@import "Assets/theme.scss";

$fontFamily: map-get($theme-font, $key: "primaryFontFamily");

$checkboxSize: 16px;
$checkboxRadius: 4px;

*,
*::before,
*::after {
  box-sizing: border-box;
}

.new-password-box-container {
  height: 516px;
}
.new-password-header {
  height: 178px;
}
.new-password-content {
  height: 254px;
}
.new-password-form-container {
  height: 186px;
}
.new-password-form-comment {
  height: 96px;
}
.new-password-form {
  height: 70px;
}
.new-password-input-label-container-comment {
  height: 96px;
}
.new-password-input-label-container {
  height: 70px;
}
.new-password-label {
  height: 20px;
}

.new-password-header-text-support {
  height: 98px;
}
.new-password-header-text {
  height: 38px;
}
.new-password-header-support {
  height: 48px;
}

.new-password-margin-bot {
  margin-bottom: 20px;
}
.new-password-action-button-margin-top {
  margin-top: 24px;
}
