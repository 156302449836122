@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.loading-text {
  display: flex;
  width: 100%;
  height: 300px;
  justify-content: center;
  align-items: center;

  font-size: 14px;
  line-height: 20px;
  font-family: 'Inter';
  font-weight: 400 !important;
}

.events-section-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 60px;
  gap: 24px;

  width: 100%;
  // height: 571px;
}

.events-section-split-section {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 100%;
  // height: 571px;
}

.events-section-inner-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0px;
  gap: 24px;

  width: 100%;
  // width: 90%;
  // height: 571px;
}

.events-section-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 32px;
  gap: 24px;

  width: 100%;
  // height: 100%;
}

.events-section-horizontal-tabs {
  width: 100%;
  height: 46px;

  position: relative;
}

.events-section-tabs {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  position: absolute;
  width: 100%;
  height: 46px;
  left: 0px;
  top: 0px;
}

.events-section-tab-upcoming-button-base-selected,
.events-section-tab-past-button-base-selected {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  width: 54px;
  height: 46px;

  background: #f9f5ff;

  color: #6941c6;
}

.events-section-tab-upcoming-button-base-unselected,
.events-section-tab-past-button-base-unselected {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  width: 54px;
  height: 46px;
}

.events-section-tab-upcoming-button-selected,
.events-section-tab-past-button-selected {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 8px;

  width: 54px;
  height: 44px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #6941c6;

  border: none;
  background: none;
}

.events-section-bottom-border {
  width: 54px;
  height: 3px;

  background: #6941c6;
}

.events-section-tab-past-button-base {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  width: 68px;
  height: 46px;
}

.events-section-tab-upcoming-button-unselected,
.events-section-tab-past-button-unselected {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 8px;

  width: 68px;
  height: 44px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #667085;

  border: none;
  background: none;
}

.events-section-divider {
  position: absolute;
  height: 1px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  background: #e4e7ec;
}

.events-section-vertical-divider {
  width: 571px;
  height: 1px;

  background: #e4e7ec;
  transform: rotate(90deg);
}

.loading-overlay {
  position: fixed;
  top: 80px; /* Adjust the top position based on your navbar height */
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 1);
  z-index: 9999;
}
