@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.lawp-google-autocomplete-custom-textfield-icon {
  color: #b3b3b3;
  margin-left: -15px;
  margin-right: 5px;
  font-size: 20px !important;
}

.lawp-google-autocomplete-custom-textfield-city {
  color: #000000;
  font-family: $fontFamily;
  @include get-font-weight($key: 'Medium');
  font-size: 16px !important;
}

.lawp-google-autocomplete-custom-textfield-country {
  color: gray;
  margin-left: 5px;
  font-family: $fontFamily;
  @include get-font-weight($key: 'Medium');
  font-size: 14px !important;
}

.lawp-google-autocomplete-custom-textfield-postal-code {
  color: #b3b3b3;
  font-size: 20px !important;
}

.lawp-google-autocomplete-custom-textfield-autocomplete {
  & .MuiInputBase-input {
    margin-top: -1px !important;
  }
}

.lawp-google-autocomplete-custom-textfield-textfield {
  .MuiOutlinedInput-root {
    height: 46px;

    .MuiOutlinedInput-notchedOutline {
      border: 1px solid #d0d5dd !important;
      border-radius: 8px !important;
      padding: 1px !important;
      top: 0px !important;
    }
  }

  .MuiOutlinedInput-notchedOutline legend {
    display: none !important;
  }
  .MuiInputBase-input {
    padding: 3px !important;
    font-family: $fontFamily !important;
    @include get-font-weight($key: 'Regular');
  }
}

.lawp-google-autocomplete-custom-textfield-title {
  color: #344054;
  font-family: $fontFamily;
  @include get-font-weight($key: 'Medium');
  font-size: 14px;
  line-height: 4px;
  padding-top: 3px;
  margin-bottom: -4px;
}

.lawp-google-autocomplete-custom-textfield-error {
  .MuiOutlinedInput-root {
    height: 46px;

    .MuiOutlinedInput-notchedOutline {
      border: 1px solid red !important;
      border-radius: 8px !important;
      padding: 1px !important;
      top: 0px !important;
    }
  }
}

.lawp-google-autocomplete-custom-textfield-error-message {
  font-weight: 400;
  line-height: 20px;
  color: #ff0000;
  margin-top: 5px !important;
  margin-bottom: 0;
}
