@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$checkboxSize: 16px;
$checkboxRadius: 4px;

*,
*::before,
*::after {
  box-sizing: border-box;
}

.search-bar-container {
  position: sticky;
  top: 80px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px 0px;
  gap: 10px;

  width: calc(100vw - 8px);

  min-width: 640px;

  z-index: 7;
  background: #fedf89;
}

.search-field-container {
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  width: 100%;
  @media (max-width: 1060px) {
    height: 108px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    // padding: 40px 0px 40px 0px;
  }
}
.input-field-label-container {
  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;

  min-width: 250px;
  // height: 76px;
}

.input-field-label-container-city {
  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;

  min-width: 250px;

  @media (max-width: 1060px) {
    width: 204px;
    min-width: 204px;
  }
  // height: 76px;
}

.input-field {
  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 6px;

  width: 330px;
  height: 48px;
}
.input-search {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 14px 12px 16px;
  position: static;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
}

.input-search:focus {
  outline: none !important;
  border: 1px solid #7f56d9;
  // box-shadow: 0 0 10px #719ece;
}

.input-field-label-underline {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: underline;
  /* identical to box height, or 143% */

  /* Gray/500 */

  color: #667085;
}

.dropdown-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.input-container {
  display: flex;
  flex-direction: row;
  gap: 10px;

  @media (max-width: 1060px) {
    flex-direction: column;
  }
}

.input-dropdown-rechtsgebiete {
  width: 209.45px;
  height: 48px;
}
.input-dropdown-location {
  width: 204px;
  height: 48px;
}

.dropdown-location {
  position: relative;
}

.dropdown-menu-location-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 16px;
  gap: 12px;

  width: 240px;
  height: 40px;

  /* Text sm/Normal */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Gray/700 */

  color: #344054;
}
.line {
  height: 1px;
  width: 240px;
  background: #f2f4f7;
}
.dropdown-menu-location-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 16px;
  gap: 20px;

  width: 240px;
  height: 165px;
}

.dropdown-menu-location-input-label-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4.65px;

  width: 194px;
  height: 54px;
}
.dropdown-menu-location-label {
  width: 17px;
  height: 16px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 10.85px;
  line-height: 16px;

  color: #344054;
}
.dropdown-menu-location-input {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7.75px 10.85px;
  gap: 6.2px;
  // background-image: url("/Assets/pictures/search.svg") no-repeat left;

  width: 194px;
  height: 34.5px;

  /* White */

  background: #ffffff;
  /* Gray/300 */

  border: 0.775px solid #d0d5dd;
  box-shadow: 0px 0.775px 1.55px rgba(16, 24, 40, 0.05);
  border-radius: 6.2px;
}

.dropdown-menu-location-input:focus {
  outline: none !important;
  border: 1px solid #7f56d9;
  // box-shadow: 0 0 10px #719ece;
}

.map-pin {
  left: 0;
}
.briefcase {
  left: 0;
}

.down-arrow {
  right: 0;
}

.input-button-container {
  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 200px;
  height: 100%;

  border-radius: 8px;

  //below 1060px
  @media (max-width: 1060px) {
    width: 50px;
  }
}

.dropdown-menu-location-input-search {
  /* Input */

  box-sizing: border-box;

  /* Auto layout */
  background: url('../../pictures/searchIcon.svg') no-repeat scroll 7px 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7.75px 10.85px;
  gap: 6.2px;

  width: 194px;
  height: 34.5px;

  /* White */

  background: #ffffff;
  /* Gray/300 */

  border: 0.775px solid #d0d5dd;
  box-shadow: 0px 0.775px 1.55px rgba(16, 24, 40, 0.05);
  border-radius: 6.2px;
}
