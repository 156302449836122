@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$phoneBreakpoint: map-get($screen-width-breakpoints, $key: 'phone');

.lawp-lawyer-card-skeleton-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
  padding: 25px 0px;

  @media (max-width: $phoneBreakpoint) {
    flex-direction: column;
    padding: 22px;
  }

  .card-image {
    width: 210px;
    height: 175px;

    @media (max-width: $phoneBreakpoint) {
      width: 570px;
      height: 300px;
      margin-bottom: 20px;
    }
  }

  .card-info {
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: $phoneBreakpoint) {
      height: 214px;
    }
  }

  .info-column{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 60px;
    
    @media (max-width: $phoneBreakpoint) {
      height: 130px;
    }
  }

  .info-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
  }
}
