.lawyer-card-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  margin-top: 10%;
  width: 90%;
  height: 81px;
}
.divider {
  width: 100%;
  height: 1px;

  /* Gray/200 */

  background: #e4e7ec;
}

.lawyer-content-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;

  width: 100%;
  height: 56px;
}

.avatar-label-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;
  cursor: pointer;
  // width: 342px;
  height: 56px;
}
.avatar-picture {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  width: 56px;
  height: 56px;

  /* Avatar user square/Olivia Rhye */

  object-fit: cover;

  border-radius: 200px;
}

.text-and-support-text {
  display: inline-block;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: auto;
  height: 56px;
}
.text-name {
  width: auto;
  display: inline-block;
  /* Text lg/Medium */
  align-items: flex-start;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  /* identical to box height, or 156% */

  /* Gray/900 */

  color: #101828;
}
.support-text {
  width: 170px;
  height: 24px;

  /* Text md/Normal */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Gray/500 */

  color: #667085;
}
.lawyer-card-social-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  height: 40px;
}

.lawyer-card-button-link {
  display: inline-block;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 150px;
  height: 40px;
  background: none;
  border: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Primary/700 */

  color: #6941c6;
}

.lawyer-card-button-profile {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  // width: auto;
  width: 55%;
  height: 40px;

  border-radius: 8px;
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;

  /* White */

  background: #ffffff;
  /* Gray/300 */

  border: 1px solid #d0d5dd;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  /* Text sm/Medium */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Gray/700 */

  color: #344054;
}
.copyIcon {
  left: 8.33%;
  right: 8.33%;
  top: 8.33%;
  bottom: 8.33%;
  margin-right: 8%;
  /* Primary/700 */

  width: 20px;
  height: 20px;

  /* Primary/700 */
}
