@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$phoneBreakpoint: map-get($screen-width-breakpoints, $key: 'phone');
$tabletBreakpoint: map-get($screen-width-breakpoints, $key: 'tablet');

.lawp-contact-lawyer-component-map-component-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 40px;

  width: 95%;

  background: #ffffff;

  backdrop-filter: blur(4px);

  // margin-top: 40px;
  // margin-bottom: 40px;

  @media (max-width: $tabletBreakpoint) {
    padding: 40px 0;
  }

  .parentComponent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    // padding: 32px;

    width: 100%;

    @media (max-width: $tabletBreakpoint) {
      padding: 0;
    }
  }

  .gMap {
    position: static;
    width: 850px;
    height: 350px;
    left: 0px;
    top: 0px;

    @media (max-width: $tabletBreakpoint) {
      width: 972px;
    }

    @media (max-width: $phoneBreakpoint) {
      width: 608px;
    }
  }

  .google-maps-container {
    position: static;
    width: 70%;
    left: 0px;
    top: 0px;

    @media (max-width: $tabletBreakpoint) {
      width: 100%;
    }
  }

  .elements {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;

    gap: 30px;

    width: 100%;

    @media (max-width: $tabletBreakpoint) {
      flex-direction: column;
      gap: 40px;
    }
  }

  .mapTitleElement {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 100%;
    margin-bottom: 25px;
  }

  .map-placeholder-container {
    position: static;
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    width: 850px;
    height: 350px;
    left: 0px;
    top: 0px;

    border-radius: 5px;

    background-image: url('../../pictures/GoogleMapPlaceholder.png');

    background-size: cover;

    background-position: 50% 50%;

    @media (max-width: $tabletBreakpoint) {
      width: 972px;
    }

    @media (max-width: $phoneBreakpoint) {
      width: 608px;
    }
  }

  .map-placeholder-description-text {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.02em;

    width: 60%;

    color: #486174 !important;

    margin-bottom: 5px;
  }

  .map-placeholder-learn-more-text {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.02em;

    color: #7f56d9 !important;
    //under line
    text-decoration-line: underline;
    // on hover pointer
    margin-bottom: 30px;

    &:hover {
      cursor: pointer;
    }
  }

  .map-allow-button {
    display: flex !important;
    height: 48px !important;

    padding: 5px 16px 5px 16px !important;
    color: white !important;
    font-family: $fontFamily !important;
    @include get-font-weight($key: 'Medium');
    font-size: 16px !important;
    line-height: 24px !important;

    border-radius: 8px !important;
    background-color: #7f56d9 !important;
    text-transform: none !important;
    border-radius: 7px !important;

    box-sizing: border-box !important;
  }

  .FindUsHere {
    font-family: 'Inter';
    font-size: 36px;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: -2%;
  }

  .contactDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: flex-end; */
    align-items: flex-start;
    padding: 0px;
    gap: 20px;
    position: static;
    width: 30%;

    @media (max-width: $tabletBreakpoint) {
      width: 100%;
    }
  }
  .officeName {
    position: static;
    /* width: 308px; */
    width: 100%;
    // height: 44px;
    left: 12px;
    top: 0px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;

    letter-spacing: -0.02em;
  }

  .adressData {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    gap: 24px;

    width: 100%;

    @media (max-width: $tabletBreakpoint) {
      flex-direction: row;
    }

    @media (max-width: $phoneBreakpoint) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .adressElement {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    gap: 8px;

    width: 100%;
  }

  .websiteElement {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    gap: 8px;

    width: 100%;
  }

  .telephoneElement {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    gap: 8px;

    width: 100%;
  }

  .emailElement {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    gap: 8px;

    width: 100%;
  }

  .emailaltElement {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    position: static;
    width: 100%;
    left: 0px;
    top: 240px;

    /* Inside auto layout */
  }

  .PurpleText {
    display: flex;
    align-items: flex-start;

    font-family: 'Inter';
    font-size: 16px;
    font-weight: 500;
    // font-weight: 500, Medium;
    line-height: 24px;

    color: #6941c6;

    background: none;
    border: none;
  }

  .websiteLink,
  .websiteLink:hover {
    font-size: 16px;
    color: #6941c6;
    text-decoration: none;
  }

  .TitleText {
    display: flex;
    flex-direction: column;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 500;
    // font-weight: 500, Medium;
    line-height: 20px;
    gap: 8px;
    color: #667085;
  }

  .NormalText {
    font-family: 'Inter';
    font-size: 14px;
    // font-weight: 400, Regular;
    font-weight: 400;
    line-height: 16px;

    color: #667085;
  }

  .OfficeText {
    font-family: 'Inter';
    font-size: 36px;
    font-weight: 600, Semi Bold;
    line-height: 44px;

    letter-spacing: -0.02em;

    color: #101828;
  }

  .officeNameContainer {
    width: 100%;
  }
}
