@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.lawp-operator-social-media-content-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;

  .updateButton {
    display: inline-block !important;
    margin-top: 25px !important;
    height: 46px !important;
    left: 0px !important;
    top: 0px !important;
    padding: 5px 16px 5px 16px !important;
    color: white !important;
    font-family: $fontFamily !important;
    @include get-font-weight($key: 'Medium');
    font-size: 14px !important;
    line-height: 20px !important;
    margin-left: 15px !important;
    background-color: #7f56d9 !important;
    text-transform: none !important;
    border-radius: 9px !important;
  }

  .facebook-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    margin-bottom: 2rem;

    width: 100%;
  }
}
