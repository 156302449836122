@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.icon {
  width: 16px;
  height: 16px;
  margin-right: 14px;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #f2f4f7;
}

.text {
  font-size: 15px;
  line-height: 20px;
  font-family: $fontFamily;
  @include get-font-weight($key: 'Regular');
  color: #344054;
}

.menuIcon {
  background-color: #f9f5ff !important;

  &:hover {
    background-color: #f9f5ff !important;
  }
}
