@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');
.delete-stepper-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 48px 16px 0px 48px;
  gap: 80px;

  width: 464px;
  height: 364px;
}

.anwado-logo {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  left: 0.35%;
  right: 77.11%;
  top: 0%;
  bottom: -0.09%;
  width: 150px;
  gap: 10px;
}

.stepper-title-text {
  position: relative;
  left: 10px;
  right: 10.56%;
  top: 12.5%;
  bottom: 12.5%;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;

  color: #000000;
}

.stepper-content-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 400px;
  height: 204px;
}
.delete-stepper-connector-gray {
  width: 2px;
  height: 62px;

  background: #e4e7ec;
  border-radius: 2px;
}
.delete-stepper-connector-purple {
  width: 2px;
  height: 62px;

  background: #7f56d9;
  border-radius: 2px;
}

.verify-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 40px;
  margin-top: 99%;
  width: 464px;
  height: 96px;
}

.verify-footer-text {
  width: 118px;
  height: 20px;
  position: relative;
  align-items: center;
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 0;

  color: #667085;

  flex: none;
  order: 0;
  flex-grow: 0;
}
.verify-footer-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 162px;
  height: 20px;
}
.verifyFooterEmailText {
  width: 13px;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 0;

  color: #667085;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.email-logo {
  color: grey;
}
.step-icon-base {
  width: 32px;
  height: 32px;
  position: relative;

  background: #f9f5ff;
  box-shadow: 0px 0px 0px 4px #f4ebff;
  border-radius: 16px;

  flex: none;
  order: 0;
  flex-grow: 0;
}
.step-icon-base-check {
  width: 32px;
  height: 32px;
  position: relative;

  background: #f9f5ff;
  // box-shadow: 0px 0px 0px 4px #F4EBFF;
  border-radius: 16px;

  flex: none;
  order: 0;
  flex-grow: 0;
}
.selected-icon-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // height: 32px;
  // width: 32px;
  // left: 0px;
  // top: 0px;
  border-radius: 16px;

  background: #f9f5ff;
  border-radius: 16px;
}
.selected-dot {
  position: absolute;
  width: 10px;
  height: 10px;
  left: calc(50% - 10px / 2);
  top: calc(50% - 10px / 2);
  border-radius: 50%;

  background: #7f56d9;

  z-index: 2;
}
.delete-stepper-check {
  // position: absolute;
  left: 26.46%;
  right: 23.56%;
  top: 29.65%;
  bottom: 26.58%;
}
.unselected-step-icon-base {
  width: 32px;
  height: 32px;
  position: relative;

  background: #f9fafb;
  border-radius: 16px;

  flex: none;
  order: 0;
  flex-grow: 0;
}
.un-selected-icon-content {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;

  border-radius: 16px;
}
.un-selected-dot {
  position: absolute;
  width: 10px;
  height: 10px;
  left: calc(50% - 10px / 2);
  top: calc(50% - 10px / 2);
  border-radius: 50%;

  background: #e4e7ec;
}
.stepper-progration-container,
.last-stepper-progration-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 0px 4px;
  gap: 4px;

  width: 32px;
  height: 102px;
}

.delete-stepper,
.last-delete-stepper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  width: 400px;
  height: 102px;
}

.delete-stepper-text-container {
  flex-direction: column;
  align-items: flex-start;
  padding: 4px 0px 24px;
  gap: 2px;

  width: 352px;
  height: 102px;
}
.delete-sepper-text-title {
  width: 352px;
  height: 24px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #344054;
}
.delete-sepper-text-details {
  width: 352px;
  height: 48px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #667085;
}

.verifyLogo {
  width: 190px;
  height: 33px;
  cursor: pointer;

  flex: none;
  order: 0;
  flex-grow: 0;
}

@media screen and (max-width: 1060px) {
  .delete-stepper-container {
    height: auto;
  }

  .stepper-content-container {
    justify-content: center;
    align-items: unset;
  }

  .stepper-content-container,
  .stepper-progration-container {
    flex-direction: row;

    height: auto;
  }

  .stepper-progration-container {
    width: auto;
  }

  .delete-stepper {
    width: auto;
    height: auto;
  }

  .delete-stepper-connector-gray {
    width: 205px;
    height: 2px;
  }

  .delete-stepper-connector-purple {
    // width: 155px;
    width: 205px;
    height: 2px;
  }

  .anwado-logo,
  .delete-stepper-text-container,
  .last-delete-stepper,
  .last-stepper-progration-container {
    display: none;
  }
}
