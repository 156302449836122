@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');
$tabletBreakpoint: map-get($screen-width-breakpoints, $key: 'tablet');
$phoneWidthBreakpoint: map-get($screen-width-breakpoints, $key: 'phone');

.container {
  display: flex;
  width: 100%;
  height: 526px;
  background-color: #ffffff;
  align-items: center;
  justify-content: center;
  @media (max-width: 1060px) {
    height: 100%;
    padding: 40px 0;
  }
}

.centerContainer {
  display: flex;
  flex-direction: row;
  height: 75%;
  width: 90%;
  justify-content: space-between;
  align-items: center;

  @media (max-width: $tabletBreakpoint) {
    width: 100%;
  }

  @media (max-width: $phoneWidthBreakpoint) {
    flex-direction: column-reverse;
  }
}

.contentContainer {
  display: block;
  height: 90%;
  width: 70%;

  @media (max-width: $phoneWidthBreakpoint) {
    width: 90%;
  }
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 295px;
  width: 640px;

  @media (max-width: $tabletBreakpoint) {
    width: 640px;
    height: 266px;
    margin-bottom: 20px;
  }

  @media (max-width: $phoneWidthBreakpoint) {
    width: 100%;
    height: 300px;
    margin-bottom: 20px;
  }
}

.video {
  width: 100%;
  height: 100%;
}

.reactPlayer {
  height: 270px;
}

.titleContainer {
  display: flex;
  height: 40%;
  width: 90%;
  align-items: center;

  @media (max-width: $tabletBreakpoint) {
    margin-bottom: 20px;
  }

  @media (max-width: $phoneWidthBreakpoint) {
    margin-bottom: 10px;
  }
}

.subTitleContainer {
  display: flex;
  height: 25%;
  width: 100%;
  align-items: center;
}

.lawyersContainer {
  display: flex;
  height: 35%;
  width: 100%;
  align-items: center;
}

.title {
  font-family: $fontFamily !important;
  @include get-font-weight($key: 'SemiBold');
  font-size: 50px !important;
  line-height: 50px !important;
}

.subTitle {
  font-family: $fontFamily !important;
  @include get-font-weight($key: 'Regular');
  font-size: 20px !important;
  line-height: 30px !important;
  color: grey !important;
}

.rating-container {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
}

.rating-subTitle {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #667085;
}

.avatarGroup {
  display: flex;
}

.ratingContainer {
  display: flex;
  height: 100%;
  width: 100%;
  margin-left: 10px;
  flex-direction: column;
  justify-content: center;
}

.starsContainer {
  display: flex;
  flex-direction: column;
}

.ratingText {
  margin: 0;
  margin-left: 10px;
  font-family: $fontFamily;
  @include get-font-weight($key: 'SemiBold');
  color: black;
  font-size: 1.2em;
}

.reviewsContainer {
  display: block;
}

.reviewsText {
  font-family: $fontFamily;
  @include get-font-weight($key: 'Medium');
  color: grey;
  font-size: 1.2em;
  white-space: pre;
}
