.lawp-lawyer-page-container {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  flex: none;
  flex-grow: 0;

  @media (max-width: 660px) {
    height: auto;
  }
}

.lawp-lawyer-gradient-image {
  width: 100vw;
  height: 150px;

  @media (max-width: 600px) {
    width: 100%;
  }
}

.loading-overlay {
  position: fixed;
  top: 80px; /* Adjust the top position based on your navbar height */
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 1);
  z-index: 9999;
}
