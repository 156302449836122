@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$phoneBreakpoint: map-get($screen-width-breakpoints, $key: 'phone');
$tabletBreakpoint: map-get($screen-width-breakpoints, $key: 'tablet');
$desktopBreakpoint: map-get($screen-width-breakpoints, $key: 'tablet');

.payment-method-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 24px 41px 24px;
  gap: 20px;

  // width: 788px;
  // height: 237px;

  background: #ffffff;

  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
  @media (max-width: $phoneBreakpoint) {
    padding: 24px;
  }

  .payment-method-title-text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;

    height: 52px;

    .payment-method-title {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;

      color: #101828;
    }
    .payment-method-text {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      color: #667085;
    }
  }
  .payment-method-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 16px;
    gap: 16px;

    width: 740px;
    height: 100px;

    background: #ffffff;

    border: 1px solid #e4e7ec;
    border-radius: 8px;
    @media (max-width: $phoneBreakpoint) {
      width: auto;
      height: auto;
      // flex-direction: column;
    }
    .payment-method-icon-billing-container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      gap: 8px;
      .payment-method-icon {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        width: 58px;
        height: 40px;

        background: #ffffff;

        border: 1px solid #f2f4f7;
        border-radius: 6px;
        .payment-img {
        }
      }
      .payment-method-billing {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;

        .payment-method-billing-title-text {
          .payment-method-billing-title {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;

            color: #344054;
          }
          .payment-method-billing-text {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;

            color: #667085;
          }
        }
        .payment-method-billing-email {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0px;
          gap: 8px;

          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;

          color: #667085;
          .payment-mail-icon {
            color: grey;
          }
        }
      }
    }

    .payment-method-action-container {
      cursor: pointer;
      box-sizing: border-box;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px 16px;
      gap: 8px;

      width: 215px;

      height: 40px;

      background: #ffffff;

      border: 1px solid #d0d5dd;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;

      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;

      color: #344054;
      white-space: nowrap;
    }
  }
}
