@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$checkboxSize: 16px;
$checkboxRadius: 4px;

*,
*::before,
*::after {
  box-sizing: border-box;
}

.register-container {
  display: flex;

  width: 100%;
  // height: 960px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  z-index: 10;

  flex: none;
  order: 0;
}

.errorMessage {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  font-family: $fontFamily;
  margin-bottom: 25px;
}

.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 32px;

  width: 100%;
  // min-height: 864px;
  min-height: 830px;
}

.full-width-header-navigation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 0px;

  width: 100%;
  height: 104px;

  background: #ffffff;
}

.full-width-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  width: 100%;
  height: 80px;
}

.full-width-header-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 24px;

  width: 100%;
  height: 44px;

  background: #ffffff;
}

.full-width-header-lawyerButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  // width: 162px;
  // height: 44px;

  background: #f9f5ff;

  border: 1px solid #f9f5ff;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #6941c6;
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;

  width: 360px;
  // height: 572px;
  min-height: 640px;
}

.header-and-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;

  max-width: 100%;
  // height: 558px;
  // height: 640px;
}
.header-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 24px;

  width: 100%;
  height: 104px;
}
.text-supporting-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  width: 100%;
  // height: 220px;
}

.header-text {
  width: 100%;
  // height: 76px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 1.875rem;
  line-height: 38px;

  color: #101828;
}
.header-supporting-text {
  width: 100%;
  // height: 72px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #667085;
}

.rightSectionInputs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.rightSectionForm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.firstNameInputField {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.lastNameInputField {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
}

.emailInputField {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
}

.label-input {
  width: 80px;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #344054;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.registerInput {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;

  width: 100%;
  height: 44px;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.inputWithLabel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.actions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
}

.registerButton {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 100%;
  height: 44px;

  background: #7f56d9;

  border: 1px solid #7f56d9;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  flex: none;
  order: 0;
  flex-grow: 1;
}

.buttonText {
  width: 135px;
  height: 24px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 0;

  color: #ffffff;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 100%;
  height: 20px;

  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}

.loginText {
  max-width: 100%;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #667085;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.loginButton {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  max-width: 100%;
  height: 20px;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.loginButtonBase {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 106px;
  height: 20px;

  flex: none;
  order: 0;
  flex-grow: 0;
  background-color: #ffffff;

  outline: none;
  border: none;
}

.loginButtonText {
  width: 106px;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;

  color: #6941c6;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.footer-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 32px;

  width: 100%;
  height: 96px;
}

.text {
  max-width: 100%;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #667085;
}

.footer-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;

  max-width: 100%;
  height: 20px;
}

.email-logo {
  color: grey;
}

.register-footer-email-text {
  max-width: 100%;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  margin: 0;

  color: #667085;
}

.hintText {
  width: 360px;
  height: 20px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 0;

  color: #667085;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.disabledButton {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 100%;
  height: 2.75rem;

  background: grey;

  border: 1px solid grey;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  flex: none;
  order: 0;
  flex-grow: 1;
}

.rowTerms {
  display: flex;
  flex-direction: row;
  // justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  margin-top: 24px;

  // width: 25rem;
  // height: 1.25rem;

  flex: none;
  order: 2;
  // align-self: stretch;
  // flex-grow: 0;
}

.checkbox-label-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 164px;
  height: 20px;
}
.checkbox-container-selected {
  width: $checkboxSize;
  height: $checkboxSize;
  max-width: $checkboxSize;
  display: inline-flex;
  background-color: #f9f5ff;
  border: 2px solid #7f56d9;
  border-radius: $checkboxRadius;
  align-items: center;
  justify-content: center;

  margin-top: 2px;
}
.checkbox-container {
  width: $checkboxSize;
  height: $checkboxSize;
  max-width: $checkboxSize;
  display: inline-flex;
  background-color: #ffffff;
  border: 2px solid #d0d5dd;
  border-radius: $checkboxRadius;
  align-items: center;
  justify-content: center;

  margin-top: 2px;
}

.check-button {
  width: $checkboxSize !important;
  height: $checkboxSize !important;
  min-width: $checkboxSize !important;
}

.check-style-selected-img {
  font-size: 16px;
  height: 14px;
  margin-right: 1px;
  margin-top: 2px;
  color: #7f56d9;
}
.check-style-img {
  display: none;
  font-size: 16px;
  color: rgba(0, 0, 0, 0);
}
.checkbox-text-label {
  // width: 140px;
  // height: 20px;
  // height: 16px;

  // display: flex;
  // align-items: center;

  /* Text sm/Medium */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Gray/700 */

  color: #344054;
}

.checkbox-link {
  /* Text md/Medium */
  text-decoration: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  // font-size: 16px;
  line-height: 24px;

  /* identical to box height, or 150% */

  /* Primary/700 */
  color: #6941c6;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: #6941c6;
  }
}

@media screen and (max-width: 1060px) {
  .register-container {
    height: 100%;
    // padding: 64px 0;
  }

  .main-container {
    padding: 64px 32px;
    height: 100%;
  }

  .header-text {
    font-size: 1.5rem;
  }

  .header-supporting-text {
    font-size: 12px;
  }

  .registerInput,
  .label-input {
    font-size: 12px;
  }

  .loginText {
    width: 200px;
  }

  .loginButton {
    width: 100px;
  }

  .loginText,
  .loginButtonText,
  .register-footer-email-text,
  .text {
    margin: 0;
    font-size: 12px;
  }

  .buttonText {
    font-size: 14px;
  }

  .footer-container {
    width: 100%;
    height: 96px;
  }
}

@media screen and (max-width: 1480px) and (min-width: 1060px) {
  .register-container {
    width: 50%;
  }
}

@media screen and (min-width: 1480px) {
  .register-container {
    width: 50%;
  }
}
