@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.lawp-hour-interval-container {
  display: flex;
  flex-direction: row;
  width: 50px;
}

.lawp-hour-interval-title {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 14px;
  margin-bottom: '10px';
  color: '#344054';
}

.lawp-hour-interval-textfields-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.lawp-hour-interval-textfield-container {
  display: flex;
  min-width: 50px;
}

.lawp-hour-interval-textfield-divider-line {
  display: flex;
  position: relative;
  width: 0px;
  height: 22px;
  border-left: 1px solid #344054;
  bottom: -7px;
  z-index: 1;
}

.lawp-hour-interval-textfield-divider-double-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 15px;

  p {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 24px;
    line-height: 0px;
    margin-top: 10px;
  }
}

.lawp-hour-interval-hours-divider-line {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;

  // border: 1px solid red;

  p {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 24px;
    line-height: 0px;
    margin-top: 46px;
  }
}

.lawp-hour-interval-fixed-time {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50px;
  max-width: 50px;
  border-radius: 8px;
  // background-color: #f9f5ff;
}

.lawp-hour-interval-minutes-button {
  display: flex !important;

  height: 100% !important;
  width: 50px !important;
  min-width: 50px !important;

  border: 1px solid #9e77ed !important;
  border-radius: 8px !important;

  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 0px !important;
  color: #344054 !important;

  text-transform: none !important;
  border-radius: 8px !important;

  margin-top: 0px !important;
}
