@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$checkboxSize: 16px;
$checkboxRadius: 4px;

*,
*::before,
*::after {
  box-sizing: border-box;
}

.forgot-password-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 96px 32px 48px;
  gap: 32px;

  position: relative;
  width: 100%;
  height: 960px;
}

.forgot-password-box-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 32px;

  width: 360px;
}

.forgot-password-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 24px;

  width: 360px;
}

.forgot-password-header-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;

  background: #f4ebff;

  border: 10px solid #f9f5ff;
  border-radius: 28px;
}
.forgot-password-header-text-support-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  width: 360px;
}
.forgot-password-header-text {
  width: 360px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;

  text-align: center;

  color: #101828;
}

.forgot-password-header-support {
  width: 360px;
  // height: 48px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  text-align: center;

  color: #667085;
}
.forgot-password-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  // gap: 24px;

  width: 360px;

  border-radius: 12px;
}

.forgot-password-form-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // margin-bottom: 20px;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // padding: 0px;
  // gap: 24px;

  // width: 360px;

  // border-radius: 12px;
}
.forgot-password-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;

  width: 360px;
}

.forgot-password-input-label-container {
  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  justify-content: space-between;
  padding: 0px;
  gap: 6px;

  width: 360px;
}

.forgot-password-label {
  width: 360px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #344054;
}
.forgot-password-input {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;

  width: 360px;
  height: 44px;

  /* White */

  background: #ffffff;
  /* Gray/300 */

  border: 1px solid #d0d5dd;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Gray/500 */

  color: #667085;
}

.forgot-password-input-comment {
  /* Hint text */

  width: 360px;
  height: 20px;

  /* Text sm/Normal */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Gray/500 */

  color: #667085;
}

.forgot-password-form-button-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  // margin-top: 24px;
  // gap: 16px;

  width: 360px;
  height: 44px;
}
.forgot-password-form-button {
  /* _Button base */

  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 360px;
  height: 44px;

  /* Primary/600 */

  background: #7f56d9;
  /* Primary/600 */

  border: 1px solid #7f56d9;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
.forgot-password-back-button-text-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 180px;
  height: 20px;
  cursor: pointer;
}
.forgot-password-back-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
}
.forgot-password-back-text {
  width: 152px;
  height: 20px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  margin: o;

  color: #667085;
}

.forgot-password-form-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 360px;
  height: 44px;

  /* Primary/600 */

  background: #7f56d9;
  /* Primary/600 */

  border: 1px solid #7f56d9;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.forgot-password-back-button-text-container {
  /* _Button base */

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 180px;
  height: 20px;
  cursor: pointer;
}
.forgot-password-back-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
}
.forgot-password-back-text {
  /* Text */

  width: 152px;
  height: 20px;

  margin: 0;

  /* Text sm/Medium */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Gray/500 */

  color: #667085;
}

@media screen and (max-width: 1060px) {
  .forgot-password-page-container {
    justify-content: center;
    padding: 96px 0;
  }
}
