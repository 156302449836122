@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$phoneBreakpoint: map-get($screen-width-breakpoints, $key: 'phone');
$tabletBreakpoint: map-get($screen-width-breakpoints, $key: 'tablet');

.lawp-about-us-achievements-container {
  display: flex;
  flex-direction: column;
  width: 250px;
  margin-left: 30px;
  margin-right: 30px;
  align-items: left;
  justify-content: center;
  // align-items: center;
  align-items: flex-start;

  gap: 8px;

  @media (max-width: $tabletBreakpoint) {
    margin-left: 0px;
    margin-right: 0px;
    width: 300px;

    align-items: center;
  }

  @media (max-width: $phoneBreakpoint) {
    width: 250px;
  }

  .number {
    /* Display xl/Semibold */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    line-height: 72px;

    /* identical to box height, or 120% */
    letter-spacing: -0.02em;

    /* Primary/600 */
    color: #7f56d9;
  }

  h2 {
    /* Text lg/Medium */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;

    text-align: left;

    /* identical to box height, or 156% */

    /* Gray/900 */
    color: #101828;

    @media (max-width: $tabletBreakpoint) {
      text-align: center;
    }
  }

  .description {
    /* Text md/Normal */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    // text-align: center;

    /* or 150% */

    /* Gray/500 */
    color: #667085;

    @media (max-width: $tabletBreakpoint) {
      text-align: center;
    }
  }
}
