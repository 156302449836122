@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.second-state {
  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  align-items: center;
  padding: 0px 32px 40px;
  gap: 24px;
  // margin-top: 24px;
  // width: 360px;
  // height: 192px;
  // margin-bottom: 120px;
}

.text {
  width: 360px;
  height: 76px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  margin: 0;

  text-align: center;

  color: #101828;
}
.second-state-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  padding: 0px;
  gap: 4px;
  // margin-bottom: 24px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: #101828;

  width: 296px;
  height: 24px;

  // margin-left: 32px;
}

.no-delete-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  margin-bottom: 4px;

  width: 296px;
  height: 24px;
}

.container-benefits {
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 16px;
  // padding: 0px 32px 40px 32px;

  width: 296px;
  // margin-left: 32px;
  height: 104px;
}
.row-details {
  display: flex;
  flex-direction: row;

  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  width: 296px;
  height: 24px;
}
.purple-circle {
  width: 24px;
  height: 24px;
  position: absolute;

  background: #f4ebff;
  border-radius: 12px;
}
.check-mark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 12px;
  height: 10.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  //   left: 26.46%;
  //   right: 23.56%;
  //   top: 29.65%;
  //   bottom: 26.58%;
}

.delete-lawyer-row-details {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.check-mark-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-left: 36px;
  padding: 0px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #667085;

  width: 260px;
  // height: 24px;
}
.delete-text-subtitle {
  width: 296px;
  height: 24px;

  /* Text md/Semibold */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Gray/900 */

  color: #101828;
}
@media screen and (max-width: 1230px) {
}
