.star-rating {
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 0;
  gap: 4px;
}

.star-rating-pointer {
  cursor: pointer;
}

.star-rating-default {
  cursor: default;
}
