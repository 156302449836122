@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$phoneBreakpoint: map-get($screen-width-breakpoints, $key: 'phone');
$tabletBreakpoint: map-get($screen-width-breakpoints, $key: 'tablet');

.subscription {
  box-sizing: border-box;

  width: 1362.07px;
  // height: 710.48px;

  background: #ffffff;

  border: 1.28014px solid #e4e7ec;
  box-shadow: 0px 5.12057px 10.2411px -2.56028px rgba(16, 24, 40, 0.1),
    0px 2.56028px 5.12057px -2.56028px rgba(16, 24, 40, 0.06);
  border-radius: 10.2411px;
  // border-collapse: initial;
  border-collapse: separate !important;
  border-spacing: 0;
}

.subscription td,
th {
  border-bottom: 1px solid #e4e7ec;
}

.subscription tr:nth-child {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 24px;
  gap: 12px;

  width: 162.58px;
  height: 56.33px;

  background: #f9fafb;

  flex: none;
  order: 0;
  flex-grow: 0;
}

table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background: #f9fafb;
}

.thead {
  height: 56.33px;
}

.name {
  // width: 602.95px;
  height: 92.17px;

  // display: flex;
  // flex-direction: row;

  // padding: 16px 24px;
  gap: 12px;
}

.date {
  padding: 20.4823px 30.7234px;

  width: 210px;
  height: 95px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.date-text {
  width: 210px;
  height: 26px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 17.922px;
  line-height: 26px;

  margin: 0;

  display: flex;
  align-items: center;

  color: #667085;
}

.status {
  // padding: 20.4823px 30.7234px;

  // display: flex;
  // justify-content: center;
  // align-items: center;
  position: relative;

  width: 131.85px;
  height: 92.17px;
}

.totalValue {
  padding: 16px 24px;

  width: 162.58px;
  height: 92.17px;
}

.totalValue-text {
  // width: 91px;
  height: 26px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 17.922px;
  line-height: 26px;

  margin: 0;

  text-align: right;

  color: #667085;

  display: flex;
  align-items: center;
}

.plan {
  padding: 16px 24px;

  width: 200px;
  height: 92.17px;
}

.plan-text {
  width: 200px;
  height: 26px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 17.922px;
  line-height: 26px;

  margin: 0;

  color: #667085;

  display: flex;
  align-items: center;
}

.featured-icon {
  width: 51.21px;
  height: 51.21px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #f4ebff;
  border-radius: 25.6028px;
}

.invoice-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20.4823px 30.7234px;
  gap: 15.36px;
}

.text {
  width: 230px;
  height: 26px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 17.922px;
  line-height: 26px;

  margin: 0;

  color: #101828;

  display: flex;
  align-items: center;
}

.table-download-cell {
  // display: flex;
  // flex-direction: row;
  // justify-content: center;
  // align-items: center;
  padding: 16px 24px;
  gap: 12px;

  // width: 147.22px;
  height: 92.17px;
}

.button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 86px;
  height: 26px;
}

.button-base {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 10.24px;

  background: none;
  border: none;

  width: 86px;
  height: 26px;
}

.button-text {
  width: 86px;
  height: 26px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 17.922px;
  line-height: 26px;

  margin: 0;

  color: #6941c6;
}

// .badge {

//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);

//     padding: 0px;

//     width: 70.4px;
//     height: 29.12px;

//     mix-blend-mode: multiply;
// }

.badge-base {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  align-items: center;

  padding: 2.56028px 10.2411px 2.56028px 7.68085px;
  gap: 5.12px;

  // width: 70.4px;
  height: 29.12px;

  background: #ecfdf3;
  border-radius: 20.4823px;
}

.badge-text {
  // width: 32px;
  height: 24px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 15.3617px;
  line-height: 23px;

  margin: 0;

  text-align: center;

  color: #027a48;

  display: flex;
  align-items: center;
  justify-content: center;
}

.success-image {
  width: 15.36px;
  height: 15.36px;

  display: flex;
  align-items: center;
}

.Status {
  padding: 15.3617px 30.7234px;
  gap: 15.36px;

  width: 131.85px;
  height: 56.33px;

  background: #f9fafb;
}

.Status-text {
  width: 48px;
  height: 24px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 15.3617px;
  line-height: 23px;

  margin: 0;

  color: #667085;
}

.invoice-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15.3617px 30.7234px;
  gap: 15.36px;

  /////////////////
  width: 450px;
  height: 56.33px;
  border-top-left-radius: 10.2411px;

  background: #f9fafb;
}

.Invoice {
  padding: 0px;
  gap: 5.12px;

  width: 53px;
  height: 24px;
  border-top-left-radius: 10.2411px;
}

.Invoice-text {
  width: 53px;
  height: 24px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 15.3617px;
  line-height: 23px;

  margin: 0;

  color: #667085;
}

.Datum {
  padding: 15.3617px 30.7234px;

  width: 200px;
  height: 56.33px;

  background: #f9fafb;
}

.Datum-button {
  display: flex;
  flex-direction: row;
  gap: 15.36px;
  background: none;
  border: none;
}

.Datum-text {
  width: 49px;
  height: 24px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 15.3617px;
  line-height: 23px;

  margin: 0;

  color: #667085;
}

.arrow-down {
  width: 20.48px;
  height: 20.48px;

  display: flex;
  align-items: center;
  justify-self: center;
}

.Betrag {
  padding: 15.3617px 30.7234px;
  gap: 15.36px;

  width: 162.58px;
  height: 56.33px;

  background: #f9fafb;
}

.Betrag-text {
  width: 49px;
  height: 24px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 15.3617px;
  line-height: 23px;

  margin: 0;

  text-align: right;

  color: #667085;
}

.Plan {
  padding: 15.3617px 30.7234px;
  gap: 15.36px;

  width: 148.5px;
  height: 56.33px;

  background: #f9fafb;
}

.Plan-text {
  width: 32px;
  height: 24px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 15.3617px;
  line-height: 23px;

  margin: 0;

  color: #667085;
}

.checkbox-container-selected {
  width: 25.6px;
  height: 25.6px;
  max-width: 25.6px;
  display: inline-flex;
  background-color: #f9f5ff;
  border: 1.28014px solid #7f56d9;
  border-radius: 7.68085px;
  align-items: center;
  justify-content: center;
}

.checkbox-container {
  width: 25.6px;
  height: 25.6px;
  max-width: 25.6;
  display: inline-flex;
  background-color: #ffffff;
  border: 1.28014px solid #d0d5dd;
  border-radius: 7.68085px;
  align-items: center;
  justify-content: center;
}

.checkbox-label-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;

  // width: 164px;
  height: 20px;
}

.check-style-selected-img {
  font-size: 16px;
  height: 14px;
  //   margin-right: 1px;
  //   margin-top: 2px;
  color: #7f56d9;
}

.check-style-img {
  display: none;
  font-size: 16px;
  color: rgba(0, 0, 0, 0);
}

.check-button {
  width: 25.6px;
  height: 25.6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;

  position: relative;
}

.download {
  border-top-right-radius: 10.2411px;
}

@media screen and (max-width: $tabletBreakpoint) {
  .invoice-header {
    width: fit-content;
  }
  .Betrag {
    display: none;
  }
  .totalValue {
    display: none;
  }
  .subscription {
    width: fit-content;
  }
}

@media screen and (max-width: $phoneBreakpoint) {
  .Datum {
    display: none;
  }
  .Datum-text {
    display: none;
  }
  .Status {
    display: none;
  }
  .Status-text {
    display: none;
  }
  .Plan {
    display: none;
  }
  .Plan-text {
    display: none;
  }
  .date {
    display: none;
  }
  .status {
    display: none;
  }
  .plan {
    display: none;
  }
}
