@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px 30px 30px 60px;
  gap: 20px;

  width: 100%;
  // height: 385px;

  flex: none;
  order: 2;
  flex-grow: 0;
}

.frame10 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 0px;
  gap: 20px;

  width: 100%;
  height: 159px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.frame10-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 128px;

  width: 100%;
  height: 98px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.text-and-supporting-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 280px;
  height: 60px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.text {
  width: 280px;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  margin: 0;

  color: #344054;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.supporting-text {
  width: 280px;
  height: 40px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  margin: 0;

  color: #667085;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.input-field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  // gap: 16px;
  gap: 6px;

  // width: 512px;
  height: 98px;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.email-input-base {
  display: flex;
  align-items: center;
  background-color: #ffffff;

  padding: 0px;
  gap: 6px;

  // width: 512px;
  height: 98px;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.mailIcon {
  position: relative;
  padding: 15px 0 15px 15px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.input-base {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0px;
  gap: 6px;

  width: 512px;
  height: 98px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.frame10-input-email {
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  // padding: 10px 14px;
  gap: 8px;

  border-radius: 20px;

  // width: 415px;
  width: 392px;
  height: 44px;

  background-color: #ffffff;
  border: none;
  outline: none;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.frame1031 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;

  width: 80%;
  // width: 356px;
  height: 48px;

  flex: none;
  order: 2;
  flex-grow: 0;
}

.unselectedStepIconBase {
  width: 32px;
  height: 32px;
  position: relative;

  background: #f9fafb;
  border-radius: 16px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.unSelectedIconContent {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;

  border-radius: 16px;
}

.unSelectedDot {
  position: absolute;
  width: 10px;
  height: 10px;
  left: calc(50% - 10px / 2);
  top: calc(50% - 10px / 2);
  border-radius: 50%;

  background: #e4e7ec;
}

.frame1031-text {
  // width: 424px;
  // width: 350px;
  width: 300px;
  height: auto;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0;

  color: #d0d5dd;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.Divider {
  width: 100%;
  height: 1px;

  background: #e4e7ec;

  // margin: 40px 0px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.frame11 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 0px;
  gap: 20px;

  width: 100%;
  // height: 146px;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.frame11-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 128px;

  width: 100%;
  height: 106px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.frame11-password-label {
  // width: 121px;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #344054;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.frame11-password-input {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;

  // width: 512px;
  width: 435px;
  height: 48px;

  background: #ffffff;

  border: 1px solid #d0d5dd;
  outline: none;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.frame14 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  padding: 0px;
  gap: 10px;

  // width: 512px;
  height: 20px;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.frame14-delete {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  padding: 0px;
  gap: 10px;

  width: 435px;
  height: 20px;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.check-step-icon-base {
  position: relative;
  width: 32px;
  height: 32px;

  background: #f9f5ff;
  border-radius: 16px;

  flex: none;
  order: 2;
  flex-grow: 0;
}

.checked-icon-content {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;

  border-radius: 16px;
}

.check-icon {
  position: absolute;
  left: 26.46%;
  right: 23.56%;
  top: 29.65%;
  bottom: 26.58%;
}

.error-message-and-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 512px;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.frame14-button {
  width: 512px;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.error-icon-base {
  width: 32px;
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  gap: 8px;

  background: #f9fafb;
  border-radius: 16px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.error-text {
  // width: 300px;
  height: auto;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0;

  display: flex;
  align-items: center;

  color: #d0d5dd;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.frame14-container {
  width: 435px !important;
}

.message-text-and-button {
  display: flex;
  flex-direction: row;
  // width: 512px;
  width: 412px;
}

.delete-account-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;

  border: none;
  background: none;

  width: 119px;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;

  color: #6941c6;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #101828 !important;
}

@media screen and (max-width: 1060px) {
  .frame10-content,
  .frame11-content {
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }

  .frame11,
  .Divider {
    padding: unset;
  }

  .frame11-content,
  .frame11,
  .frame10,
  .frame10-content,
  .form {
    height: unset;
  }

  .text-and-supporting-text,
  .input-field {
    width: 70%;
  }

  .frame1031-text {
    width: 100%;
    font-size: 12px;
  }

  .frame1031 {
    width: 65%;
    height: 100%;
  }

  .email-input-base,
  .input-base,
  .frame14-container,
  .frame11-password-input,
  .text,
  .supporting-text {
    width: 100% !important;
    height: unset;
  }

  .message-text-and-button {
    width: 100%;
  }

  .frame10-input-email {
    width: 70%;
  }
}
