@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$phoneBreakpoint: map-get($screen-width-breakpoints, $key: 'phone');
$tabletBreakpoint: map-get($screen-width-breakpoints, $key: 'tablet');

.container {
  width: 100%;
}

.loadingContainer {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CreateArticleButton {
  box-sizing: border-box;

  // display: inline-block;
  display: flex;

  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 15px;
  margin-bottom: 24px;

  height: 36px;
  background: #ffffff;
  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.PlusIcon {
  width: 14px;
  height: 14px;
  margin-right: 12px;
}

.CreateArticleButtonText {
  font-family: $fontFamily;
  font-style: normal;
  @include get-font-weight($key: 'Medium');
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Gray/700 */
  color: #344054;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.ArticleContainer {
  display: flex;
  height: 200px;
  margin-top: 16px;
  margin-bottom: 16px;
  gap: 24px;

  @media (max-width: $tabletBreakpoint) {
    align-items: flex-start;
    flex-direction: column;
    height: 100%;
  }

  &:hover {
    cursor: pointer;
  }
}
.ArticleImage {
  display: flex;
  height: 100%;
  // height: 200px;
  width: 320px;
  min-width: 320px;
  max-width: 320px;

  object-fit: cover;
  background-size: cover;
}

.ArticlesContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.ArticleInformation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 86%;

  justify-content: space-between;

  @media (max-width: $tabletBreakpoint) {
    gap: 20px;
  }
}

.ArticleTags {
  display: flex;
  justify-content: space-between;

  margin-top: 2px;
}

.ContainerTag {
  display: flex;
  // width: 158px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  padding: 4px 12px 4px 4px;

  background-color: #f9f5ff;
}

.ArticleTypeTag {
  background-color: white;
  border-radius: 15px;
  padding: 3px 8px 3px 8px;

  font-family: $fontFamily;
  font-style: normal;
  @include get-font-weight($key: 'Medium');
  font-size: 12px;
  line-height: 18px;
  color: $primaryColor;
  margin-right: 8px;
}

.ArticleReadTime {
  font-family: $fontFamily;
  font-style: normal;
  @include get-font-weight($key: 'Medium');
  color: $primaryColor;
  font-size: 12px;
  line-height: 18px;
}

.ArticleTitle {
  display: flex;
  // border: 1px solid #d0d5dd;
  align-items: center;
  font-family: $fontFamily;
  font-size: 18px;
  font-style: normal;
  @include get-font-weight($key: 'SemiBold');
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: -10px;
  width: 95%;

  //max 2 lines of text
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.ArticleDescription {
  display: flex;
  font-family: $fontFamily;
  font-size: 16px;
  @include get-font-weight($key: 'Regular');
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #667085;
  margin-top: -5px;
}

.ArticleAuthor {
  display: flex;
  align-items: center;
}

.ArticleAuthorImage {
  display: flex;
  flex-direction: row;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 13px;

  object-fit: cover;
}

.AuthorName {
  font-family: $fontFamily;
  font-size: 14px;
  @include get-font-weight($key: 'Medium');
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.ArticleDate {
  font-family: $fontFamily;
  font-size: 14px;
  @include get-font-weight($key: 'Regular');
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #667085;
}

.divider {
  width: 100%;
  height: 1.5px;
  background-color: #e4e7ec;
}

.NavigationContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 30px;

  font-family: $fontFamily !important;
  font-size: 14px !important;
  @include get-font-weight($key: 'Regular');
  line-height: 20px !important;
  color: #667085;
}

.PaginationText {
  margin-left: 22px;
}
.PaginationTextBlack {
  color: black;
}

.NavigationButton {
  color: black !important;
  padding: 8px 16px 8px 16px !important;
  text-transform: none !important;
  border-radius: 6px !important;
  border: 1px solid #d0d5dd !important;
  font-family: $fontFamily !important;
  font-size: 13px !important;
  line-height: 20px !important;
  @include get-font-weight($key: 'Regular');
  background-color: #fffefe !important;
  margin-left: 12px !important;
  box-shadow: 0px 1px 2px 0px #1018280d !important;
}
