@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$phoneBreakpoint: map-get($screen-width-breakpoints, $key: 'phone');
$tabletBreakpoint: map-get($screen-width-breakpoints, $key: 'tablet');

.permissionText {
  width: 100%;
  height: 50vh;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  line-height: 20px;
  font-family: $fontFamily;
  font-weight: 400 !important;
  color: gray;
}

.snackbar {
  width: 100% !important;
  background-color: #7f56d9 !important;
  font-family: $fontFamily !important;
}

.container {
  display: block;
  width: 95.5%;
  margin: auto;
  margin-bottom: 120px;
}

// .edit-lawyer-top-menu {
//     margin-top: 65px;
// }

.EditSaveChangesSection {
  margin-top: 68px;
  margin-bottom: 30px;
  padding: 30px 30px 30px 60px;
}

.EditSaveChangesSectionBottom {
  margin-top: 10px;
  margin-bottom: 30px;
}

.Divider {
  width: 100%;
  height: 1px;

  background: #e4e7ec;

  margin: 50px 0px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.update-details-divider {
  width: 100%;
  height: 1px;

  background: #e4e7ec;

  margin: 20px 0px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.tables {
  position: fixed;

  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  padding: 0px;

  width: 658px;
  height: 469px;

  z-index: 1;

  background: #ffffff;

  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1),
    0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  border-radius: 8px;

  @media (max-width: $phoneBreakpoint) {
    scale: 0.9;
    top: 50% * 0.9;
    left: 50% * 0.84;

    transform: translate(-55%, -50%);
  }
}

.date-pickers {
  display: 1px solid green;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0px;

  width: 657px;
  height: 396px;
}

.divider {
  width: 1px;
  height: 396px;

  background: #e4e7ec;
}

.horizontal-divider {
  width: 657px;
  height: 1px;

  background: #e4e7ec;

  @media (max-width: $phoneBreakpoint) {
    width: 100%;
  }
}

.bottom-panel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px 24px;
  gap: 12px;

  width: 657px;
  height: 72px;

  @media (max-width: $phoneBreakpoint) {
    // width: 50%;
    // height: 140px;
    // flex-direction: column;
  }
}

.input-fields {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 12px;

  width: 288px;
  height: 40px;
}

.start-date-input,
.end-date-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 14px;
  gap: 8px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  // width: 128px;
  width: 142px;
  height: 40px;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  // &:focus-visible {
  //   outline: 1px solid#7f56d9;
  // }
}

.separator-line {
  width: 8px;
  height: 24px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  margin: 0;

  text-align: right;

  color: #667085;
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  width: 230px;
  height: 40px;
}

.cancel-button {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 109px;
  height: 40px;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  flex: none;
  order: 0;
  flex-grow: 1;
}

.cancel-text {
  // width: 74px;
  // height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  margin: 0;

  text-align: right;

  color: #344054;
}

.send-data-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;

  width: 109px;
  height: 40px;

  background: #7f56d9;

  border: 1px solid #7f56d9;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.send-data-text {
  // width: 68px;
  // height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  margin: 0;

  text-align: right;

  color: #ffffff;
}

.unselected-calendar-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;

  width: 207px;
  height: 40px;

  // opacity: 0.4;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.calendar-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;

  // width: 166px;
  width: 176px;
  height: 40px;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #f2f4f7;
  border-radius: 8px;
}

.unselected-calendar-button-text {
  width: 147px;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  // text-align: right;

  margin: 0;

  // color: #667085;
}

.calendar-button-text {
  width: 80%;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  margin: 0;

  // text-align: right;

  color: #344054;
}

.calendar-image {
  width: 15px;
  height: 16.67px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #101828 !important;
}

//Time interval:
.button-container {
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: flex-end;
}

.button-holiday-settings {
  display: inline-block !important;
  height: 45px !important;
  left: 0px !important;
  top: 0px !important;
  border-radius: 8px !important;
  padding: 5px 15px 5px 15px !important;
  color: #344054 !important;
  font-family: $fontFamily !important;
  @include get-font-weight($key: 'Medium');
  font-size: 14px !important;
  line-height: 20px !important;
  background-color: white !important;
  text-transform: none !important;
  border-radius: 9px !important;
  border: 1px solid #d6dae1 !important;
}

.time-interval-revert-save-buttons-container {
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 50px;
}

.time-interval-revert-button {
  display: inline-block !important;
  height: 45px !important;
  left: 0px !important;
  top: 0px !important;
  border-radius: 8px !important;
  padding: 5px 15px 5px 15px !important;
  color: #344054 !important;
  font-family: $fontFamily !important;
  @include get-font-weight($key: 'Medium');
  font-size: 14px !important;
  line-height: 20px !important;
  background-color: white !important;
  text-transform: none !important;
  border-radius: 9px !important;
  border: 1px solid #d6dae1 !important;
}

.time-interval-save-button {
  display: inline-block !important;
  height: 44px !important;
  left: 0px !important;
  top: 0px !important;
  border-radius: 8px !important;
  padding: 5px 16px 5px 16px !important;
  color: white !important;
  font-family: $fontFamily !important;
  @include get-font-weight($key: 'Medium');
  font-size: 14px !important;
  line-height: 20px !important;
  margin-left: 15px !important;
  background-color: #7f56d9 !important;
  text-transform: none !important;
  border-radius: 9px !important;
}

.time-interval-content-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.time-interval-description-container {
  display: flex;
  flex-direction: column;
  width: 400px;
}

.time-interval-description-container-title-text {
  /* Text */

  width: 280px;
  // height: 20px;

  /* Text sm/Medium */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  /* identical to box height, or 143% */

  /* Gray/700 */
  color: #344054;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.time-interval-description-container-subtitle-text {
  margin-top: -15px;
  /* Supporting text */

  width: 280px;
  // height: 40px;

  /* Text sm/Normal */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  /* or 143% */

  /* Gray/500 */
  color: #667085;

  /* Inside auto layout */
}

.time-interval-intervals-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 75%;
}
//Time interval//

.infoContainer {
  display: flex;
  flex-direction: column;
  padding: 30px 0px 30px 60px;
}

.Section {
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 0px 30px 0px 0px;
  // margin-top: 40px;
  // margin-bottom: 40px;

  @media (max-width: $phoneBreakpoint) {
    flex-direction: column;
    padding: 0px;
    width: 100%;
  }
}

.SectionInformation {
  width: 26%;
  display: flex;
  min-width: 300px;
  @media (max-width: $phoneBreakpoint) {
    width: 100%;
    min-width: 0px;

    margin-bottom: 24px;
  }
}

.SectionInformationContainer {
  width: 74%;

  @media (max-width: $phoneBreakpoint) {
    width: 100%;
  }
}
.SectionContent {
  width: 74%;
}

@media (max-width: 1060px) {
  .time-interval-content-container {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .time-interval-vacation-container {
    width: 40%;
  }

  .time-interval-description-container-title-text,
  .time-interval-description-container-subtitle-text {
    width: 100%;
  }

  .time-interval-description-container,
  .time-interval-intervals-container {
    width: 70%;
  }
}
