@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$phoneBreakpoint: map-get($screen-width-breakpoints, $key: 'phone');
$tabletBreakpoint: map-get($screen-width-breakpoints, $key: 'tablet');

.boxContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1070px;
  max-height: 95%;
  background-color: white;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.4);
  padding: 24px;
  overflow-y: scroll;

  @media (max-width: $phoneBreakpoint) {
    width: auto;
  }
}

.errorMessage {
  color: red;
  font-size: 14px;
  line-height: 20px;
  font-family: $fontFamily;
  @include get-font-weight($key: 'Regular');
}

.InnerContainer {
  display: block;
  width: 100%;
}

.titleContainer {
  display: block;
  margin-top: 5px;
}

.titleText {
  font-size: 17px;
  line-height: 24px;
  font-family: $fontFamily;
  @include get-font-weight($key: 'Medium');
}

.contentContainer {
  display: block;
}

.contentText {
  font-size: 13px;
  line-height: 20px;
  font-family: $fontFamily;
  @include get-font-weight($key: 'Regular');
  color: gray;
}

.Divider {
  width: 100%;
  height: 1.5px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #e4e7ec;
}

.SectionRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  //   align-items: center;
  width: 100%;

  @media (max-width: $phoneBreakpoint) {
    flex-direction: column;
    gap: 24px;
  }
}

.SectionRowInformation {
  width: 28%;

  @media (max-width: $phoneBreakpoint) {
    width: 100%;
  }
}

.SectionRowContent {
  width: 72%;
  margin-left: 10px;

  @media (max-width: $phoneBreakpoint) {
    width: 100%;
    margin-left: 0px;
  }
}

.ImageContainer {
  display: flex;
  width: 256px;
}

.ImageContainerList {
  margin-top: 10px;
}

.Image {
  aspect-ratio: 1.5/1;
  width: 120px;
  object-fit: cover;

  margin-right: 26px;
}

.Buttons {
  height: 100%;
  margin-right: 16px;
  font-size: 13px;
  line-height: 16px;
  font-family: $fontFamily;
  @include get-font-weight($key: 'Medium');

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.ButtonsTextUpdate {
  color: $primaryColor;
}

.ButtonsTextDelete {
  color: #667085;
}

.TitleContainer {
  width: 512px;
  margin-bottom: 10px;
}

.DescriptionContainer {
  width: 512px;
  margin-bottom: 20px;
}

.PLACE_HOLDER_TEXT_EDITOR {
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;

  width: 66%;

  margin-top: 20px;
}

.AreasOfLawContainer {
  width: 512px;
  margin-top: 7px;
}

.ImageDropzone {
  width: 100%;
}

.BottomButtonsContainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}

.revertButton {
  display: inline-block !important;
  height: 40px !important;
  left: 0px !important;
  top: 0px !important;
  border-radius: 8px !important;
  padding: 5px 15px 5px 15px !important;
  color: #344054 !important;
  font-family: $fontFamily !important;
  @include get-font-weight($key: 'Medium');
  font-size: 13px !important;
  line-height: 20px !important;
  background-color: white !important;
  text-transform: none !important;
  border-radius: 9px !important;
  border: 1px solid #d6dae1 !important;
}

.saveButton {
  display: inline-block !important;
  height: 40px !important;
  left: 0px !important;
  top: 0px !important;
  border-radius: 8px !important;
  padding: 5px 15px 5px 15px !important;
  color: white !important;
  font-family: $fontFamily !important;
  @include get-font-weight($key: 'Medium');
  font-size: 13px !important;
  line-height: 20px !important;
  margin-left: 12px !important;
  background-color: #7f56d9 !important;
  text-transform: none !important;
  border-radius: 9px !important;
}
