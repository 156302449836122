@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.lawp-vacation-button-container {
  display: flex;
  width: 100%;
  height: 50px;

  align-items: center;
  justify-content: flex-start;
}

.lawp-vacation-button-holiday-settings {
  display: inline-block !important;
  height: 45px !important;
  left: 0px !important;
  top: 0px !important;
  border-radius: 8px !important;
  padding: 5px 15px 5px 15px !important;
  color: #344054 !important;
  font-family: $fontFamily !important;
  @include get-font-weight($key: 'Medium');
  font-size: 14px !important;
  line-height: 20px !important;
  background-color: white !important;
  text-transform: none !important;
  border-radius: 9px !important;
  border: 1px solid #d6dae1 !important;
}

.lawp-vacation-modal {
  padding: 0px;

  display: block !important;
  max-height: 100%;

  background: rgba(52, 64, 84, 0.6);

  backdrop-filter: blur(16px);

  overflow-y: auto;
}

.lawp-vacation-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lawp-vacation-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  // gap: 32px;
  gap: 52px;

  width: 400px;
  height: auto;

  background: #ffffff;

  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1),
    0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  border-radius: 12px;
}

.lawp-vacation-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;

  width: 352px;
  height: auto;
}

.lawp-vacation-featured-icon {
  position: relative;
  width: 48px;
  height: 48px;

  background: #f4ebff;

  border: 8px solid #f9f5ff;
  border-radius: 28px;
}

.lawp-vacation-calendar-icon {
  position: absolute;
  width: 18px;
  height: 18px;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lawp-vacation-text-and-supporting-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;

  width: 352px;
  // height: 56px;
}

.lawp-vacation-text {
  width: 352px;
  // height: 28px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  // line-height: 28px;

  margin: 0;

  color: #101828;
}

.lawp-vacation-supporting-text {
  width: 352px;
  // height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  // line-height: 20px;

  margin: 0;

  color: #667085;
}

.lawp-vacation-checkbox {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  width: 344px;
  // height: 74px;
}

.lawp-vacation-checkbox-text-and-supporting-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 2px;

  width: 344px;
  // height: 74px;
}

.lawp-vacation-checkbox-text {
  width: 344px;
  height: 24px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  margin: 0;

  color: #344054;
}

.lawp-vacation-checkbox-supporting-text {
  width: 344px;
  height: 48px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  margin: 0;

  color: #667085;
}

.lawp-vacation-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 12px;

  width: 352px;
  height: 44px;
}

.lawp-vacation-buttons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  position: relative;
  float: right;

  width: 247px;
  height: 44px;
}

.lawp-vacation-cancel-button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 121px;
  height: 44px;

  border-radius: 8px;
}

.lawp-vacation-cancel-button-base {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 121px;
  height: 44px;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.lawp-vacation-cancel-text {
  // width: 85px;
  // height: 24px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  margin: 0;

  text-align: right;

  color: #344054;
}

.lawp-vacation-apply-button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  // width: 114px;
  // height: 44px;

  border-radius: 8px;
}

.lawp-vacation-apply-button-base {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 114px;
  height: 44px;

  background: #7f56d9;

  border: 1px solid #7f56d9;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.lawp-vacation-apply-text {
  // width: 78px;
  // height: 24px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  text-align: right;

  margin: 0;

  color: #ffffff;
}

.lawp-vacation-frame-1033 {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 10px;

  width: 352px;
  max-height: 200px;

  overflow-y: scroll;

  .lawp-unselected-calendar-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    gap: 8px;

    width: 207px;
    height: 40px;

    // opacity: 0.4;

    background: #ffffff;

    border: 1px solid #d0d5dd;

    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
  }

  .lawp-unselected-calendar-button-text {
    width: 147px;
    height: 20px;

    font-family: $fontFamily;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    // text-align: right;

    margin: 0;

    // color: #667085;
    color: #101828;
  }

  .lawp-calendar-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    gap: 8px;

    // width: 166px;
    width: 176px;
    height: 40px;

    background: #ffffff;

    border: 1px solid #d0d5dd;

    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #f2f4f7;
    border-radius: 8px;
  }

  .lawp-calendar-button-text {
    width: 80%;
    height: 20px;

    font-family: $fontFamily;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    margin: 0;

    // text-align: right;

    color: #344054;
  }
}

.lawp-vacation-frame-1034 {
  position: relative;
  width: 352px;
  height: 36px;
}

.lawp-vacation-radio-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  position: absolute;
  width: 257px;
  height: 36px;
}

.lawp-vacation-add-vacation-button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 257px;
  height: 36px;

  border-radius: 8px;
}

.lawp-vacation-add-vacation-button-base {
  display: flex;
  flex-direction: row;
  // justify-content: center;
  align-items: center;
  padding: 8px 14px;
  gap: 8px;

  border: none;
  background: none;

  width: 257px;
  height: 36px;

  border-radius: 8px;
}

.lawp-vacation-add-vacation-button-text {
  // width: 201px;
  // height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  margin: 0;

  color: #101842;
}

@media (max-width: 1060px) {
  .lawp-vacation-button-holiday-settings {
    width: 100%;
  }
}
