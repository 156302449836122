@import 'Assets/theme.scss';

$checkboxSize: 16px;
$checkboxRadius: 4px;

.container {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  margin-top: 24px;

  // width: 25rem;
  // height: 1.25rem;

  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}

.rowContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
 
  gap: 4px;
}

.checkbox-container-selected {
  width: $checkboxSize;
  height: $checkboxSize;
  max-width: $checkboxSize;
  display: inline-flex;
  background-color: #f9f5ff;
  border: 2px solid #7f56d9;
  border-radius: $checkboxRadius;
  align-items: center;
  justify-content: center;

  margin-top: 2px;
}

.checkbox-container {
  width: $checkboxSize;
  height: $checkboxSize;
  max-width: $checkboxSize;
  display: inline-flex;
  background-color: #ffffff;
  border: 2px solid #d0d5dd;
  border-radius: $checkboxRadius;
  align-items: center;
  justify-content: center;

  margin-top: 2px;
}

.check-button {
  width: $checkboxSize !important;
  height: $checkboxSize !important;
  min-width: $checkboxSize !important;
}

.check-style-selected-img {
  font-size: 16px;
  height: 14px;
  margin-right: 1px;
  margin-top: 2px;
  color: #7f56d9;
}
.check-style-img {
  display: none;
  font-size: 16px;
  color: rgba(0, 0, 0, 0);
}

.checkbox-text-label {
  // width: 140px;
  // height: 20px;
  // height: 16px;

  // display: flex;
  // align-items: center;

  /* Text sm/Medium */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Gray/700 */

  color: #344054;
}

.under-checkbox-container {
  margin-top: 12px;
  margin-bottom: -10px;
}

.under-checkbox-label {
  /* Text sm/Medium */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Gray/700 */

  color: #9d9d9d;
}

.checkbox-link {
  /* Text md/Medium */
  text-decoration: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  // font-size: 16px;
  line-height: 24px;

  /* identical to box height, or 150% */

  /* Primary/700 */
  color: #6941c6;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: #6941c6;
  }
}
