@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$phoneBreakpoint: map-get($screen-width-breakpoints, $key: 'phone');
$tabletBreakpoint: map-get($screen-width-breakpoints, $key: 'tablet');

.ql-container.ql-snow {
  width: 0;
  height: 0;
}

.descriptionLawyerOuterContainer {
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  // padding: 40px;
  padding: 5px 40px;

  // margin-top: 40px;
  // margin-bottom: 40px;

  @media (max-width: $tabletBreakpoint) {
    margin-top: 24px;
    padding: 5px 40px;
  }
}

.descriptionLawyerInnerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;

  width: 100%;
  // height: 371px;
}

.descriptionLawyerContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-top: 24px;
  padding: 0 32px;
}

.descriptionLawyerTitle {
  // width: 622px;

  font-weight: 500;
  font-family: $fontFamily;
  font-size: 16px;
  line-height: 24px;
  color: #101828;
  margin-top: 10px;
}

.descriptionLawyerText {
  font-family: $fontFamily;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
  margin-top: 10px;
  // width: 80%;

  a {
    color: #6941c6;

    text-decoration: none;

    // font-size: 18px;
  }

  @media (max-width: $tabletBreakpoint) {
    width: 100%;
  }

  text-align: justify;
}

.moreButton {
  color: #6941c6;
  border: 0;
  background-color: #fff;

  padding: 0px;

  width: 35px;

  font-size: 14px;
  font-family: $fontFamily;
  font-style: normal;
  text-transform: none;
}

.descriptionLawyerImageContainer {
  width: 350px;
  height: 350px;
  // height: 371px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.descriptionLawyerImage {
  width: 350px;
  height: 350px;
  // height: 371px;
  border-radius: 50%;
  object-fit: cover;
}

@media screen and (max-width: $phoneBreakpoint) {
  .descriptionLawyerContainer {
    width: 100%;
  }

  .descriptionLawyerContent {
    width: 100%;
    height: 100%;
  }

  .descriptionLawyerTitle {
    width: 100%;
  }

  .descriptionDefaultImage {
    width: unset;
  }

  .descriptionLawyerImageContainer {
    width: 100%;
    height: 100%;
    // position: relative;
    // left: 0px;
    // top: 25px;
  }
}

@media (max-width: $tabletBreakpoint) {
  .descriptionLawyerInnerContainer {
    width: 100%;
    height: 100%;
    // padding: 40px 120px;
    flex-direction: column-reverse;
    align-items: center;
  }
}
