@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$phoneBreakpoint: map-get($screen-width-breakpoints, $key: 'phone');
$tabletBreakpoint: map-get($screen-width-breakpoints, $key: 'tablet');

.verificationCodeSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  width: 50%;
  height: 960px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
}

.errorMessage {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  font-family: $fontFamily;
  margin-bottom: 10px;
}

.verificationCodeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 32px;
  gap: 80px;

  width: 100%;
  // height: 508px;
  margin-bottom: 160px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  @media (max-width: $phoneBreakpoint) {
    margin-bottom: 0px;
  }
}

.frame2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 80px;

  width: 360px;
  height: 344px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 32px;

  width: 360px;
  height: 344px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 24px;

  width: 360px;
  // height: 216px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.featuredIcon {
  width: 56px;
  height: 56px;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  background: #f4ebff;

  border: 10px solid #f9f5ff;
  border-radius: 28px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.mail {
  position: absolute;
  width: 28px;
  height: 28px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.textAndSupportingText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  width: 360px;
  // height: 136px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.text {
  width: 360px;
  height: 76px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  margin: 0;

  text-align: center;

  color: #101828;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.supportingText {
  width: 360px;
  // height: 48px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0;

  text-align: center;

  color: #667085;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.verificationCodeInputField {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 6px;

  width: 580px;
  height: 80px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.inputWithLabel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 6px;

  width: 580px;
  height: 80px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.inputContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px;
  gap: 12px;

  width: 580px;
  height: 80px;
}

.megaInputFieldBase {
  display: flex;
  // flex-direction: column;
  // align-items: flex-start;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 10px;

  width: 80px;
  height: 80px;

  border-radius: 8px;
}

.verifyInput {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 8px;

  width: 80px;
  height: 80px;

  background: #ffffff;

  border: 1px solid #d6bbfb;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 60px;

  text-align: center;
  letter-spacing: -0.02em;

  color: #7f56d9;

  &:focus-visible {
    outline: 1px solid #7f56d9;
  }
}

.lineBetween {
  width: 28px;
  height: 72px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 60px;
  line-height: 72px;

  margin: 0;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;

  color: #d0d5dd;
}

.verifyCodeButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 24px;

  width: 360px;
  // height: 44px;

  border-radius: 12px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.verifyCodeButton {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 360px;
  height: 44px;

  border-radius: 8px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.verifyCodeButtonBase {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 360px;
  height: 44px;

  background: #7f56d9;

  border: 1px solid #7f56d9;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  flex: none;
  order: 0;
  flex-grow: 1;
}

.verifyCodeButtonText {
  width: 192px;
  height: 24px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 0;

  color: #ffffff;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.resendCodeButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 360px;
  height: 20px;

  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}

.resendText {
  // width: 209px;
  // height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #667085;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.resendCodeButton {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  // width: 185px;
  height: 20px;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.resendCodeButtonBase {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;

  // width: 185px;
  height: 20px;

  border: 0;
  background: none;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.resendCodeButtonText {
  // width: 185px;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;

  color: #6941c6;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.resendCodeButtonTextDisabled {
  color: gray;
}

.loginButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 180px;
  height: 20px;

  flex: none;
  order: 2;
  flex-grow: 0;
}

.loginButtonBase {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;

  border: 0;
  background: none;

  width: 180px;
  height: 20px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.loginButtonText {
  width: 152px;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;

  color: #667085;

  flex: none;
  order: 1;
  flex-grow: 0;
}

@media screen and (max-width: 1060px) {
  .verificationCodeSection {
    width: unset;
    height: unset;
    padding-bottom: 600px;
  }

  .verificationCodeContainer {
    // align-items: unset;

    width: unset;
    // height: unset;
  }

  .text {
    font-size: 1.5rem;
  }

  .supportingText,
  .resendCodeButtonText,
  .resendText,
  .loginButtonText {
    font-size: 12px;
  }

  .verifyCodeButtonText {
    font-size: 14px;
  }

  .inputWithLabel {
    width: 290px;
    height: 80px;
  }

  .megaInputFieldBase {
    gap: 10px;

    width: 60px;
    height: 60px;
  }

  .inputContent {
    width: 550px;

    // gap: 30px;
  }

  .verifyInput {
    width: 60px;
    height: 60px;

    font-size: 32px;
  }
}

@media screen and (max-width: 1480px) and (min-width: 1060px) {
  .emailCheckerSection {
    width: unset;
  }

  .emailCheckerContainer {
    width: 100%;
  }

  .verificationCodeInputField {
    width: 450px;
    height: 60px;
  }

  // .verificationCodeInputField,
  .inputWithLabel,
  .megaInputFieldBase,
  .inputContent {
    width: 100%;
    height: 60px;
  }

  .verifyInput {
    width: 60px;
    height: 60px;

    font-size: 32px;
  }
}
