@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$phoneBreakpoint: map-get($screen-width-breakpoints, $key: 'phone');
$tabletBreakpoint: map-get($screen-width-breakpoints, $key: 'tablet');

.lawp-article-blog-page-pagination {
  .MuiButtonBase-root {
    font-family: $fontFamily !important;
    color: #667085 !important;
    background-color: white !important;
  }

  .Mui-selected {
    font-family: $fontFamily !important;
    color: #7f56d9 !important;
    background-color: #f9f5ff !important;
  }

  .MuiPaginationItem-icon {
    display: none !important;
  }

  .MuiPagination-ul {
    li:nth-last-child(1),
    li:nth-last-child(2) {
      display: none !important;
    }
  }
}

.lawp-article-blog-page-pagination-container {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: row !important;
}

.lawp-article-blog-page-articles-blog-container {
  display: block;
  width: 90%;
  margin: auto;
}

.lawp-article-blog-page-subheader {
  font-family: $fontFamily;
  color: #7f56d9;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}

.lawp-article-blog-page-header {
  //styleName: Display lg/Semibold;
  font-family: $fontFamily;
  color: #42307d;
  font-size: 48px;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: -0.02em;
  text-align: center;
}

.lawp-article-blog-page-description {
  font-family: $fontFamily;
  color: #667085;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
}

.lawp-article-blog-page-header-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: auto;
  margin-top: 96px;
}

.lawp-article-blog-page-search-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 50px;

  @media (max-width: $phoneBreakpoint) {
    gap: 16px;
  }
}

.lawp-article-blog-page-search-inputs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 536px;

  @media (max-width: $phoneBreakpoint) {
    gap: 20px;
    margin-right: 10px;
    flex-direction: column;
    width: 300px;
    align-items: flex-start;
  }
}

.lawp-article-blog-page-aol-container {
  width: 100%;
  margin-top: -5px;
}

.lawp-article-blog-page-search-textfield {
  margin-right: 4px !important;

  .MuiOutlinedInput-root {
    font-family: $fontFamily;
    color: #101828;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

    height: 43px;
    width: 320px;

    @media (max-width: $phoneBreakpoint) {
      width: 300px;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: #d0d5dd !important;
    border-radius: 8px !important;
  }

  .MuiInputAdornment-root {
    margin-bottom: 5px;
  }

  .MuiOutlinedInput-input {
    margin-bottom: 5px;
  }

  legend {
    float: left;
  }
}

.lawp-article-blog-page-search-dropdown {
  font-family: 'Inter' !important;
  font-size: 100px !important;

  @media (max-width: $phoneBreakpoint) {
    width: 300px;
  }

  .MuiOutlinedInput-root {
    padding-left: 10px !important;
    padding-right: 100px !important;
  }
  .MuiSelect-select {
    font-family: $fontFamily;
    color: #101828;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

    height: 28px !important;

    padding-top: 9px;
    padding-bottom: 9px;
  }
}

// //Global styles !!!!
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  font-family: 'Inter' !important;
}

.lawp-article-blog-page-search-button {
  display: inline-block !important;
  height: 50px !important;
  min-width: 88px !important;
  color: white !important;
  font-family: $fontFamily !important;
  @include get-font-weight($key: 'Medium');
  font-size: 16px !important;
  margin-left: 5px !important;
  background-color: #7f56d9 !important;
  text-transform: none !important;
  border-radius: 9px !important;

  bottom: 3px !important;

  @media (max-width: $phoneBreakpoint) {
    min-width: 50px !important;
    height: 111px !important;
  }
}

.lawp-article-blog-page-article-pagination-divider {
  width: 100%;
  height: 1.5px;
  background-color: #e4e7ec;
  margin-top: 35px;
  margin-bottom: 20px;
}

.lawp-article-blog-page-articles-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.lawp-article-blog-page-articles-pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 20px;
}

.lawp-article-blog-page-no-article-text {
  // margin-top: 50px;
  margin-bottom: 50px;
  font-family: $fontFamily !important;
  @include get-font-weight($key: 'Medium');
  font-size: 20px !important;
  color: #344054 !important;
  text-align: center;
}

.lawp-article-blog-page-article-spacing {
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 25px;
  margin-top: 25px;
}

.lawp-article-blog-page-left-arrow-button {
  display: flex;
  justify-content: flex-start !important;
  width: 150px;
  height: 64px;
  cursor: pointer;

  font-family: $fontFamily !important;
  @include get-font-weight($key: 'Medium');
  font-size: 14px !important;
  color: #667085 !important;

  &:hover {
    background-color: transparent !important;
  }
}

.lawp-article-blog-page-right-arrow-button {
  display: flex;
  justify-content: flex-end !important;
  width: 150px;
  height: 64px;
  cursor: pointer;

  font-family: $fontFamily !important;
  @include get-font-weight($key: 'Medium');
  font-size: 14px !important;
  color: #667085 !important;

  &:hover {
    background-color: transparent !important;
  }
}
