.faqsFooterTitle {
  width: 100%;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;

  color: #101828;
}

.faqsFooterText {
  // width: 100%;
  // width: 450px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #667085;
}

.faqCardFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 32px 32px 32px 0px;
  gap: 32px;

  width: 100%;

  margin-left: 22px;

  background: #ffffff;
  border-radius: 16px;

  @media (max-width: 1480px) {
    gap: 16px;
  }
}

.componentTextCardFooter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 768px;
}

.appointmentButton {
  // width: 189px;
  height: 44px;

  background: #7f56d9;
  padding: 10px 18px;
  border: 1px solid #7f56d9;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #fff;
  font-size: 16px;
  font-family: 'Inter';
  font-style: normal;
  text-transform: none;

  @media (max-width: 1480px) {
    // width: 220px;
    width: 260px;
  }
}

.requestAppointmentButton {
  // width: 189px;
  height: 44px;

  background-color: #ffffff;
  padding: 10px 18px;
  border: 1px solid #7f56d9;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #7f56d9;
  font-size: 16px;
  font-family: 'Inter';
  font-style: normal;
  text-transform: none;

  @media (max-width: 1480px) {
    // width: 220px;
    width: 260px;
  }
}

@media only screen and (max-width: 1060px) {
  .faqCardFooter {
    width: 100%;
    margin-left: 0;
  }

  .componentTextCardFooter {
    width: 70%;
  }
}
