@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.lawp-complete-meeting-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 32px;

  width: 100%;
  // width: 976px;
  height: 364px;
}

.lawp-complete-meeting-box-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 20px;

  width: 360px;
  height: 236px;
}

.lawp-complete-meeting-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 20px;

  width: 360px;
  height: 236px;
}

.lawp-complete-meeting-complete-header-icon {
  width: 56px;
  height: 56px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: #d1fadf;

  border: 10px solid #ecfdf3;
  border-radius: 28px;
}

.lawp-complete-meeting-header-text-support-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  width: 360px;
  height: 160px;
}

.lawp-complete-meeting-header-text {
  width: 360px;
  height: 76px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;

  text-align: center;

  color: #101828;
}

.lawp-complete-meeting-header-support {
  width: 360px;
  height: 72px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  text-align: center;

  color: #667085;
}

.lawp-complete-meeting-form-button-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 360px;
  height: 44px;

  border-radius: 8px;
}

.lawp-complete-meeting-form-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 360px;
  height: 44px;

  background: #7f56d9;

  border: 1px solid #7f56d9;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #ffffff;
}

.lawp-complete-meeting-back-button-text-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 170px;
  height: 20px;
}

.lawp-complete-meeting-back-button {
  width: 20px;
  height: 20px;

  display: flex;
  align-items: center;

  margin-right: 8px;
}

.lawp-complete-meeting-back-text {
  width: 142px;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #667085;
}
