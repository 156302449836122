@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.suggest-new-appointment-modal-actions-cancel-event-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;

  // width: 179px;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #6941c6;

  border: none;
  background: none;
}

.suggest-new-appointment-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  width: 100%;
  height: 100%;

  background: rgba(52, 64, 84, 0.6);

  backdrop-filter: blur(8px);
}

.suggest-new-appointment-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 32px;

  width: 679px;

  background: #ffffff;

  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1),
    0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  border-radius: 12px;

  height: auto;

  overflow-y: auto;
}

.suggest-new-appointment-modal-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;

  width: 100%;
}

.suggest-new-appointment-modal-supporting-text {
  width: 100%;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #667085;
}

.suggest-new-appointment-modal-heading-and-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 12px;

  width: 100%;
}

.suggest-new-appointment-modal-check-icon {
  width: 64px;
  height: 64px;

  // background: #0601fc;
  border-radius: 10px;
}

.suggest-new-appointment-modal-text-and-supporting-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 90%;
}

.suggest-new-appointment-modal-area-of-law {
  width: 100%;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  color: #6941c6;
}

.suggest-new-appointment-modal-text-name {
  width: 100%;
  height: 24px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #101828;
}

.suggest-new-appointment-modal-meeting-type {
  width: 100%;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #667085;
}

.suggest-new-appointment-modal-textarea-input-field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  width: 100%;
}

.suggest-new-appointment-modal-meeting-type-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;

  width: 100%;
}

.suggest-new-appointment-modal-meeting-type-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 100%;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #344054;
}

.suggest-new-appointment-modal-radio-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  width: 100%;
}

.suggest-new-appointment-modal-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;

  gap: 16px;

  width: 100%;
}

.suggest-new-appointment-modal-actions-divider {
  width: 100%;
  height: 1px;

  background: #e4e7ec;
}

.suggest-new-appointment-modal-actions-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;

  width: 100%;
}

.suggest-new-appointment-modal-cancel-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 170px;
  height: 44px;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #344054;
}

.suggest-new-appointment-modal-success-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 170px;
  height: 44px;

  background: #7f56d9;

  border: 1px solid #7f56d9;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #ffffff;
}

.suggest-new-appointment-modal-unselected-calendar-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;

  width: 207px;
  height: 40px;

  opacity: 0.4;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.suggest-new-appointment-modal-unselected-calendar-button-text {
  width: 147px;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  margin: 0;

  color: #667085;
}

.suggest-new-appointment-modal-selected-calendar-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;

  width: 226px;
  height: 40px;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #f2f4f7;
  border-radius: 8px;
}

.suggest-new-appointment-modal-date-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  gap: 10px;
}

.suggest-new-appointment-modal-date-error-message {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ff0000;
}

.suggest-new-appointment-modal-selected-calendar-button-text {
  // width: 80%;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  margin: 0;

  color: #344054;
}

@media screen and (max-width: 1060px) {
  .suggest-new-appointment-modal-container {
    max-width: 90%;
  }

  .suggest-new-appointment-modal-actions-cancel-event-button {
    font-size: 12px;
    justify-content: flex-end;
  }
}
