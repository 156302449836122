$theme-colors: (
  "primaryColor": #6941c6,
  "secondaryColor": #ff9800,
  "primaryTextColor": #ffffff,
  "secondaryTextColor": #ffffff,
);

$theme-font: (
  "primaryFontFamily": "Inter",
);

$primary-font-weights: (
  "Thin": 100,
  "ExtraLight": 200,
  "Light": 300,
  "Regular": 400,
  "Medium": 500,
  "SemiBold": 600,
  "Bold": 700,
  "ExtraBold": 800,
  "Black": 900,
);

$screen-widths: (
  "phone": 640px,
  "tablet": 1024px,
  "desktop": 1440px,
);

$screen-width-breakpoints: (
  "phone": 1060px,
  "tablet": 1480px,
);

@mixin get-color($key: "primaryColor") {
  color: map-get($theme-colors, $key) !important;
}

@mixin get-font-weight($key: "Regular") {
  font-weight: map-get($primary-font-weights, $key) !important;
}
