@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$phoneBreakpoint: map-get($screen-width-breakpoints, $key: 'phone');
$tabletBreakpoint: map-get($screen-width-breakpoints, $key: 'tablet');

.lawp-filters-container-not-displaying {
  display: none;
}

.MuiTooltip-tooltip {
  border-radius: 5px !important;
  // border: 1px solid darkgray !important;
  box-shadow: 0 2px 8px -3px rgb(0 0 0 / 25%) !important;
}

.lawp-filters-container {
  display: inline-block;

  .divider {
    width: 100%;
    border: 1px solid grey !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .checkboxesTitle {
    color: #344054 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    font-family: $fontFamily !important;
    @include get-font-weight($key: 'Medium');
    width: 230px;
  }

  .drawer-menu-top {
    display: flex;

    height: 35px;

    // display: none;
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;

    @media (max-width: $tabletBreakpoint) {
      display: flex;
    }

    .MuiSvgIcon-root {
      width: 35px !important;
      height: 35px !important;
      color: #7f56d9 !important;
      margin-bottom: -15px;
      cursor: pointer;
    }
  }

  .mobile-close-button {
    display: none;

    @media (max-width: $tabletBreakpoint) {
      display: flex;

      justify-content: center;
      align-items: flex-start;

      width: 35px;
      height: 35px;
    }
  }

  .drawer-menu-bottom {
    display: flex;
    height: 35px;

    width: 100%;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 10px;

    @media (max-width: $tabletBreakpoint) {
      display: none;
    }

    .MuiSvgIcon-root {
      width: 35px !important;
      height: 35px !important;
      color: #7f56d9 !important;
      margin-bottom: -15px;
      cursor: pointer;
    }
  }
}
