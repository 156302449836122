@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.container {
  width: 100%;
}

.titleContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-family: $fontFamily;
  @include get-font-weight($key: 'Medium');
  font-size: 14px;
  line-height: 20px;
}

.Switches {
  display: block;
}

.Switch {
  display: flex;
  height: 100%;
  width: 50px;
  margin-top: 7px;
}

.SwitchRow {
  display: flex;
  gap: 10px;
}

.SwitchTitleContainer {
  display: block;
  margin-top: 7px;
}

.SwitchTitleText {
  font-size: 14px;
  line-height: 20px;
  font-family: $fontFamily;
  @include get-font-weight($key: 'Medium');
}

.SwitchDescriptionText {
  font-size: 14px;
  line-height: 20px;
  font-family: $fontFamily;
  @include get-font-weight($key: 'Regular');
  color: gray;
  margin-top: -10px;
  margin-bottom: 0px;
}

.CheckBoxes {
  display: block;
  margin-top: 15px;
}

.CheckBox {
  display: flex;
  height: 100%;
  // width: 35px;
  margin-top: 3px;
}
