@import "Assets/theme.scss";

$fontFamily: map-get($theme-font, $key: "primaryFontFamily");

.calendar {
  width: 900px;
  height: 600px;
  display: flex;
  flex-direction: column;
}

.calendar-header {
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
}

.calendar-body {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.table-header {
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.weekday {
  width: 100px;
  text-align: center;
}

.table-content {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  box-sizing: border-box;
}

.calendar-day {
  width: 125px;
  height: 75px;
  position: relative;
  border: 1px solid #a6a6a6;
}

.calendar-day:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.calendar-day p {
  position: absolute;
  right: 10px;
  color: #a6a6a6;
}

.current p {
  color: #000000;
}

.selected p {
  color: #cc0000;
  font-weight: bold;
}
