@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$phoneBreakpoint: map-get($screen-width-breakpoints, $key: 'phone');
$tabletBreakpoint: map-get($screen-width-breakpoints, $key: 'tablet');

*,
*::before,
*::after {
  box-sizing: border-box;
}

.RightSection {
  // max-width: 100%;
  width: 50%;
  // height: 960px;

  position: relative;

  display: flex;
  justify-content: center;

  background: url('../../pictures//Image_register_client.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // padding: 0px 120px;
  gap: 32px;

  position: relative;
  height: 674.08px;
  left: 0px;
  right: 0px;
  top: calc(50% - 674.08px / 2 - 64px);

  @media (max-width: $phoneBreakpoint) {
    padding: 0;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 48px;

  width: 100%;
  height: 674.08px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.registerLawyerLogo {
  width: 86px;
  height: 86.08px;

  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 2;
}

.textAndSupportingText {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 24px;

  width: 100%;
  // height: 444px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  z-index: 2;
}

.text {
  width: 672px;
  // height: 330px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 72px;
  line-height: 90px;
  margin: 0;

  letter-spacing: -0.02em;

  color: #ffffff;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.supportingText {
  width: 100%;
  // height: 60px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin: 0;

  color: #e9d7fe;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.lawyersContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;

  width: 100%;
  // height: 48px;

  flex: none;
  order: 2;
  flex-grow: 0;

  z-index: 2;
}

.avatarGroup {
  display: flex;
}

.ratingContainer {
  display: flex;
  width: 397px;
  height: 48px;
  margin-left: 10px;
  flex-direction: column;
  justify-content: center;
}

.starsContainer {
  display: flex;
  align-items: center;
}

.ratingText {
  margin-left: 10px;
  font-family: $fontFamily;
  @include get-font-weight($key: 'SemiBold');
  color: black;
  font-size: 1.2em;
}

.reviewsContainer {
  display: block;
}

.reviewsText {
  width: 100%;
  height: auto;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #e9d7fe;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.backgroundOverlay {
  position: absolute;
  left: 0px;
  right: 0px;
  // top: 0px;
  // bottom: 0px;

  max-width: 100%;
  // height: 92%;
  height: 100%;

  background: linear-gradient(45deg, #42307d 0%, #7f56d9 100%);
  opacity: 0.8;
}

//--------------------

.ComponentContainer {
  background: linear-gradient(45deg, #42307d 0%, #7f56d9 100%);
  display: block;
  width: 50%;
  // height: 100%;
  height: 1028px;
  position: relative;
}

.carouselContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 32px;
  //   gap: 64px;

  // height: 538px;
  // position: absolute;
  // left: 0px;
  // right: 0px;
  // top: calc(50% - 538px / 2);
}

.mobile-stepper {
  background-color: white;
  z-index: 3;
}

.carousel {
  width: 50%;
  height: 100%;
  position: relative;
}

.carousel > ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.slide {
  position: absolute;
  inset: 0;
  opacity: 0;
}

.slide > img {
  display: block;
  width: 500px;
  height: 500px;
}

.slide[data-active] {
  opacity: 1;
}

.carousel-button-prev {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 12px;

  width: 36px;
  height: 36px;

  border-radius: 18px;
  background-color: transparent;
  z-index: 3;
  color: white;
  font-size: 4rem;
}
.carousel-button-next {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 12px;

  width: 36px;
  height: 36px;
  border-radius: 18px;

  background-color: transparent;
  z-index: 3;
  color: white;
  font-size: 4rem;
}

.mockup {
  width: 432px;
  height: 328px;
  position: relative;
  background-color: transparent;
}
.mockup-show-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  position: absolute;
  width: 432px;
  height: 260px;
  left: 0px;
  top: 0px;

  /* White */

  background: #ffffff;
  /* Shadow/2xl */

  box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.25);
  border-radius: 12px;
}

.mockup-back-card {
  /* Card */

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  gap: 32px;

  position: absolute;
  width: 192px;
  height: 192px;
  left: 280px;
  top: 108px;

  background: rgba(255, 255, 255, 0.9);
  /* Background blur/md */

  backdrop-filter: blur(16px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 12px;
}

.text-and-support {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 8px;

  margin-bottom: 64px;

  width: 512px;
  height: 86px;
  // padding-top: 40px;
  background-color: transparent;
}
// .text {
//   width: 512px;
//   height: 30px;

//   /* Text xl/Medium */

//   font-family: 'Inter';
//   font-style: normal;
//   font-weight: 500;
//   font-size: 20px;
//   line-height: 30px;

//   text-align: center;

//   /* White */

//   color: #ffffff;

//   /* Inside auto layout */

//   flex: none;
//   order: 0;
//   align-self: stretch;
//   flex-grow: 0;
// }

.support {
  width: 512px;
  height: 48px;

  /* Text md/Medium */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;

  /* Primary/200 */

  color: #e9d7fe;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.mobile-stepper-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 64px;

  width: 656px;
  height: 36px;
}

.shadow-img {
  width: 100%;
}

@media screen and (max-width: 560px) {
  .shadow-img {
    width: 100%;
  }
}

@media screen and (max-width: 1060px) {
  .RightSection {
    width: 100%;
    height: 800px;
  }

  .content {
    // width: 400px;
    width: 590px;
    justify-content: center;
  }

  .ComponentContainer {
    width: 100%;
  }

  .carouselContainer {
    padding: 64px 32px;
  }

  .text {
    font-size: 42px;
    line-height: 60px;
  }

  .support {
    font-size: 14px;
  }

  .text-and-support,
  .text,
  .support {
    width: 100%;
  }
}

@media screen and (max-width: 1480px) and (min-width: 1060px) {
  .container {
    padding: 0;
  }

  .content {
    width: 470px;
  }

  .text {
    font-size: 48px;
    line-height: unset;

    width: 100%;
  }

  .ComponentContainer {
    width: 50%;
    // height: 960px;
  }
  .carouselContainer {
    height: 100%;
  }
}

@media screen and (min-width: 1480px) {
  .ComponentContainer {
    width: 50%;
    // height: 960px;
  }

  .carouselContainer {
    height: 100%;
  }
}
