@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$phoneBreakpoint: map-get($screen-width-breakpoints, $key: 'phone');
$tabletBreakpoint: map-get($screen-width-breakpoints, $key: 'tablet');

.Divider {
  width: 100%;
  height: 1.5px;
  background-color: #e4e7ec;

  margin-top: 40px;
  margin-bottom: 10px;
}

.subscription-process-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 61.4468px;
  gap: 40px;

  left: 0;

  position: relative;

  width: 100%;

  background: #ffffff;
}

@media screen and (max-width: 1060px) {
  .subscription-process-container {
    align-items: center;
  }
}
