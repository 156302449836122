@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.subscription-footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 32px;
  gap: 32px;

  // width: 1280px;
  // height: 294px;
}

.subscription-footer-container-gray {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  gap: 32px;

  width: 1216px;
  // height: 294px;

  /* Gray/50 */

  background: #f9fafb;
  border-radius: 16px;
}

.subscription-footer-container-avatar-group {
  width: 120px;
  height: 56px;

  /* Inside auto layout */
  position: relative;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.subscription-footer-container-avatar-one {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  position: absolute;
  width: 56px;
  height: 56px;
  left: 32px;
  top: 0px;

  /* Avatar user square/Olivia Rhye (color background) */

  border-radius: 200px;
}

.subscription-footer-container-avatar-two {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  position: absolute;
  width: 48px;
  height: 48px;
  left: 72px;
  top: 8px;

  /* Avat     ar user square/Lori Bryson (color background) */

  border-radius: 200px;
}
.subscription-footer-container-avatar-three {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  position: absolute;
  width: 48px;
  height: 48px;
  left: 0px;
  top: 8px;

  /* Avatar user square/Alec Whitten (color background) */

  border-radius: 200px;
}

.img-dimensions {
  height: 56px;
  width: 56px;
}

.subscription-footer-container-heading-and-supportingtext-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 8px;

  // width: 768px;
  // height: 66px;
}

.subscription-footer-heading {
  // width: 768px;
  // height: 30px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;

  text-align: center;

  color: #101828;
}
.subscription-supporting-text {
  width: 768px;
  // height: 28px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;

  text-align: center;

  color: #667085;
}

.subscription-footer-button-container {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px;
  gap: 12px;

  // width: 223px;
  height: 44px;
}

.subscription-footer-button {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  // width: 253px;
  height: 44px;

  /* Primary/600 */

  background: #7f56d9;
  /* Primary/600 */

  border: 1px solid #7f56d9;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}
.subscription-footer-button-text {
  // width: 200px;
  height: 24px;

  /* Text md/Medium */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* White */

  color: #ffffff;
}

@media screen and (max-width: 1480px) {
  .subscription-footer-container,
  .subscription-footer-container-gray,
  .subscription-footer-container-heading-and-supportingtext-container,
  .subscription-supporting-text {
    width: 100%;
  }
}
