.lawp-loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;

  width: 100vw;
  height: 100vh;

  background-color: white;
  z-index: 1000;
}
