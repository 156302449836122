@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.container {
  display: flex;
}

.imageRound {
  aspect-ratio: 1/1;
  width: 120px;
  object-fit: cover;
  border-radius: 50%;
  border: 4px solid #ffffff;
  margin-right: 13px;

  box-shadow: 0px 4px 6px -2px #1018280d;
  box-shadow: 0px 12px 16px -4px #1018281a;
}

.image {
  aspect-ratio: 1/1;
  width: 120px;
  object-fit: cover;
  // border-radius: 50%;
  border-radius: 10px;
  border: 4px solid #ffffff;
  margin-right: 13px;

  box-shadow: 0px 4px 6px -2px #1018280d;
  box-shadow: 0px 12px 16px -4px #1018281a;
}

.buttons {
  height: 100%;
  margin-right: 15px;
  font-size: 14px;
  line-height: 20px;
  font-family: $fontFamily;
  @include get-font-weight($key: 'Medium');

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.buttonsTextUpdate {
  color: $primaryColor;
}

.buttonsTextDelete {
  color: #667085;
}
