@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.searchBarContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 1.09%;
  background: #fedf89;
  text-align: center;
  margin: 0 auto;
  padding-top: 1.5%;
  z-index: 10;
  width: 100%;
  height: 109%;

  left: 0;
  right: 0;
}

.lawp-search-bar-search-button {
  box-sizing: border-box;

  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 8px !important;

  width: 100% !important;
  height: 48px !important;

  background: #7f56d9 !important;

  border: 1px solid #7f56d9 !important;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
  border-radius: 8px !important;
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;

  text-transform: none !important;

  color: #ffffff !important;

  @media (max-width: 1060px) {
    height: 108px !important;
    width: 50px !important;
    min-width: 10px !important;
    font-size: 24px !important;

    .icon-search-button {
      font-size: 32px !important;
    }
  }
  &:hover {
    background: #7f56d9;
    border: 1px solid #7f56d9;
  }
}

.lawp-search-bar-sticky {
  width: inherit !important;
}

.searchContainter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #fedf89;
  color: #667085;
  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
  height: 53.9%;
  width: 22.525%;
}
.locationFilterContainer {
  background-color: #fedf89;
  height: 60px;
  width: 300px;
}
.areaOfLawFilterContainer {
  background-color: #fedf89;
  height: 60px;
  width: 300px;
}
.searchButtonContainer {
  background-color: #fedf89;
  height: 60px;
  width: 300px;
}
.inputSearch {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 14px 12px 16px;
  position: static;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
}
.filter1 {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 6%;
  padding-left: 6%;
  position: static;
  width: 204px;
  height: 48.82px;

  color: #101828;

  background: #ffffff;

  border: 1.09091px solid #d0d5dd;
  box-shadow: 0px 1.09091px 2.18182px rgba(16, 24, 40, 0.05);
  border-radius: 8.72727px;
}

.mapPin {
  left: 0;
}

.downArrow {
  right: 0;
}

.dropdown-location {
  position: relative;
}

.dropdown-menu-location-down {
  top: calc(100% + 0.25rem) !important;
  height: 190px !important;
}

.dropdown-menu-location {
  position: absolute;
  left: 0;
  width: 240px;
  height: 168px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  padding: 0px;

  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1),
    0px 4px 6px -2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  opacity: 0;
  pointer-events: none;
  transform: translateY(-10px);
  transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
  z-index: 10;

  top: calc(-190px - 0.25rem);
  height: 190px !important;
}

.dropdown-location.active > .filter1 > .downArrow {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.dropdown-location.active > .filter1 + .dropdown-menu-location {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
  z-index: 1;
}

.filter2 {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 6%;
  padding-left: 6%;

  position: static;
  width: 209px;
  height: 48.82px;
  left: 0px;
  top: 0px;

  background: #ffffff;

  border: 1.09091px solid #d0d5dd;
  box-shadow: 0px 1.09091px 2.18182px rgba(16, 24, 40, 0.05);
  border-radius: 8.72727px;
}

.dropdown-areaoflaw {
  position: relative;
}

.dropdown-menu-areaoflaw-down {
  top: calc(100% + 0.25rem) !important;
}

.dropdown-menu-areaoflaw {
  position: absolute;

  left: 0;
  width: 400px;
  height: 368px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  border-radius: 0.25rem;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1),
    0px 4px 6px -2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  opacity: 0;
  pointer-events: none;
  transform: translateY(-10px);
  transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
  z-index: 10;
  overflow: hidden !important;
  overflow-y: scroll !important;

  top: calc(-368px - 0.25rem);
}

.dropdown-areaoflaw.active > .filter2 > .downArrow {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.dropdown-areaoflaw.active > .filter2 + .dropdown-menu-areaoflaw {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}

.searchBtn {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;

  position: static;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;

  background: #7f56d9;

  border: 1px solid #7f56d9;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.lawp-search-bar-component-distance-slider {
  color: #7f56d9 !important;
  height: 5px !important;
  left: 7px !important;
  margin-top: -20px;

  & .MuiSlider-thumb {
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
      0px 2px 4px -2px rgba(16, 24, 40, 0.06) !important;
    height: 16px !important;
    width: 16px !important;
    background-color: #fff !important;

    &:focus,
    &:hover,
    &.Mui-active,
    &.Mui-focusVisible {
      box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
        0px 2px 4px -2px rgba(16, 24, 40, 0.06) !important;
    }

    &:before {
      display: none !important;
    }
  }

  & .MuiSlider-valueLabel {
    transform: translate(5%, -120%) rotate(-45deg) scale(1) rotate(45deg) !important;
    font-size: 14px !important;
    font-family: 'Inter' !important;
    background: unset !important;
    padding: 0px !important;
    top: 40px !important;
    color: #101828 !important;
  }
}

.lawp-search-bar-component-distance-slider-disabled {
  & .MuiSlider-valueLabelLabel {
    margin-left: 40px;
  }
}

.lawp-search-bar-component-search-title {
  color: #344054;
  font-family: $fontFamily;
  @include get-font-weight($key: 'Medium');
  font-size: 14px;

  margin-bottom: 1rem;
}
