.cardComponent {
  position: relative;
  width: 100%;
  /* height: 668px; */
  /* left: calc(50% - 1280px/2); */
  padding: 32px 0px 0px 0px;
  padding: 0px 32px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 22px;
  margin: 64px 0px;
}

.FaqComponentsFirstRow > * {
  flex: 0 1 0;
}

.FaqsCard {
  /* gap:64px;
 display: flex;
flex-direction: column;
align-items: center; */
  /* padding: 96px 0px;

position: static;
width: 1463px;
height: 1111px;
left: 0px;
top: 2823px; */

  /* margin: 64px 0px;

background: #FFFFFF; */
}

.FaqsCard {
  display: flex;
  flex-direction: column;
  align-items: center;

  /* padding: 40px; */
  padding: 72px;
  gap: 64px;

  width: 100%;
  /* height: 1111px; */
  height: auto;

  background: #ffffff;
}

.FaqsContent {
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */

  /* margin-left: 22px; */

  width: 100%;
}

.firstComponentTexts {
  width: 100%;

  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;

  padding: 0px;

  margin: 32px 0px;
}

.secondComponentTexts {
  width: 1248px;
  height: 334px;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0px;

  margin: 32px 0px;
}

@media only screen and (max-width: 1060px) {
  .cardComponent {
    align-items: center;
    margin-left: 0;
  }

  .FaqsCard {
    padding: 96px 40px;
  }

  .firstComponentTexts {
    align-items: center;
    /* justify-content: space-around; */
    margin-bottom: 1em;
  }

  .secondComponentTexts {
    width: 624px;
    height: 668px;

    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 640px) {
  .cardComponent {
    margin: 0;
  }
  .FaqsCard {
    padding: 40px 0;
    width: 95%;
  }
}
