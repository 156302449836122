@import "Assets/theme.scss";

$primaryColor: map-get($theme-colors, $key: "primaryColor");
$fontFamily: map-get($theme-font, $key: "primaryFontFamily");

.Dropzone {
  display: block;
  //  height: 100%;
}

.DropzoneContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  //   background-color: #fafafa;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  //   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.DropzoneContainerHovered {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.DropdownIcon {
  width: 45px;
  height: 45px;
  margin-top: 10px;
}

.SpanPurple {
  color: $primaryColor;
  font-size: 12px;
  line-height: 18px;
  font-family: $fontFamily;
  @include get-font-weight($key: "Regular");
}

.SpanGrey {
  color: #798294;
  font-size: 12px;
  line-height: 18px;
  font-family: $fontFamily;
  @include get-font-weight($key: "Regular");
}

.DescriptionContainer {
  display: flex;
  justify-content: center;
  width: 70%;
}

.DescriptionContainerSectionLeft {
  display: flex;
  margin-right: 5px;
}

.DescriptionContainerSectionRight {
  display: flex;
  margin-left: 5px;
}

.AcceptedFilesContainer {
  display: block;
  margin-bottom: 10px;
}

@media screen and (max-width: 1060px) {
  .DescriptionContainer {
    width: 100%;
  }
}
