@import "Assets/theme.scss";

$fontFamily: map-get($theme-font, $key: "primaryFontFamily");

.results {
  width: 35px;
  height: 25px;
  display: inline-flex;
  background-color: #f2f4f7;
  border-radius: 20px;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 18px;
  font-family: $fontFamily;
  @include get-font-weight($key: "Medium");
}

.checkboxContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  // margin-bottom: 20px;
}

.dropdown-checkbox-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 16px;
  gap: 12px;

  width: 100%;
  height: 40px;
}

.checkboxText {
  display: flex;
  height: 100%;
  color: #344054 !important;
  margin-left: 10px !important;
  font-family: $fontFamily !important;
  @include get-font-weight($key: "Medium");
}
