@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$phoneBreakpoint: map-get($screen-width-breakpoints, $key: 'phone');
$tabletBreakpoint: map-get($screen-width-breakpoints, $key: 'tablet');

.background-container {
  background-color: lightgray;
  width: 100%;
  display: flex;
  //background image
  background-image: url('../../../Assets/pictures/backgroundImage.png');
}

.lawp-landing-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
}

.searchBar {
  display: flex;
  width: 100%;
  height: 20%;

  background-color: aqua;
  border: 1px solid #f2f4f7;
}

.lawyerSearchContainer {
  display: flex;

  margin: auto;
  margin-top: 30px;

  @media (max-width: $tabletBreakpoint) {
    width: 100%;
    justify-content: center;
  }
}
.lawyerFilterContainer {
  display: block;
  width: 25%;
  min-width: 260px;
  margin-right: 30px;
  margin-bottom: 64px;

  @media (max-width: 1480px) {
    display: none;
  }
}

.hamburgerMenuNotDisplaying,
.lawyerCardsContainerNotDisplaying {
  display: none;
}

// .emptyListText {
//   display: flex;
//   justify-content: center;

//   width: 1000px;

//   font-family: $fontFamily;
//   font-style: normal;
//   font-weight: 400;
//   font-size: 16px;
//   line-height: 44px;
// }

.emptyListText {
  font-size: 14px;
  line-height: 20px;
  font-family: 'Inter';
  font-weight: 400 !important;
  color: gray;

  width: 1110px;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1060px) {
    width: 100%;
    height: 300px;
  }
}

.hamburgerMenu {
  display: none;

  margin-top: 25px;
  margin-bottom: 25px;

  @media (max-width: 1480px) {
    display: flex;
    width: 95%;
    justify-content: flex-start;
  }
}

.lawyerCardsContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;

  //width: 75%;
  width: 1000px;
}
.lawyerSearchButtons {
  display: flex;
  flex-direction: row;
}

.lawyerCards {
  display: block;
  width: 100%;
  padding-bottom: 20px;
}

.lawyerCardsResultsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  flex-wrap: wrap;

  width: 100%;
}

.lawp-article-blog-page-articles-pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.lawp-article-blog-page-left-arrow-button {
  display: flex;
  justify-content: flex-start !important;
  width: 150px;
  height: 64px;
  cursor: pointer;

  font-family: $fontFamily !important;
  @include get-font-weight($key: 'Medium');
  font-size: 14px !important;
  color: #667085 !important;

  &:hover {
    background-color: transparent !important;
  }
}

.lawp-article-blog-page-pagination {
  .MuiButtonBase-root {
    font-family: $fontFamily !important;
    color: #667085 !important;
    background-color: white !important;
  }

  .Mui-selected {
    font-family: $fontFamily !important;
    color: #7f56d9 !important;
    background-color: #f9f5ff !important;

    .MuiTouchRipple-root {
      border-radius: 50%;
    }
  }

  .MuiPaginationItem-icon {
    display: none !important;
  }
}

.lawp-article-blog-page-right-arrow-button {
  display: flex;
  justify-content: flex-end !important;
  width: 150px;
  height: 64px;
  cursor: pointer;

  font-family: $fontFamily !important;
  @include get-font-weight($key: 'Medium');
  font-size: 14px !important;
  color: #667085 !important;

  &:hover {
    background-color: transparent !important;
  }
}

.lawp-article-blog-page-no-lawyers-text {
  margin-top: 50px;
  font-family: $fontFamily !important;
  @include get-font-weight($key: 'Medium');
  font-size: 20px !important;
  color: #344054 !important;
  text-align: center;
}
