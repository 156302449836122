@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$phoneBreakpoint: map-get($screen-width-breakpoints, $key: 'phone');
$tabletBreakpoint: map-get($screen-width-breakpoints, $key: 'tablet');

.lawp-client-reviews-client-profile-card-container {
  width: 33.33%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: $tabletBreakpoint) {
    width: 50%;
  }

  @media (max-width: $phoneBreakpoint) {
    width: 100%;
  }
}

.lawp-client-reviews-client-profile-settings-top-menu {
  margin-top: 0px;
}
.lawp-client-reviews-client-profile-settings-page-container {
  display: block;
  width: 95.5%;
  margin: auto;
}

.lawp-client-reviews-no-reviews-container {
  display: flex;
  width: 100%;
  height: 300px;
  justify-content: center;
  align-items: center;
}
.lawp-client-reviews-no-reviews-container-text {
  font-size: 14px;
  line-height: 20px;
  font-family: $fontFamily;
  @include get-font-weight($key: 'Regular');
  color: gray;
}
