@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.subscription-step-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  margin-bottom: 32px;
  .subscription-step-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 32px;
    gap: 40px;
    .subscription-step-header-heading-support-text {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px;
      gap: 24px;
      .subscription-step-header-heading-sub-heading {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        gap: 12px;
        .subscription-step-header-sub-heading {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;

          text-align: center;

          color: #7f56d9;
          .subscription-step-header-sub-heading-button {
            height: 24px;

            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;

            background-color: #ffffff;
            text-align: center;
            border: none;

            color: #7f56d9;
          }
        }
        .subscription-step-header-heading {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          font-size: 48px;
          line-height: 60px;

          text-align: center;
          letter-spacing: -0.02em;

          color: #101828;
        }
      }
      .subscription-step-header-support-text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;

        text-align: center;
        color: #667085;
      }
    }

    .subscription-step-header-button {
      box-sizing: border-box;

      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 6px;
      gap: 8px;

      width: 425px;
      height: 56px;

      background: #f9fafb;

      border: 1px solid #f2f4f7;
      border-radius: 8px;
    }
  }
  .subscription-step-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    // overflow: scroll;
    // overflow-x: hidden;
    padding: 0px 0px 32px;
    gap: 32px;
    .subscription-step-content-1 {
      box-sizing: border-box;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;

      width: 384px;
      height: 633px;

      background: salmon;

      border: 1px solid #e4e7ec;

      box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1),
        0px 4px 6px -2px rgba(16, 24, 40, 0.05);
      border-radius: 16px;
    }
  }
  .subscription-step-actions {
    width: 320px;
    height: 48px;

    .update-subscription-button {
      box-sizing: border-box;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px 20px;
      gap: 8px;

      width: 320px;
      height: 48px;

      background: #7f56d9;

      border: 1px solid #7f56d9;

      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;

      color: #ffffff;

      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
    }
  }
}
.horizontal-tabs-month {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;

  width: 180px;
  height: 44px;

  border-radius: 6px;

  background: #f9fafb;

  border: 0px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  color: #667085;
}
.horizontal-tabs-month-active {
  background: #ffffff;

  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
}

.horizontal-tabs-month-text {
  width: 151px;
  height: 24px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #667085;
}

.horizontal-tabs-year-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;

  width: 226px;
  height: 44px;
  border: none;

  background: #ffffff;

  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 6px;
  border-color: #ffffff;
}

.horizontal-tabs-year-text {
  width: 136px;
  height: 24px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #344054;
}

.horizontal-tabs-year-badge {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 2px 10px;

  width: 54px;
  height: 24px;

  background-color: white;
  border-radius: 16px;
}

.horizontal-tabs-year-badge-active {
  background-color: #f2f4f7 !important;
}

.horizontal-tabs-year-badge-text {
  width: 34px;
  height: 20px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  text-align: center;

  color: #344054;
}

.horizontal-tabs-month-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;

  width: 189px;

  border: none;

  width: 179px;
  left: 6px;
  top: 6px;

  background: #ffffff;

  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 6px;
  border-color: #ffffff;
}

.horizontal-tabs-year {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;
  border: 0;
  cursor: pointer;

  width: 226px;
  height: 44px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #344054;

  background: #f9fafb;
}
.horizontal-tabs-year-active {
  background: #ffffff;

  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 6px;
}

.horizontal-tabs-button-text {
  width: 156px;
  height: 24px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #344054;
}
