@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.container {
  display: block;
  width: 95.5%;
  // width: 92%;
  margin: auto;
}

.snackbar {
  width: 100% !important;
  background-color: #7f56d9 !important;
  font-family: $fontFamily !important;
}

.Divider {
  width: 100%;
  height: 1.5px;
  background-color: #e4e7ec;
}

// .EditLawyerTopMenu {
//   margin-top: 65px;
// }

.EditSaveChangesSection {
  margin-top: 68px;
  margin-bottom: 30px;
  padding: 0px 30px 0px 60px;
}
.EditSaveChangesSectionBottom {
  margin-top: 10px;
  margin-bottom: 30px;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  padding: 30px 0px 30px 60px;
}

.Section {
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 0px 30px 0px 0px;
  // margin-top: 40px;
  // margin-bottom: 40px;
}

.SectionInformation {
  width: 26%;
  display: flex;
  min-width: 300px;
}

.SectionInformationContainer {
  width: 74%;
}
.SectionContent {
  width: 74%;
}

.ImageDropzoneTitle {
  font-family: $fontFamily;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.PLACE_HOLDER_TEXT_EDITOR {
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  width: 70%;
  // height: 222px;
  margin-top: 20px;

  a {
    color: #6941c6;

    text-decoration: none !important;
  }
}

.websiteFullSection {
  width: 512px;
}

.LawyerImages {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  flex-wrap: wrap;
}

.Divider {
  width: 100%;
  height: 1px;

  background: #e4e7ec;

  margin-top: 20px;
}

.goldContainer {
  position: relative;
}

.goldInfoContainer {
  display: flex;
  flex-direction: column;
  padding: 30px 0px 30px 60px;
}

.goldSection {
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 0px 30px 0px 0px;
  // margin-top: 40px;
  // margin-bottom: 40px;
}

.goldSectionInformation {
  width: 26%;
  display: flex;
  min-width: 300px;
}

.goldSectionInformationContainer {
  width: 74%;
}

.goldSectionContent {
  width: 74%;
}

@media screen and (max-width: 1060px) {
  .Section,
  .goldSection {
    flex-direction: column;
    gap: 30px;
  }

  .SectionInformation,
  .SectionInformationContainer,
  .SectionContent,
  .goldSectionInformation,
  .goldSectionInformationContainer,
  .goldSectionContent {
    width: 100%;
  }

  .goldSectionInformation,
  .goldSectionInformationContainer,
  .goldSectionContent {
    height: auto;
    position: absolute;
  }
}
