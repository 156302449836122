@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.emailCheckerSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  width: 50%;
  height: 960px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
}

.emailCheckerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 32px;
  gap: 80px;

  width: 100%;
  height: 490px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.frame2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 80px;

  width: 360px;
  height: 344px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 32px;

  width: 360px;
  height: 344px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 24px;

  width: 360px;
  height: 216px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.featuredIcon {
  width: 56px;
  height: 56px;

  background: #f4ebff;

  border: 10px solid #f9f5ff;
  border-radius: 28px;

  display: flex;
  justify-content: center;
  align-items: center;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.key {
  position: absolute;
  width: 28px;
  height: 28px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.textAndSupportingText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  width: 360px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.text {
  width: 360px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  margin: 0;

  text-align: center;

  color: #101828;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.supportingText {
  width: 360px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0;

  text-align: center;

  color: #667085;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.formContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.EmailInput {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.inputWithLabel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 6px;

  width: 360px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.setEmailButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 24px;
}

.setEmailButtonBase {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 360px;
  height: 44px;

  background: #7f56d9;

  border: 1px solid #7f56d9;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  flex: none;
  order: 0;
  flex-grow: 1;
}

.setEmailButtonText {
  width: 191px;
  height: 24px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 0;

  color: #ffffff;

  flex: none;
  order: 0;
  flex-grow: 0;
}

@media screen and (max-width: 1060px) {
  .emailCheckerSection {
    width: unset;
    height: unset;
    padding-bottom: 600px;
  }

  .text {
    font-size: 1.5rem;
  }

  .supportingText,
  .setEmailButtonText,
  .emailInput,
  .emailLabel {
    font-size: 14px;
  }
  .emailCheckerContainer {
    width: unset;
  }
}
