@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.lawp-legal-directory-page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 24px;
  width: 100%;

  h1 {
    font-family: $fontFamily;
    font-size: 28px;
    font-weight: 500;
    color: #101828;
    margin: 24px 0;
  }

  a {
    font-family: $fontFamily;
    font-size: 16px;
    font-weight: 400;
    color: #6941c6;
    text-decoration: none;
    margin: 8px 0;

    &:hover {
      text-decoration: underline;
    }
  }
}
