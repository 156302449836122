@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.lawp-lawyer-card-preview-container {
  display: flex;
  flex-direction: column;

  margin-top: 10px;
  margin-bottom: 10px;

  cursor: pointer;

  &:hover {
    box-shadow: 0 2px 8px -3px rgb(0 0 0 / 25%);
  }

  .lawyer-card {
    /* Team member wrap */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 24px;

    width: 280px;
    height: 368px;

    /* White */
    background: #ffffff;

    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;
  }

  .top {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;

    gap: 10px;
  }

  .bottom {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    margin-bottom: -22px;
  }

  .lawyer-image-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .lawyer-image {
      /* Auto layout */
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0px;

      width: 80px;
      height: 80px;

      /* Avatar user square/Olivia Rhye (color background) */
      border-radius: 40px;
      object-fit: cover;

      /* Inside auto layout */
      flex: none;
      order: 0;
      flex-grow: 0;
    }
  }

  .name-text {
    /* Text lg/Medium */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    // line-height: 28px;

    /* identical to box height, or 156% */
    text-align: center;
    align-self: stretch;
    /* Gray/900 */
    color: #101828;

    margin-top: 15px;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical;
    white-space: normal;
  }

  .specialist-text {
    margin-top: -5px;
    margin-bottom: 10px;
    /* Text md/Normal */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    // line-height: 24px;

    /* identical to box height, or 150% */
    text-align: center;
    align-self: stretch;

    /* Gray/500 */
    color: #667085;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 1 !important;
    -webkit-box-orient: vertical;
    white-space: normal;
  }

  .description-text {
    /* Text md/Normal */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    /* or 150% */
    text-align: center;

    /* Gray/500 */
    color: #667085;

    flex-grow: 0;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 4 !important;
    -webkit-box-orient: vertical;
    white-space: normal;
  }

  .profile-text {
    /* Text sm/Medium */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    margin-top: 0;
    margin-bottom: 1rem;

    /* identical to box height, or 143% */

    /* Primary/700 */
    color: #6941c6;
    align-self: stretch;
    text-align: center;

    .arrow-image {
      width: 12px;
      height: 12px;
      margin-top: -2px;

      margin-left: 7px;
    }

    //pointer on hover
    cursor: pointer;
  }
}
