@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$checkboxSize: 25px;
$checkboxRadius: 7px;

// 16 initial

.unselected {
  width: 22px;
  height: 22px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  border-radius: 22px;
}

.selected {
  box-sizing: border-box;
  width: 22px;
  height: 22px;
  background: #f9f5ff;
  border: 1px solid #7f56d9;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkedCircle {
  width: 8px;
  height: 8px;
  background: #7f56d9;
  border-radius: 32px;
}

.title {
  width: 512px;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  margin: 0;

  color: #344054;
}

.text {
  width: auto;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.phone-number-text,
.address-text {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  margin-left: 30px;
}
