@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.review-card-public-full-info-modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 512px;
  background-color: #fff;
  border-radius: 9px;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.75);
  padding-top: 16px;
  padding-right: 32px;
  padding-left: 32px;
  padding-bottom: 24px;

  border: none;

  .title-date {
    /* Text lg/Medium */
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    //align left
    text-align: left;
    width: 100%;
    margin-bottom: 0px;

    /* identical to box height, or 156% */

    /* Gray/900 */
    color: #101828;
  }

  .title-text {
    /* Text lg/Medium */
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    //align left
    text-align: left;
    width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;

    /* identical to box height, or 156% */

    /* Gray/900 */
    color: #101828;
  }

  .review-card {
    display: flex;
    width: 100%;
    margin-bottom: 16px;

    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    gap: 8px;

    background: #ffffff;

    /* Shadow/lg */
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1),
      0px 4px 6px -2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;

    .client-info-container {
      display: flex;
      flex-direction: row;
    }

    .client-name-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin-bottom: 8px;
    }

    .lawyer-image {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 8px;
      object-fit: cover;
    }
    .lawyer-name-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 8px;
    }

    .client-name-image {
      display: flex;
      flex-direction: row;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      margin-right: 13px;
    }

    .client-name {
      /* Text sm/Medium */
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 0px;

      /* identical to box height, or 143% */

      /* Gray/900 */
      color: #101828;
    }

    .review-content {
      font-family: 'Inter';
      font-style: italic;
      font-weight: 200;
      font-size: 14px;
      line-height: 20px;

      /* or 143% */

      /* Gray/500 */
      color: #667085;
    }
  }

  .button-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin-top: 16px;
  }

  .cancel-button {
    display: inline-block;
    height: 44px;
    border-radius: 9px;
    padding: 5px 16px 5px 16px;
    color: white;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    background-color: #7f56d9;
    text-transform: none;

    &:hover {
      background-color: #7f56d9;
    }
  }
}
