@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.verifyLawyerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  position: relative;
  width: 100%;
  // height: 960px;

  background: #ffffff;
}

.topSection {
  display: none;
}

.topSectionSubscription {
  display: flex;
  width: 100%;
  // height: 50px;

  padding-top: 128px;

  background: #ffffff;
}

.verifyLeftSection {
  position: sticky;
  // top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;

  width: 33%;
  // height: 960px;

  // margin-top: 80px;

  background: #f9fafb;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.verifyLeftSectionContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 48px 16px 0px 48px;
  gap: 80px;

  width: 50%;
  // height: 568px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.verifyLogo {
  width: 190px;
  height: 33px;
  // cursor: pointer;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;

  width: 400px;
  // height: 490px;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.supportingText {
  width: 352px;
  height: 48px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #7f56d9;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.verifyFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 40px;

  width: 464px;
  height: 96px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.verifyFooterText {
  width: 118px;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 0;

  color: #667085;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.verifyFooterRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 162px;
  height: 20px;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.verifyFooterEmailText {
  width: 138px;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 0;

  color: #667085;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.emailLogo {
  color: grey;
}

.verifyRightSectionContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0px;

  width: 67%;
  height: 960px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
}

@media screen and (max-width: 1060px) {
  .verifyLawyerContainer {
    flex-direction: column;
    height: 100%;
  }

  .verifyClientSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 100px;

    position: relative;
    width: 100%;
    height: 50%;

    background: #ffffff;
  }

  .verifyLawyerSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 50px;

    width: 100%;
    height: 100%;
  }

  .topSection {
    display: flex;
    width: 100%;
    // height: 50px;

    padding-top: 128px;

    background: #ffffff;
  }

  .verifyLeftSection {
    display: none;
  }
}

@media screen and (max-width: 1480px) and (min-width: 1060px) {
  .verifyLeftSection {
    width: 50%;
  }

  .verifyLawyerSection {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .verifyClientSection {
    display: flex;
    width: 100%;
  }
}

@media screen and (min-width: 1480px) {
  .verifyLeftSection {
    width: 33%;
  }

  .verifyClientSection {
    display: flex;
    width: 100%;
  }

  .verifyLawyerSection {
    display: flex;
    width: 100%;
  }
}
