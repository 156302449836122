@import "Assets/theme.scss";

$fontFamily: map-get($theme-font, $key: "primaryFontFamily");

.lawp-lawyer-notification-mockup-container {
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.lawp-lawyer-notification-mockup-article-author {
  display: flex;
  align-items: center;

  width: 300px;
  height: 72px;

  background: rgba(255, 255, 255);

  backdrop-filter: blur(16px);

  margin-bottom: 12px;

  border-radius: 8px;
}

.lawp-lawyer-notification-mockup-article-author-image {
  display: flex;
  flex-direction: row;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 13px;
  margin-left: 15px;
}

.lawp-lawyer-notification-mockup-author-name {
  font-family: $fontFamily;
  font-size: 14px;
  @include get-font-weight($key: "Medium");
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #6941c6;
}

.lawp-lawyer-notification-mockup-article-date {
  font-family: $fontFamily;
  font-size: 14px;
  @include get-font-weight($key: "Regular");
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #667085;
}
