@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$phoneBreakpoint: map-get($screen-width-breakpoints, $key: 'phone');
$tabletBreakpoint: map-get($screen-width-breakpoints, $key: 'tablet');

.lawp-add-meeting-modal-container-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  position: absolute;
  width: 100%;
  height: 100%;
  left: 7217px;
  top: 7459px;

  background: rgba(52, 64, 84, 0.6);

  backdrop-filter: blur(8px);
}

.lawp-add-meeting-modal-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.lawp-add-meeting-modal-container-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 48px;
  gap: 32px;
  background: #f9fafb;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  width: 29rem;
}

.lawp-add-meeting-modal-profile-image {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
}

.lawp-add-meeting-modal-container-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 61rem;
  min-height: 900px;
}

.lawp-add-meeting-modal-container-right-second-step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 61rem;
  min-height: 1000px;

  @media (max-width: $phoneBreakpoint) {
    width: 100%;
  }
}

.lawp-add-meeting-modal-back-to-profile-overview-button {
  font-size: 16px !important;
  color: #7f56d9 !important;
  line-height: 24px !important;
  text-transform: none !important;
  font-family: $fontFamily !important;
  @include get-font-weight($key: 'Medium');
}

.lawp-add-meeting-modal-lawyer-image-container {
  display: flex;
}

.lawp-add-meeting-modal-lawyer-image {
  aspect-ratio: 1/1;
  width: 160px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: -20px;
}

.lawp-add-meeting-modal-lawyer-specialization {
  width: 368px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  color: #6941c6;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  margin-bottom: -16px;
}

.lawp-add-meeting-modal-lawyer-name-text {
  font-size: 24px !important;
  line-height: 32px !important;
  font-family: $fontFamily !important;
  @include get-font-weight($key: 'SemiBold');
  color: #000000;

  margin-bottom: -18px;
}

.lawp-add-meeting-modal-rating-container {
  width: 250px;
  display: flex;

  margin-bottom: -20px;
}

.lawp-add-meeting-modal-rating-stars {
  margin-left: -3px;
}

.lawp-add-meeting-modal-rating-text {
  margin-left: 8px;
  font-family: $fontFamily;
  @include get-font-weight($key: 'Medium');
  color: black;
  line-height: 24px;
  font-size: 16px;
}

.lawp-add-meeting-modal-rating-reviews {
  margin-left: 8px;
  font-family: $fontFamily;
  @include get-font-weight($key: 'Regular');
  color: grey;
  font-size: 16px;
  line-height: 24px;
  white-space: pre;
}

.lawp-add-meeting-modal-lawyer-description {
  margin-top: 10px !important;
}

.lawp-add-meeting-modal-lawyer-description-text {
  font-family: $fontFamily;
  @include get-font-weight($key: 'Regular');
  color: grey;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 1rem;
}

.lawp-add-meeting-modal-areas-of-law-container {
  margin-top: -40px;
}

.lawp-add-meeting-modal-area-of-law-tag-container {
  display: inline-block;
  align-items: center;
  justify-content: 'center';
  padding: 2px 10px 2px 10px;
  border-radius: 80px;
  margin-top: 12px;
  margin-left: 3px;
  margin-right: 3px;
}

.lawp-add-meeting-modal-area-of-law-tag-text {
  font-family: $fontFamily;
  @include get-font-weight($key: 'Medium');
  font-size: 13;
}

.lawp-add-meeting-modal-container-right-centered {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 32px;

  width: 560px;

  @media (max-width: $tabletBreakpoint) {
    width: 100%;
    padding: 0px 16px;
    max-width: 580px;
  }

  @media (max-width: $phoneBreakpoint) {
    width: 80%;
  }
}

.lawp-add-meeting-modal-container-right-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 48px;

  width: 100%;
  @media (max-width: $phoneBreakpoint) {
    width: 90%;
  }

  @media (max-width: $tabletBreakpoint) {
    max-width: 558px;
  }
}

.lawp-add-meeting-modal-container-right-heading-and-supporting-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  width: 100%;

  @media (max-width: $tabletBreakpoint) {
    width: 96%;
  }
}

.lawp-add-meeting-modal-container-right-heading {
  width: 100%;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;

  margin: 0;

  letter-spacing: -0.02em;

  color: #101828;
}

.lawp-add-meeting-modal-container-right-supporting-text {
  width: 100%;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;

  margin: 0;

  color: #667085;
}

.lawp-add-meeting-modal-container-right-date-picker-menu {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 328px;
  height: 448px;

  background: #ffffff;

  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1),
    0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  border-radius: 8px;
}

.lawp-add-meeting-modal-container-right-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 12px;

  width: 200px;
  height: 452px;
  overflow-y: auto;
}

.lawp-add-meeting-modal-container-right-row-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 14px;
  gap: 8px;

  width: 148px;
  height: 36px;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #344054;
}

.lawp-add-meeting-modal-container-right-row-text {
  width: 148px;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  margin: 0;

  color: #344054;
}

.frame1037 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;

  width: 148px;
  height: 36px;
}

.lawp-add-meeting-modal-container-right-row-selected-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 14px;
  gap: 8px;

  width: 75%;
  height: 36px;

  background: #f9f5ff;

  border: 1px solid #f9f5ff;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #f4ebff;
  border-radius: 8px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 13.5px;
  line-height: 20px;

  margin: 0;

  color: #6941c6;
}

.lawp-add-meeting-modal-container-right-row-arrow-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;

  width: 44px;
  height: 36px;

  background: #7f56d9;

  border: 1px solid #7f56d9;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #f4ebff;
  border-radius: 8px;
}

.lawp-add-meeting-modal-container-right-frame1038 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;

  width: 512px;
  height: 36px;
}

.lawp-add-meeting-modal-container-right-row-arrow-button-back {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;

  width: 36px;
  height: 36px;

  background: #f9f5ff;

  border: 1px solid #f9f5ff;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #f4ebff;
  border-radius: 8px;
}

.lawp-add-meeting-modal-container-right-text {
  width: 100%;
  height: 28px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;

  margin: 0;

  color: #344054;
}

.lawp-add-meeting-modal-container-right-content-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 15px;

  width: 100%;
  height: 888px;
}

.lawp-add-meeting-modal-container-right-gdpr-text {
  width: 512px;
  height: 40px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  margin: 0;

  color: #667085;
}

.lawp-add-meeting-modal-container-right-submit-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 100%;
  height: 44px;

  background: #7f56d9;

  border: 1px solid #7f56d9;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #ffffff;
}

.lawp-add-meeting-modal-container-right-submit-button-disabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 100%;
  height: 44px;

  background: gray;

  border: 1px solid gray;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #ffffff;
}

.lawp-add-meeting-modal-container-right-hintText {
  width: 330px;
  height: 28px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  margin: 0;

  color: #667085;
}

.lawp-add-meeting-modal-container-right-back-button-container {
  display: none;
}

.lawp-add-meeting-modal-container-right-row-login {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 100%;
  height: 20px;
}

.lawp-add-meeting-modal-container-right-row-text-login {
  width: 221px;
  height: 20px;
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #667085;
}

.lawp-add-meeting-modal-container-right-row-button-login {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 106px;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #6941c6;

  background: none;
  border: none;
}

.lawp-add-meeting-modal-error-message {
  color: red;
  font-size: 14px;
  margin-top: -20px;
  font-family: $fontFamily;
}

@media screen and (max-width: 1060px) {
  .lawp-add-meeting-modal-container {
    height: 100% !important;
  }

  .lawp-add-meeting-modal-container-left {
    width: 80%;
  }

  .lawp-add-meeting-modal-container-right {
    padding: 64px 0 0 0;
  }

  .lawp-add-meeting-modal-container-right-centered {
    flex-direction: column;
    gap: 32px;

    padding: 0;
  }

  .lawp-add-meeting-modal-container {
    flex-direction: column-reverse;
    align-items: center;
    gap: 32px;
  }
  .lawp-add-meeting-modal-container-right,
  .lawp-add-meeting-modal-container-right-centered {
    width: 100%;
  }

  .lawp-add-meeting-modal-container-right,
  .lawp-add-meeting-modal-container-right-centered,
  .lawp-add-meeting-modal-container-right-content,
  .lawp-add-meeting-modal-container-right-content-form {
    height: unset;
  }

  .lawp-add-meeting-modal-container-right-heading {
    font-size: 30px;
  }

  .lawp-add-meeting-modal-container-left {
    align-self: unset;
  }

  .lawp-add-meeting-modal-back-to-profile-overview-button {
    z-index: -1;
  }

  .lawp-add-meeting-modal-container-right-back-button-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;

    width: 100%;
    height: 36px;
  }
}
