@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.cancel-event-modal-actions-cancel-event-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 115px;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #6941c6;

  border: none;
  background: none;
}

.cancel-event-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  position: absolute;
  width: 100%;
  height: 100%;
  left: 7217px;
  top: 7459px;

  background: rgba(52, 64, 84, 0.6);

  backdrop-filter: blur(8px);
}

.cancel-event-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 32px;

  width: 400px;
  // height: 420px;

  background: #ffffff;

  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1),
    0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  border-radius: 12px;
}

.cancel-event-modal-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;

  width: 100%;
  // height: 296px;
}

.cancel-event-modal-text-and-supporting-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;

  width: 100%;
  // height: 96px;
  // height: 140px;
}

.cancel-event-modal-text {
  width: 100%;
  height: 28px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;

  color: #101828;
}

.cancel-event-modal-supporting-text {
  width: 100%;
  // height: 60px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #667085;
}

.cancel-event-modal-details-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  // line-height: 20px;

  width: 100%;

  margin: 0;
}

.cancel-event-modal-textarea-input-field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 100%;
  height: 180px;
}

.cancel-event-modal-actions {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  width: 100%;
  height: 44px;
}

.cancel-event-modal-cancel-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 170px;
  height: 44px;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #344054;
}

.cancel-event-modal-success-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 170px;
  height: 44px;

  background: #7f56d9;

  border: 1px solid #7f56d9;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #ffffff;
}

@media screen and (max-width: 1060px) {
  .cancel-event-modal-actions-cancel-event-button {
    font-size: 12px;
    justify-content: flex-end;
  }
}
