@import "Assets/theme.scss";

$fontFamily: map-get($theme-font, $key: "primaryFontFamily");

$checkboxSize: 16px;
$checkboxRadius: 4px;

*,
*::before,
*::after {
  box-sizing: border-box;
}

.email-password-request-box-container {
  height: 400px;
}
.email-password-request-header {
  height: 178px;
}
.email-password-request-content {
  height: 186px;
}
.email-password-request-form-container {
  height: 96px;
}
.email-password-request-form-comment {
  height: 96px;
}
.email-password-request-input-label-container-comment {
  height: 96px;
}

// .email-password-request-form {
//   height: 70px;
// }

// .email-password-request-input-label-container {
//   height: 70px;
// }
.email-password-request-label {
  height: 20px;
}

.email-password-request-header-text-support {
  height: 98px;
}
.email-password-request-header-text {
  height: 38px;
}
.email-password-request-header-support {
  height: 48px;
}

.email-password-request-margin-bot {
  margin-bottom: 20px;
}
.email-password-request-action-button-margin-top {
  margin-top: 24px;
}
