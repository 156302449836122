@import "Assets/theme.scss";

$fontFamily: map-get($theme-font, $key: "primaryFontFamily");

.lawp-delete-review-modal-container {
  /* Auto layout */
  display: flex;
  flex-direction: column;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  padding: 24px;
  gap: 32px;

  width: 400px;
//   height: 527px;

  /* White */
  background: #ffffff;

  /* Shadow/xl */
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1),
    0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  border-radius: 12px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.lawp-delete-review-modal-title {
  /* Text */

  width: 352px;
  height: 28px;

  /* Text lg/Medium */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;

  /* identical to box height, or 156% */

  /* Gray/900 */
  color: #101828;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.lawp-delete-review-modal-rating-container {
  // width: 200px;
  display: flex;

  margin-top: -40px;
  margin-bottom: -35px;
}

.lawp-delete-review-modal-rating-stars {
  margin-left: -3px;
}

.lawp-delete-review-modal-rating-text {
  margin-left: 8px;
  font-family: $fontFamily;
  @include get-font-weight($key: "Medium");
  color: black;
  line-height: 24px;
  font-size: 16px;
}

.lawp-delete-review-modal-rating-reviews {
  margin-left: 8px;
  font-family: $fontFamily;
  @include get-font-weight($key: "Regular");
  color: grey;
  font-size: 16px;
  line-height: 24px;
  white-space: pre;
}

.lawp-delete-review-modal-lawyer-description {
  margin-top: 10px !important;

  width: 100%;
}

.lawp-delete-review-modal-lawyer-description-text {
  font-family: $fontFamily;
  @include get-font-weight($key: "Regular");
  color: grey;
  font-size: 15px;
  line-height: 28px;
}

.lawp-delete-review-modal-textfield-container {
  margin-top: -25px;
}

.lawp-delete-review-modal-button-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 0px;
}

.lawp-delete-review-modal-cancel-button {
  display: inline-block !important;
  height: 44px !important;
  width: 170px;

  padding: 5px 15px 5px 15px !important;
  color: #344054 !important;
  font-family: $fontFamily !important;
  @include get-font-weight($key: "Medium");
  font-size: 14px !important;
  line-height: 20px !important;

  border-radius: 8px !important;
  background-color: white !important;
  text-transform: none !important;
  border-radius: 9px !important;
  border: 1px solid #d6dae1 !important;
}

.lawp-delete-review-modal-submit-button {
  display: inline-block !important;
  height: 44px !important;
  width: 170px;

  padding: 5px 16px 5px 16px !important;
  color: white !important;
  font-family: $fontFamily !important;
  @include get-font-weight($key: "Medium");
  font-size: 14px !important;
  line-height: 20px !important;

  border-radius: 8px !important;
  background-color: #7f56d9 !important;
  text-transform: none !important;
  border-radius: 9px !important;
}
//lawp-delete-review-modal
