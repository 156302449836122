@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$checkboxSize: 25px;
$checkboxRadius: 7px;

.checkboxContainerSelected {
  width: $checkboxSize;
  height: $checkboxSize;
  max-width: $checkboxSize;
  display: inline-flex;
  background-color: #f9f5ff;
  border: 2px solid #7f56d9;
  border-radius: $checkboxRadius;
  align-items: center;
  justify-content: center;
}

.checkboxContainerDisabled {
  width: $checkboxSize;
  height: $checkboxSize;
  max-width: $checkboxSize;
  display: inline-flex;
  background-color: #d0d5dd;
  border: 2px solid #d0d5dd;
  border-radius: $checkboxRadius;
  align-items: center;
  justify-content: center;
}

.checkboxContainer {
  width: $checkboxSize;
  height: $checkboxSize;
  max-width: $checkboxSize;
  display: inline-flex;
  background-color: #ffffff;
  border: 2px solid #d0d5dd;
  border-radius: $checkboxRadius;
  align-items: center;
  justify-content: center;
}

.checkStyleSelected {
  font-size: 24px;
  color: #7f56d9;
}

.checkStyle {
  display: none;
  font-size: 24px;
  color: rgba(0, 0, 0, 0);
}

.checkStyleSelectedImg {
  font-size: 24px;
  height: 18px;
  margin-right: 1px;
  margin-top: 2px;
  color: #7f56d9;
}

.checkStyleImg {
  display: none;
  font-size: 24px;
  color: rgba(0, 0, 0, 0);
}

.checkButton {
  max-width: $checkboxSize !important;
  min-width: $checkboxSize !important;
  max-height: $checkboxSize !important;
  min-height: $checkboxSize !important;
  border-radius: $checkboxRadius !important;
}
