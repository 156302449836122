@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$phoneWidth: map-get($screen-widths, $key: 'phone');
$tabletWidth: map-get($screen-widths, $key: 'tablet');
$desktopWidth: map-get($screen-widths, $key: 'desktop');

$phoneBreakpoint: map-get($screen-width-breakpoints, $key: 'phone');
$tabletBreakpoint: map-get($screen-width-breakpoints, $key: 'tablet');

.lawp-contact-us-page-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .snackbarSuccess {
    width: 100% !important;
    background-color: #7f56d9 !important;
    font-family: $fontFamily !important;
  }

  .snackbarError {
    width: 100% !important;
    background-color: #dd3f3f !important;
    font-family: $fontFamily !important;
  }

  .snackbar {
    width: 100% !important;
    background-color: #7f56d9 !important;
    font-family: $fontFamily !important;
  }

  .error-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
    font-family: $fontFamily;
    margin-bottom: -20px;
  }

  .title-text {
    /* Display lg/Semibold */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 60px;

    /* identical to box height, or 125% */
    letter-spacing: -0.02em;

    /* Gray/900 */
    color: #101828;
  }

  .subtitle-text {
    /* Text xl/Normal */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;

    /* or 150% */

    /* Gray/500 */
    color: #667085;
  }

  // .email-text-color {
  // color: $primaryColor;
  // }

  .form-container {
    display: flex;
    flex-direction: row;

    margin-top: 100px;

    @media (max-width: $tabletBreakpoint) {
      justify-content: center;
      align-items: center;
      margin: 0;
    }

    .form-container-background {
      display: flex;
      width: 50%;
      height: 920px;

      @media (max-width: $tabletBreakpoint) {
        display: none;
      }
    }

    .form-container-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      gap: 15px;

      width: 50%;
      height: 100%;

      padding: 52px 104px 52px 104px;

      @media (max-width: $tabletBreakpoint) {
        width: 71%;
      }

      @media (max-width: $phoneBreakpoint) {
        width: 100%;
        padding: 96px 63px 96px 63px;
      }

      .textfields-container {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }

      .firstName {
        width: 48%;
      }

      .lastName {
        width: 48%;
      }

      .email {
        margin-top: 10px;
        width: 100%;
      }

      .phone {
        width: 100%;
      }

      .how-can-we-help {
        margin-top: 10px;
        width: 100%;
      }

      .services {
        width: 100%;

        .text {
          /* Text sm/Medium */
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;

          /* identical to box height, or 143% */

          /* Gray/700 */
          color: #344054;
        }

        .checkboxes {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
        }

        .checkbox-container {
          display: flex;
          flex-direction: row;
          width: 40%;
        }

        .checkbox-text {
          display: flex;
          color: #344054 !important;
          margin-left: 10px !important;
          font-family: $fontFamily !important;
          @include get-font-weight($key: 'Medium');
        }
      }

      .submit-button-container {
        width: 100%;
        margin-top: 32px;

        .button-style {
          display: inline-block !important;
          width: 100% !important;
          height: 44px !important;
          left: 0px !important;
          top: 0px !important;
          border-radius: 8px !important;
          padding: 5px 16px 5px 16px !important;
          color: white !important;
          font-family: $fontFamily !important;
          @include get-font-weight($key: 'Medium');
          font-size: 14px !important;
          line-height: 20px !important;
          background-color: #7f56d9;
          text-transform: none !important;
          border-radius: 9px !important;
        }
      }
    }
  }

  .legal-checkbox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;

    margin-top: 10px;
    margin-bottom: -25px;

    .legal-checkbox-text {
      /* Text sm/Medium */
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;

      /* identical to box height, or 143% */

      /* Gray/700 */
      color: #344054;

      // border: 1px solid red;
      margin-top: 2px;
    }
  }
}

.lawp-contact-us-page-our-values-container {
  margin-top: 100px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.lawp-contact-us-page-our-values-container-info {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 30%;

  h4 {
    /* Text md/Semibold */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;

    /* identical to box height, or 150% */

    /* Primary/700 */
    color: #6941c6;
  }

  h2 {
    /* Display md/Semibold */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    text-align: center;

    /* identical to box height, or 122% */
    letter-spacing: -0.02em;

    /* Gray/900 */
    color: #101828;
  }

  h3 {
    /* Text lg/Normal */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    /* or 156% */

    /* Gray/500 */
    color: #667085;
  }
}

.checkbox-text-container {
  display: flex;
  flex-direction: row;
}

.checkbox-link {
  /* Text md/Medium */
  text-decoration: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  // font-size: 16px;
  font-size: 14px;
  line-height: 20px;

  margin-top: 2px;

  /* identical to box height, or 150% */

  /* Primary/700 */
  color: #6941c6;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: #6941c6;
  }
}

.lawp-contact-us-page-map-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 96px;
  margin-bottom: 96px;

  @media (max-width: $tabletBreakpoint) {
    margin-top: 64px;
    margin-bottom: 64px;
  }

  @media (max-width: $phoneBreakpoint) {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .map-image {
    width: 80%;
    height: 80%;

    @media (max-width: $tabletBreakpoint) {
      width: 85%;
      height: 85%;
    }

    @media (max-width: $phoneBreakpoint) {
      width: 90%;
      height: 90%;
    }
  }
}
