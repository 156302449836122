@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.upcoming-events-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 100%;

  background: #ffffff;

  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1),
    0px 4px 6px -2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.noMeetingsContainer {
  display: flex;
  width: 100%;
  height: 300px;
  justify-content: center;
  align-items: center;
}

.emptyListText {
  font-size: 14px;
  line-height: 20px;
  font-family: 'Inter';
  font-weight: 400 !important;
  color: gray;
}

.upcoming-events-card-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;

  width: 100%;
}

.upcoming-events-card-time-supporting-text {
  width: 100%;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #f04438;
}

.upcoming-events-card-time-supporting-text-gray {
  width: 100%;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #667085;
}

.upcoming-events-card-heading-content {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 12px;

  width: 100%;
}

.upcoming-events-card-heading-and-icon {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px;
  gap: 12px;

  width: 100%;
}

.upcoming-events-card-check-icon {
  width: 64px;
  height: 64px;
  border-radius: 10px;
}

.default-profile-image {
  width: 48px;
  height: 48px;
  border-radius: 10px;
}

.upcoming-events-card-supporting-text-and-accordion {
  display: flex;
  flex-direction: row;

  padding: 0;
  gap: 30px;
}

.upcoming-events-card-text-and-supporting-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
}

.upcoming-events-card-author {
  width: 100%;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  color: #6941c6;
}

.upcoming-events-card-name {
  width: 100%;

  cursor: pointer;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #101828;
}

.upcoming-events-card-supporting-text {
  width: 100%;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #667085;
}

.upcoming-events-card-go-to-conversation-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px;

  width: 126px;
  height: 36px;

  border-radius: 8px;

  background: #7f56d9;

  border: 1px solid #7f56d9;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #ffffff;
}

.upcoming-events-card-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;

  width: 100%;
  height: 53px;
}

.upcoming-events-card-actions-divider {
  width: 100%;
  height: 1px;

  background: #e4e7ec;
}

.upcoming-events-card-actions-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 24px;
  gap: 16px;

  width: 100%;
  height: 52px;
}

.upcoming-events-card-actions-details-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 46px;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #6941c6;

  border: none;
  background: none;
}

.upcoming-events-card-actions-frame1039 {
  display: flex;

  justify-content: flex-end;

  gap: 16px;

  width: 310px;
  height: 20px;
}

.upcoming-events-card-actions-suggest-new-appointment-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 179px;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #6941c6;

  border: none;
  background: none;
}

@media screen and (max-width: 1060px) {
  .upcoming-events-card-go-to-conversation-button {
    font-size: 11px;
  }

  .upcoming-events-card-text-and-supporting-text {
    width: 50%;
  }

  .upcoming-events-card-actions-details-button,
  .upcoming-events-card-author,
  .upcoming-events-card-supporting-text,
  .upcoming-events-card-time-supporting-text-gray,
  .upcoming-events-card-time-supporting-text {
    font-size: 12px;
  }

  .upcoming-events-card-name {
    font-size: 14px;
  }

  .upcoming-events-card-check-icon {
    width: 56px;
    height: 56px;
  }
}
