@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.modal {
  padding: 0px;

  display: block !important;
  max-height: 100%;

  background: none;
}

.box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 32px;

  width: 400px;
  height: 415px;

  background: #ffffff;

  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1),
    0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  border-radius: 12px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 32px;

  width: 352px;
  height: 291px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 24px;

  width: 352px;
  height: 216px;
}

.featured-icon {
  width: 56px;
  height: 56px;

  background: #f4ebff;

  border: 10px solid #f9f5ff;
  border-radius: 28px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.mail {
  width: 28px;
  height: 28px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.text-and-supporting-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  width: 352px;
  height: 136px;
}

.text {
  width: 352px;
  height: 76px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;

  margin: 0;

  text-align: center;

  color: #101828;
}

.supporting-text {
  width: 352px;
  height: 48px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  text-align: center;

  color: #667085;
}

.row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 4px;

  width: 352px;
  height: 43px;
}

.row-text {
  // width: 209px;
  // height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  margin: 0;

  color: #667085;
}

.row-button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  // width: 185px;
  // height: 20px;
}

.row-button-base {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;

  border: none;
  background: none;

  // width: 185px;
  // height: 20px;
}

.row-button-text {
  // width: 185px;
  // height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  margin: 0;

  color: #6941c6;
}

.confirm-button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 170px;
  height: 44px;

  border-radius: 8px;
}

.confirm-button-base {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 170px;
  height: 44px;

  background: #7f56d9;

  border: 1px solid #7f56d9;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.confirm-button-text {
  width: 62px;
  height: 24px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  margin: 0;

  color: #ffffff;
}
