@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.lawp-time-interval-view-container {
  width: 570px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.lawp-time-interval-view-day-section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 10px;

  .MuiSvgIcon-root {
    color: #344054;
  }
}

.lawp-time-interval-view-checkbox-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 250px;
  margin-top: 30px;
  p {
    /* Text */

    width: 52px;
    height: 6px;
    margin-left: 10px;
    /* Text sm/Medium */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;

    /* identical to box height, or 143% */

    /* Gray/700 */
    color: #344054;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
  }
}

.lawp-time-interval-view-intervals-list {
  display: flex;
  flex-direction: column;
}

.lawp-time-interval-view-textfields-and-icon-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  margin-top: 16px;
}

.lawp-time-interval-view-time-textfields-container {
  width: 273px;
}

.lawp-time-interval-view-divider {
  display: block;
  width: 100%;
  border: 1px solid #e4e7ec;
}

.lawp-time-interval-view-add-remove-icon {
  margin-top: 20px !important;
  margin-left: 10px !important;
  width: 30px !important;
  height: 30px !important;
}

.lawp-time-interval-view-disabled-day-container {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  height: 75px;

  p {
    // width: 85px;
    // height: 20px;
    margin-top: 25px;
    /* Text sm/Medium */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    // line-height: 20px;

    /* identical to box height, or 143% */

    /* Gray/700 */
    color: #344054;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
  }
}

@media (max-width: 1060px) {
  .lawp-time-interval-view-container {
    width: 100%;
  }
}
