@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.lawp-delete-account-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;

  border: none;
  background: none;

  width: 119px;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;

  color: #6941c6;
}

.lawp-delete-account-modal {
  padding: 0px;

  display: block !important;
  max-height: 100%;
  // overflow-y: auto;

  background: rgba(52, 64, 84, 0.6);

  backdrop-filter: blur(16px);
}

.lawp-delete-account-modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lawp-delete-account-modal-snackbar {
  width: 100% !important;
  background-color: #7f56d9 !important;
  font-family: $fontFamily !important;
}
