@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.delete-modal-parent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  // position: relative;
  // width: 1440px;
  // height: 960px;

  //  background: rgba(52, 64, 84, 0.6);
  /* Background blur/md */
  // z-index: 300;
  // backdrop-filter: blur(90%) ;
  //  opacity: 0.5;
}

$checkboxSize: 16px;
$checkboxRadius: 4px;

.add-comment-success-modal {
  padding: 0px;

  display: block !important;
  max-height: 100%;
  overflow-y: auto;
  //   margin-left: 32%;
  background: rgba(52, 64, 84, 0.6);

  backdrop-filter: blur(16px);
}

.add-comment-success-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  gap: 32px;

  width: 512px;
  height: 288px;

  background: #ffffff;

  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1),
    0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  border-radius: 12px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 20px;

  width: 432px;
  height: 104px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.featured-icon {
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;

  background: #d1fadf;

  border: 10px solid #ecfdf3;
  border-radius: 28px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.check-circle {
  width: 28px;
  height: 28px;
}

.text-and-supporting-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;

  width: 432px;
  height: 28px;
  margin-bottom: 32px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.text {
  width: 432px;
  height: 28px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;

  text-align: center;
  margin: 0;

  color: #101828;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.supporting-text {
  width: 432px;
  height: 20px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  text-align: center;

  color: #667085;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.success-modal-actions {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  width: 464px;
  height: 44px;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.success-button {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 464px;
  height: 44px;

  background: #7f56d9;

  border: 1px solid #7f56d9;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  flex: none;
  order: 0;
  flex-grow: 1;
}

.success-button-text {
  // width: 126px;
  height: 24px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 0;

  color: #ffffff;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.send-data-button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 348px;
  height: 20px;

  flex: none;
  order: 2;
  flex-grow: 0;

  background: #7f56d9;

  border: 1px solid #7f56d9;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  flex: none;
  order: 0;
  flex-grow: 1;
}

.send-data-text {
  width: 118px;
  height: 24px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 0;

  color: #ffffff;

  flex: none;
  order: 0;
  flex-grow: 0;
}
.no-delete-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 24px;

  width: 360px;
  height: 44px;

  border-radius: 12px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}
.button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 360px;
  height: 44px;

  border-radius: 8px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.button-base {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 360px;
  height: 44px;

  /* Primary/600 */

  background: #7f56d9;
  /* Primary/600 */

  border: 1px solid #7f56d9;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.button-text {
  width: 184px;
  height: 24px;
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 0;

  color: #ffffff;

  flex: none;
  order: 0;
  flex-grow: 0;
}
.delete-buttons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  grid-gap: 8px;
  gap: 8px;
}

.delete-modal-subtext {
  width: 432px;
  height: 20px;

  /* Text sm/Normal */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: center;

  /* Gray/500 */
  margin-bottom: 32px;

  color: #667085;
}

@media screen and (max-width: 1230px) {
}
