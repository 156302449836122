.lawp-lawyer-section-card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 360px;
  height: 480px;

  flex: none;
  order: 1;
  flex-grow: 1;
  margin: 0px 16px;

  position: relative;

  // cursor: pointer;

  &:hover {
    box-shadow: 0 0 22px rgba(33, 33, 33, 0.2);
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .info-container {
    position: absolute;
    bottom: 15px;
    // height: 33%;
    height: 34%;
    width: 90%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 20px;

    background: rgba(255, 255, 255, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.5);

    backdrop-filter: blur(12px);

    .css-ryrseu-MuiRating-root {
      color: #fff;
      font-size: 20px;
      margin-top: -5px;
    }

    .name {
      /* Display sm/Semibold */
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      // line-height: 50px;
      min-height: 50px;
      width: 100%;

      /* identical to box height, or 127% */

      /* White */
      color: #ffffff;

      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      // display: -webkit-box !important;
      // -webkit-line-clamp: 1 !important;
      // -webkit-box-orient: vertical;
      // white-space: normal;
      // margin-bottom: 0px;
    }

    .specialist {
      /* Text lg/Semibold */
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;

      min-height: 30px;
      /* identical to box height, or 156% */

      /* White */
      color: #ffffff;

      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      // display: -webkit-box !important;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      white-space: normal;
      margin-bottom: 0px;
      // border: 1px solid blue;
    }

    .law-firm {
      width: 100%;

      /* Text md/Normal */
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 15px;

      /* identical to box height, or 150% */

      /* White */
      // color: #ffffff;
    }
  }
}

.active {
  cursor: grabbing;
}
