@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.lawp-legal-directory-details-page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 24px;
  width: 100%;

  h1 {
    font-family: $fontFamily;
    font-size: 28px;
    font-weight: 500;
    color: #101828;
    margin: 24px 0;
  }

  h2 {
    font-family: $fontFamily;
    font-size: 24px;
    font-weight: 700;
    color: #667085;
    margin: 24px 0;
  }

  a {
    font-family: $fontFamily;
    font-size: 16px;
    font-weight: 400;
    color: #6941c6;
    text-decoration: none;
    margin: 8px 0;

    &:hover {
      text-decoration: underline;
    }
  }

  .lists-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin: 24px 0;

    flex-wrap: wrap;
  }

  .list-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      width: 100%;

      li {
        width: 25%;
      }

      li::marker {
        color: #6941c6;
      }
    }
  }
}
