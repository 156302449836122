.faqCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px 32px 0px 0px;
  position: static;
  width: 1281px;
  height: 126px;
  left: 0px;
  top: 0px;

  background: #ffffff;
  border-radius: 16px;
}

.componentcard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;

  position: static;
  width: 394.67px;
  left: 0px;
  top: 0px;
  margin-bottom: 50px;
}

.componentTextCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  position: static;
  width: 394.67px;
  left: 0px;
  top: 0px;
}

.componentCardOneTextField {
  position: static;
  width: 394.67px;
  left: 0px;

  /* Text md/Normal */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Gray/500 */

  color: #667085;
}

.componentCardOneTitle {
  position: static;
  width: 394.67px;
  /* height: 60px; */
  left: 0px;
  top: 0px;

  /* Text xl/Medium */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  /* or 150% */

  /* Gray/900 */

  color: #101828;
}

.pictureDesign {
  display: flex;
  justify-content: center;
  align-items: center;

  position: static;
  width: 48px;
  height: 48px;

  background: #f4ebff;
  border-radius: 24px;
}

.innerPicture {
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  width: 20px;
  height: 20px;
  aspect-ratio: 1/1;
}

@media only screen and (max-width: 1060px) {
  .componentcard,
  .componentTextCard,
  .componentCardOneTextField {
    width: 100%;
  }
}

@media only screen and (max-width: 640px) {
  .componentTextCard,
  .componentcard,
  .componentCardOneTitle {
    width: 100%;
  }
}
