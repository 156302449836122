@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.container {
  display: block;
  width: 95.5%;
  // width: 100%;
  margin: auto;
  // margin-bottom: 120px;
}

.edit-lawyer-top-menu {
  margin-top: 0px;
}

.EditSaveChangesSection {
  margin-top: 68px;
  margin-bottom: 30px;
  padding: 30px 30px 30px 60px;
}

.EditSaveChangesSectionBottom {
  margin-top: 10px;
  margin-bottom: 30px;
}

.Divider {
  width: 100%;
  height: 1px;

  background: #e4e7ec;

  margin: 50px 0px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.update-details-divider {
  width: 100%;
  height: 1px;

  background: #e4e7ec;

  margin: 20px 0px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

@media screen and (max-width: 1480px) {
  .header-text {
    width: 90%;
  }

  .pageHeader,
  .buttonsContainer {
    width: 100%;
  }
}
