@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.ql-editor h1 {
  font-size: 32px;
}

.ql-editor h2 {
  font-size: 26px;
}

.ql-editor h3 {
  font-size: 22px;
}

.ql-editor h4 {
  font-size: 18px;
}

.ql-editor h5 {
  font-size: 14px;
}

.ql-editor h6 {
  font-size: 12px;
}

.quillTab {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;

  width: 512px;
}

.text-editor {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;
}

.titleElement {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 512px;
  height: 20px;
  margin-top: 0px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #344054;

  margin-bottom: -11px;
}

.titleElementAbove {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 139px;
  height: 20px;
  // margin-top: 6px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #344054;

  margin-bottom: -8px;
}

.toolBarBelow {
  // margin-bottom: 12px;
}

.ql-container {
  font-size: 18px;
}

.ql-container.ql-snow {
  box-sizing: border-box;

  font-family: $fontFamily;

  display: block;

  flex-direction: row;
  align-items: center;
  // padding: 10px 14px;
  gap: 8px;

  width: 512px;
  height: auto;
  min-height: 148px;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  overflow-y: auto;
  overflow-x: hidden;
  resize: vertical;
  // resize: both;
}

.ql-editor {
  padding: 10px 12px;
  width: 100%;
  height: 100%;
  min-height: 128px;
}

.ql-snow {
  //  resize: vertical;
  // overflow-y: auto;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;
  position: absolute;
  width: 240px;
  height: 44px;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.ql-picker-label {
  display: flex !important;
  align-items: center !important;
  margin-left: 6px;
}

.ql-picker {
  width: 240px !important;
}

.ql-header {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-right: 12px;

  width: 240px;
  height: 44px;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  grid-gap: 8px;
  grid-gap: 8px;
  /* gap: 8px; */
  position: relative;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #101828;

  &:focus {
    box-shadow: none !important;
    border: 1px solid #7f56d9 !important;
  }
}

.ql-picker-item {
  //on hover, make border blue
  &:hover {
    color: #7f56d9 !important;
  }
}

.ql-snow .ql-picker.ql-size {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-right: 12px;

  width: 240px;
  height: 44px;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.ql-snow .ql-picker.ql-header {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-right: 12px;

  width: 240px;
  height: 44px;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: none;

  width: 176px;
  height: 32px;
}
.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
  width: 32px;
  height: 32px;
  gap: 4px;
  border-radius: 6px;
}
.ql-snow .ql-stroke {
  stroke: #98a2b3;
}

.ql-tooltip {
  // left: auto !important;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  //dropdown elements
}

.ql-picker {
  //on focus make border blue
  &:focus {
    box-shadow: none !important;
    border: 1px solid #7f56d9 !important;
  }

  &:hover {
    box-shadow: none !important;
    border: 1px solid #7f56d9 !important;
  }
}

// .ql-tooltip input {
//   &:focus {
//     box-shadow: none !important;
//     border: 1px solid #7f56d9 !important;
//   }
// }

.ql-size {
  &:focus {
    box-shadow: none !important;
    border: 1px solid #7f56d9 !important;
  }
}

.ql-expanded {
  border: 1px solid #7f56d9 !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  // padding: 0px;
  // gap: 8px;
  // position: relative;
  // width: 184px;
  // height: 24px;
  // width: 89px;
  // height: 24px;
  // margin-top: 2.7%;

  // font-family: 'Inter';
  // font-style: normal;
  // font-weight: 400;
  // font-size: 16px;
  // line-height: 24px;

  color: #101828;
}

.ql-picker-label {
  color: #101828 !important;
}

// .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg
// {
//     // gap: 4px !important;
//     margin-top: -9%;
// }
.ql-toolbar.ql-snow {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 12px;
  grid-gap: none;
  width: 512px;
  height: 44px;

  border: none;

  margin-bottom: -8px;

  border: none !important;
}

.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  stroke: none;
  color: #000000;
}

.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow .ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: #000000;
}
.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
  fill: #98a2b3;
}
.ql-snow.ql-toolbar button:hover .ql-fill,
.ql-snow .ql-toolbar button:hover .ql-fill,
.ql-snow.ql-toolbar button:focus .ql-fill,
.ql-snow .ql-toolbar button:focus .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: #000000;
}

.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  margin-top: -9px;
}

.parentdiv {
  //     display: flex;
  // flex-direction: column;
  // align-items: flex-start;
  // padding: 0px;
  // gap: 12px;

  // width: 512px;
  // height: 210px;
}

.button_link:focus {
  stroke: #000000;
}

.ql-link,
.ql-bold,
.ql-italic,
.ql-list {
  fill: #98a2b3;
}
.ql-link:hover,
.ql-bold:hover,
.ql-italic:hover,
.ql-list:hover,
.ql-active {
  fill: #000000;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  border-color: #ccc;
  width: 240px;
  margin-left: -1px;
  height: 44px;
}

.dropImg {
  position: relative;
  left: 25%;
  right: 25%;
  top: 37.5%;
  bottom: 37.5%;
}

.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  // display: none;
}

.dropPicture {
  position: absolute;

  bottom: 40%;
  left: 90%;
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* padding: 0px; */
  grid-gap: 8px;
  grid-gap: 8px;
  /* gap: 8px; */
  position: relative;
  /* width: 89%; */
  /* height: 55%; */
  /* margin-top: 3.2%; */
  /* margin-left: 5%; */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #101828;
}

// .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {

//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     padding: 0px;
//     grid-gap: 8px;
//     gap: 8px;
//     position: relative;
//     width: 184px;
//     height: 24px;
//     width: 89px;
//     height: 24px;
//     margin-top: 3.2%;
//     margin-left: 5%;
//     font-family: "Inter";
//     font-style: normal;
//     font-weight: 400;
//     font-size: 16px;
//     line-height: 24px;
//     color: #101828;

// }
.ql-snow .ql-picker-label {
  cursor: pointer;
  display: inline-block;
  /* height: 100%; */
  padding-left: 8px;
  padding-right: 2px;
  position: relative;
  width: 100%;
}
.ql-toolbar.ql-snow .ql-picker-label {
  border: none;
}

.ql-tooltip {
  &:focus {
    box-shadow: none !important;
    border: 1px solid #7f56d9 !important;
  }
}
