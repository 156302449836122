@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.emailCheckerSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  width: 100%;
  height: 960px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
}

.emailCheckerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 32px;
  gap: 80px;

  width: 976px;
  // height: 344px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.frame2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 80px;

  width: 360px;
  // height: 344px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 32px;

  width: 360px;
  // height: 344px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 24px;

  width: 360px;
  // height: 216px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.featuredIcon {
  width: 56px;
  height: 56px;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  background: #f4ebff;

  border: 10px solid #f9f5ff;
  border-radius: 28px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.mail {
  position: absolute;
  width: 28px;
  height: 28px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.textAndSupportingText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  width: 360px;
  // height: 136px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.profile-checker-text {
  width: 360px;
  height: 76px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  margin: 0;

  text-align: center;

  color: #101828;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.EmailCheckerTitle {
  width: 360px;
  height: 76px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  margin: 0;

  text-align: center;

  color: #101828;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.supportingText {
  width: 360px;
  // height: 48px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0;

  text-align: center;

  color: #667085;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.sendCodeButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 24px;

  width: 360px;
  height: 44px;

  border-radius: 12px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.sendCodeButton {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 360px;
  height: 44px;

  border-radius: 8px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.sendCodeButtonBase {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 360px;
  height: 44px;

  background: #7f56d9;

  border: 1px solid #7f56d9;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  flex: none;
  order: 0;
  flex-grow: 1;
}

.sendCodeButtonText {
  width: 200px;
  height: 24px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 0;

  color: #ffffff;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.loginButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 180px;
  height: 20px;

  flex: none;
  order: 2;
  flex-grow: 0;
}

.loginButtonBase {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;

  border: 0;
  background: none;

  width: 180px;
  height: 20px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.loginButtonText {
  width: 152px;
  height: 20px;

  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;

  color: #667085;

  flex: none;
  order: 1;
  flex-grow: 0;
}

@media screen and (max-width: 1060px) {
  .emailCheckerSection {
    width: unset;
    // height: unset;

    // padding-top: 200px;
    // padding-bottom: 279px;
  }

  .emailCheckerContainer {
    width: unset;
    height: unset;
    padding-bottom: 600px;
  }

  .text {
    font-size: 1.5rem;
  }

  .supportingText,
  .loginButtonText {
    font-size: 12px;
  }
  .sendCodeButtonText {
    font-size: 14px;
  }

  .sendCodeButton,
  .sendCodeButtonBase,
  .sendCodeButtonContainer {
    font-size: 12px;
  }

  .signUp {
    justify-content: center;
    width: unset;
    height: 750px;
  }

  .rightSection {
    width: unset;
    height: unset;
  }
}

@media screen and (max-width: 1480px) and (min-width: 1060px) {
  .emailCheckerSection {
    width: unset;
  }

  .emailCheckerContainer {
    width: 100%;
  }
}

@media screen and (min-width: 1480px) {
  .emailCheckerSection {
    width: unset;
  }

  .emailCheckerContainer {
    width: 100%;
  }
}
