.lawp-simple-text-field-container {
  display: block;
  width: 100%;

  p {
    font-family: 'Inter';
    font-size: 14px;
  }

  .title {
    font-weight: 500;
    color: '#344054';
  }

  .form-control {
    width: 100%;
    margin-top: -12px;
  }

  .characters-left {
    font-weight: 400;
    line-height: 20px;
    color: #667085;
  }

  .error-message {
    font-weight: 400;
    line-height: 20px;
    color: #ff0000;
    margin-top: 5px;
    margin-bottom: 0;
  }

  .MuiInputBase-multiline {
    padding: 0;
  }

  .MuiInputBase-input {
    border-radius: 8px !important;
    background-color: white !important;
    border: 1px solid #d0d5dd !important;
    font-size: 16px !important;
    padding: 10px 12px !important;
    font-family: 'Inter' !important;
    height: 24px;
    transition: border-color 0.3s ease-in-out;

    &:focus {
      border: 1px solid #7f56d9 !important;
    }
  }

  .error {
    .MuiInputBase-input {
      border: 1px solid #ff0000 !important;
    }
  }

  .phone-input {
    transition: border-color 0.3s ease-in-out;
    &:focus {
      box-shadow: none !important;
      border: 1px solid #7f56d9 !important;
    }
  }

  .phone-input-error {
    box-shadow: none !important;
    border: 1px solid #ff0000 !important;
  }

  .disabled {
    .MuiInputBase-input {
      background-color: lightgray !important;
      border: 1px solid #d0d5dd !important;
      color: #667085 !important;
    }

    cursor: not-allowed;
  }
}
