@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$phoneBreakpoint: map-get($screen-width-breakpoints, $key: 'phone');
$tabletBreakpoint: map-get($screen-width-breakpoints, $key: 'tablet');

.lawp-review-modal-container {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 400px !important;
  //   height: 200px !important;
  background-color: white !important;
}

.lawp-review-modal-open-button {
  display: flex !important;
  align-items: center !important;
  padding: 0;
  border: 0;
  background-color: #fff !important;
}

.lawp-review-modal-open-button-text {
  font-size: 14px !important;
  font-family: $fontFamily !important;
  font-weight: 500 !important;
  color: #6941c6 !important;
  box-shadow: none !important;

  @media (max-width: 1230px) {
    font-size: '14px';
  }
}

.lawyerInfoCardOuterContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 95%;

  padding: 72px 72px 113px 72px;

  // margin-bottom: 40px;

  @media (max-width: $tabletBreakpoint) {
    align-items: flex-start;
    justify-content: space-evenly;
    gap: 32px;
    padding: 0;
  }

  @media (max-width: $phoneBreakpoint) {
    gap: 64px;
  }
}

.lawyerInfoCardInnerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 95%;

  padding: 32px;

  // margin-bottom: 40px;

  @media (max-width: $tabletBreakpoint) {
    align-items: flex-start;
    justify-content: space-evenly;
    gap: 32px;
    padding: 0;
  }

  @media (max-width: $phoneBreakpoint) {
    gap: 64px;
  }
}

.shortDescriptionContainer {
  width: 30%;
  gap: 18px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: $tabletBreakpoint) {
    width: 47%;
  }

  @media (max-width: $phoneBreakpoint) {
    width: 100%;
  }
}

.specialistText {
  width: 100%;

  font-family: $fontFamily;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #6941c6;
}

.lawyerNameText {
  width: 100%;
  // height: 20px;
  // margin: 8px 0px;
  color: #101828;
  font-size: 18px;
  font-family: $fontFamily;
  font-weight: 500;
}

.descriptionText {
  width: 100%;
  // height: 100%;
  // margin: 8px 0px;
  color: #667085;
  font-size: 16px;
  font-weight: 400;
  font-family: $fontFamily;
}

.areasOfLawContainer {
  width: 100%;
  // height: 24px;
  display: flex;
  flex-flow: row wrap;
  // gap: 10px;
  align-items: flex-start;
  // margin: 24px 0px;
  align-self: stretch;
}

.areasOfLawContent {
  width: auto;
  height: 24px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  font-weight: 500;
  justify-content: center;
  font-family: Inter;
  font-size: 14px;
  background: #f9f5ff;
  border-radius: 16px;
  align-items: flex-start;
  align-self: stretch;
  mix-blend-mode: multiply;
  color: #6941c6;
}

.lawyerDetailsContainer {
  width: 34%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 0;
  gap: 32px;

  @media (max-width: $tabletBreakpoint) {
    width: 49%;
  }

  @media (max-width: $phoneBreakpoint) {
    width: 100%;
  }
}

.lawyerLocationAndWebsiteDetailsContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 32px;
  width: 100%;
  align-self: stretch;
}

.lawyerLocationDetailsContainer {
  position: static;
  width: 100%;
  height: 52px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  left: 0;
  top: 0;

  padding: 0px;
  gap: 8px;
}

.infoTitle {
  font-size: 14px;
  color: #667085;
  font-family: $fontFamily;
  line-height: 20px;
  font-weight: 500;
  margin: 0px;
}

.infoTextLink {
  font-size: 16px;
  font-family: $fontFamily;
  font-weight: 500;
  line-height: 24px;
  color: #6941c6;
  margin: 0px;

  text-decoration: none;
  &:hover {
    color: #6941c6;
    text-decoration: underline;
  }
}

.lawyerLocationContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  gap: 8px;

  width: 100%;
}

.flagImage {
  width: 20px;
  height: 20px;
}

.locationText {
  width: 100%;
  height: 24px;

  font-family: $fontFamily;
  font-weight: 500;
  line-height: 24px;
  font-size: 16px;
  color: #344054;
}

.lawyerWebsiteDetailsContainer {
  width: 100%;
  height: 52px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 8px;
  padding: 0;
  position: static;
}

.lawyerWebsiteContainer {
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.lawyerWebsiteText {
  height: 24px;
  font-family: $fontFamily;
  top: 2px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #6941c6;
}

.websiteLink,
.websiteLink:hover {
  color: #6941c6;
  text-decoration: none;
}

.lawyerAppointmentAndEmailDetailsContainer {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: flex-start;

  gap: 32px;
  padding: 0;

  align-self: stretch;
}

.lawyerAppointmentDetailsContainer {
  width: 100%;
  height: 52px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  order: 0;
  flex-grow: 1;

  gap: 8px;

  position: static;
}

.lawyerAppointmentContainer {
  width: 100%;

  display: flex;
  flex-direction: row;

  padding: 0px;

  color: #6941c6;
}

.lawyerAppointmentText {
  left: 0;
  font-size: 16px;
  font-family: $fontFamily;
  font-weight: 500;
  line-height: 24px;
}

.appointmentLink {
  padding: 0;
  border: 0;
  background-color: #fff;
  font-weight: 500;
  padding: 0px;
  color: #6941c6;
}

.appointmentButton {
  // margin-right: 40px;

  width: 100%;
  height: 100%;

  background-color: #7f56d9 !important;
  color: #fff !important;
  font-size: 14px !important;
  text-transform: none !important;
  font-family: $fontFamily !important;
  font-style: normal;
  border-radius: 8px !important;
}

.appointmentButtonHref {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;

  width: 100%;
  height: 100%;

  background-color: #7f56d9 !important;
  color: #fff !important;
  font-size: 14px !important;
  text-transform: none !important;
  font-weight: 500;
  font-family: $fontFamily !important;
  font-style: normal;
  border-radius: 8px !important;
}

.requestAppointmentButton {
  // margin-right: 40px;

  width: 100%;
  height: 100%;

  background-color: #ffffff !important;
  color: #7f56d9 !important;
  border: 1px solid #7f56d9 !important;
  font-size: 14px !important;
  text-transform: none !important;
  font-family: $fontFamily !important;
  font-style: normal;
  border-radius: 8px !important;
}

.requestAppointmentButtonHref {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;

  width: 100%;
  height: 100%;

  background-color: #ffffff !important;
  color: #7f56d9 !important;
  border: 1px solid #7f56d9 !important;
  font-size: 14px !important;
  font-weight: 500;
  text-transform: none !important;
  font-family: $fontFamily !important;
  font-style: normal;
  border-radius: 8px !important;
}

.lawyerEmailDetailsContainer {
  width: 100%;
  height: 52px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 0;
  gap: 8px;

  order: 1;
  flex-grow: 1;
  position: static;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.lawyerEmailText {
  width: 100%;
  height: 24px;

  display: flex;
  font-family: $fontFamily;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #6941c6;
}

.lawyerReviewAndClaimAccountDetailsContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  padding: 0;
  gap: 32px;

  width: 100%;
  height: 52px;

  align-self: stretch;
}

.lawyerReviewDetailsContainer {
  width: 100%;
  height: 52px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 0;
  gap: 8px;

  order: 0;
  flex-grow: 1;
  position: static;
}

.lawyerClaimAccountDetailsContainer {
  width: 100%;
  height: 52px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 8px;

  order: 1;
  flex-grow: 1;
  position: static;
}

.lawyerClaimAccountContainer {
  display: flex;
  flex-direction: row;

  font-family: $fontFamily;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #6941c6;

  width: 100%;
}

// .claimAccountLink {
//   padding: 0;
//   border: 0;
//   background-color: #fff;
//   font-weight: 500;
//   padding: 0px;
//   color: #6941c6;
// }

.specialistTextParagraph {
  font-family: $fontFamily;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #6941c6;
  margin: 0;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.locationTextLink {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.claimAccountButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  margin-top: -5px;
  color: white;
  font-family: Inter;
  font-weight: 500;
  font-size: 14px;
  background-color: #6941c6;
  text-transform: none;
  border-radius: 9px;
  border: none;
  cursor: pointer;
}

.claimAccountButton:hover {
  background-color: #6941c6;
}

.lawyerAppointmentBoxContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 28.2667px;
  // gap: 37.69px;
  gap: 9.42px;

  width: 27%;
  // height: 220px;

  background: #f9fafb;
  box-shadow: 6px 6px 24px rgba(105, 65, 198, 0.1);
  border-radius: 9.42222px;
  // align-self: stretch;

  @media (max-width: $tabletBreakpoint) {
    width: 100%;
  }

  @media (max-width: $phoneBreakpoint) {
    width: 100%;
  }
}

.lawyerAppointmentTitleAndTextContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 9.42px;

  width: 100%;
  // height: 90.42px;
}

.lawyerAppointmentTitleText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 100%;
  // height: 48px;

  margin: 0;

  font-family: $fontFamily;
  font-weight: 500;
  font-size: 21.2px;
  line-height: 24px;
  color: #101828;
}

.lawyerAppointmentTextContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 100%;
  // height: 48px;

  margin: 0;

  font-family: $fontFamily;
  font-weight: 400;
  font-size: 16.4889px;
  line-height: 24px;
  color: #667085;
}

.lawyerAppointmentButtonContent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  // width: 200.67px;
  height: 47.11px;
}

@media screen and (max-width: 1480px) {
  .shortDescriptionContainer {
    // width: 100%;
    justify-content: center;
    align-items: center;

    // border: 1px solid green;
  }

  .areasOfLawContainer {
    position: relative;
    margin: 0 auto;
  }

  .lawyerAppointmentTitleText {
    font-size: 18px;
  }
}
