@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.edit-client-profile-information-container {
  // width: 100%;
  width: 512px;
}

.edit-client-profile-information-one-third-section {
  display: flex;
  width: 45%;
  justify-content: center;
  align-items: flex-end;
}

// .edit-client-profile-information-one-third-section {
//   display: flex;
//   width: 55%;
//   justify-content: center;
//   align-items: flex-end;
// }

.edit-client-profile-information-full-section {
  display: flex;
  width: 100%;

  margin-top: 20px;
}

.edit-client-profile-information-phone-section {
  display: flex;
  width: 100%;
  height: 96px;
  // margin-top: -20px;
}

.edit-client-profile-information-phone-number-input,
.edit-client-profile-information-city-input,
.edit-client-profile-information-zip-code-input,
.edit-client-profile-information-address-input {
  display: flex;
  // flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  // width: 360px;
  width: 100%;
  margin-top: 7px;
  margin-bottom: 7px;
}

.edit-client-profile-information-input-with-label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 6px;

  width: 100%;
  margin-right: 10px;
  height: auto !important;
  

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.ledit-client-profile-information-abel {
  font-family: $fontFamily;
  font-weight: 500;
  font-size: 14px;
  color: rgb(52, 64, 84);
}

.edit-client-profile-information-location {
  display: flex;
  width: 100%;

  flex-direction: column;
}

.edit-client-profile-information-input {
  border-radius: 8px;
  position: relative;
  background-color: white;
  border: 1px solid #d0d5dd;
  font-size: 16px;

  width: 100%;

  padding: 10px 12px;

  font-family: $fontFamily;
}

@media screen and (max-width: 1060px) {
  .edit-client-profile-information-container {
    width: 100%;
  }
}
