@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$phoneBreakpoint: map-get($screen-width-breakpoints, $key: 'phone');
$tabletBreakpoint: map-get($screen-width-breakpoints, $key: 'tablet');

.lawp-article-blog-page-details-blog-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  padding: 0px;

  position: relative;

  height: auto;

  /* White */

  background: #ffffff;
  margin-bottom: 50px;

  .ql-editor {
    font-family: 'Inter';
  }

  .go-back-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
    margin-top: 64px;

    p {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 17px;
      color: #7f56d9;
      margin-bottom: 0px;
      margin-left: 10px;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
        text-decoration-color: #7f56d9;
      }
    }

    .icon {
      color: #7f56d9;
    }
  }

  .container-tag {
    display: flex;

    // width: 200px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    padding: 4px 12px 4px 4px;

    background-color: #f9f5ff;
  }

  .article-type-tag {
    background-color: white;
    border-radius: 15px;
    padding: 3px 8px 3px 8px;

    font-family: $fontFamily;
    font-style: normal;
    @include get-font-weight($key: 'Medium');
    font-size: 12px;
    line-height: 18px;
    color: $primaryColor;
    margin-right: 8px;
  }

  .article-read-time {
    font-family: $fontFamily;
    font-style: normal;
    @include get-font-weight($key: 'Medium');
    color: $primaryColor;
    font-size: 12px;
    line-height: 18px;
  }

  .blog-page-header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 96px;
    gap: 64px;

    width: 90%;
    height: auto;

    /* White */

    background: #ffffff;
  }
  .blog-page-header-container-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 32px;
    gap: 32px;

    width: 100%;
  }
  .blog-page-header-container-title-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 40px;

    width: 100%;
  }

  .title-content-header-support {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 24px;

    width: 100%;
  }

  .content-header-subhead {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;

    width: 100%;
  }

  .content-subhead {
    width: 100%;
    // height: 24px;

    /* Text md/Semibold */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    text-align: center;

    /* Primary/600 */

    color: #7f56d9;
  }

  .content-head {
    width: 100%;

    /* Display lg/Bold */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    /* or 125% */

    text-align: center;
    letter-spacing: -0.02em;

    /* Gray/900 */

    color: #101828;

    word-wrap: break-word;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 24px;

    height: auto;

    width: 720px;

    @media (max-width: $phoneBreakpoint) {
      width: 100%;
    }
  }

  .support-text {
    width: 100%;

    /* Text xl/Normal */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    /* or 150% */

    text-align: center;

    /* Gray/500 */

    color: #667085;

    word-wrap: break-word;
  }
  .tags-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: 100%;

    flex-wrap: wrap;
  }
  .blog-page-header-container-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 32px;
    gap: 64px;
    width: 100%;
  }

  .blog-page-header-container-image-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 48px;

    width: 100%;
  }

  .image {
    width: 100%;

    aspect-ratio: auto;
  }

  .content-text {
    width: 90%;

    font-family: $fontFamily;
    font-size: 18px;

    p {
      margin: 0;
      color: #667085;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: $fontFamily;
      color: #667085;
    }

    a {
      color: #7f56d9;
      text-decoration: none;
    }
  }
}
