@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$phoneBreakpoint: map-get($screen-width-breakpoints, $key: 'phone');
$tabletBreakpoint: map-get($screen-width-breakpoints, $key: 'tablet');

.settings-menu-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 10px;

  width: 100%;

  background: #fffefe;
  // margin-bottom: 40px;

  .settings-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px 30px 30px 60px;
    gap: 20px;

    width: 100%;

    .title-text-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 4px;

      .title-container {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 38px;

        color: #101828;
      }
      .text-container {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        color: #667085;
      }
    }
    .settings-menu-buttons-container {
      display: block;

      width: 100%;
      .settings-menu-client-stack {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 8px;
        @media (max-width: $tabletBreakpoint) {
          display: grid !important;
          grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
          gap: 15px;
        }
      }
      .settings-menu-lawyer-stack {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 8px;
        @media (max-width: $tabletBreakpoint) {
          display: grid !important;
          grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
          gap: 15px;
        }
      }
    }
  }
  .settings-menu-divider {
    width: 100%;
    height: 1px;

    background: #e4e7ec;
  }
}

.buttonInactive {
  margin: 0 !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 8px 12px !important;

  // width: 59px;
  width: 111px !important;
  height: 36px !important;

  border-radius: 6px !important;

  color: #667085 !important;
  // padding: 10px 5px 10px 5px !important;
  text-transform: none !important;
  border: 0px !important;
  font-family: $fontFamily !important;
  font-size: 14px !important;
  line-height: 20px !important;
  @include get-font-weight($key: 'Medium');
  background-color: #fffefe !important;

  &:hover {
    color: #344054 !important;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
      0px 1px 2px rgba(16, 24, 40, 0.06) !important;
  }
}

.buttonActive {
  width: 111px !important;
  height: 36px !important;
  margin: 0 !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 6px !important;
  color: #344054 !important;
  padding: 8px 12px !important;
  text-transform: none !important;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06) !important;
  border: 0px !important;
  font-family: $fontFamily !important;
  font-size: 14px !important;
  line-height: 20px !important;
  @include get-font-weight($key: 'Medium');
  background-color: #fffefe !important;
}

@media screen and (max-width: 1060px) {
  .buttonActive {
    height: 100%;
  }

  .lawyerStack {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    gap: 15px;
  }

  .clientStack {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    gap: 15px;
  }

  .buttonsContainer,
  .pageHeader {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 1480px) {
  .header-text {
    width: 90%;
  }

  .pageHeader,
  .buttonsContainer {
    width: 100%;
  }
}
