@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.pac-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1000000;

  font-family: $fontFamily;
  ::after {
    display: none;
    z-index: 1000000;
  }
}

.pac-item {
  border: none;

  font-size: 13px;
}

.pac-item-query {
  font-size: 15px;
}

.pac-container:after {
  background-image: none !important;
  height: 0px;
}

.error-message {
  // height: 0;
  font-weight: 400;
  line-height: 20px;
  font-size: 14px;
  color: #ff0000;
  margin-top: 5px;
}

.error {
  .MuiInputBase-input {
    border: 1px solid #ff0000 !important;
  }
}
