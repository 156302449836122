@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$phoneBreakpoint: map-get($screen-width-breakpoints, $key: 'phone');
$tabletBreakpoint: map-get($screen-width-breakpoints, $key: 'tablet');

ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

ul li {
  color: black;
}

ol > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

ol > li:before {
  content: counters(item, '.') '. ';
  display: table-cell;
  padding-right: 0.6em;
}

li ol > li {
  margin: 0;
}

li ol > li:before {
  content: counters(item, '.') ' ';
}

.lawp-cookies-page-list-element {
  font-size: 30px;
}

.lawp-cookies-page-container {
  // margin-top: 96px;
  display: flex;
  flex-direction: column;

  align-items: center;

  white-space: pre-wrap;

  @media (max-width: $phoneBreakpoint) {
    width: 90%;
  }
}

.lawp-cookies-page-link {
  /* Text md/Medium */
  text-decoration: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  // font-size: 16px;
  line-height: 24px;

  /* identical to box height, or 150% */

  /* Primary/700 */
  color: #6941c6;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: #6941c6;
  }
}

.lawp-cookies-page-faq-container {
  display: block;
  width: 50%;
  margin: auto;
  white-space: pre-wrap;

  padding-top: 40px;

  @media (max-width: $tabletBreakpoint) {
    width: 75%;
  }

  @media (max-width: $phoneBreakpoint) {
    width: 90%;
  }
}

.lawp-cookies-page-header-horizontal-tabs {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px;
  gap: 8px;

  // width: 425px;
  // height: 56px;

  margin-bottom: 50px;

  background: #f9fafb;

  border: 1px solid #f2f4f7;
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.lawp-cookies-page-horizontal-tabs-lawyer {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;

  width: 180px;
  height: 44px;

  border-radius: 6px;

  background: #f9fafb;

  border: 0px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  color: #667085;
}
.lawp-cookies-page-horizontal-tabs-lawyer-active {
  background: #ffffff;

  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
}

.lawp-cookies-page-horizontal-tabs-client {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;
  border: 0;
  cursor: pointer;

  // width: 226px;
  width: 180px;
  height: 44px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Gray/700 */

  color: #344054;

  background: #f9fafb;
}

.lawp-cookies-page-horizontal-tabs-client-active,
.active {
  background: #ffffff !important;

  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 6px;
}

.lawp-cookies-page-horizontal-tabs-lawyer-text {
  width: 151px;
  height: 24px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #667085;
}

.lawp-cookies-page-horizontal-tabs-client-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;

  width: 226px;
  height: 44px;
  border: none;

  background: #ffffff;

  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 6px;
  border-color: #ffffff;
}

.lawp-cookies-page-horizontal-tabs-client-text {
  // width: 136px;
  height: 24px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #667085;
}

.lawp-cookies-page-header-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  margin-bottom: 90px;
}

.lawp-cookies-page-subheader {
  font-family: $fontFamily;
  color: #7f56d9;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;

  margin-bottom: 12px;
}

.lawp-cookies-page-header {
  //styleName: Display lg/Semibold;
  font-family: $fontFamily;
  color: #101828;
  font-size: 48px;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: -0.02em;
  text-align: center;

  margin-bottom: 24px;
}

.lawp-cookies-page-description {
  font-family: $fontFamily;
  color: #667085;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
}

.lawp-cookies-page-title-block {
  width: 100%;

  font-size: 18px;
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #667085;

  margin-bottom: 1rem;
}

.lawp-cookies-page-text-block {
  width: 100%;

  font-size: 18px;
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #667085;

  margin-bottom: 1rem;
}

.lawp-cookies-page-faq-section {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;

  list-style-type: upper-roman;

  width: 100%;

  font-size: 18px;
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #667085;
}

.lawp-cookies-page-faq-section-title {
  text-align: left;
  width: 100%;

  font-size: 18px;
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
  color: #101828;

  margin-top: 48px;
  margin-bottom: 24px;
}

.lawp-cookies-page-faq-list {
  text-align: left;
  width: 100%;

  font-size: 18px;
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
  color: #101828;
}

.lawp-cookies-page-text-block-privacy {
  font-size: 18px;
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #667085;

  margin-bottom: 1rem;
}

.lawp-cookies-page-table {
  margin-top: 40px;
  // min-width: 650px !important;
  // @media (max-width: $phoneBreakpoint) {
  // max-width: 500px !important;
  // }
}

.MuiTable-root,
.MuiPaper-root {
  // min-width: 650px;
  @media (max-width: $phoneBreakpoint) {
    width: unset !important;
    max-width: 500px !important;
  }
}

.lawp-cookies-pagge-list-text {
  display: inline-block;
  font-size: 18px;
  font-weight: 400;
}
