@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$phoneBreakpoint: map-get($screen-width-breakpoints, $key: 'phone');
$tabletBreakpoint: map-get($screen-width-breakpoints, $key: 'tablet');

.actual-plan-container {
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 520px;
  height: 237px;

  background: #ffffff;

  border: 1px solid #e4e7ec;
  border-radius: 8px;

  .actual-plan-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 20px 16px 16px;
    gap: 4px;

    width: 100%;
    height: 60px;

    background: #f9f5ff;

    border: 1px solid #d6bbfb;
    border-radius: 8px 8px 0px 0px;

    .actual-plan-header-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 16px;

      width: 100%;
      height: 28px;
      .actual-plan-header-content-img-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        background: #f4ebff;

        border: 4px solid #f9f5ff;
        border-radius: 28px;
        .actual-plan-header-content-img {
          width: 13.3px;
          height: 10px;
        }
      }
      .actual-plan-header-content-text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;

        color: #53389e;
      }
    }
  }
  .actual-plan-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;

    width: 518px;
    height: 177px;

    background: #ffffff;

    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
      0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;

    .actual-plan-content-information {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 24px;
      gap: 24px;

      width: 520px;
      height: 124px;
      .details-and-price {
        display: flex;
        // flex-direction: row;
        // gap: 32px;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;

        width: 472px;
        height: 76px;
        .details {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 4px 0px 0px;
          gap: 4px;

          flex-grow: 1;
          height: 76px;
          .title {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            gap: 8px;

            height: 28px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 28px;

            color: #101828;
          }
          .text {
            height: 40px;

            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #667085;
          }
        }
        .price-container {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          align-self: flex-end;
          padding: 0px;
          gap: 2px;
          height: 60px;
          width: fit-content;
          .wrap {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px 0px 10px;
            gap: 10px;

            // width: 25px;
            height: 54px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 36px;
            line-height: 44px;

            letter-spacing: -0.02em;

            color: #101828;
          }
          .price {
            // width: 33px;

            height: 60px;

            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 48px;
            line-height: 60px;

            letter-spacing: -0.02em;
            color: #101828;
          }
          .period-wrap {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 0px 0px 7px;

            // width: 79px;
            height: 31px;

            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;

            color: #667085;
          }
        }
      }
    }
    .actual-plan-content-action {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px;

      width: 520px;
      height: 53px;
      .divider {
        width: 100%;
        height: 1px;

        background: #e4e7ec;
      }
      .action-button-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: 16px 24px;
        gap: 16px;
        cursor: pointer;
        width: 520px;
        height: 52px;
        .action-button {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 0px;
          gap: 8px;

          // width: 169px;
          height: 20px;

          .button-text {
            // width: 141px;
            height: 20px;

            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;

            color: #6941c6;
          }
          .button-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}
