@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.card-component {
  /* Auto layout */

  display: inline-block;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 384px;
  height: fit-content;

  /* White */

  background: #ffffff;
  /* Gray/200 */

  border: 1px solid #e4e7ec;
  /* Shadow/lg */

  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1),
    0px 4px 6px -2px rgba(16, 24, 40, 0.05);
  border-radius: 16px;
}

.card-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  gap: 32px;

  width: 384px;
  height: 300px;
}
.card-header-and-price {
  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  width: 320px;
  height: 156px;
}
.card-header-and-badge {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  // gap: 16px;
  gap: 10px;

  width: 320px;
  height: 28px;
}

.heading-text {
  // width: 320px;
  height: 28px;

  /* Text lg/Medium */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  /* identical to box height, or 156% */

  /* Gray/500 */

  color: #667085;
}

.badge {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  // width: 134px;
  height: 28px;

  mix-blend-mode: multiply;
}
.badge-color {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // padding: 4px 12px;
  padding: 4px 5px;

  // width: 134px;
  height: 28px;

  /* Primary/50 */

  background: #f9f5ff;
  border-radius: 16px;
}
.badge-text {
  // width: 110px;
  height: 20px;

  /* Text sm/Medium */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: center;

  /* Primary/700 */

  color: #6941c6;
}

.price-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0px;
  gap: 4px;

  width: 320px;
  height: 72px;
}
.price-text {
  height: 72px;

  /* Display xl/Semibold */
  display: inline-block;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 72px;
  /* identical to box height, or 120% */

  letter-spacing: -0.02em;

  /* Gray/900 */

  color: #101828;
}

.price-per-time {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 0px 8px;

  width: 79px;
  height: 32px;
}

.price-per-time-text {
  width: 79px;
  height: 24px;

  /* Text md/Medium */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Gray/500 */

  color: #667085;
}

.under-price-text {
  width: 320px;
  height: 24px;

  /* Text md/Normal */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Gray/500 */

  color: #667085;

  /* Inside auto layout */

  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  width: 320px;
  height: 48px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}
.button-current-subscription-base {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 8px;

  width: 320px;
  height: 48px;

  /* Primary/50 */

  background: #f9f5ff;
  /* Primary/50 */

  border: 1px solid #f9f5ff;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}
.button-current-subscription-text {
  width: 106px;
  height: 24px;

  /* Text md/Medium */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Primary/700 */

  color: #6941c6;
}
.divider {
  width: 384px;
  height: 1px;

  /* Gray/200 */

  background: #e4e7ec;
}
.card-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 32px 24px;
  gap: 24px;

  width: 384px;
}
.card-content-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 320px;
  height: 52px;
}
.card-content-header-title {
  width: 320px;
  height: 24px;

  /* Text md/Semibold */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Gray/900 */

  color: #101828;
}
.card-content-header-undertitle {
  width: 320px;
  height: 24px;

  /* Text md/Normal */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Gray/500 */

  color: #667085;
}

.card-content-check-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  width: 320px;
  height: 24px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}
.card-content-check-items-text {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 12px;
  margin-left: 32px;
  // padding: 32px 32px 12px;
  gap: 12px;

  width: 320px;
  // height: 24px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.card-content-check-items-text-check {
  width: 24px;
  height: 24px;

  /* Primary/100 */

  background: #f4ebff;
  border-radius: 12px;
}
.card-content-check-items-text-checkIcon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-content-check-items-text-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 284px;
  // height: 24px;
}

.card-content-check-items-text-wrapText {
  width: 284px;
  // height: 24px;

  /* Text md/Normal */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Gray/500 */

  color: #667085;
}
.card-content-container {
  margin-top: -10px;
  margin-bottom: 40px;
}

.button-subscriptionPurchase {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 8px;

  width: 320px;
  height: 48px;

  background: #7f56d9;
  border: 1px solid #7f56d9;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.button-subscription-textpurchase {
  width: 150px;
  height: 24px;

  /* Text md/Medium */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* White */

  color: #ffffff;
}
.button-containerPurchase {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 320px;
  height: 48px;

  border-radius: 8px;
}

.downgrade-subscription-button {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 8px;

  width: 320px;
  height: 48px;

  background: #fee4e2;

  border: 1px solid #fef3f2;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #344054;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.actual-subscription-button {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 8px;

  width: 320px;
  height: 48px;

  background: #f9f5ff;
  pointer-events: none;

  border: 1px solid #f9f5ff;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #6941c6;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.update-subscription-button {
  cursor: pointer;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 8px;

  width: 320px;
  height: 48px;

  background: #7f56d9;

  border: 1px solid #7f56d9;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #ffffff;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.arrow-container {
  position: absolute;
  display: flex;
  flex-direction: row-reverse;
  left: 56%;
  top: 14.2%;
}

.arrow-container-text {
  display: flex;
  position: relative;
  margin-top: -7px;
  // left: -64%;
  font-size: 20px;
  line-height: 30px;
  font-family: $fontFamily;
  @include get-font-weight($key: 'Medium');
  color: #6941c6;
}

@media screen and (max-width: 1480px) {
  .arrow-container {
    flex-direction: row-reverse;
    left: 63.5%;
    top: 27.4%;
  }
}
