@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$phoneBreakpoint: map-get($screen-width-breakpoints, $key: 'phone');
$tabletBreakpoint: map-get($screen-width-breakpoints, $key: 'tablet');

.ContainerTag {
  position: absolute;
  bottom: 0px;
  right: 7px;

  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  // width: 200px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  padding: 4px 12px 4px 4px;

  background-color: #f9f5ff;
}

.ArticleTypeTag {
  background-color: white;
  border-radius: 15px;
  padding: 3px 8px 3px 8px;

  font-family: $fontFamily;
  font-style: normal;
  @include get-font-weight($key: 'Medium');
  font-size: 12px;
  line-height: 18px;
  color: $primaryColor;
  margin-right: 8px;
}

.ArticleReadTime {
  font-family: $fontFamily;
  font-style: normal;
  @include get-font-weight($key: 'Medium');
  color: $primaryColor;
  font-size: 12px;
  line-height: 18px;
}

.article-card-container {
  width: 384px;
  height: 500px;
  cursor: pointer;

  @media (max-width: $phoneBreakpoint) {
    // width: 100%;
  }
}

.image-container {
  position: relative;
  width: 100%;
  height: 240px;
}

.article-image {
  width: 100%;
  height: 100%;

  box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.25);

  object-fit: cover;
}

.name-and-date-paragraph {
  font-family: $fontFamily;
  @include get-font-weight($key: 'SemiBold');
  font-size: 14px;
  color: $primaryColor;
  margin-top: 32px;
}

.title-container {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  width: 100%;
  height: 64px;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.go-to-article-icon-container {
  display: flex;
  justify-content: flex-end;
  margin-right: 5px;
  width: 5%;
  height: 15px;
  cursor: pointer;
}

.go-to-article-icon {
  width: 12px;
  height: 12px;
}

.title-text {
  font-family: $fontFamily;
  @include get-font-weight($key: 'SemiBold');
  font-size: 24px;
  color: #101828;
  line-height: 32px;
  margin-bottom: 0px;

  &:hover {
    color: #101828;
  }
}

.description-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 72px;
  margin-top: 10px;
}

.description-text {
  font-family: $fontFamily;
  @include get-font-weight($key: 'Regular');
  font-size: 16px;
  color: #667085;
  line-height: 24px;
  margin-bottom: 0px;
  width: 100%;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.areas-of-law-and-overflow-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.area-of-laws-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  margin-bottom: 5px;
}

.area-of-law-text {
  font-family: $fontFamily;
  @include get-font-weight($key: 'Regular');
  color: grey;
  font-size: 14px;
  //   line-height: 20px;
  max-width: 90%;
  height: 90px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  // -webkit-box-orient: vertical;
  white-space: normal;

  @media (max-width: $phoneBreakpoint) {
    font-size: 14px;
  }
}

.area-of-law-overflow {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 35px;

  border-radius: 50%;
  background-color: #f9f5ff;
}

.area-of-law-tag-text {
  font-family: $fontFamily;
  @include get-font-weight($key: 'Medium');
  font-size: 13;
}

@media screen and (max-width: 1060px) {
  .title-text {
    font-size: 18px;
  }
}
