@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$phoneBreakpoint: map-get($screen-width-breakpoints, $key: 'phone');
$tabletBreakpoint: map-get($screen-width-breakpoints, $key: 'tablet');

.lawp-review-reports-table-container {
  width: 100%;
  overflow: hidden;

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  /* White */
  background: #ffffff;

  /* Gray/200 */
  border: 1.28014px solid #e4e7ec;
  box-shadow: 0px 5.12057px 10.2411px -2.56028px rgba(16, 24, 40, 0.1),
    0px 2.56028px 5.12057px -2.56028px rgba(16, 24, 40, 0.06);
  border-radius: 10.2411px;

  .table-head-text {
    /* Text sm/Medium */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;

    /* or 143% */

    /* Gray/900 */
    color: #101828;
  }

  .table-body-text {
    /* Text sm/Medium */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    /* or 143% */

    /* Gray/900 */
    color: #101828;
  }

  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    // go to next line if there is not enough space
    flex-wrap: wrap;
    width: 100%;
    max-width: 500px;
  }

  .table-action-button {
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 8px;
    gap: 8px;

    /* Primary/600 */
    background: #7f56d9;

    /* Primary/600 */
    border: 1px solid #7f56d9;

    /* Shadow/xs */
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;

    /* Text sm/Medium */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    /* or 143% */

    /* Gray/900 */
    color: white;
  }

  .actions-menu-text {
    /* Text sm/Medium */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    /* or 143% */

    /* Gray/900 */
    color: #101828;
  }

  .MuiTablePagination-selectLabel {
    /* Text sm/Medium */
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 0px;
    margin-bottom: 3px;
    /* or 143% */

    /* Gray/900 */
    color: #101828;
  }

  .css-16c50h-MuiInputBase-root-MuiTablePagination-select {
    /* Text sm/Medium */
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    // line-height: 0px !important;
    margin-bottom: 0px !important;

    /* or 143% */

    /* Gray/900 */
    color: #101828;
  }

  .css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input {
    margin-bottom: -4px;
  }

  .css-levciy-MuiTablePagination-displayedRows {
    /* Text sm/Medium */
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 0px;
    margin-bottom: 3px;

    /* or 143% */

    /* Gray/900 */
    color: #101828;
  }

  .css-11bfvty-MuiToolbar-root-MuiTablePagination-toolbar {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important;
  }
}

.lawp-review-reports-table-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .button {
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 8px;
    gap: 8px;

    /* Primary/600 */
    background: #7f56d9;

    /* Primary/600 */
    border: 1px solid #7f56d9;

    /* Shadow/xs */
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;

    /* Text sm/Medium */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    /* or 143% */

    /* Gray/900 */
    color: white;
  }
}
