@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.lawp-confirmation-delete-account-modal-content-actions-accept-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 352px;
  height: 24px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #6941c6;

  flex: none;
  order: 0;
  flex-grow: 1;
}

.lawp-confirmation-delete-account-modal {
  padding: 0px;

  display: block !important;
  max-height: 100%;
}

.lawp-confirmation-delete-account-modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lawp-confirmation-delete-account-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 32px;

  width: 400px;
  height: 500px;

  background: #ffffff;

  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1),
    0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  border-radius: 12px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.lawp-confirmation-delete-account-modal-stepper-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;

  width: 343px;
  height: 24px;

  flex: none;
  order: 0;
  flex-grow: 0;
}
.lawp-confirmation-delete-account-modal-check-step-icon-base {
  position: relative;
  width: 32px;
  height: 32px;

  background: #f9f5ff;
  border-radius: 16px;

  flex: none;
  order: 0;
  flex-grow: 0;
}
.lawp-confirmation-delete-account-modal-checked-icon-content {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;

  border-radius: 16px;
}
.lawp-confirmation-delete-account-modal-check-icon {
  position: absolute;
  left: 26.46%;
  right: 23.56%;
  top: 29.65%;
  bottom: 26.58%;
}
.lawp-confirmation-delete-account-modal-unselected-line {
  width: 48px;
  height: 2px;

  background: #e4e7ec;
}
.lawp-confirmation-delete-account-modal-step-icon-base {
  width: 32px;
  height: 32px;
  position: relative;

  background: #f9f5ff;
  box-shadow: 0px 0px 0px 4px #f4ebff;
  border-radius: 16px;

  flex: none;
  order: 0;
  flex-grow: 0;
}
.lawp-confirmation-delete-account-modal-selected-icon-content {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;

  border: 1.5px solid #7f56d9;

  background: #f9f5ff;
  border-radius: 16px;
}
.lawp-confirmation-delete-account-modal-selected-dot {
  position: absolute;
  width: 10px;
  height: 10px;
  left: calc(50% - 10px / 2);
  top: calc(50% - 10px / 2);
  border-radius: 50%;

  background: #7f56d9;

  z-index: 2;
}

.lawp-confirmation-delete-account-modal-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;

  width: 352px;
  height: 370px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}
.lawp-confirmation-delete-account-modal-content-text-and-support-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;

  width: 352px;
  height: 84px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}
.lawp-confirmation-delete-account-modal-content-text-container {
  width: 352px;
  height: 56px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;

  color: #101828;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.lawp-confirmation-delete-account-modal-content-support-container {
  width: 352px;
  height: 20px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #667085;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}
.lawp-confirmation-delete-account-modal-input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 6px;

  width: 352px;
  height: 180px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}
.lawp-confirmation-delete-account-modal-input-label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 6px;

  width: 352px;
  height: 154px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
}
.lawp-confirmation-delete-account-modal-label {
  width: 139px;
  height: 20px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #344054;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.lawp-confirmation-delete-account-modal-input:focus {
  outline: none !important;
  border: 1px solid #7f56d9;
}
.lawp-confirmation-delete-account-modal-input {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;

  width: 352px;
  height: 128px;

  resize: none;

  background: #ffffff;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #667085;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
}
.lawp-confirmation-delete-account-modal-hint {
  width: 352px;
  height: 20px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #667085;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.lawp-confirmation-delete-account-modal-content-actions-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  width: 352px;
  height: 80px;

  flex: none;
  order: 3;
  align-self: stretch;
  flex-grow: 0;
}
.lawp-confirmation-delete-account-modal-content-actions-cancel-button-container {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 352px;
  height: 44px;

  border-radius: 8px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.lawp-confirmation-delete-account-modal-content-actions-cancel-button {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 352px;
  height: 44px;

  background: #7f56d9;

  border: 1px solid #7f56d9;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #ffffff;

  flex: none;
  order: 0;
  flex-grow: 1;
}
.lawp-confirmation-delete-account-modal-content-actions-accept-button-container {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 352px;
  height: 24px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}
