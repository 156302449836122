@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$phoneBreakpoint: map-get($screen-width-breakpoints, $key: 'phone');
$tabletBreakpoint: map-get($screen-width-breakpoints, $key: 'tablet');

.lawp-home-page-container {
  display: flex;

  flex-direction: column;
  align-items: center;
  align-self: center;
  margin: auto;
  width: inherit;
  // width: 1440px;

  .achievements-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    width: 90%;

    flex-wrap: wrap;

    margin: 96px 0px;

    @media (max-width: $tabletBreakpoint) {
      width: 70%;
    }

    @media (max-width: $phoneBreakpoint) {
      width: 100%;
      justify-content: space-around;
    }
  }

  .video-container {
    // margin-top: 100px;
    display: flex;
    width: 90%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #f9fafb;
    padding: 64px 0px 64px 0px;

    .video {
      width: 100%;
      aspect-ratio: 16/9;
      background-color: black;
      margin-top: 64px;
    }

    .video-container-info {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 70%;

      @media (max-width: 1060px) {
        width: 80%;
      }

      h4 {
        /* Text md/Semibold */
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;

        /* identical to box height, or 150% */

        /* Primary/700 */
        color: #6941c6;
      }

      h2 {
        /* Display md/Semibold */
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
        text-align: center;

        width: 90%;

        /* identical to box height, or 122% */
        letter-spacing: -0.02em;

        /* Gray/900 */
        color: #101828;

        margin-bottom: 24px;
      }

      h3 {
        /* Text lg/Normal */
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        /* or 156% */

        /* Gray/500 */
        color: #667085;
      }
    }
  }

  .hourglass-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin: 96px 0px;

    .hourglass-image {
      width: 140px;
      height: 40px;
    }

    h2 {
      /* Display lg/Medium */
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 48px;
      line-height: 60px;

      /* or 125% */
      text-align: center;
      letter-spacing: -0.02em;

      /* Gray/900 */
      color: #101828;

      margin-top: 37px;
    }

    .client-image {
      display: flex;
      flex-direction: row;
      height: 64px;
      width: 64px;
      border-radius: 50%;
      margin-top: 32px;
    }

    .client-name {
      /* Text lg/Medium */
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;

      /* identical to box height, or 156% */
      text-align: center;

      /* Gray/900 */
      color: #101828;
      margin-top: 16px;
    }

    .client-description {
      /* Text md/Normal */
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      /* identical to box height, or 150% */
      text-align: center;

      /* Gray/500 */
      color: #667085;

      // margin-top: -10px;
    }
  }

  .create-profile-container {
    display: flex;
    width: 90%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 64px;
    background: #f9fafb;
    border-radius: 16px;

    //media max 1060px;
    @media (max-width: 1480px) {
      padding: 64px 32px;
    }

    @media (max-width: 1060px) {
      padding: 64px 32px;
      flex-direction: column;
    }

    .left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 65%;

      @media (max-width: 1060px) {
        width: 100%;
        margin-bottom: 32px;
      }

      h2 {
        /* Display sm/Semibold */
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 38px;

        /* identical to box height, or 127% */

        /* Gray/900 */
        color: #101828;
      }

      h4 {
        /* Text xl/Normal */
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;

        /* identical to box height, or 150% */

        /* Gray/500 */
        color: #667085;

        margin-bottom: 0px;
      }
    }

    .right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      width: 35%;

      @media (max-width: 1060px) {
        width: 100%;
        justify-content: space-between;
      }

      .lawyer-portal-button {
        display: inline-block !important;
        height: 45px !important;
        left: 0px !important;
        top: 0px !important;
        border-radius: 8px !important;
        padding: 5px 15px 5px 15px !important;
        color: #344054 !important;
        font-family: $fontFamily !important;
        @include get-font-weight($key: 'Medium');
        font-size: 14px !important;
        line-height: 20px !important;
        background-color: white !important;
        text-transform: none !important;
        border-radius: 9px !important;
        border: 1px solid #d6dae1 !important;
      }

      .register-button {
        display: inline-block !important;
        height: 44px !important;
        left: 0px !important;
        top: 0px !important;
        border-radius: 8px !important;
        padding: 5px 16px 5px 16px !important;
        color: white !important;
        font-family: $fontFamily !important;
        @include get-font-weight($key: 'Medium');
        font-size: 14px !important;
        line-height: 20px !important;
        margin-left: 15px !important;
        background-color: #7f56d9 !important;
        text-transform: none !important;
        border-radius: 9px !important;
      }
    }
  }

  .characteristics-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin: 96px 0px;

    .characteristics-info {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 60%;
      white-space: pre-wrap;

      @media (max-width: 1480px) {
        width: 80%;
      }

      @media (max-width: 1060px) {
        width: 100%;
      }

      h4 {
        /* Text sm/Medium */
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;

        /* identical to box height, or 143% */
        text-align: center;

        /* Primary/700 */
        color: #6941c6;

        background: #f9f5ff;
        border-radius: 16px;
        padding: 4px 12px;
      }

      h1 {
        /* Display md/Semibold */
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
        text-align: center;

        /* identical to box height, or 122% */
        letter-spacing: -0.02em;

        /* Gray/900 */
        color: #101828;
      }

      h3 {
        /* Text lg/Normal */
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        /* or 156% */

        /* Gray/500 */
        color: #667085;
      }
    }

    .mockup-container {
      display: flex;
      // width: 768px;
      // height: 512px;
      border: 1px solid #f2f4f7;
      border-radius: 10px;

      margin-top: 64px;

      border: 1px solid #d6dae1;

      @media (max-width: 1060px) {
        width: 100%;
        height: 100%;
      }

      .mockup-image {
        width: 100%;
        height: 100%;
      }
    }

    .characteristics {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 90%;
      height: 250px;
      margin-top: 96px;

      @media (max-width: 1060px) {
        flex-direction: column;
        gap: 32px;
        height: auto;
      }

      .value {
        display: flex;
        width: 30%;

        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media (max-width: 1480px) {
          justify-content: center;
          height: 100%;
        }

        @media (max-width: 1060px) {
          width: 100%;
          justify-content: space-between;
        }

        .image-container {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          /* Primary/600 */
          background: #f4ebff;

          /* Primary/700 */
          border: 8px solid #f9f5ff;
          border-radius: 28px;

          .image {
            max-height: 22px;
            // aspect-ratio: 1/1;
          }

          margin-bottom: 20px;
        }

        .value-title {
          /* Text xl/Medium */
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 30px;

          /* identical to box height, or 150% */
          text-align: center;

          /* Gray/900 */
          color: #101828;
        }

        .value-text {
          /* Text md/Normal */
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;

          /* or 150% */
          text-align: center;

          /* Gray/500 */
          color: #667085;
        }

        .button {
          background-color: transparent;
          height: 34px !important;
          text-transform: none !important;

          box-shadow: none !important;

          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;

          color: #6941c6;
        }
      }
    }
  }

  .lawp-home-page-experts-in-your-area-section {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 96px;
    background: #f9fafb;
    padding: 96px 30px;

    .left-container {
      width: 45%;

      .faqCard {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0px 32px 0px 0px;
        position: static;
        height: 126px;
        left: 0px;
        top: 0px;

        background: #ffffff;
        border-radius: 16px;
      }

      .component-card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 50px;
      }

      .component-text-card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        position: static;
        left: 0px;
        top: 0px;
      }

      .component-card-one-text-field {
        /* Text xl/Normal */
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;

        /* or 150% */

        /* Gray/500 */
        color: #667085;
      }

      .component-card-one-title {
        /* Display sm/Semibold */
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 38px;

        /* identical to box height, or 127% */

        /* Gray/900 */
        color: #101828;
      }

      .buttons-container {
        display: flex;
        width: 100%;
        height: 50px;
        align-items: center;
        justify-content: flex-start;
        margin-top: 20px;
      }

      .faq-button {
        display: inline-block !important;
        height: 45px !important;
        left: 0px !important;
        top: 0px !important;
        border-radius: 8px !important;
        padding: 5px 15px 5px 15px !important;
        color: #344054 !important;
        font-family: $fontFamily !important;
        @include get-font-weight($key: 'Medium');
        font-size: 14px !important;
        line-height: 20px !important;
        background-color: white !important;
        text-transform: none !important;
        border-radius: 9px !important;
        border: 1px solid #d6dae1 !important;
      }

      .lawyer-button {
        display: inline-block !important;
        height: 44px !important;
        left: 0px !important;
        top: 0px !important;
        border-radius: 8px !important;
        padding: 5px 16px 5px 16px !important;
        color: white !important;
        font-family: $fontFamily !important;
        @include get-font-weight($key: 'Medium');
        font-size: 14px !important;
        line-height: 20px !important;
        margin-left: 15px !important;
        background-color: #7f56d9 !important;
        text-transform: none !important;
        border-radius: 9px !important;
      }
    }

    .right-container {
      margin-left: 20px;
      width: 45%;
      height: 496px;
      min-width: 574px;
      display: flex;
      justify-content: flex-end;
      .images-group {
        height: 100%;
      }
    }

    @media (max-width: 1060px) {
      flex-direction: column-reverse;
      gap: 64px;

      .left-container {
        width: 100%;
      }
    }
  }

  .article-section {
    // width: 100%;
    width: 90%;
    margin-top: 96px;
    // margin-bottom: 96px;
  }

  .review-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 96px 0px;
    gap: 128px;
    width: 100%;
    background-color: #f9fafb;

    @media (max-width: 1060px) {
      flex-direction: column;
      gap: 64px;
      padding: 64px 32px;
    }

    .review-image {
      width: 328px;
      height: 328px;
      border-radius: 16px;
      object-fit: cover;
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-right: -10px;
      width: 55%;

      @media (max-width: 1480px) {
        width: 95%;
        margin-left: 0px;
        margin-right: 0px;
      }

      .review-text {
        margin-top: 23px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 36px;
        line-height: 44px;

        letter-spacing: -0.02em;

        color: #101828;
      }

      .name {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;

        color: #101828;
        margin-bottom: 0px;
      }

      .description {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        color: #667085;
      }
    }
  }

  .review-section-only-text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 96px 0px;
    width: 90%;

    @media (max-width: 1060px) {
      flex-direction: column;
      gap: 64px;
      padding: 64px 32px;
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 90%;

      @media (max-width: 1480px) {
        margin-left: 60px;
      }

      @media (max-width: 1060px) {
        width: 95%;
        margin-left: 0px;
        margin-right: 0px;
      }

      .review-text {
        // margin-top: 23px;
        /* Display md/Medium */
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 44px;

        /* or 122% */
        letter-spacing: -0.02em;

        /* Gray/900 */
        color: #101828;
      }

      .name {
        /* Text lg/Medium */
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;

        /* identical to box height, or 156% */

        /* Gray/900 */
        color: gray;
        margin-bottom: 0px;
      }

      .description {
        /* Text md/Normal */
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        /* identical to box height, or 150% */

        /* Gray/500 */
        color: #667085;
      }
    }
  }
}
