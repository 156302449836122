@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$phoneBreakpoint: map-get($screen-width-breakpoints, $key: 'phone');
$tabletBreakpoint: map-get($screen-width-breakpoints, $key: 'tablet');

.lawp-faq-page-top-container {
  display: flex;
  width: 100%;
  // height: 300px;
  align-items: center;
  flex-direction: column;
  background-color: #53389e;

  padding: 64px 0;

  @media (max-width: $phoneBreakpoint) {
    padding: 32px 0;
  }
}

.lawp-faq-page-top-container-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  height: 100%;

  @media (max-width: $phoneBreakpoint) {
    flex-direction: column;
    justify-content: center;
  }
}

.lawp-faq-page-top-container-content-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // width: 45%;

  @media (max-width: $phoneBreakpoint) {
    width: 100%;
  }

  h3 {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #e9d7fe;
  }

  h2 {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 60px;
    color: #e9d7fe;

    @media (max-width: $phoneBreakpoint) {
      font-size: 46px;
    }
  }
}

.lawp-faq-page-top-container-content-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 45%;

  @media (max-width: $phoneBreakpoint) {
    width: 100%;
  }

  p {
    margin-top: 60px;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #e9d7fe;
  }
}

.lawp-faq-page-bottom-container {
  margin-top: 100px;
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
}

.lawp-faq-page-bottom-container-content {
  display: flex;
  width: 90%;
  align-items: center;
  flex-direction: column;
}

.lawp-faq-page-faq-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: $phoneBreakpoint) {
    flex-direction: column;
  }

  .lawp-faq-page-faq-container-expandable-questions {
    width: 100%;

    @media (max-width: $phoneBreakpoint) {
      margin-top: 32px;
      width: 100%;
    }
  }
}

.lawp-faq-page-faq-container-info {
  width: 60%;

  @media (max-width: $phoneBreakpoint) {
    width: 90%;
  }

  h4 {
    /* Text md/Semibold */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    /* identical to box height, or 150% */

    /* Primary/700 */
    color: #6941c6;
  }

  h1,
  h2 {
    /* Display md/Semibold */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;

    /* identical to box height, or 122% */
    letter-spacing: -0.02em;

    /* Gray/900 */
    color: #101828;
  }

  h3 {
    /* Text lg/Normal */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;

    /* or 156% */

    /* Gray/500 */
    color: #667085;
  }
}

.lawp-faq-page-join-container {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 322px;

  background-color: #f9f5ff;

  h2 {
    /* Display md/Semibold */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;

    /* identical to box height, or 122% */
    text-align: center;
    letter-spacing: -0.02em;

    /* Primary/900 */
    color: #42307d;

    margin-bottom: 20px;
  }

  h3 {
    /* Text xl/Normal */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;

    padding: 0 30px;

    /* or 150% */
    text-align: center;

    /* Primary/700 */
    color: #6941c6;
  }
  .lawp-faq-page-join-container-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;

    .buttons-container {
      display: flex;
      width: 100%;
      height: 50px;
      align-items: center;
      justify-content: center;
      margin-top: 20px;

      .client-button {
        display: inline-block !important;
        height: 45px !important;
        left: 0px !important;
        top: 0px !important;
        border-radius: 8px !important;
        padding: 5px 15px 5px 15px !important;
        color: #344054 !important;
        font-family: $fontFamily !important;
        @include get-font-weight($key: 'Medium');
        font-size: 14px !important;
        line-height: 20px !important;
        background-color: white !important;
        text-transform: none !important;
        border-radius: 9px !important;
        border: 1px solid #d6dae1 !important;
      }

      .lawyer-button {
        display: inline-block !important;
        height: 44px !important;
        left: 0px !important;
        top: 0px !important;
        border-radius: 8px !important;
        padding: 5px 16px 5px 16px !important;
        color: white !important;
        font-family: $fontFamily !important;
        @include get-font-weight($key: 'Medium');
        font-size: 14px !important;
        line-height: 20px !important;
        margin-left: 15px !important;
        background-color: #7f56d9 !important;
        text-transform: none !important;
        border-radius: 9px !important;
      }
    }
  }
}

.lawp-faq-page-useful-information-container {
  margin-top: 100px;
  display: flex;
  width: 100%;
  align-items: left;
}

.lawp-faq-page-faq-information-container {
  margin-top: 50px;
  display: flex;
  width: 105%;
  margin-left: 6.2%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: $tabletBreakpoint) {
    flex-direction: column;
    width: 100%;
    margin-left: 0;
  }

  .content {
    display: flex;
    width: 50%;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: $tabletBreakpoint) {
      width: 100%;
    }

    .value {
      display: flex;
      width: 45%;

      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .image-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        /* Primary/600 */
        background: #f4ebff;

        /* Primary/700 */
        border: 8px solid #f9f5ff;
        border-radius: 28px;

        .image {
          max-height: 22px;
          // aspect-ratio: 1/1;
        }

        margin-bottom: 20px;
      }

      .value-title {
        /* Text xl/Medium */
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;

        /* identical to box height, or 150% */
        text-align: left;

        margin-bottom: 16px;

        /* Gray/900 */
        color: #101828;
      }

      .value-text {
        white-space: pre-line;
        /* Text md/Normal */
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        /* or 150% */
        text-align: left;

        margin-bottom: 16px;

        /* Gray/500 */
        color: #667085;
      }
    }
  }

  .mockup {
    display: flex;
    width: 40%;
    justify-content: center;
    align-items: center;

    @media (max-width: $tabletBreakpoint) {
      width: 100%;
      margin-top: 32px;
    }

    .gray-container {
      position: relative;
      height: 70%;
      width: 670px;
      background-color: #f2f4f7;
      border-top-left-radius: 24px;
      border-bottom-left-radius: 24px;
      right: 56px;
      // min-width: 800px;

      padding: 32px 0px 32px 16px;

      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: $tabletBreakpoint) {
        position: relative;
        width: 100%;
        justify-content: center;
        align-items: center;
        border-top-right-radius: 24px;
        border-bottom-right-radius: 24px;
        right: 0px;
        padding: 16px 0px;
      }
    }

    .iphone-mockup-container {
      position: relative;
      // top: -90px;
      left: 0;

      @media (max-width: $tabletBreakpoint) {
        top: 0px;
      }
    }

    .iphone-mockup {
      position: relative;
      top: 0;
      left: 0;
      // width: 753px;
      height: 445px;

      @media (max-width: $tabletBreakpoint) {
        height: 400px;
      }

      @media (max-width: $phoneBreakpoint) {
        transform: scale(0.8);
      }
    }

    .iphone-mockup-image {
      position: absolute;
      top: 13px;
      left: 69px;
      // width: 700px;
      width: 571px;
      height: 400px;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;

      @media (max-width: $tabletBreakpoint) {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        top: 15px;
        left: 60px;
        width: 533px;
        height: 345px;
      }

      @media (max-width: $phoneBreakpoint) {
        transform: scale(0.8);
      }

      object-fit: cover;
    }
  }
}
