@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.lawp-update-subscription-modal-button {
  cursor: pointer;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 8px;

  width: 320px;
  height: 48px;

  background: #7f56d9;

  border: 1px solid #7f56d9;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #ffffff;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.lawp-update-subscription-downgrade-modal-button {
  cursor: pointer;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 8px;

  width: 320px;
  height: 48px;

  background: #fee4e2;

  border: 1px solid #fef3f2;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #344054;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.lawp-update-subscription-modal {
  padding: 0px;

  display: block !important;
  max-height: 100%;
  // overflow-y: auto;

  background: rgba(52, 64, 84, 0.6);

  backdrop-filter: blur(16px);
}

.lawp-update-subscription-modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lawp-update-subscription-modal-snackbar {
  width: 100% !important;
  background-color: #7f56d9 !important;
  font-family: $fontFamily !important;
}
