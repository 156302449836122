@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.lawp-review-card-container {
  /* Bewertung Item */

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 32px;
  gap: 22.4px;

  width: 407.67px;
  max-width: 408px;
  height: 426.52px;

  /* White */
  background: #ffffff;

  /* Gray/200 */
  border: 0.933136px solid #e4e7ec;
  border-radius: 14.9302px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 1;

  margin: 20px;
}

.lawp-review-card-image-container {
  display: flex;
  flex-direction: row;

  // margin-bottom: -25px;
  // margin-top: -5px;
}

.lawp-review-card-image-container-placeholder {
  display: flex;
  flex-direction: row;

  // margin-bottom: -30px;
}

.lawp-review-card-menu-container {
  display: flex;
  width: 100%;
  height: 0px;
  justify-content: flex-end;
}

.lawp-review-card-center-content-container {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  align-items: center;
  width: 100%;
  // height: 80px;
  // min-height: 80px;

  gap: 10px;

  // margin-top: 10px;
  // margin-bottom: -25px;
}

.lawp-review-card-lawyer-specialization {
  // height: 20px;

  text-align: center;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  margin: 0;

  /* identical to box height, or 143% */

  /* Primary/700 */
  color: #6941c6;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  //no more than 2 lines
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  // margin-bottom: -16px;
}

.lawp-review-card-lawyer-image-container {
  display: flex;
}

.lawp-review-card-lawyer-image {
  aspect-ratio: 1/1;
  width: 120px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 0px;
}

.lawp-review-card-lawyer-name-text {
  font-size: 16px !important;
  // line-height: 32px !important;
  font-family: $fontFamily !important;
  @include get-font-weight($key: 'Medium');
  color: #101828;

  // margin-bottom: -18px;
}

.lawp-review-card-rating-container {
  // width: 200px;
  display: flex;
}

.lawp-review-card-rating-stars {
  margin-left: -3px;
}

.lawp-review-card-rating-text {
  margin-left: 8px;
  font-family: $fontFamily;
  @include get-font-weight($key: 'Medium');
  color: black;
  line-height: 24px;
  font-size: 16px;
}

.lawp-review-card-rating-reviews {
  margin-left: 8px;
  font-family: $fontFamily;
  @include get-font-weight($key: 'Regular');
  color: grey;
  font-size: 16px;
  line-height: 24px;
  white-space: pre;
}

.lawp-review-card-lawyer-description {
  margin-top: 10px !important;

  width: 100%;
}

.lawp-review-card-lawyer-description-text {
  font-family: $fontFamily;
  @include get-font-weight($key: 'Regular');
  color: grey;
  font-size: 15px;
  line-height: 22px;
  // text-overflow: ellipsis;
  // white-space: nowrap;

  // margin: 0;

  // height: 135px;

  --max-lines: 6;

  display: -webkit-box;
  overflow: hidden;
  // text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--max-lines);

  // display: -webkit-box !important;
  // -webkit-line-clamp: 6 !important;
  // -webkit-box-orient: vertical;
  // white-space: normal;
}
