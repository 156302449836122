@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$phoneBreakpoint: map-get($screen-width-breakpoints, $key: 'phone');
$tabletBreakpoint: map-get($screen-width-breakpoints, $key: 'tablet');

.lawyerDetailsCardOuterContainer {
  display: flex;
  flex-direction: row;
  // align-items: space-between;
  justify-content: center;

  // padding: 40px;
  padding: 5px 40px;

  width: 95%;

  @media (max-width: $tabletBreakpoint) {
    padding: 5px 40px;
  }
}

.lawyerDetailsCardInnerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 32px;
  gap: 24px;

  width: 100%;
  // height: 650px;
}

.lawyerDetailsCardTopContent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  width: 100%;

  gap: 20px;
  // height: 74px;
}

.lawyerDetailsCardOuterContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 20px;
}

.lawyerDetailsCardInnerContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lawyerDetailsCardBaseContent {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.lawyerDetailsCardDetailsTitle {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  font-family: $fontFamily;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
}

.lawyerDetailsCardDetailsText {
  width: 100%;
  // height: 20px;
  top: 32px;
  color: #667085;
  font-family: $fontFamily;
  font-size: 14px;
  line-height: 28px;
  margin: 4px 0px;
}

.lawyerDetailsCardDivider {
  width: 100%;
  height: 1px;

  background: #e4e7ec;
}

.lawyerDetailsCardBottomContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 0px;
  gap: 32px;

  width: 100%;
  // height: 488px;
}

.lawyerDetailsCardQuote {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #f9f5ff;
  border-radius: 8px;
  color: #6941c6;
  font-family: $fontFamily;

  padding: 16px;

  width: 100%;
}

.lawyerDetailsCardPointsTitle {
  color: #101828;
  font-family: $fontFamily;
  position: static;
  font-weight: 500;
  font-size: 16px;

  width: 100%;
  height: 24px;
}

.lawyerDetailsCardIntroductionText {
  color: #667085;
  font-family: $fontFamily;

  width: 100%;
}

.lawyerDetailsCardList {
  color: #667085;
  font-family: $fontFamily;

  width: 100%;

  a {
    color: #6941c6;

    text-decoration: none;
  }
}

.lawyerDetailsCardImageContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  flex-wrap: wrap;

  padding: 0;

  width: 100%;
}

.lawyerDetailsImage {
  width: 30% !important;
  margin-top: 30px;

  @media (max-width: $phoneBreakpoint) {
    width: 45% !important;
  }
}
