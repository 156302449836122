@import 'Assets/theme.scss';

$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.lawp-lawyer-section-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  box-sizing: border-box;
  // border: 1px solid red;

  padding: 96px 0;

  // margin-bottom: 30px;
}

.lawp-lawyer-section-public-top-container {
  display: flex;
  flex-direction: row;

  // width: 100%;
  width: 100%;
  margin-bottom: 64px;
}

.lawp-lawyer-section-public-title-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 70%;
  margin-left: 22px;

  box-sizing: border-box;
}

.lawp-lawyer-section-view-all-button-container {
  display: flex;
  justify-content: flex-end;
  width: 30%;

  box-sizing: border-box;
}

.lawp-lawyer-section-view-all-button {
  display: inline-block !important;
  height: 48px !important;

  padding: 5px 16px 5px 16px !important;
  color: white !important;
  font-family: $fontFamily !important;
  @include get-font-weight($key: 'Medium');
  font-size: 15px !important;
  line-height: 24px !important;

  border-radius: 8px !important;
  background-color: #7f56d9 !important;
  text-transform: none !important;
  border-radius: 7px !important;

  // margin-right: 112px !important;

  box-sizing: border-box !important;
}

.lawp-lawyer-section-client-name {
  height: 20px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  /* identical to box height, or 143% */

  /* Primary/700 */
  color: #6941c6;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  margin-bottom: 5px;
}

.lawp-lawyer-section-review-title {
  font-size: 24px !important;
  line-height: 32px !important;
  font-family: $fontFamily !important;
  @include get-font-weight($key: 'Medium');
  color: #101828;

  margin-bottom: -5px;
}

.lawp-lawyer-section-lawyer-description {
  margin-top: 10px !important;

  width: 100%;
}

.lawp-lawyer-section-lawyer-description-text {
  font-family: $fontFamily;
  @include get-font-weight($key: 'Regular');
  color: grey;
  font-size: 16px;
  line-height: 24px;
  // text-overflow: ellipsis;
  // white-space: nowrap;
  // overflow: hidden;
}

.lawp-lawyer-section-horizontal-scroll {
  // display: flex;
  // justify-content: flex-start;
  // flex-direction: row;
  // width: 100%;
  width: 100%;
  // margin-left: 300px;
  overflow: scroll;
  overflow: visible;
  overflow-y: hidden;
  overflow-x: hidden;
  scroll-behavior: smooth;

  position: relative;
  height: 500px;
}

.lawp-lawyer-inner-section-horizontal-scroll {
  width: fit-content;

  position: absolute;

  display: flex;
  justify-content: flex-start;
  flex-direction: row;

  cursor: pointer;
}

.lawp-lawyer-section-arrow-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  // width: 100%;
  width: 100%;
  margin-left: 42px;
  margin-top: 32px;

  .lawp-lawyer-section-arrow-buttons {
    margin-right: 32px !important;
  }

  .MuiSvgIcon-root {
    font-size: 26px;
  }
}

.lawp-lawyer-section-arrow-buttons {
  height: 56px;
  width: 56px;
  border: 1px solid #e4e7ec !important;
}
