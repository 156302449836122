@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

.permissionText {
  width: 100%;
  height: 50vh;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  line-height: 20px;
  font-family: $fontFamily;
  font-weight: 400 !important;
  color: gray;
}

.events-settings-container {
  display: block;
  width: 95.5%;
  margin: auto;
  margin-bottom: 50px;
}

.events-settings-top-menu {
  margin-top: 0px;
}

.events-settinngs-edit-save-changes-section {
  padding: 30px 30px 30px 60px;
}
