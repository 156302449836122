.not-favorite-lawyer {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11.1976px;
  gap: 7.47px;

  width: 41.06px;
  height: 41.06px;

  /* Primary/50 */

  background: #f9f5ff;
  /* Primary/50 */

  border: 0.933136px solid #f9f5ff;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 7.46509px;
}

.container-not-favorite-lawyer {
  width: 18.66px;
  height: 18.66px;
}

.favorite-lawyer {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11.1976px;
  gap: 7.47px;

  width: 41.06px;
  height: 41.06px;

  /* Primary/600 */

  background: #7f56d9;
  /* Primary/600 */

  border: 0.933136px solid #7f56d9;
  box-shadow: 0px 0.933136px 1.86627px rgba(16, 24, 40, 0.05);
  border-radius: 7.46509px;
}

.favorite-lawyer-img {
  width: 100%;
}
