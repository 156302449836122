@import 'Assets/theme.scss';

$primaryColor: map-get($theme-colors, $key: 'primaryColor');
$fontFamily: map-get($theme-font, $key: 'primaryFontFamily');

$phoneBreakpoint: map-get($screen-width-breakpoints, $key: 'phone');
$tabletBreakpoint: map-get($screen-width-breakpoints, $key: 'tablet');

.lawp-navbar-center {
  display: flex;
  align-items: center;
  z-index: 11;

  width: calc(100vw - 8px);

  min-width: 640px;

  .avatar-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;

    object-fit: cover;
  }
}

.lawp-navbar-menu {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  width: 100%;

  height: 80px;
  z-index: 8;
  border-bottom: 1px solid #e1e3e6;

  .logoContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .logoAnwado {
    padding-left: 5%;
    cursor: pointer;
    height: 44px;
  }
  .navbarButtons {
    padding-left: 5%;
    cursor: pointer;
  }

  .buttons {
    background: white;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;

    padding: 0 30px 0 17px;
    grid-gap: 12px;
    gap: 12px;

    height: 70%;
    width: 70%;

    @media (max-width: $phoneBreakpoint) {
      width: 30%;
    }
  }

  .lawyerButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;

    width: 172px;
    height: 44px;

    background-color: #f9f5ff;

    border: 1px solid #f9f5ff;

    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    color: #6941c6;
  }

  .loginbutton {
    display: inline-block;
    font-family: 'Inter';
    font-weight: 400;
    background-color: white;
    color: #667085;
    height: 44px;
    left: 0;
    top: 0;
    border-radius: 8px;
    border: none;
    cursor: pointer;
  }

  .registerbutton {
    display: inline-block;
    height: 44px;
    width: 109px;
    color: white;
    font-family: Inter;
    font-weight: 500;
    font-size: 14px;
    background-color: #6941c6;
    text-transform: none;
    border-radius: 9px;
    border: none;
    cursor: pointer;
  }

  .registerbutton:hover {
    background-color: #6941c6;
  }

  .logoutbutton {
    display: inline-block;
    height: 44px;
    width: 100px;
    color: white;
    font-family: Inter;
    font-weight: 500;
    font-size: 14px;
    margin-left: 15px;
    background-color: #6941c6;
    text-transform: none;
    border-radius: 9px;
    border: none;
    cursor: pointer;
  }

  .logoutbutton:hover {
    background-color: #6941c6;
  }

  .loggedInContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 16px;

    // height: 40px;
  }

  .loggedInActions {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;

    width: 128px;
    height: 40px;

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .nav-item-button {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px;
    gap: 105px;

    width: 40px;
    height: 40px;

    background: #ffffff;
    border-radius: 6px;
  }

  .search,
  .settings,
  .bell {
    width: 20px;
    height: 20px;
  }

  .dropdown {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    // padding: 0px;

    // padding-top: 7px;

    width: 70px;
    height: 50px;

    .MuiSvgIcon-root {
      width: 40px !important;
      height: 40px !important;
      color: #7f56d9 !important;
    }
  }

  .default-avatar,
  .default-header-avatar {
    padding: 0px;

    width: 30px;

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .default-header-avatar {
    border-radius: 200px;
    border: 4px solid #f4ebff;
  }

  .avatar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;

    width: 40px;
    height: 40px;

    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;

    border: 4px solid #f4ebff;
  }

  .dropdown-content {
    display: none;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 12;

    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1),
      0px 4px 6px -2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;

    padding: 2px;

    background: #ffffff;
  }

  // .dropdown:hover .dropdown-content {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   flex-flow: column wrap;
  //   position: absolute;
  //   top: 100%;
  //   right: 0;
  // }

  // for testing purposes
  .show {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column wrap;
    position: absolute;
    top: 100%;
    right: 0;
  }

  .dropdown-list-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 16px;

    width: 100%;
  }

  .dropdown-avatar-label-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;

    width: 193px;
    height: 40px;
  }

  .dropdown-avatar {
    position: relative;
    width: 40px;
    height: 40px;

    display: flex;
    justify-content: center;

    background: #f9f5ff;
    border-radius: 200px;
  }

  .dropdown-avatar-user {
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .avatar-online-indicator {
    position: absolute;
    width: 10px;
    height: 10px;
    right: 0px;
    bottom: 0px;

    background: #12b76a;

    border: 1.5px solid #ffffff;
    border-radius: 5px;
  }

  .dropdown-avatar-text-and-supporting-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px;

    width: 141px;
    height: 40px;
  }

  .dropdown-avatar-text {
    max-width: 141px;
    height: 20px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    color: #344054;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .dropdown-avatar-supporting-text {
    width: 100%;
    height: 20px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #667085;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .dropdown-divider {
    width: 100%;
    height: 1px;

    background: #f2f4f7;

    margin: 0px;
  }

  .dropdown-menu-base {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;

    width: 100%;

    background: #ffffff;
  }

  .public-profile-button,
  .settings,
  .logout {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 16px;
    margin-top: 3px;
    margin-bottom: 3px;

    width: 240px;
    height: 40px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    color: #344054;

    background: none;
    border: none;
  }
}
