@import "Assets/theme.scss";

$primaryColor: map-get($theme-colors, $key: "primaryColor");
$fontFamily: map-get($theme-font, $key: "primaryFontFamily");

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 96px 32px 48px;
    gap: 32px;

    position: relative;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 32px;

    width: 360px;
    // height: 368px;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 24px;

    width: 360px;
    // height: 240px;
}

.featured-icon {
    width: 56px;
    height: 56px;

    background: #D1FADF;

    border: 10px solid #ECFDF3;
    border-radius: 28px;

    display: flex;
    justify-content: center;
    align-items: center;

    flex: none;
    order: 0;
    flex-grow: 0;
}

.check-circle {
    position: absolute;
    width: 28px;
    height: 28px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.text-and-supporting-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;

    width: 360px;
    // height: 160px;
}

.text {
    width: 360px;
    // height: 76px;

    font-family: $fontFamily;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;

    margin: 0;

    text-align: center;

    color: #101828;
}

.supporting-text {
    width: 360px;
    // height: 72px;

    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    margin: 0;

    text-align: center;

    color: #667085;
}

.done-button {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;

    width: 360px;
    height: 44px;

    border-radius: 8px;
}

.done-button-base {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 18px;
    gap: 8px;

    width: 360px;
    height: 44px;

    background: #7F56D9;

    border: 1px solid #7F56D9;

    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
}

.done-button-text {
    width: 41px;
    height: 24px;

    font-family: $fontFamily;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    margin: 0;

    color: #FFFFFF;
}

.home-button {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;

    width: 189px;
    height: 20px;
}

.home-button-base {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 8px;

    background: none;
    border: none;

    width: 189px;
    height: 20px;
}

.home-button-text {
    width: 161px;
    height: 20px;

    font-family: $fontFamily;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    margin: 0;

    color: #667085;
}