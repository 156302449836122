.lawp-nav-bar-language-select-container {
  .language-icon {
    width: 20px !important;
    height: 20px !important;
    font-size: 20px !important;
  }
}

.MuiList-root {
  background: #f9fafb;
}

.MuiPaper-elevation8 {
  box-shadow: 8px 8px 10px -6px rgb(0 0 0 / 20%) !important;
}
