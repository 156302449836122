.calendar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  width: 280px;
  height: 356px;
}

.calendar-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 24px;
  gap: 16px;

  width: 328px;
  height: 396px;
}

/* lawp-calendar-calendar-content */

.calendar-header {
  position: relative;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  margin: 0;

  text-align: right;

  color: #344054;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 57px;

  width: 280px;
  height: 40px;
}

.button-left {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 8px;

  background: none;
  border: none;

  width: 40px;
  height: 40px;

  border-radius: 8px;
}

.button-right {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 8px;

  background: none;
  border: none;

  margin-left: auto;
  margin-right: 0;

  width: 40px;
  height: 40px;

  border-radius: 8px;
}

.month-year-text {
  width: auto;
  text-align: center;
  margin: 0;

  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.calendar-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 280px;
  height: 304px;
}

.table-header {
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.tables {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 657px;
  height: 396px;
}

.divider {
  width: 1px;
  height: 396px;

  background: #e4e7ec;
}

.weekday {
  margin: 0;
  width: 100px;
  text-align: center;
}

.weekday-names {
  margin: 0;
}

.table-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
  gap: 4px;

  width: 280px;
  height: 304px;
}

.calendar-day {
  width: 125px;
  height: 75px;
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 20px;

  background: none;

  display: flex;
  align-items: center;
  justify-content: center;
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
}

.calendar-day:hover {
  background: #f9f5ff;
}

.disabled:hover {
  background: none;
}

.calendar-day .day-text {
  margin: 0;
  position: absolute;
  /* right: 10px; */
  color: #a6a6a6;
}

.current .day-text {
  margin: 0;
  color: #344054;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
}

.day-of-month {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  background: #f2f4f7 !important;
  border-radius: 20px !important;
}

.available-days {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  background: #f9fafb !important;
  border-radius: 20px !important;
}

.selected .day-text {
  margin: 0;
  background: #7f56d9;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 20px;
}
